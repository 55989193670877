import { ITradeUnionMember } from 'app/api/tradeUnionMembers/model'
import { IPayloadUpdateUnit, PositionType, UnitType } from 'app/api/unit/model'
import R from 'app/assets/R'
import { IRefModal } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { usePagination } from 'app/hook'
import { useGetTradeUnionMember } from 'app/react-query/hook/tradeUnionMember'
import { useUpdateUnit } from 'app/react-query/hook/unit'
import { isEqual } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IProps } from './type'

export const useHook = ({ unit, reload }: IProps) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IPayloadUpdateUnit>()
  const modalRef = useRef<IRefModal>({})
  const [unitType, setUnitType] = useState<UnitType | undefined>(undefined)
  const { data: dataTradeUnionMember, isLoading: isLoadingTradeUnionMember } =
    useGetTradeUnionMember({ page: 1, limit: 100000 })

  const { flattenDataList } = usePagination()

  const tums: ITradeUnionMember[] = useMemo(() => {
    return flattenDataList(dataTradeUnionMember)?.data
  }, [dataTradeUnionMember])
  const { mutateAsync: mutateAsyncUpdate, isLoading } = useUpdateUnit()

  useEffect(() => {
    form?.setFieldsValue({
      name: unit?.name,
      descriptions: unit?.description,
      tradeUnionMemberIds:
        unit?.tradeUnionMembers?.map(item => +(item?.id ?? 0)) ?? [],
      unionLeaderId: unit?.positions?.find(po =>
        isEqual(po.type, PositionType.UNION_LEADER),
      )?.member?.id,
      unionPresidentId: unit?.positions?.find(po =>
        isEqual(po.type, PositionType.UNION_PRESIDENT),
      )?.member?.id,
      unionVicePresidentId: unit?.positions?.find(po =>
        isEqual(po.type, PositionType.UNION_VICE_PRESIDENT),
      )?.member?.id,
      unionMemberId: unit?.positions?.find(po =>
        isEqual(po.type, PositionType.UNION_MEMBER),
      )?.member?.id,
      type: unit?.type,
      schoolName: unit?.schoolName,
    })

    setUnitType(unit?.type)
  }, [unit])

  const handleSubmit = async (values: IPayloadUpdateUnit) => {
    const newUnit: IPayloadUpdateUnit = {
      id: unit?.id,
      ...values,
    }

    const res = await mutateAsyncUpdate?.(newUnit)
    if (res) {
      reload?.()
      modalRef?.current?.hide?.()
      form?.resetFields()
      notificationController.success({
        message: t(R.strings.success),
      })
    }
  }

  const onChangeUnitType = (value: UnitType) => {
    setUnitType(value)
  }

  return {
    form,
    modalRef,
    handleSubmit,
    isLoading,

    tums,
    isLoadingTradeUnionMember,
    unitType,
    onChangeUnitType,
  }
}
