import { memo } from 'react'
import { isEqual } from 'lodash'
import ReportContainer from 'app/containers/Report'
import { PageTitle } from 'app/components/common/PageTitle'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

const ReportCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.report)}</PageTitle>
      <ReportContainer />
    </>
  )
}
export const ReportPage = memo(ReportCpn, isEqual)
