import { TypeEventEnum } from 'app/api/event/model'
import { ITradeUnionMember } from 'app/api/tradeUnionMembers/model'
import { Dayjs } from 'dayjs'

export interface IFormUpdateEvent {
  name?: string
  startDate?: Dayjs | null
  endDate?: Dayjs | null
  description?: string
  startDateRegister?: Dayjs | null
  endDateRegister?: Dayjs | null
  link?: string
  type?: TypeEventEnum

  isHaveTeam?: boolean
}

export enum TypeColumn {
  STRING = 'string',
  DATE = 'date',
  NUMBER = 'number',
  OTHER = 'other',
}

export enum RewardTypeEnum {
  SINGLE = 'INDIVIDUAL',
  GROUP = 'COLLECTIVE',
}

export const DefineRewardType = {
  [RewardTypeEnum.SINGLE]: 'Cá nhân',
  [RewardTypeEnum.GROUP]: 'Tập thể',
}

export interface IColumnActivity {
  name?: string
  type?: TypeColumn
  keyOfMainData?: keyof ITradeUnionMember
}

export interface IDataTumActivity extends ITradeUnionMember {
  [key: string]: string | number | Date | undefined | null | boolean
}

export interface IRewardActivity {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  id?: any
  name?: string
  description?: string
  gift?: string
  type?: TypeColumn
  countPerReward?: number

  activity?: IActivityEvent
}

export interface ITeam {
  id?: number
  name?: string
  description?: string
  tums?: IDataTumActivity[]
}

export interface ILocation {
  id?: number
  address?: string
  long?: number
  lat?: number
  radius?: number
  key_map_check_in?: string
  coordinates?: string
}

export interface IActivityEvent {
  title?: string
  valueCare?: string
  columns?: IColumnActivity[]
  data?: IDataTumActivity[]
  rewards?: IRewardActivity[]

  teams?: ITeam[]
  locations?: ILocation[]
}
