import { ApiClient } from 'app/service/api-service'
import { RoleEndPoint } from './constant'
import {
  IDeleteRole,
  IParamsGetRole,
  IPayloadCreateRole,
  IPayloadUpdate,
  IUpdateStatusRole,
} from './model'

export const requestGetRoleList = (params: IParamsGetRole) =>
  ApiClient.Get({
    url: RoleEndPoint.ADMIN_GET_ROLE,
    params: { params },
  })

export const requestGetPermissionList = () =>
  ApiClient.Get({
    url: RoleEndPoint.ADMIN_GET_PERMISSION,
    params: {},
  })

export const requestPostRoleList = (body?: IPayloadCreateRole) =>
  ApiClient.Post({
    url: RoleEndPoint.ADMIN_CREATE_ROLE,
    body,
  })

export const requestUpdateRoleList = (body?: IPayloadUpdate) =>
  ApiClient.Put({
    url: RoleEndPoint.ADMIN_UPDATE_ROLE,
    body,
  })

// change status
export const requestChangeStatusRoleList = (body?: IUpdateStatusRole) =>
  ApiClient.Put({
    url: RoleEndPoint.ADMIN_CHANGE_STATUS_ROLE,
    body,
  })

// delete
export const requestDeleteRoleList = (body: IDeleteRole) =>
  ApiClient.Put({
    url: RoleEndPoint.ADMIN_DELETE_ROLE,
    body,
  })
