/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance } from 'antd'
import {
  IActionEvent,
  IEvent,
  IPayloadActionEvent,
  IPayloadCreateActionEvent,
  TypeEventEnum,
} from 'app/api/event/model'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { IRefModal } from 'app/components/common/ModalComponent'
import { notificationController } from 'app/controllers/notification-controller'
import { usePagination } from 'app/hook'
import {
  useGetActionByEvent,
  useUpdateActionEvent,
} from 'app/react-query/hook/event'
import { IResponseQueryList } from 'app/react-query/model/common'
import { isEqual } from 'lodash'
import {
  createContext,
  MutableRefObject,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useActivityHook } from './layouts/ActionEvent/hook'
import {
  IActivityEvent,
  IColumnActivity,
  IDataTumActivity,
  IFormUpdateEvent,
  IRewardActivity,
  TypeColumn,
} from './type'

interface IUpdateEventContext {
  form?: FormInstance<IFormUpdateEvent>
  modalRef?: MutableRefObject<IRefModal>

  openModal?: () => void
  closeModal?: () => void
  handleSubmit?: () => void

  onChangeInfoEvent?: (values: IFormUpdateEvent) => void
  isLoading?: boolean
  prevSteps?: () => void
  current?: number

  activities?: IActivityEvent[]
  addActivity?: (activity: IActivityEvent) => void
  updateTitleInActiveByIndex?: (index: number, title: string) => void
  updateValueCareInActiveByIndex?: (index: number, value: string) => void
  removeActivityByIndex?: (index: number) => void
  addColumnInActivityByIndex?: (index: number, column: IColumnActivity) => void
  removeColumnInActivityByIndex?: (
    activityIndex: number,
    columnIndex: number,
  ) => void
  updateColumnNameInActivityByIndex?: (
    activityIndex: number,
    columnIndex: number,
    name: string,
  ) => void
  updateColumnTypeInActivityByIndex?: (
    activityIndex: number,
    columnIndex: number,
    type: TypeColumn,
  ) => void
  updateDataTumInActivityByIndex?: (
    activityIndex: number,
    data: IDataTumActivity[],
  ) => void
  updateActivityByIndex?: (index: number, activity: IActivityEvent) => void

  updateRewardInActivityByIndex?: (
    activityIndex: number,
    rewardIndex: number,
    reward: IRewardActivity,
  ) => void

  deleteRewardInActivityByIndex?: (
    activityIndex: number,
    rewardIndex: number,
  ) => void

  addRewardInActivityByIndex?: (
    activityIndex: number,
    reward: IRewardActivity,
  ) => void

  isLoadingSubmit?: boolean

  eventType?: TypeEventEnum
  onChangeEventType?: (type: TypeEventEnum) => void
}
export const UpdateEventContext = createContext<IUpdateEventContext>({})

export const UpdateEventProvider = ({
  children,
  event,
}: {
  children?: ReactNode
  event?: IEvent
}) => {
  const [form] = BaseForm.useForm<IFormUpdateEvent>()
  const modalRef = useRef<IRefModal>({})
  const [eventType, setEventType] = useState<TypeEventEnum | undefined>(
    undefined,
  )
  const { flattenDataList } = usePagination()

  const {
    mutateAsync: mutateAsyncUpdateActionEvent,
    isLoading: isLoadingSubmit,
  } = useUpdateActionEvent()

  const [isLoading, setIsLoading] = useState(false)

  const { data: dataApi } = useGetActionByEvent({
    id: event?.id ?? 0,
    eventId: event?.id,
  })

  const dataActivity = useMemo(() => {
    const data: IResponseQueryList<IActionEvent[]> = flattenDataList(dataApi)

    const activityEvents: IActivityEvent[] =
      data?.data?.map((item, index) => {
        const columns = item?.columns?.map(column => {
          return {
            name: column?.name_column,
            keyOfMainData: column?.key_of_main_data,
            type: column?.type_column,
          } as IColumnActivity
        })

        const rewards = item?.rewards?.map(reward => {
          return {
            name: reward?.name,
            countPerReward: reward?.countPerReward,
            description: reward?.description,
            gift: reward?.gift,
            type: reward?.type,
          } as IRewardActivity
        })

        const dataR = item?.members?.map(member => {
          let data: IDataTumActivity = {
            ...member.tum,
            id: member?.tum?.id,
            checkedIn: member?.is_checked_in,
          }

          columns?.forEach(column => {
            data = {
              ...data,
              [column?.name ?? '']: member?.listMemberCategory?.find(
                category => category?.idCategory === column?.keyOfMainData,
              )?.value,
            }
          })

          return data
        })

        return {
          title: item?.name,
          valueCare: item?.valueCare ?? item?.members?.[0]?.value_care,
          columns,
          data: dataR,
          rewards,
          key: index + 1,
        }
      }) ?? []

    return activityEvents ?? []
  }, [dataApi])

  const openModal = () => {
    modalRef.current.open?.()
  }

  const closeModal = () => {
    modalRef.current.hide?.()
  }

  const activityHook = useActivityHook()

  const handleSubmit = async () => {
    if (!event?.id) {
      return
    }
    setIsLoading(true)
    try {
      if (isEqual(eventType, TypeEventEnum.ACTIVITY)) {
        const payloadCreateAction: IPayloadCreateActionEvent = {
          event_id: event?.id,
          actions:
            activityHook?.activities?.map(item => {
              return {
                activity: {
                  event_id: event?.id,
                  name: item?.title ?? '',
                },
                columns:
                  item?.columns?.map(column => {
                    return {
                      type: column?.type,
                      name: column?.name,
                      keyOfMainData: column?.keyOfMainData,
                    }
                  }) ?? [],
                tums:
                  item?.data?.map(member => ({
                    id: member?.id,
                    is_checked_in: member?.checkedIn,
                    columns: item?.columns?.map(col => {
                      const key = col?.keyOfMainData ?? col?.name ?? ''
                      const valueExist = member[key]
                      return {
                        name: key,
                        value: valueExist,
                      }
                    }),
                  })) ?? [],
                reward:
                  item?.rewards?.map(reward => ({
                    name: reward?.name,
                    type_prize: reward?.type,
                    countPerReward: reward?.countPerReward,
                    description: reward?.description,
                    gift: reward?.gift,
                  })) ?? [],
              } as IPayloadActionEvent
            }) ?? [],
        }

        const res = await mutateAsyncUpdateActionEvent?.(payloadCreateAction)

        if (res) {
          notificationController?.success?.({
            message: 'Cập nhật danh sách hoạt động thành công',
          })

          closeModal?.()
          window.location.reload()
        }
      } else if (isEqual(eventType, TypeEventEnum.NEWS)) {
        // do something
      } else {
        const payloadCreateAction: IPayloadCreateActionEvent = {
          event_id: event?.id,
          actions:
            activityHook?.activities?.map(item => {
              return {
                activity: {
                  event_id: event?.id,
                  name: item?.title ?? '',
                  description: '',
                },
                columns: [],
                tums: item?.data?.map(member => ({
                  id: member?.id,
                  columns: [],
                  valueCare: item?.valueCare,
                })),
                reward: [],
              } as IPayloadActionEvent
            }) ?? [],
        }

        const res = await mutateAsyncUpdateActionEvent?.(payloadCreateAction)

        if (res) {
          notificationController?.success?.({
            message: 'Cập nhật danh sách hoạt động thành công',
          })

          closeModal?.()
          window.location.reload()
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!event) {
      notificationController?.error?.({
        message: 'Không tìm thấy sự kiện',
      })
      closeModal?.()
      return
    }

    if (event) {
      setEventType(event?.type)
      activityHook?.handleInitActivity?.(dataActivity)
    }
  }, [event, dataActivity])

  const onChangeEventType = (type?: TypeEventEnum) => {
    setEventType(type)

    form?.setFieldsValue({
      link: undefined,
    })
  }

  return (
    <UpdateEventContext.Provider
      value={{
        form,
        modalRef,
        openModal,
        closeModal,
        handleSubmit,
        isLoading,
        eventType,
        onChangeEventType,
        isLoadingSubmit,
        ...activityHook,
      }}
    >
      {children}
    </UpdateEventContext.Provider>
  )
}

export function useUpdateEventContext() {
  const context = useContext(UpdateEventContext)

  if (context === undefined) {
    throw new Error('useUpdateEvent must be used within a UpdateEventProvider')
  }
  return context
}
