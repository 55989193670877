import { memo } from 'react'
import { isEqual } from 'lodash'
import {DetailUnitContainer} from 'app/containers/UnitManagement/DetailUnit'
import { PageTitle } from 'app/components/common/PageTitle'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

const UnitDetailCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.unit_detail)}</PageTitle>
      <DetailUnitContainer />
    </>
  )
}
export const UnitDetailPage = memo(UnitDetailCpn, isEqual)
