import { PlusOutlined } from '@ant-design/icons'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { useRef } from 'react'
import { ILocation } from '../../type'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import styled from 'styled-components'
import { useCreateEventContext } from '../../context'
import { getRandomUuid } from 'parkway-web-common'

function extractCoordinates(input) {
  // Xóa bỏ khoảng trắng thừa
  const trimmedInput = input.trim()

  // Tách chuỗi theo dấu phẩy
  const coordinates = trimmedInput.split(',')

  // Đảm bảo rằng chuỗi nhập có hai phần: vĩ độ và kinh độ
  if (coordinates.length !== 2) {
    throw new Error(
      'Invalid input. Please provide valid coordinates in the format "latitude, longitude".',
    )
  }

  // Chuyển đổi chuỗi thành số thực
  const latitude = parseFloat(coordinates[0])
  const longitude = parseFloat(coordinates[1])

  // Kiểm tra nếu giá trị là số
  if (isNaN(latitude) || isNaN(longitude)) {
    throw new Error(
      'Invalid input. Latitude and longitude must be valid numbers.',
    )
  }

  return { latitude, longitude }
}

export const ModalAddLocation = ({ index }: { index: number }) => {
  const { addLocationInActivityByIndex } = useCreateEventContext()
  const [form] = BaseForm.useForm<ILocation>()
  const modalRef = useRef<IRefModal>({})

  const openModal = () => {
    modalRef?.current?.open?.()
  }

  const handleSubmit = (values: ILocation) => {
    const { latitude, longitude } = extractCoordinates(values?.coordinates)

    addLocationInActivityByIndex?.(index, {
      ...values,
      lat: latitude,
      long: longitude,
      key_map_check_in: getRandomUuid(),
    })
    form?.resetFields()
    modalRef?.current?.hide?.()
  }

  return (
    <BaseModalComponent
      titleModal={'Thêm địa điểm'}
      handleSubmit={form?.submit}
      ref={modalRef}
      buttonOpenModal={
        <BaseButton icon={<PlusOutlined rev={undefined} />} onClick={openModal}>
          {'Thêm địa điểm'}
        </BaseButton>
      }
      renderContent={
        <BaseForm form={form} onFinish={handleSubmit}>
          <BaseForm.Item
            label={'Địa chỉ'}
            name={'address'}
            required
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập địa chỉ',
              },
            ]}
          >
            <BaseInput placeholder="Nhập địa chỉ" />
          </BaseForm.Item>

          <BaseForm.Item
            label={'Toạ độ'}
            name={'coordinates'}
            required
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập toạ độ',
              },
            ]}
          >
            <BaseInput placeholder="Nhập Toạ độ" />
          </BaseForm.Item>

          <BaseForm.Item
            label={'Bán kính (m)'}
            name={'radius'}
            required
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập bán kính',
              },
            ]}
            initialValue={1}
          >
            <InputNumberWrapper min={1} placeholder="Nhập bán kính" />
          </BaseForm.Item>
        </BaseForm>
      }
    />
  )
}

const InputNumberWrapper = styled(InputNumber)`
  width: 100%;
`
