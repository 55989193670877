/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestGetHrmTradeUnionMemberList,
  requestUpdateHrmTradeUnionMember,
} from 'app/api/tumHRM'

import {
  IParamsGetTradeUnionMemberHRM,
  IResponseTradeUnionMemberList,
  ITradeUnionMember
} from 'app/api/tradeUnionMembers/model'
import { getToken } from 'app/service/storage/storage-service'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { HrmTradeUnionMemberKeys } from '../query-key/tumHrm'

export function useGetHrmTradeUnionMember(
  params: IParamsGetTradeUnionMemberHRM,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<ITradeUnionMember[]>, undefined>(
    HrmTradeUnionMemberKeys.listHrmTradeUnionMember(params),
    async () => {
      try {
        const res: IResponseTradeUnionMemberList =
          await requestGetHrmTradeUnionMemberList(params)
        const data: ITradeUnionMember[] = res?.data?.rows || []
        
        return {
          data,
          total: res?.data?.total ?? 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export const useUpdateHrmTum = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, ITradeUnionMember, unknown>(
    async (body?: ITradeUnionMember) => {
      return await requestUpdateHrmTradeUnionMember(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: HrmTradeUnionMemberKeys.allHrmTradeUnionMember,
        })
      },
    },
  )
}
