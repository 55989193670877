import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseModalComponent } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import { useHook } from './hook'
import { DefinePermissionEnum } from 'app/api/role/constant'

export const CreateModalLayout = () => {
  const { t } = useTranslation()
  const {
    form,
    modalRef,
    handleSubmit,
    isLoading,
    isLoadingPermission,
    permissions,
  } = useHook()
  return (
    <BaseModalComponent
      titleModal={t(R.strings.add_new_role)}
      titleOpenModal={t(R.strings.add_new_role)}
      ref={modalRef}
      handleSubmit={form?.submit}
      borderRadiusButtonOpen={BORDER_RADIUS}
      borderButtonOpen={`1px solid ${convertedVariables.neutralBlack9Color}`}
      styleButtonOpen={{
        backgroundColor: convertedVariables?.backgroundColor,
      }}
      isLoadingConfirm={isLoading}
      renderContent={
        <BaseForm
          form={form}
          onFinish={handleSubmit}
          initialValues={{
            isActive: true,
          }}
        >
          <BaseSpace size={5}>
            <BaseForm.Item
              name={'name'}
              required
              label={t(R.strings.name_role)}
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.name_role),
                  }),
                },
              ]}
            >
              <BaseInput placeholder={'Nhập tên quyền'} />
            </BaseForm.Item>
            <BaseForm.Item
              name={'description'}
              label={t(R.strings.description)}
            >
              <BaseInput placeholder={t(R.strings.enter_description)} />
            </BaseForm.Item>

            <BaseForm.Item
              name={'permissionIds'}
              label={t(R.strings.permission)}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.permission),
                  }),
                },
              ]}
            >
              <BaseSelect
                placeholder={t(R.strings.select_permission)}
                showSearch
                loading={isLoadingPermission}
                mode="multiple"
                maxTagCount={2}
                options={permissions?.map(item => ({
                  label: item?.permission
                    ? t(DefinePermissionEnum?.[item?.permission]?.keyI18n)
                    : item?.name,
                  value: item?.id,
                }))}
              />
            </BaseForm.Item>
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}
