import { ApiClient } from 'app/service/api-service'
import { EventEndPoint } from './constant'
import {
  IDeleteEvent,
  IParamsGetActionByEvent,
  IParamsGetActionRegisteredByMember,
  IParamsGetEvents,
  IParamsGetMemberByAction,
  IPayloadChangeStatusMemberRegister,
  IPayloadCheckInActionByMember,
  IPayloadCreateActionEvent,
  IPayloadCreateEvent,
  IPayloadPushRewardTum,
  IPayloadRegisterActionByMember,
  IPayloadUpdateEvent,
} from './model'

export const requestGetEventList = (params: IParamsGetEvents) =>
  ApiClient.Get({
    url: EventEndPoint.ADMIN_GET_EVENT,
    params: { params },
  })

export const requestDownloadEventList = (params: IParamsGetEvents) =>
  ApiClient.Get({
    url: EventEndPoint.DOWNLOAD_EVENT_LIST,
    params: { params },
  })

export const requestGetEventListWithTum = (params: IParamsGetEvents) =>
  ApiClient.Get({
    url: EventEndPoint.ADMIN_GET_EVENT_WITH_TUM,
    params: { params },
  })

export const requestGetEventById = (id: number) =>
  ApiClient.Get({
    url: `${EventEndPoint.ADMIN_GET_EVENT_BY_ID}/${id}`,
    params: { id },
  })

export const requestGetActionById = (id: number) =>
  ApiClient.Get({
    url: EventEndPoint.GET_ACTION_EVENT_BY_ID,
    params: { params: { id } },
  })

export const requestDeleteEvent = (body: IDeleteEvent) =>
  ApiClient.Put({
    url: EventEndPoint.ADMIN_DELETE_EVENT,
    body: {
      listId: [body?.id],
    },
  })

export const requestUpdateEvent = (body: IPayloadUpdateEvent) =>
  ApiClient.Put({
    url: `${EventEndPoint.ADMIN_UPDATE_EVENT}`,
    body,
  })

export const requestCreateEvent = (body?: IPayloadCreateEvent) =>
  ApiClient.Post({
    url: EventEndPoint.ADMIN_CREATE_EVENT,
    body,
  })

export const requestGetActionByEvent = (params: IParamsGetActionByEvent) =>
  ApiClient.Get({
    url: `${EventEndPoint.ADMIN_GET_ACTION_BY_EVENT_ID?.replace(
      ':id',
      params?.id.toString(),
    )}`,
    params: { params },
  })

export const requestGetMemberOfPrize = (params: IParamsGetActionByEvent) =>
  ApiClient.Get({
    url: EventEndPoint.ADMIN_GET_MEMBER_BY_PRIZE,
    params: { params },
  })

export const requestGetTeamOfPrize = (params: IParamsGetActionByEvent) =>
  ApiClient.Get({
    url: EventEndPoint.ADMIN_GET_TEAM_BY_PRIZE,
    params: { params },
  })

export const requestGetMemberPrizeByEventId = (
  params: IParamsGetActionByEvent,
) =>
  ApiClient.Get({
    url: EventEndPoint.ADMIN_GET_MEMBER_PRIZE_BY_EVENT_ID,
    params: { params },
  })

export const requestGetTeamPrizeByEventId = (params: IParamsGetActionByEvent) =>
  ApiClient.Get({
    url: EventEndPoint.ADMIN_GET_TEAM_PRIZE_BY_EVENT_ID,
    params: { params },
  })

export const requestGetUnitPrizeByEventId = (params: IParamsGetActionByEvent) =>
  ApiClient.Get({
    url: `${EventEndPoint.ADMIN_GET_UNIT_PRIZE_BY_EVENT_ID?.replace(
      ':id',
      params?.id.toString(),
    )}`,
    params: { params },
  })

export const requestCreateActionByEvent = (body?: IPayloadCreateActionEvent) =>
  ApiClient.Post({
    url: EventEndPoint.ADMIN_CREATE_ACTION,
    body,
  })

export const requestUpdateActionByEvent = (body?: IPayloadCreateActionEvent) =>
  ApiClient.Put({
    url: EventEndPoint.ADMIN_UPDATE_ACTION,
    body,
  })

export const requestCreateActionByEventTypeActivity = (
  body?: IPayloadCreateActionEvent,
) =>
  ApiClient.Post({
    url: EventEndPoint.ADMIN_CREATE_ACTION_TYPE_ACTIVITY,
    body,
  })

export const requestUpdateActionByEventTypeActivity = (
  body?: IPayloadCreateActionEvent,
) =>
  ApiClient.Put({
    url: EventEndPoint.ADMIN_UPDATE_ACTION_TYPE_ACTIVITY,
    body,
  })

export const requestGetActionNotJoinByMemberId = (id: number) =>
  ApiClient.Get({
    url: EventEndPoint.GET_ACTION_NOT_JOIN_BY_MEMBER,
    params: { params: { id } },
  })

export const requestGetActionRegisteredByMember = (
  params: IParamsGetActionRegisteredByMember,
) =>
  ApiClient.Get({
    url: EventEndPoint.GET_REGISTER_ACTION_WAITING_BY_MEMBER,
    params: { params },
  })

export const requestGetMemberRegisterByActionId = (
  params: IParamsGetMemberByAction,
) =>
  ApiClient.Get({
    url: EventEndPoint.GET_REGISTER_MEMBER_WAITING_BY_ACTION,
    params: { params },
  })

export const requestChangeStatusMemberRegister = (
  body?: IPayloadChangeStatusMemberRegister,
) =>
  ApiClient.Put({
    url: EventEndPoint.REGISTER_MEMBER_WAITING_CHANGE_STATUS,
    body,
  })

export const requestRegisterActionByMember = (
  body: IPayloadRegisterActionByMember,
) =>
  ApiClient.Post({
    url: EventEndPoint.REGISTER_MEMBER_WITH_ACTION,
    body,
  })

export const requestCheckInAction = (body: IPayloadCheckInActionByMember) =>
  ApiClient.Put({
    url: EventEndPoint.ACTION_CHECK_IN,
    body,
  })

export const requestCheckInActionWithLocation = (
  body: IPayloadCheckInActionByMember,
) =>
  ApiClient.Put({
    url: EventEndPoint.ACTION_CHECK_IN_WITH_LOCATION,
    body,
  })

export const requestGetMemberByActivityId = (id: number) =>
  ApiClient.Get({
    url: `${EventEndPoint.ADMIN_GET_MEMBER_BY_ACTIVITY_ID}`,
    params: { params: { id } },
  })

// push prize
export const requestPushRewardTum = (body: IPayloadPushRewardTum) =>
  ApiClient.Put({
    url: EventEndPoint.ADMIN_PUSH_PRIZE,
    body,
  })

// ADD_TUM_TO_ACTIVITY
export const requestAddTumToActivity = (body: IPayloadRegisterActionByMember) =>
  ApiClient.Post({
    url: EventEndPoint.ADD_TUM_TO_ACTIVITY,
    body,
  })
