/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { TypeEventEnum } from 'app/api/event/model'
import { PlusOutlined } from '@ant-design/icons'
import { useGetTradeUnionMember } from 'app/react-query/hook/tradeUnionMember'
import { ITradeUnionMember } from 'app/api/tradeUnionMembers/model'
import { usePagination } from 'app/hook'
import { IResponseQueryList } from 'app/react-query/model/common'
import { IDataTumActivity } from 'app/containers/EventManagement/layouts/CreateEvent/type'
import { useCreateEventContext } from 'app/containers/EventManagement/layouts/CreateEvent/context'
import { ITeam } from 'app/containers/EventManagement/layouts/UpdateEvent/type'

interface IProps {
  data?: IDataTumActivity[]
  onSubmit?: (data: IDataTumActivity[]) => void
  otherTeams?: ITeam[]
}

export const ModalSelectTum = ({ data, onSubmit, otherTeams }: IProps) => {
  const { eventType } = useCreateEventContext()
  const modalRef = useRef<IRefModal>({})
  const [dataSelected, setDataSelected] = useState<IDataTumActivity[]>([])
  const [search, setSearch] = useState<string>('')
  const { flattenDataList } = usePagination()

  const { data: listTums, isLoading } = useGetTradeUnionMember({})

  const onSelected = (record: IDataTumActivity) => {
    const isSelected = dataSelected.some(item => item?.id === record?.id)

    if (isSelected) {
      setDataSelected(dataSelected.filter(item => item?.id !== record?.id))
    } else {
      setDataSelected([...dataSelected, record])
    }
  }

  const openModal = () => {
    modalRef?.current?.open?.()
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setDataSelected(data ?? [])
    }, 1000)

    return () => clearTimeout(timer)
  }, [data])

  const dataTable = useMemo(() => {
    const tumData: IResponseQueryList<ITradeUnionMember[]> =
      flattenDataList(listTums)
    let data = tumData?.data ?? []

    if (otherTeams?.length) {
      const listTumsOfOtherTeams = otherTeams.flatMap(team => team.tums)

      const teamIdsSet = new Set(listTumsOfOtherTeams?.map(item => item?.id))

      const availableCandidates =
        data.filter(candidate => !teamIdsSet.has(candidate?.id)) ?? []

      data = availableCandidates
    }

    return data?.filter(
      item =>
        item?.fullName?.toLowerCase().includes(search.toLowerCase()) ||
        item?.email?.toLowerCase().includes(search.toLowerCase()),
    )
  }, [search, listTums, otherTeams])

  const handleSubmit = () => {
    onSubmit?.(dataSelected)
    modalRef?.current?.hide?.()
    setDataSelected([])
  }

  const titleModal = useMemo(() => {
    switch (eventType) {
      case TypeEventEnum.TRAINING:
      case TypeEventEnum.ACTIVITY:
        return 'Chọn đoàn viên'
      case TypeEventEnum.CARE:
        return 'Chọn đối tượng được chăm lo'
      default:
        return 'Chọn đoàn viên'
    }
  }, [eventType])

  const textOpenModal = useMemo(() => {
    return dataSelected?.length ? `Cập nhật` : 'Thêm'
  }, [dataSelected])

  return (
    <BaseModalComponent
      titleModal={titleModal}
      ref={modalRef}
      buttonOpenModal={
        <BaseButton
          icon={data?.length ? null : <PlusOutlined rev={undefined} />}
          onClick={openModal}
        >
          {textOpenModal}
        </BaseButton>
      }
      widthModal={800}
      handleSubmit={handleSubmit}
      renderContent={
        <BaseSpace>
          <BaseFormItem label={'Tìm kiếm'}>
            <BaseInput
              value={search}
              onChange={e => setSearch(e?.target?.value)}
              placeholder="Tìm kiếm theo tên, email"
            />
          </BaseFormItem>

          <BaseText children={'Danh sách'} fontWeight="medium" fontSize="xs" />
          <BaseTableManagement
            columns={[
              convertColumnTable<IDataTumActivity>({
                key: 'fullName',
                title: 'Họ và tên',
                render: (text: string, record) => {
                  return (
                    <BaseText
                      children={`${text} ${
                        record?.is_active ? '' : '(Dừng công tác)'
                      }`}
                      fontWeight="medium"
                      colorText={
                        record?.is_active ? 'primaryColor' : 'statesRedColor'
                      }
                    />
                  )
                },
              }),
              convertColumnTable<IDataTumActivity>({
                key: 'email',
                title: 'Email',
                render: (text: string, record) => {
                  return (
                    <BaseText
                      children={text}
                      fontWeight="medium"
                      colorText={
                        record?.is_active ? 'primaryColor' : 'statesRedColor'
                      }
                    />
                  )
                },
              }),
              {
                key: 'id',
                title: '',
                width: '10%',

                dataIndex: 'id',
                render: (_, record: IDataTumActivity) => {
                  const isSelected = dataSelected.some(
                    item => item?.id === record?.id,
                  )
                  return (
                    <BaseCheckbox
                      value={isSelected}
                      checked={isSelected}
                      onClick={() => onSelected(record)}
                      disabled={!record?.is_active}
                    />
                  )
                },
              },
            ]}
            dataSource={dataTable}
            className="row-overwrite-style"
            scroll={{ x: true }}
            loading={isLoading}
            pagination={{
              pageSize: 5,
            }}
          />
        </BaseSpace>
      }
    />
  )
}

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`
