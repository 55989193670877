// Message code define

import R from 'app/assets/R'
import { PermissionEnum } from 'app/common/enum'

// Endpoint
export const RoleEndPoint = {
  ADMIN_GET_ROLE: 'admin/role/list',
  ADMIN_DELETE_ROLE: 'admin/role/delete',
  ADMIN_CREATE_ROLE: 'admin/role/create',
  ADMIN_UPDATE_ROLE: 'admin/role/update',
  ADMIN_CHANGE_STATUS_ROLE: 'admin/role/update-status',
  //permission
  ADMIN_GET_PERMISSION: 'admin/role/permission',
}

export const DefinePermissionEnum = {
  [PermissionEnum.CreateEvent]: {
    keyI18n: R.strings.permission_content_create_event,
  },
  [PermissionEnum.UpdateEvent]: {
    keyI18n: R.strings.permission_content_update_event,
  },
  [PermissionEnum.ReadEvent]: {
    keyI18n: R.strings.permission_content_read_event,
  },
  [PermissionEnum.DeleteEvent]: {
    keyI18n: R.strings.permission_content_delete_event,
  },
  [PermissionEnum.CreateAccount]: {
    keyI18n: R.strings.permission_content_create_account,
  },
  [PermissionEnum.UpdateAccount]: {
    keyI18n: R.strings.permission_content_update_account,
  },
  [PermissionEnum.ReadAccount]: {
    keyI18n: R.strings.permission_content_read_account,
  },
  [PermissionEnum.DeleteAccount]: {
    keyI18n: R.strings.permission_content_delete_account,
  },
  [PermissionEnum.UpdateRoleAccount]: {
    keyI18n: R.strings.permission_content_update_role_account,
  },
  [PermissionEnum.CreateRole]: {
    keyI18n: R.strings.permission_content_create_role,
  },
  [PermissionEnum.UpdateRole]: {
    keyI18n: R.strings.permission_content_update_role,
  },
  [PermissionEnum.ReadRole]: {
    keyI18n: R.strings.permission_content_read_role,
  },
  [PermissionEnum.DeleteRole]: {
    keyI18n: R.strings.permission_content_delete_role,
  },
  [PermissionEnum.ReadRolePermission]: {
    keyI18n: R.strings.permission_content_read_role_permission,
  },
  [PermissionEnum.ReadReport]: {
    keyI18n: R.strings.permission_content_read_report,
  },
  [PermissionEnum.UpdateTradeUnionMember]: {
    keyI18n: R.strings.permission_content_update_trade_union_member,
  },
  [PermissionEnum.ReadTradeUnionMember]: {
    keyI18n: R.strings.permission_content_read_trade_union_member,
  },
  [PermissionEnum.SyncTradeUnionMember]: {
    keyI18n: R.strings.permission_content_sync_trade_union_member,
  },
  [PermissionEnum.CreateRolePermission]: {
    keyI18n: R.strings.permission_content_create_role_permission,
  },
  [PermissionEnum.UpdateRolePermission]: {
    keyI18n: R.strings.permission_content_update_role_permission,
  },
  [PermissionEnum.DeleteRolePermission]: {
    keyI18n: R.strings.permission_content_delete_role_permission,
  },

  [PermissionEnum.CreateTradeUnionMember]: {
    keyI18n: R.strings.permission_content_create_trade_union_member,
  },
  [PermissionEnum.DeleteTradeUnionMember]: {
    keyI18n: R.strings.permission_content_delete_trade_union_member,
  },
  [PermissionEnum.ChangePassword]: {
    keyI18n: R.strings.permission_content_change_password,
  },
  
    // // new tum register permission
    // CreateNewTumRegister = 'create-new-tum-register',
    // ReadNewTumRegister = 'read-new-tum-register',
    // UpdateNewTumRegister = 'update-new-tum-register',
    // ApproveNewTumRegister = 'approve-new-tum-register',
  
    // // tum hrm permission
    // CreateTumHrm = 'create-tum-hrm',
    // ReadTumHrm = 'read-tum-hrm',
    // UpdateTumHrm = 'update-tum-hrm',
    // // unit permission
    // CreateUnit = 'create-unit',
    // ReadUnit = 'read-unit',
    // UpdateUnit = 'update-unit',
    // DeleteUnit = 'delete-unit',
    // ExportUnit = 'export-unit',

  [PermissionEnum.CreateNewTumRegister]: {
    keyI18n: R.strings.permission_content_create_new_tum_register,
  },
  [PermissionEnum.ReadNewTumRegister]: {
    keyI18n: R.strings.permission_content_read_new_tum_register,
  },
  [PermissionEnum.UpdateNewTumRegister]: {
    keyI18n: R.strings.permission_content_update_new_tum_register,
  },
  [PermissionEnum.ApproveNewTumRegister]: {
    keyI18n: R.strings.permission_content_approve_new_tum_register,
  },

  [PermissionEnum.CreateTumHrm]: {
    keyI18n: R.strings.permission_content_create_tum_hrm,
  },
  [PermissionEnum.ReadTumHrm]: {
    keyI18n: R.strings.permission_content_read_tum_hrm,
  },
  [PermissionEnum.UpdateTumHrm]: {
    keyI18n: R.strings.permission_content_update_tum_hrm,
  },

  [PermissionEnum.CreateUnit]: {
    keyI18n: R.strings.permission_content_create_unit,
  },
  [PermissionEnum.ReadUnit]: {
    keyI18n: R.strings.permission_content_read_unit,
  },
  [PermissionEnum.UpdateUnit]: {
    keyI18n: R.strings.permission_content_update_unit,
  },
  [PermissionEnum.DeleteUnit]: {
    keyI18n: R.strings.permission_content_delete_unit,
  },
  [PermissionEnum.ExportUnit]: {
    keyI18n: R.strings.permission_content_export_unit,
  },
}
