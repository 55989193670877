import { TypeEventEnum } from 'app/api/event/model'
import { ITradeUnionMember } from 'app/api/tradeUnionMembers/model'
import R from 'app/assets/R'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import BaseText from 'app/components/common/BaseText'
import { TRADE_UNION_MEMBER_DETAILS_PATH } from 'app/components/router/route-path'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useCheckInActionByMember } from 'app/react-query/hook/event'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
export const TumTable = ({
  data,
  eventType,
  activityId,
}: {
  data: ITradeUnionMember[]
  eventType?: TypeEventEnum
  activityId?: number
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { mutateAsync: mutateAsyncCheckIn, isLoading } =
    useCheckInActionByMember()

  const handleCheckIn = async ({
    value,
    tumId,
  }: {
    value: boolean
    tumId?: string
  }) => {
    if (!activityId) return

    if (!tumId) return

    await mutateAsyncCheckIn({
      value: value,
      actionId: activityId,
      tumId: tumId,
    })
  }

  console.log('data', data);
  

  return (
    <BaseTableManagement
      dataSource={data?.map((item, index) => ({
        ...item,
        index: index + 1,
        key: index + 1,
      }))}
      pagination={{
        pageSize: 10,
      }}
      columns={[
        convertColumnTable<{ index: number }>({
          key: 'index',
          title: 'STT',
          fixed: 'left',
          classNameWidthColumnOverwrite: 'number-column',
          render: (value: number) => (
            <BaseText children={value} opacity="0.7" fontWeight="medium" />
          ),
        }),
        convertColumnTable<ITradeUnionMember>({
          key: 'fullName',
          title: t(R.strings.full_name),
          fixed: 'left',
          render: (_, record) => {
            return (
              <RenderValueTable
                type="Link"
                value={record?.fullName}
                onClick={() =>
                  navigate(TRADE_UNION_MEMBER_DETAILS_PATH, {
                    state: {
                      item: record,
                    },
                  })
                }
              />
            )
          },
        }),
        convertColumnTable<ITradeUnionMember>({
          key: 'mql',
          title: t(R.strings.id_management),
          render: (_, record) => {
            return (
              <BaseText fontWeight="semibold" children={record?.mql ?? '-'} />
            )
          },
        }),

        convertColumnTable<ITradeUnionMember>({
          key: 'gioiTinh',
          title: t(R.strings.gender),
          render: (_, record) => {
            return (
              <BaseText
                fontWeight="medium"
                opacity="0.7"
                children={record?.gioiTinh ?? '-'}
              />
            )
          },
        }),
        convertColumnTable<ITradeUnionMember>({
          key: 'nguyenQuan',
          title: t(R.strings.domicile),
          render: (_, record) => {
            return (
              <BaseText
                fontWeight="medium"
                opacity="0.7"
                children={record?.nguyenQuan ?? '-'}
              />
            )
          },
        }),
        convertColumnTable<ITradeUnionMember>({
          key: 'cmnd',
          title: t(R.strings.CCCD),
          render: (_, record) => {
            return (
              <BaseText
                fontWeight="medium"
                opacity="0.7"
                children={record?.cmnd ?? '-'}
              />
            )
          },
        }),

        convertColumnTable<ITradeUnionMember>({
          key: 'email',
          title: t(R.strings.email),
          render: (_, record) => {
            return (
              <BaseText
                fontWeight="medium"
                opacity="0.7"
                children={record?.email ?? '-'}
              />
            )
          },
        }),
        convertColumnTable<ITradeUnionMember>({
          key: 'dienThoaiDiDong',
          title: t(R.strings.phone),
          render: (_, record) => {
            return (
              <BaseText
                fontWeight="medium"
                opacity="0.7"
                children={record?.dienThoaiDiDong ?? '-'}
              />
            )
          },
        }),
        ...(isEqual(eventType, TypeEventEnum.CARE)
          ? [
              convertColumnTable<{ valueCare?: string }>({
                key: 'valueCare',
                title: 'Phần quà',
                render: (value?: string) => (
                  <BaseText
                    children={value ?? '-'}
                    opacity="0.7"
                    fontWeight="medium"
                  />
                ),
              }),
            ]
          : [
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              convertColumnTable<any>({
                key: 'checkedIn',
                title: 'Check in',
                fixed: 'right',
                render: (_, record) => {
                  return (
                    <BaseCheckbox
                      checked={record?.checkedIn}
                      value={record?.checkedIn}
                      onChange={e =>
                        handleCheckIn({
                          value: e?.target?.checked,
                          tumId: record?.id,
                        })
                      }
                    />
                  )
                },
              }),
            ]),
      ]}
      loading={isLoading}
      scroll={{
        x: true,
      }}
    />
  )
}
