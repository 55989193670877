import { PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { PermissionEnum } from 'app/common/enum'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { CREATE_EVENT_PATH } from 'app/components/router/route-path'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { useVerifyPermission } from 'app/helpers/permission.helper'
import React, { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useHook } from './hook'
import { FilterLayout } from './layouts'
import * as S from './styles'

const EventManagementContainer: React.FC = () => {
  const {
    columns,
    data,
    isLoading,
    filter,
    onChangeFilter,
    page,
    handleChangeTable,
    pageSize,
    total,
    handleDownloadEventExcel,
  } = useHook()

  const navigate = useNavigate()

  const { user, verifyPermissionAndRedirect, verifyPermission } =
    useVerifyPermission()

  useEffect(() => {
    verifyPermissionAndRedirect(PermissionEnum.ReadEvent)
  }, [user])

  const isHavePermissionCreateEvent = useMemo(() => {
    return verifyPermission(PermissionEnum.CreateEvent)
  }, [user])

  const navigateToCreate = () => {
    navigate(CREATE_EVENT_PATH)
  }

  return (
    <S.ScrollWrapper>
      <HeaderPage
        titleI18nKey={R.strings.event_management}
        isExport
        rightComponent={
          isHavePermissionCreateEvent ? (
            <BaseCol>
              <BaseButton
                onClick={navigateToCreate}
                icon={<PlusOutlined rev={undefined} />}
              >
                {'Tạo mới sự kiện'}
              </BaseButton>
            </BaseCol>
          ) : null
        }
        onPressExport={handleDownloadEventExcel}
      />

      <SectionBase>
        <BaseSpace>
          <FilterLayout filter={filter} onChangeFilter={onChangeFilter} />
          <BaseTableManagement
            columns={columns}
            dataSource={data}
            loading={isLoading}
            pagination={{
              current: page,
              pageSize,
              total,
              showSizeChanger: true,
            }}
            rowClassName="row-overwrite-style"
            onChange={handleChangeTable}
            scroll={{
              x: true,
            }}
          />
        </BaseSpace>
      </SectionBase>
    </S.ScrollWrapper>
  )
}

export default EventManagementContainer
