import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { useMemo } from 'react'
import { useUpdateEventContext } from '../../../../context'
import { IActivityEvent, IDataTumActivity } from '../../../../type'
import { ModalSelectTum } from './layout'
import { ColumnTumActivityOfTeam } from './layout/ColumnTumOfTeam'
import { isEqual } from 'lodash'
import { BaseEmpty } from 'app/components/common/BaseEmpty'

export const TeamLayout = ({
  activity,
  indexAc,
}: {
  activity: IActivityEvent
  indexAc: number
}) => {
  const { deleteTeamInActivityByIndex, addTumToTeamByActivityIndex } =
    useUpdateEventContext()

  const data = useMemo(() => {
    return activity?.teams?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [activity?.teams])

  const handleDelete = (recordIndex?: number) => {
    if (recordIndex === undefined) return

    deleteTeamInActivityByIndex?.(indexAc, recordIndex)
  }

  const onDeleteTumByIndex = (teamIndex: number, tumIndex: number) => {
    const newData =
      data?.[teamIndex]?.tums?.filter((_, idx) => !isEqual(tumIndex, idx)) ?? []
    addTumToTeamByActivityIndex?.(indexAc, teamIndex, newData)
  }

  return (
    <BaseSpace>
      {data?.length ? data?.map((item, idx) => {
        const otherTeams = data?.filter((_, index) => !isEqual(index, idx))
        return (
          <BaseCollapse
            items={[
              {
                key: idx,
                label: <BaseText children={item?.name} fontWeight="semibold" />,
                children: (
                  <BaseSpace>
                    <BaseText
                      children={`Mô tả: ${item?.description ?? '-'}`}
                      fontSize="xs"
                    />

                    <BaseRow
                      gutter={[16, 8]}
                      align={'middle'}
                      justify={'space-between'}
                    >
                      <BaseCol>
                        <BaseText
                          children={'Danh sách thành viên'}
                          fontWeight="medium"
                          fontSize="xs"
                        />
                      </BaseCol>

                      <BaseCol>
                        <ModalSelectTum
                          data={item?.tums ?? []}
                          onSubmit={(data: IDataTumActivity[]) => {
                            addTumToTeamByActivityIndex?.(indexAc, idx, data)
                          }}
                          otherTeams={otherTeams}
                        />
                      </BaseCol>
                    </BaseRow>
                    <ColumnTumActivityOfTeam
                      team={item}
                      activity={activity}
                      index={idx}
                      onDeleteDataTum={tumIndex =>
                        onDeleteTumByIndex?.(idx, tumIndex)
                      }
                    />
                  </BaseSpace>
                ),
                extra: (
                  <div onClick={e => e?.stopPropagation()}>
                    <BaseRow align={'middle'} gutter={8}>
                      <BaseCol>
                        <BaseButton
                          icon={<SvgDeleteDesignIcon />}
                          onClick={() => handleDelete?.(idx)}
                          type="text"
                        />
                      </BaseCol>
                    </BaseRow>
                  </div>
                ),
                style: {
                  alignItems: 'center',
                },
              },
            ]}
          />
        )
      }) : <BaseEmpty description={"Danh sách đội/nhóm rỗng"}/>}
    </BaseSpace>
  )
}
