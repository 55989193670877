/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableProps } from 'antd'
import React from 'react'
import * as S from './styles'
export type BaseTableProps<T> = TableProps<T>

export const BaseTableManagement: React.FC<BaseTableProps<any>> = props => {
  return (
    <S.BaseTableManagementWrapper
      scroll={{ x: true, y: props?.dataSource?.length ? 580 : undefined }}
      {...props}
    />
  )
}
