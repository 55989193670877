import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseModalComponent } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHook } from './hook'
import { IProps } from './type'
import { DefinePermissionEnum } from 'app/api/role/constant'

export const DisplayPermissionLayout = (props: IProps) => {
  const { t } = useTranslation()
  const {
    handleCloseModal,
    modalRef,
    permissions,
    handleOpenModal,
    onChangeSearch,
    search,
  } = useHook(props)
  return (
    <BaseModalComponent
      titleModal={t(R.strings.permission)}
      ref={modalRef}
      handleSubmit={handleCloseModal}
      buttonOpenModal={
        <ButtonOpenModal onClick={handleOpenModal}>
          <BaseText
            children={`+${props?.count}`}
            colorText="collapseBackgroundColor"
            fontWeight="medium"
          />
        </ButtonOpenModal>
      }
      showCancelButton={false}
      renderContent={
        <BaseForm>
          <BaseSpace size={5}>
            <BaseForm.Item label={t(R.strings.search)}>
              <BaseInput
                placeholder={t(R.strings.enter_name_description_for_search)}
                value={search}
                onChange={e => onChangeSearch(e.target.value)}
              />
            </BaseForm.Item>

            <ListWrapper>
              {permissions?.map((item, index) => {
                return (
                  <ItemWrapper key={index}>
                    <BaseText
                      children={
                        item?.permission
                          ? t(DefinePermissionEnum?.[item?.permission]?.keyI18n)
                          : item?.name ?? '-'
                      }
                      fontWeight="medium"
                    />
                  </ItemWrapper>
                )
              })}
            </ListWrapper>
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}

const ListWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
  background-color: ${convertedVariables.neutralBlack9Color};
  max-height: 400px;
  overflow-y: auto;
`
const ItemWrapper = styled(BaseSpace)`
  padding: ${PADDING.xxxs} ${PADDING.xxs};
  border-radius: ${BORDER_RADIUS};
  background-color: ${convertedVariables.backgroundColor};
`
const ButtonOpenModal = styled.div`
  cursor: pointer;
  padding: 5px 10px;
  border-radius: ${PADDING.xl};
  background-color: ${convertedVariables.neutralBlack9Color};
`
