import { UnitEnum } from 'app/api/tradeUnionMembers/model'
import R from 'app/assets/R'
import { PermissionEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { useVerifyPermission } from 'app/helpers/permission.helper'
import React, { useEffect, useMemo } from 'react'
import { useHook } from './hook'
import { CreateModalLayout, FilterLayout, ListUnit } from './layouts'
import * as S from './styles'
import { ReviewTumModal } from '../TradeUnionMemberManagement/components'

const UnitManagementContainer: React.FC = () => {
  const {
    filter,
    onChangeFilter,
    downloadExcel,

    data,
  } = useHook()

  const { user, verifyPermissionAndRedirect, verifyPermission } =
    useVerifyPermission()

  useEffect(() => {
    verifyPermissionAndRedirect(PermissionEnum.ReadUnit)
  }, [user])

  const isCreateUnit = useMemo(() => {
    return verifyPermission(PermissionEnum.CreateUnit)
  }, [user])

  const isReviewTum = useMemo(() => {
    return verifyPermission(PermissionEnum.CreateNewTumRegister)
  }, [user])

  return (
    <S.ScrollWrapper>
      <HeaderPage
        titleI18nKey={R.strings.unit_management}
        rightComponent={
          <>
            {isReviewTum ? (
              <BaseCol>
                <ReviewTumModal />
              </BaseCol>
            ) : null}
            {isCreateUnit ? (
              <BaseCol>
                <CreateModalLayout />
              </BaseCol>
            ) : null}
          </>
        }
        isExport={verifyPermission(PermissionEnum.ReadUnit)}
        onPressExport={downloadExcel}
      />

      <SectionBase>
        <BaseSpace>
          <FilterLayout filter={filter} onChangeFilter={onChangeFilter} />

          {Object.values(UnitEnum).map((item, index) => {
            return (
              <BaseCollapse
                key={index}
                items={[
                  {
                    label: (
                      <BaseText
                        children={item}
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    ),
                    children: <ListUnit schoolName={item} filter={filter} />,
                    extra: (
                      <BaseText
                        children={`${data?.[item] ?? 0} Đơn vị`}
                        fontWeight="medium"
                        colorText="statesGreenColor"
                      />
                    ),
                  },
                ]}
              />
            )
          })}
        </BaseSpace>
      </SectionBase>
    </S.ScrollWrapper>
  )
}

export default UnitManagementContainer
