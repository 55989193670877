import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseEmpty } from 'app/components/common/BaseEmpty'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { useMemo } from 'react'
import { IActivityEvent } from '../../../CreateEvent/type'
import { ColumnTumActivityOfTeam } from './layout/ColumnTumOfTeam'

export const TeamLayout = ({ activity }: { activity: IActivityEvent }) => {
  const data = useMemo(() => {
    return activity?.teams?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [activity?.teams])

  return (
    <BaseSpace>
      {data?.length ? (
        data?.map((item, idx) => {
          return (
            <BaseCollapse
              items={[
                {
                  key: idx,
                  label: (
                    <BaseText children={item?.name} fontWeight="semibold" />
                  ),
                  children: (
                    <BaseSpace>
                      <BaseText
                        children={`Mô tả: ${item?.description ?? '-'}`}
                        fontSize="xs"
                      />

                      <BaseRow
                        gutter={[16, 8]}
                        align={'middle'}
                        justify={'space-between'}
                      >
                        <BaseCol>
                          <BaseText
                            children={'Danh sách thành viên'}
                            fontWeight="medium"
                            fontSize="xs"
                          />
                        </BaseCol>
                      </BaseRow>
                      <ColumnTumActivityOfTeam
                        team={item}
                        activity={activity}
                        index={idx}
                      />
                    </BaseSpace>
                  ),

                  style: {
                    alignItems: 'center',
                  },
                },
              ]}
            />
          )
        })
      ) : (
        <BaseEmpty description={'Danh sách đội/nhóm rỗng'} />
      )}
    </BaseSpace>
  )
}
