import { DownloadOutlined, ExportOutlined } from '@ant-design/icons'
import { StatusMemberRegisterWaiting, TypeEventEnum } from 'app/api/event/model'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseTabs } from 'app/components/common/BaseTabs'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Loading } from 'app/components/common/Loading'
import { SectionBase } from 'app/components/common/SectionBase'
import { isEmpty, isEqual } from 'lodash'
import QRCode from 'qrcode.react'
import { useHook } from './hook'
import {
  ActionRegisteredByStatusLayout,
  ColumnTumActivity,
  LocationLayout,
  RewardLayout,
  UpdateTumInActionEvent,
} from './layouts'
import { TumTable } from './layouts/TumTable'
import { IProps } from './type'
import { useVerifyPermission } from 'app/helpers/permission.helper'
import { PermissionEnum } from 'app/common/enum'
import { TeamLayout } from './layouts/Team'

export function ExpandedRowRender(props: IProps) {
  const { data, isLoading, tumData, downloadQRCode } = useHook(props)
  const { verifyPermission } = useVerifyPermission()

  return (
    <BaseForm>
      <SectionBase>
        {isEqual(props?.event?.type, TypeEventEnum.ACTIVITY) &&
        props?.event?.isHaveTeam ? (
          <BaseSpace>
            <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
              <BaseCol>
                <BaseText
                  children={'Danh sách hoạt động'}
                  fontWeight="semibold"
                  fontSize="xs"
                />
              </BaseCol>
            </BaseRow>

            {data?.length ? (
              data?.map((item, index) => {
                return (
                  <BaseCollapse
                    items={[
                      {
                        key: index,
                        label: (
                          <BaseText
                            children={
                              isEmpty(item?.title) ? 'Chưa có tên' : item?.title
                            }
                            fontWeight="semibold"
                            fontSize="xs"
                          />
                        ),
                        children: (
                          <BaseSpace>
                            <BaseRow
                              align={'middle'}
                              justify={'center'}
                              gutter={16}
                            >
                              <BaseCol>
                                <QRCode
                                  id="qrCodeEl"
                                  size={200}
                                  style={{
                                    height: 'auto',
                                    maxWidth: '200px',
                                    width: '200px',
                                  }}
                                  value={`https://api.ueh.thailairnd.com.vn/check-in-action/${item?.id}`}
                                  viewBox={`0 0 200 200`}
                                />
                              </BaseCol>
                              <BaseCol>
                                <BaseButton
                                  type="text"
                                  icon={<DownloadOutlined rev={undefined} />}
                                  onClick={downloadQRCode}
                                />
                              </BaseCol>
                            </BaseRow>
                            {props?.event?.type === TypeEventEnum.ACTIVITY ? (
                              <BaseSpace>
                                <BaseRow
                                  align={'middle'}
                                  justify={'space-between'}
                                  gutter={[16, 8]}
                                >
                                  <BaseCol>
                                    <BaseText
                                      children={'Danh sách đội/nhóm'}
                                      fontSize="xs"
                                      fontWeight="medium"
                                    />
                                  </BaseCol>
                                </BaseRow>
                                <TeamLayout activity={item} />
                              </BaseSpace>
                            ) : (
                              <BaseSpace>
                                <BaseRow
                                  align={'middle'}
                                  justify={'space-between'}
                                  gutter={[16, 8]}
                                >
                                  <BaseCol>
                                    <BaseText
                                      children={'Danh sách đối tượng tham gia'}
                                      fontSize="xs"
                                      fontWeight="medium"
                                    />
                                  </BaseCol>
                                </BaseRow>
                                <ColumnTumActivity activity={item} />
                              </BaseSpace>
                            )}

                            <BaseText
                              children={'Danh sách chờ phê duyệt'}
                              fontWeight="semibold"
                              fontSize="xs"
                            />

                            {verifyPermission(PermissionEnum.UpdateEvent) ? (
                              <BaseTabs
                                items={[
                                  {
                                    key: '1',
                                    label: 'Đang chờ xác nhận',
                                    children: (
                                      <ActionRegisteredByStatusLayout
                                        status={
                                          StatusMemberRegisterWaiting.WAITING
                                        }
                                        actionId={item?.id}
                                        listTeam={item?.teams ?? []}
                                        locations={item?.locations ?? []}
                                      />
                                    ),
                                  },
                                  {
                                    key: '2',
                                    label: 'Đã xác nhận',
                                    children: (
                                      <ActionRegisteredByStatusLayout
                                        status={
                                          StatusMemberRegisterWaiting.ACCEPTED
                                        }
                                        actionId={item?.id}
                                      />
                                    ),
                                  },
                                  {
                                    key: '3',
                                    label: 'Từ chối',
                                    children: (
                                      <ActionRegisteredByStatusLayout
                                        status={
                                          StatusMemberRegisterWaiting.REJECTED
                                        }
                                        actionId={item?.id}
                                      />
                                    ),
                                  },
                                ]}
                              />
                            ) : null}

                            <BaseSpace>
                              <BaseRow
                                align={'middle'}
                                justify={'space-between'}
                                gutter={[16, 8]}
                              >
                                <BaseCol>
                                  <BaseText
                                    children={
                                      'Danh sách địa điểm (dùng để check in)'
                                    }
                                    fontSize="xs"
                                    fontWeight="medium"
                                  />
                                </BaseCol>
                              </BaseRow>
                              <LocationLayout activity={item} />
                            </BaseSpace>

                            <BaseRow
                              gutter={[16, 8]}
                              align={'middle'}
                              justify={'space-between'}
                            >
                              <BaseCol>
                                <BaseText
                                  children={'Danh sách giải thưởng'}
                                  fontWeight="medium"
                                  fontSize="xs"
                                />
                              </BaseCol>
                            </BaseRow>

                            <RewardLayout activity={item} />
                          </BaseSpace>
                        ),

                        style: {
                          alignItems: 'center',
                        },
                      },
                    ]}
                    defaultActiveKey={index}
                    expandIconPosition="right"
                  />
                )
              })
            ) : (
              <BaseText
                children={'Danh sách hoạt động trống'}
                fontWeight="medium"
              />
            )}
          </BaseSpace>
        ) : (
          <BaseSpace>
            <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
              <BaseCol>
                <BaseText
                  children={'Danh sách hoạt động'}
                  fontWeight="semibold"
                  fontSize="xs"
                />
              </BaseCol>
              <BaseRow gutter={16} align={'middle'}>
                {verifyPermission(PermissionEnum.UpdateEvent) ? (
                  <BaseCol>
                    <UpdateTumInActionEvent event={props?.event} />
                  </BaseCol>
                ) : null}
                <BaseCol>
                  <BaseButton icon={<ExportOutlined rev={undefined} />}>
                    {'Export to excel'}
                  </BaseButton>
                </BaseCol>
              </BaseRow>
            </BaseRow>
            {tumData?.length ? (
              tumData?.map((item, index) => {
                return (
                  <BaseCollapse
                    items={[
                      {
                        key: index,
                        label: (
                          <BaseText
                            children={
                              isEmpty(item?.title) ? 'Chưa có tên' : item?.title
                            }
                            fontWeight="semibold"
                            fontSize="xs"
                          />
                        ),
                        children: (
                          <BaseSpace>
                            {isEqual(
                              props?.event?.type,
                              TypeEventEnum.TRAINING,
                            ) ||
                            isEqual(
                              props?.event?.type,
                              TypeEventEnum.ACTIVITY,
                            ) ? (
                              <BaseRow
                                align={'middle'}
                                justify={'center'}
                                gutter={16}
                              >
                                <BaseCol>
                                  <QRCode
                                    id="qrCodeEl"
                                    size={200}
                                    style={{
                                      height: 'auto',
                                      maxWidth: '200px',
                                      width: '200px',
                                    }}
                                    value={`https://api.ueh.thailairnd.com.vn/check-in-action/${item?.id}`}
                                    viewBox={`0 0 200 200`}
                                  />
                                </BaseCol>
                                <BaseCol>
                                  <BaseButton
                                    type="text"
                                    icon={<DownloadOutlined rev={undefined} />}
                                    onClick={downloadQRCode}
                                  />
                                </BaseCol>
                              </BaseRow>
                            ) : null}
                            <BaseRow
                              align={'middle'}
                              justify={'space-between'}
                              gutter={[16, 8]}
                            >
                              <BaseCol>
                                <BaseText
                                  children={(() => {
                                    switch (props?.event?.type) {
                                      case TypeEventEnum.CARE:
                                        return 'Danh sách đối tượng được chăm lo'
                                      case TypeEventEnum.TRAINING:
                                        return 'Danh sách đối tượng tham gia'
                                      case TypeEventEnum.ACTIVITY:
                                        return 'Danh sách đoàn viên tham gia'
                                      default:
                                        return 'Danh sách đối tượng tham gia'
                                    }
                                  })()}
                                  fontSize="xs"
                                  fontWeight="medium"
                                />
                              </BaseCol>
                            </BaseRow>

                            <TumTable
                              data={item?.infoData ?? []}
                              eventType={props?.event?.type}
                              activityId={item?.id}
                            />

                            {verifyPermission(PermissionEnum.UpdateEvent) &&
                            (isEqual(
                              props?.event?.type,
                              TypeEventEnum.TRAINING,
                            ) ||
                              isEqual(
                                props?.event?.type,
                                TypeEventEnum.ACTIVITY,
                              )) ? (
                              <>
                                <BaseText
                                  children={'Danh sách chờ phê duyệt'}
                                  fontWeight="semibold"
                                  fontSize="xs"
                                />
                                <BaseTabs
                                  items={[
                                    {
                                      key: '1',
                                      label: 'Đang chờ xác nhận',
                                      children: (
                                        <ActionRegisteredByStatusLayout
                                          status={
                                            StatusMemberRegisterWaiting.WAITING
                                          }
                                          actionId={item?.id}
                                        />
                                      ),
                                    },
                                    {
                                      key: '2',
                                      label: 'Đã xác nhận',
                                      children: (
                                        <ActionRegisteredByStatusLayout
                                          status={
                                            StatusMemberRegisterWaiting.ACCEPTED
                                          }
                                          actionId={item?.id}
                                        />
                                      ),
                                    },
                                    {
                                      key: '3',
                                      label: 'Từ chối',
                                      children: (
                                        <ActionRegisteredByStatusLayout
                                          status={
                                            StatusMemberRegisterWaiting.REJECTED
                                          }
                                          actionId={item?.id}
                                        />
                                      ),
                                    },
                                  ]}
                                />
                              </>
                            ) : null}

                            {isEqual(
                              props?.event?.type,
                              TypeEventEnum.ACTIVITY,
                            ) ? (
                              <>
                                <BaseSpace>
                                  <BaseRow
                                    align={'middle'}
                                    justify={'space-between'}
                                    gutter={[16, 8]}
                                  >
                                    <BaseCol>
                                      <BaseText
                                        children={
                                          'Danh sách địa điểm (dùng để check in)'
                                        }
                                        fontSize="xs"
                                        fontWeight="medium"
                                      />
                                    </BaseCol>
                                  </BaseRow>
                                  <LocationLayout activity={item} />
                                </BaseSpace>

                                <BaseRow
                                  gutter={[16, 8]}
                                  align={'middle'}
                                  justify={'space-between'}
                                >
                                  <BaseCol>
                                    <BaseText
                                      children={'Danh sách giải thưởng'}
                                      fontWeight="medium"
                                      fontSize="xs"
                                    />
                                  </BaseCol>
                                </BaseRow>

                                <RewardLayout activity={item} />
                              </>
                            ) : null}
                          </BaseSpace>
                        ),

                        style: {
                          alignItems: 'center',
                        },
                      },
                    ]}
                  />
                )
              })
            ) : (
              <BaseText
                children={'Danh sách hoạt động trống'}
                fontWeight="medium"
              />
            )}
          </BaseSpace>
        )}
        {isLoading ? <Loading isAbsolute size="small" /> : null}
      </SectionBase>
    </BaseForm>
  )
}
