export enum DegreeClassification {
  excellent = 'excellent',
  good = 'good',
  average_good = 'average_good',
  average = 'average',
  below_average = 'below_average',
  weak = 'weak',
  unknown = 'unknown',
}

export enum JobTitleGroup {
  doctor = 'doctor',
  nurse = 'nurse',
  callCenter = 'callcenter',
  other = 'other',
}

export enum EYesNo {
  no = 0,
  yes = 1,
}

export enum StatusEnum {
  ACTIVE = 1,
  INACTIVE = 2,
  BLOCK = 3,
  DELETED = 4,
}

export type StatusType = keyof typeof StatusEnum

export enum StatusOptionsCreateEnum {
  active = 'active',
  inactive = 'inactive',
}

export enum StatusOptionsUpdateEnum {
  active = 'active',
  inactive = 'inactive',
  deleted = 'deleted',
}

export enum GenderEnumKeyI18n {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}

export enum StatusTableEnum {
  active = 'active',
  deleted = 'deleted',
  error = 'error',
  inactive = 'inactive',
}

export enum PaymentOptionEnum {
  LatePaymentProcessing = 'LatePaymentProcessing',
  CompleteSettlement = 'CompleteSettlement',
}

export enum YesNoEnum {
  Yes = 'Yes',
  No = 'No',
}

export enum PaymentMethodEnum {
  Cash = 'Cash',
  Bank = 'Bank',
  EWallet = 'EWallet',
}

export enum PaymentOptionPurchaseProductServiceEnum {
  OneTimePayment = 'OneTimePayment',
  InstallmentPayment = 'InstallmentPayment',
  PaymentInStages = 'PaymentInStages',
}

export const ListStatusTable = Object.values(StatusTableEnum)

export type StatusTableType = keyof typeof StatusTableEnum

export enum PermissionEnum {
  CreateEvent = 'create-event',
  ReadEvent = 'read-event',
  UpdateEvent = 'update-event',
  DeleteEvent = 'delete-event',

  CreateAccount = 'create-account',
  ReadAccount = 'read-account',
  UpdateAccount = 'update-account',
  DeleteAccount = 'delete-account',
  UpdateRoleAccount = 'update-role-account',
  ChangePassword = 'change-password-account',

  //report
  ReadReport = 'read-report',

  //trade union member
  UpdateTradeUnionMember = 'update-trade-union-member',
  ReadTradeUnionMember = 'read-trade-union-member',
  SyncTradeUnionMember = 'sync-trade-union-member',
  CreateTradeUnionMember = 'create-trade-union-member',
  DeleteTradeUnionMember = 'delete-trade-union-member',

  // role management
  CreateRole = 'create-role',
  ReadRole = 'read-role',
  UpdateRole = 'update-role',
  DeleteRole = 'delete-role',
  ReadRolePermission = 'read-role-permission',
  UpdateRolePermission = 'update-role-permission',
  CreateRolePermission = 'create-role-permission',
  DeleteRolePermission = 'delete-role-permission',

  // new tum register permission
  CreateNewTumRegister = 'create-new-tum-register',
  ReadNewTumRegister = 'read-new-tum-register',
  UpdateNewTumRegister = 'update-new-tum-register',
  ApproveNewTumRegister = 'approve-new-tum-register',

  // tum hrm permission
  CreateTumHrm = 'create-tum-hrm',
  ReadTumHrm = 'read-tum-hrm',
  UpdateTumHrm = 'update-tum-hrm',
  // unit permission
  CreateUnit = 'create-unit',
  ReadUnit = 'read-unit',
  UpdateUnit = 'update-unit',
  DeleteUnit = 'delete-unit',
  ExportUnit = 'export-unit',
}
