import { IColumnActivity, TypeColumn } from "../../type";

export const initialColumnsActivity: IColumnActivity[] = [
    {
        name: 'Tên',
        type: TypeColumn.STRING,
        keyOfMainData: 'fullName'
    },
    {
        name: 'Email',
        type: TypeColumn.STRING,
        keyOfMainData: 'email'
    },
    {
        name: 'CCCD/CMND',
        type: TypeColumn.STRING,
        keyOfMainData: 'cmnd'
    },
    {
        name: 'Đơn Vị',
        type: TypeColumn.STRING,
        keyOfMainData: 'tenDonVi'
    },
    {
        name: 'Giới tính',
        type: TypeColumn.STRING,
        keyOfMainData: 'gioiTinh'
    },
] 