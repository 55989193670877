import { ITradeUnionMember } from 'app/api/tradeUnionMembers/model'
import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { TRADE_UNION_MEMBER_DETAILS_PATH } from 'app/components/router/route-path'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { StatusActivitiesMap } from 'app/containers/TradeUnionMemberManagement/type'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

export const ListTumLayout = ({
  tums,
  isLoading,
}: {
  tums?: ITradeUnionMember[]
  isLoading?: boolean
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <SectionBase title="Danh sách đoàn viên">
      <BaseTableManagement
        columns={[
          convertColumnTable<ITradeUnionMember>({
            key: 'fullName',
            title: t(R.strings.full_name),
            fixed: 'left',
            render: (_, record) => {
              return (
                <RenderValueTable
                  type="Link"
                  value={record?.fullName}
                  onClick={() =>
                    navigate(TRADE_UNION_MEMBER_DETAILS_PATH, {
                      state: {
                        tum: record,
                      },
                    })
                  }
                />
              )
            },
          }),
          convertColumnTable<ITradeUnionMember>({
            key: 'mql',
            title: t(R.strings.id_management),
            fixed: 'left',
            render: (_, record) => {
              return (
                <BaseText fontWeight="semibold" children={record?.mql ?? '-'} />
              )
            },
          }),
          convertColumnTable<ITradeUnionMember>({
            key: 'email',
            title: t(R.strings.email),
            render: (_, record) => {
              return (
                <BaseText
                  fontWeight="medium"
                  opacity="0.7"
                  children={record?.email ?? '-'}
                />
              )
            },
          }),

          convertColumnTable<ITradeUnionMember>({
            key: 'gioiTinh',
            title: t(R.strings.gender),
            classNameWidthColumnOverwrite: 'medium-column',
            render: (_, record) => {
              return (
                <BaseText
                  fontWeight="medium"
                  opacity="0.7"
                  children={record?.gioiTinh ?? '-'}
                />
              )
            },
          }),
          convertColumnTable<ITradeUnionMember>({
            key: 'nguyenQuan',
            title: t(R.strings.date_of_union_entry),
            render: (_, record) => {
              return (
                <BaseText
                  fontWeight="medium"
                  opacity="0.7"
                  children={record?.ngayVaoCongDoan ?? '-'}
                />
              )
            },
          }),
          convertColumnTable<ITradeUnionMember>({
            key: 'cmnd',
            title: t(R.strings.status_union_activities),
            render: (_, record) => {
              return (
                <BaseText
                  fontWeight="medium"
                  opacity="0.7"
                  children={
                    record?.tinhTrangSinhHoat
                      ? StatusActivitiesMap?.[record?.tinhTrangSinhHoat] ??
                        record?.tinhTrangSinhHoat ??
                        '-'
                      : ''
                  }
                />
              )
            },
          }),

          convertColumnTable<ITradeUnionMember>({
            key: 'dienThoaiDiDong',
            title: t(R.strings.phone),
            render: (_, record) => {
              return (
                <BaseText
                  fontWeight="medium"
                  opacity="0.7"
                  children={record?.dienThoaiDiDong ?? '-'}
                />
              )
            },
          }),
        ]}
        dataSource={tums}
        loading={isLoading}
      />
    </SectionBase>
  )
}
