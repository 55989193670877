// Endpoint
export const TradeUnionMemberEndpoint = {
  GET_TRADE_UNION_MEMBER: 'trade-union-member/get-all',
  TRADE_UNION_MEMBER_DELETE: 'trade-union-member/delete',
  TRADE_UNION_MEMBER_CREATE: 'trade-union-member/create',
  TRADE_UNION_MEMBER_UPDATE: 'trade-union-member/update',
  TRADE_UNION_MEMBER_GET_BY_ID: 'trade-union-member/get-by-id',

  // ueh area
  TRADE_UNION_MEMBER_SYNC: 'trade-union-member/sync-from-ueh',
  GET_DATA_FROM_UEH: 'trade-union-member/api/ueh',

  // get personal
  GET_ACTION_BY_TUM_ID: 'admin/activity/by-tum-id',
  GET_REWARD_BY_TUM_ID: 'admin/activity/reward-by-tum-id',

  // reward tum
  CREATE_REWARD_TUM: 'trade-union-member/create-reward-tum',
  DELETE_REWARD_TUM: 'trade-union-member/delete-reward-tum',
  REWARD_TUM_BY_TUM_ID: 'trade-union-member/reward-tum-by-tum-id',
}
