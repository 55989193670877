import { MoreOutlined } from '@ant-design/icons'
import {
  IParamsGetTradeUnionMember,
  ITradeUnionMember,
} from 'app/api/tradeUnionMembers/model'
import R from 'app/assets/R'
import { PermissionEnum } from 'app/common/enum'
import { BaseButton } from 'app/components/common/BaseButton'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { TRADE_UNION_MEMBER_DETAILS_PATH } from 'app/components/router/route-path'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useVerifyPermission } from 'app/helpers/permission.helper'
import { useDebounce, usePagination } from 'app/hook'
import { useGetTradeUnionMember } from 'app/react-query/hook/tradeUnionMember'
import { IResponseQueryList } from 'app/react-query/model/common'
import axios from 'axios'
import { Pagination, initialPagination } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { UpdateTumModal } from './components'
import { IDataTable, StatusActivitiesMap } from './type'

export const useHook = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [pagination, setPagination] = useState<Pagination>(initialPagination)
  const [filter, setFilter] = useState<IParamsGetTradeUnionMember>({})

  const filterDebounce = useDebounce(filter, 500)

  const { flattenDataList } = usePagination()
  const { data: dataApi, isLoading } = useGetTradeUnionMember({
    search: filterDebounce?.search,
    page: pagination?.current,
    limit: pagination?.pageSize,
    source: filterDebounce?.source,
    tinhTrangSinhHoat: filterDebounce?.tinhTrangSinhHoat,
  })

  const flattenData = useMemo(() => {
    const res: IResponseQueryList<ITradeUnionMember[]> =
      flattenDataList(dataApi)

    return res
  }, [dataApi])

  const data: IDataTable[] = useMemo(() => {
    return (
      flattenData?.data?.map((item, index) => ({
        ...item,
        key: index + 1,
      })) ?? []
    )
  }, [flattenData])

  const total = useMemo(() => {
    return flattenData?.total ?? data?.length
  }, [flattenData, data])

  const onChangeFilter = (newFilter: IParamsGetTradeUnionMember) => {
    setFilter(newFilter)
    setPagination(prev => ({ ...prev, current: 1 }))
  }

  const { user, verifyPermissionAndRedirect, verifyPermission } =
    useVerifyPermission()

  useEffect(() => {
    verifyPermissionAndRedirect(PermissionEnum.ReadTradeUnionMember)
  }, [user])

  // permission create
  const isPermissionCreate = useMemo(() => {
    return verifyPermission(PermissionEnum.CreateTradeUnionMember)
  }, [user])

  // permission sync
  const isPermissionSyncFromUeh = useMemo(() => {
    return verifyPermission(PermissionEnum.SyncTradeUnionMember)
  }, [user])

  // permission update
  const isPermissionUpdate = useMemo(() => {
    return verifyPermission(PermissionEnum.UpdateTradeUnionMember)
  }, [user])

  const isPermissionDownload = useMemo(() => {
    return verifyPermission(PermissionEnum.ReadTradeUnionMember)
  }, [user])

  // // permission delete
  // const isPermissionDelete = useMemo(() => {
  //   return verifyPermission(PermissionEnum.DeleteTradeUnionMember)
  // }, [user])

  const columns = useMemo(() => {
    return [
      convertColumnTable<ITradeUnionMember>({
        key: 'fullName',
        title: t(R.strings.full_name),
        fixed: 'left',
        render: (_, record) => {
          return (
            <RenderValueTable
              type="Link"
              value={record?.fullName}
              onClick={() =>
                navigate(TRADE_UNION_MEMBER_DETAILS_PATH, {
                  state: {
                    tum: record,
                  },
                })
              }
            />
          )
        },
      }),
      convertColumnTable<ITradeUnionMember>({
        key: 'mql',
        title: t(R.strings.id_management),
        fixed: 'left',
        render: (_, record) => {
          return (
            <BaseText fontWeight="semibold" children={record?.mql ?? '-'} />
          )
        },
      }),
      convertColumnTable<ITradeUnionMember>({
        key: 'email',
        title: t(R.strings.email),
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={record?.email ?? '-'}
            />
          )
        },
      }),
      convertColumnTable<ITradeUnionMember>({
        key: 'tenDonVi',
        title: 'Đơn vị UEH',
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={record?.tenDonVi}
            />
          )
        },
      }),
      convertColumnTable<ITradeUnionMember>({
        key: 'unit',
        title: 'Đơn vị công đoàn',
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={
                record?.unit?.name ?? '-'
              }
            />
          )
        },
      }),

      convertColumnTable<ITradeUnionMember>({
        key: 'gioiTinh',
        title: t(R.strings.gender),
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={record?.gioiTinh ?? '-'}
            />
          )
        },
      }),
      convertColumnTable<ITradeUnionMember>({
        key: 'nguyenQuan',
        title: t(R.strings.date_of_union_entry),
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={record?.ngayVaoCongDoan ?? '-'}
            />
          )
        },
      }),
      convertColumnTable<ITradeUnionMember>({
        key: 'cmnd',
        title: t(R.strings.status_union_activities),
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={
                record?.tinhTrangSinhHoat
                  ? StatusActivitiesMap?.[record?.tinhTrangSinhHoat] ??
                    record?.tinhTrangSinhHoat ??
                    '-'
                  : ''
              }
            />
          )
        },
      }),

      convertColumnTable<ITradeUnionMember>({
        key: 'dienThoaiDiDong',
        title: t(R.strings.phone),
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={record?.dienThoaiDiDong ?? '-'}
            />
          )
        },
      }),

      convertColumnTable<ITradeUnionMember>({
        key: 'is_sync',
        title: 'Nguồn dữ liệu',
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={record?.is_sync ? 'Từ UEH' : 'Từ hệ thống công đoàn'}
            />
          )
        },
      }),

      convertColumnTable<ITradeUnionMember>({
        key: 'id',
        classNameWidthColumnOverwrite: 'medium-column',
        fixed: 'right',
        render: (_, record) => {
          return (
            <BasePopover
              trigger={'click'}
              content={
                <BaseSpace>
                  {isPermissionUpdate ? <UpdateTumModal tum={record} /> : null}
                  {/* {isPermissionDelete ? (
                    <DeleteModalLayout
                      name={record?.fullName}
                      id={record?.id}
                    />
                  ) : null} */}
                </BaseSpace>
              }
            >
              <BaseButton icon={<MoreOutlined rev={undefined} />} type="text" />
            </BasePopover>
          )
        },
      }),
    ]
  }, [t])

  const handleChangePagination = (pagination: Pagination) => {
    setPagination(prev => ({ ...prev, ...pagination }))
  }

  const downloadExcel = async (): Promise<void> => {
    try {
      const response = await axios.get(
        `https://dev.api.ueh.cyberdx.digital/trade-union-member/download`,
        {
          responseType: 'blob', // Thiết lập kiểu phản hồi là blob
          params: {
            search: filterDebounce?.search,
            page: pagination?.current,
            limit: pagination?.pageSize,
            source: filterDebounce?.source,
            tinhTrangSinhHoat: filterDebounce?.tinhTrangSinhHoat,
          },
        },
      )

      // Tạo một URL blob từ dữ liệu file
      const url = window.URL.createObjectURL(new Blob([response.data]))

      // Tạo thẻ <a> để tự động tải file về máy
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'cong_doan_vien.xlsx') // Tên file Excel sẽ tải về
      document.body.appendChild(link)

      // Kích hoạt sự kiện click để tải file
      link.click()

      // Hủy URL sau khi tải xong
      link.parentNode?.removeChild(link) // Optional chaining để an toàn
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading Excel:', error)
    }
  }

  return {
    columns,
    pagination: {
      ...pagination,
      total,
    },
    onChangeFilter,
    filter,
    isLoading,
    total,
    handleChangePagination,
    data,
    isPermissionCreate,
    isPermissionSyncFromUeh,
    downloadExcel,
    isPermissionDownload,
  }
}
