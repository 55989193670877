import { ApiClient } from 'app/service/api-service'
import { TumHrmEndpoint } from './constant'
import { IParamsGetTradeUnionMember, ITradeUnionMember } from './model'

export const requestSyncTradeUnionMember = (body?: {
  data: ITradeUnionMember[]
}) =>
  ApiClient.Post({
    url: TumHrmEndpoint.TUM_HRM_SAVE_MANY,
    body,
  })

export const requestGetHrmTradeUnionMemberList = (
  params: IParamsGetTradeUnionMember,
) =>
  ApiClient.Get({
    url: TumHrmEndpoint.GET_TUM_HRM,
    params: { params },
  })

export const requestUpdateHrmTradeUnionMember = (body?: ITradeUnionMember) =>
  ApiClient.Put({
    url: TumHrmEndpoint.UPDATE_TUM_HRM,
    body,
  })
