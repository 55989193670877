import { ImportOutlined, SearchOutlined } from '@ant-design/icons'
import { requestGetMemberPrizeByEventId } from 'app/api/event'
import { IActionEvent, IEvent, IPrizeActionEvent } from 'app/api/event/model'
import { ITradeUnionMember } from 'app/api/tradeUnionMembers/model'
import { ENV_CONFIG } from 'app/common/config'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import axios from 'axios'
import { isEmpty } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'

interface IMemberPrizeInEvent {
  id?: number
  actionEventId?: number
  maso_doanvien?: string
  action: IActionEvent
  tum?: ITradeUnionMember
  reward?: IPrizeActionEvent
}

export const ListMemberPrizeLayout = ({ event }: { event?: IEvent }) => {
  const [data, setData] = useState<IMemberPrizeInEvent[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')

  const getData = async () => {
    if (!event?.id) return
    setLoading(true)
    try {
      const res: ResponseType<IMemberPrizeInEvent[]> =
        await requestGetMemberPrizeByEventId({
          id: event.id,
        })

      setData(res?.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getData()
    }, 1000)

    return () => clearTimeout(timer)
  }, [event])

  const dataSource = useMemo(() => {
    return data
      ?.filter(itm => {
        if (isEmpty(search)) return true

        const searchLowerCase = search.toLowerCase()

        if (itm?.tum?.fullName?.toLowerCase().includes(searchLowerCase))
          return true

        if (itm?.tum?.tenDonVi?.toLowerCase().includes(searchLowerCase))
          return true

        if (itm?.reward?.name?.toLowerCase().includes(searchLowerCase))
          return true
      })
      ?.map((item, index) => {
        return {
          ...item,
          key: index + 1,
          index: index + 1,
        }
      })
  }, [data, search])

  const downloadExcel = async (): Promise<void> => {
    if (!event?.id) return
    try {
      const response = await axios.get(
        `${ENV_CONFIG.API_ENDPOINT}admin/activity/tum-reward-by-event-id/download`,
        {
          responseType: 'blob',
          params: {
            id: event.id,
          },
        },
      )
      // Tạo một URL blob từ dữ liệu file
      const url = window.URL.createObjectURL(new Blob([response.data]))

      // Tạo thẻ <a> để tự động tải file về máy
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Danh sách đoàn viên đạt giải.xlsx') // Tên file Excel sẽ tải về
      document.body.appendChild(link)

      // Kích hoạt sự kiện click để tải file
      link.click()

      // Hủy URL sau khi tải xong
      link.parentNode?.removeChild(link) // Optional chaining để an toàn
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading Excel:', error)
    }
  }

  return (
    <BaseSpace>
      <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
        <BaseCol>
          <BaseText
            children={'Danh sách đoàn viên đoạt giải'}
            fontWeight="semibold"
            fontSize="xs"
          />
        </BaseCol>
        <BaseCol>
          <BaseButton
            icon={<ImportOutlined rev={undefined} />}
            onClick={downloadExcel}
          >
            {'Export to excel'}
          </BaseButton>
        </BaseCol>
      </BaseRow>

      <BaseRow>
        <BaseCol xl={8}>
          <BaseForm>
            <BaseForm.Item
              hiddenLabel
              style={{
                marginBottom: 0,
              }}
            >
              <BaseInput
                onChange={e => setSearch(e?.target?.value)}
                value={search}
                placeholder="Nhập tên đoàn viên, tên đơn vị, giải ..."
                allowClear
                suffix={<SearchOutlined rev={undefined} />}
              />
            </BaseForm.Item>
          </BaseForm>
        </BaseCol>
      </BaseRow>

      <BaseTableManagement
        dataSource={dataSource}
        loading={loading}
        pagination={{
          pageSize: 5,
        }}
        columns={[
          convertColumnTable({
            key: 'index',
            title: 'STT',
            classNameWidthColumnOverwrite: 'number-column',
            fixed: 'left',
            render: (value: number) => {
              return (
                <BaseText children={value} opacity="0.7" fontWeight="medium" />
              )
            },
          }),
          convertColumnTable<IMemberPrizeInEvent>({
            key: 'tum',
            title: 'Tên đoàn viên',
            fixed: 'left',
            classNameWidthColumnOverwrite: 'big-column',
            render: (_, record?: IMemberPrizeInEvent) => {
              return (
                <BaseText
                  children={record?.tum?.fullName ?? '-'}
                  opacity="0.7"
                  fontWeight="medium"
                />
              )
            },
          }),
          convertColumnTable<IMemberPrizeInEvent>({
            key: 'tum',
            title: 'Email',
            render: (_, record?: IMemberPrizeInEvent) => {
              return (
                <BaseText
                  children={record?.tum?.email ?? '-'}
                  opacity="0.7"
                  fontWeight="medium"
                />
              )
            },
          }),
          convertColumnTable<IMemberPrizeInEvent>({
            key: 'tum',
            title: 'CCCD/CMND',
            render: (_, record?: IMemberPrizeInEvent) => {
              return (
                <BaseText
                  children={record?.tum?.cmnd ?? '-'}
                  opacity="0.7"
                  fontWeight="medium"
                />
              )
            },
          }),
          convertColumnTable<IMemberPrizeInEvent>({
            key: 'tum',
            title: 'Đơn vị',
            render: (_, record?: IMemberPrizeInEvent) => {
              return (
                <BaseText
                  children={record?.tum?.tenDonVi ?? '-'}
                  opacity="0.7"
                  fontWeight="medium"
                />
              )
            },
          }),
          convertColumnTable<IMemberPrizeInEvent>({
            key: 'reward',
            title: 'Đạt giải',
            render: (_, record?: IMemberPrizeInEvent) => {
              return (
                <BaseText
                  children={record?.reward?.name ?? '-'}
                  opacity="0.7"
                  fontWeight="medium"
                />
              )
            },
          }),
        ]}
        className="row-overwrite-style"
        scroll={{
          x: true,
        }}
      />
    </BaseSpace>
  )
}
