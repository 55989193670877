import { requestGetDetailUnit } from 'app/api/unit'
import { IUnit } from 'app/api/unit/model'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { Loading } from 'app/components/common/Loading'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BORDER_RADIUS, PADDING, ResponseType } from 'parkway-web-common'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import { InfoLayout, ListTumLayout } from './layouts'
import { BaseCol } from 'app/components/common/BaseCol'
import { UpdateModalLayout } from '../layouts'
import { convertedVariables } from 'app/styles/themes/themeVariables'

export const DetailUnitContainer = () => {
  const { state } = useLocation()
  const [loading, setLoading] = useState<boolean>(false)
  const [detailUnit, setDetailUnit] = useState<IUnit | undefined>(state?.unit)

  const getData = async () => {
    try {
      setLoading(true)
      if (!state?.unit?.id) return

      const res: ResponseType<IUnit> = await requestGetDetailUnit(
        state?.unit?.id,
      )

      if (res?.data) {
        setDetailUnit(res?.data)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [state?.unit?.id])

  return (
    <Root>
      <HeaderPage
        title="Chi tiết đơn vị"
        rightComponent={
          detailUnit ? (
            <BaseCol
              style={{
                backgroundColor: convertedVariables.backgroundColor,
                borderRadius: BORDER_RADIUS,
              }}
            >
              <UpdateModalLayout unit={detailUnit} />
            </BaseCol>
          ) : undefined
        }
      />
      <BaseSpace>
        <InfoLayout unit={detailUnit} />
        <ListTumLayout tums={detailUnit?.tradeUnionMembers ?? []} />
      </BaseSpace>
      {loading ? <Loading /> : null}
    </Root>
  )
}

const Root = styled.div`
  padding: 0px ${PADDING.md};
  position: relative;
`
