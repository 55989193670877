
export enum ShowListType {
  LIST = 1,
  BLOCK,
}

export interface IChildReport {
  id: number
  label?: string
  labelI18nKey?: string
  description?: string
  descriptionI18nKey?: string
  updateAt: string
  path?: string
  isHideLabel?: boolean
}

export interface IGroupReport {
  id: number
  title?: string
  titleI18nKey?: string
  reports: IChildReport[]
}

export interface IProps {
  data: IGroupReport[]
  titleI18nKey: string
}
