// Message code define

import { UnitType } from "./model"


// Endpoint
export const UnitEndPoint = {
  UNIT: 'unit',
  DETAIL_UNIT: 'unit/by-id',
  UPDATE_UNIT: 'unit?id=unit_id',
  DELETE_UNIT: 'unit/sort-delete?id=unit_id',
  TOTAL: 'unit/count',
}


export const DefineUnitType = {
  [UnitType.DEPARTMENT]: 'Tổ công đoàn',
  [UnitType.UNION_GROUP]: 'Công đoàn bộ phận',
}