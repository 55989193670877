import { IActionEvent } from 'app/api/event/model'
import ContentLayout from './content'
import { UpdateEventProvider } from './context'

export const UpdateTumInActionEvent = ({ event }: { event: IActionEvent }) => {
  return (
    <UpdateEventProvider event={event}>
      <ContentLayout />
    </UpdateEventProvider>
  )
}
