import { IEvent, TypeEventEnum } from 'app/api/event/model'
import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseEmpty } from 'app/components/common/BaseEmpty'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { SectionBase } from 'app/components/common/SectionBase'
import { EventStatusEnum } from 'app/containers/EventManagement/type'
import { usePagination } from 'app/hook'
import { useGetEventWithTum } from 'app/react-query/hook/event'
import { useMemo } from 'react'
import styled from 'styled-components'

export const ListNewLayout = () => {
  const { data: dataNews, isLoading } = useGetEventWithTum({
    status: [EventStatusEnum.IN_PROGRESS],
    type: [TypeEventEnum.NEWS],
  })

  const { flattenDataList } = usePagination()

  const data: IEvent[] = useMemo(() => {
    return flattenDataList(dataNews)?.data || []
  }, [dataNews])

  return (
    <SectionBase title="Danh sách sự kiện tin tức">
      <BaseRow gutter={[16, 16]} align={'top'}>
        {data?.length ? (
          data?.map((item, index) => {
            return (
              <BaseCol key={index} xl={6}>
                <ItemNewsWrapper
                  onClick={() => {
                    window.open(item?.link, '_blank')
                  }}
                >
                  <BaseAvatarProfile
                    src={R.images.ic_ueh}
                    name={item?.name_event}
                    jobTitle="Tin tức công đoàn"
                  />

                  <BaseText
                    children={item?.description}
                    fontSize="xxs"
                    style={{
                      fontStyle: 'italic',
                    }}
                    opacity="0.8"
                  />
                </ItemNewsWrapper>
              </BaseCol>
            )
          })
        ) : (
          <BaseEmpty />
        )}
      </BaseRow>
      {isLoading ? <Loading isAbsolute size="small" /> : null}
    </SectionBase>
  )
}

const ItemNewsWrapper = styled(BaseSpace)`
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
`
