import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { useApproveTum } from 'app/react-query/hook/newTumRegister'
import { useRef } from 'react'
import { ENewTumRegisterStatus } from '../../type'

interface IProps {
  name?: string
  id?: string
}

export const ApproveTumModal = ({ name, id }: IProps) => {
  const modalRef = useRef<IRefModal>({})

  const { mutateAsync: mutateAsyncApprove, isLoading } = useApproveTum()

  const onSubmit = async () => {
    if (!id) {
      return
    }

    try {
      await mutateAsyncApprove({
        id: parseInt(id),
        status: ENewTumRegisterStatus.ACCEPTED,
      })
      modalRef.current?.hide?.()
    } catch (error) {
      console.log('Error:', error)
    }
  }

  return (
    <BaseModalComponent
      titleModal={'Xác nhận'}
      titleOpenModal="Phê duyệt"
      widthModal={400}
      ref={modalRef}
      handleSubmit={onSubmit}
      isLoadingConfirm={isLoading}
      renderContent={
        <BaseSpace>
          <BaseRow gutter={8} align={'middle'} justify={'center'}>
            <BaseCol>
              <BaseText fontWeight="medium" fontSize="xs">
                {'Bạn có thực sự muốn phê duyệt đoàn viên?'}
              </BaseText>
            </BaseCol>
            <BaseCol>
              <BaseText
                children={`"${name ?? ''}"`}
                colorText="statesRedColor"
                fontWeight="medium"
                fontSize="xs"
              />
            </BaseCol>
          </BaseRow>
        </BaseSpace>
      }
    />
  )
}
