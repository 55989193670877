import {
  IParamsGetTradeUnionMember,
  IParamsGetTradeUnionMemberHRM,
  ITradeUnionMember,
} from 'app/api/tradeUnionMembers/model'
import R from 'app/assets/R'
import { PermissionEnum } from 'app/common/enum'
import { BaseSwitch } from 'app/components/common/BaseSwitch'
import BaseText from 'app/components/common/BaseText'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useVerifyPermission } from 'app/helpers/permission.helper'
import { useDebounce, usePagination } from 'app/hook'
import {
  useGetHrmTradeUnionMember,
  useUpdateHrmTum,
} from 'app/react-query/hook/tumHrm'
import { IResponseQueryList } from 'app/react-query/model/common'
import { Pagination, initialPagination } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable } from './type'

export const useHook = () => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState<Pagination>(initialPagination)
  const [filter, setFilter] = useState<IParamsGetTradeUnionMemberHRM>({})

  const filterDebounce = useDebounce(filter, 500)

  const { flattenDataList } = usePagination()
  const { data: dataApi, isLoading } = useGetHrmTradeUnionMember({
    search: filterDebounce?.search,
    page: pagination?.current,
    limit: pagination?.pageSize,
    isSync: filterDebounce?.isSync,
    unit: filterDebounce?.unit,
  })

  const { mutateAsync: update, isLoading: isLoadingUpdate } = useUpdateHrmTum()

  const flattenData = useMemo(() => {
    const res: IResponseQueryList<ITradeUnionMember[]> =
      flattenDataList(dataApi)

    return res
  }, [dataApi])

  const data: IDataTable[] = useMemo(() => {
    return (
      flattenData?.data?.map((item, index) => ({
        ...item,
        key: index + 1,
      })) ?? []
    )
  }, [flattenData])

  const total = useMemo(() => {
    return flattenData?.total ?? data?.length
  }, [flattenData, data])

  const onChangeFilter = (newFilter: IParamsGetTradeUnionMember) => {
    setFilter(newFilter)
    setPagination(prev => ({ ...prev, current: 1 }))
  }

  const { user, verifyPermissionAndRedirect, verifyPermission } =
    useVerifyPermission()

  useEffect(() => {
    verifyPermissionAndRedirect(PermissionEnum.ReadTumHrm)
  }, [user])

  const isPermissionSyncFromUeh = useMemo(() => {
    return verifyPermission(PermissionEnum.SyncTradeUnionMember)
  }, [user])

  const isPermissionUpdate = useMemo(() => {
    return verifyPermission(PermissionEnum.UpdateTumHrm)
  }, [user])

  const handleUpdate = async (record: ITradeUnionMember) => {
    await update({ id: record?.id, isSync: !record.isSync })
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<ITradeUnionMember>({
        key: 'fullName',
        title: t(R.strings.full_name),
        fixed: 'left',
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="semibold"
              children={record?.fullName ?? '-'}
            />
          )
        },
      }),
      convertColumnTable<ITradeUnionMember>({
        key: 'mql',
        title: t(R.strings.id_management),
        fixed: 'left',
        render: (_, record) => {
          return (
            <BaseText fontWeight="semibold" children={record?.mql ?? '-'} />
          )
        },
      }),
      convertColumnTable<ITradeUnionMember>({
        key: 'email',
        title: t(R.strings.email),
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={record?.email ?? '-'}
            />
          )
        },
      }),
      convertColumnTable<ITradeUnionMember>({
        key: 'tenDonVi',
        title: 'Đơn vị',
        render: text => {
          return <BaseText fontWeight="medium" opacity="0.7" children={text} />
        },
      }),

      convertColumnTable<ITradeUnionMember>({
        key: 'gioiTinh',
        title: t(R.strings.gender),
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={record?.gioiTinh ?? '-'}
            />
          )
        },
      }),

      convertColumnTable<ITradeUnionMember>({
        key: 'dienThoaiDiDong',
        title: t(R.strings.phone),
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={record?.dienThoaiDiDong ?? '-'}
            />
          )
        },
      }),

      ...(isPermissionUpdate
        ? [
            convertColumnTable<ITradeUnionMember>({
              key: 'id',
              fixed: 'right',
              title: 'Đánh dấu được phép đồng bộ',
              render: (_, record) => {
                return (
                  <BaseSwitch
                    checked={record?.isSync}
                    onChange={() => {
                      handleUpdate(record)
                    }}
                  />
                )
              },
            }),
          ]
        : []),
    ]
  }, [t])

  const handleChangePagination = (pagination: Pagination) => {
    setPagination(prev => ({ ...prev, ...pagination }))
  }

  return {
    columns,
    pagination: {
      ...pagination,
      total,
    },
    onChangeFilter,
    filter,
    isLoading: isLoading || isLoadingUpdate,
    total,
    handleChangePagination,
    data,
    isPermissionSyncFromUeh,
  }
}
