import { IActionEvent } from 'app/api/event/model'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { PADDING } from 'parkway-web-common'
import styled from 'styled-components'
import { IRewardActivity } from '../EventManagement/layouts/UpdateEvent/type'
import { useHook } from './hook'
import {
  AccountInfoLayout,
  ChangePasswordModalLayout,
  OtherRewardLayout,
  TumInfoLayout,
} from './layouts'

const ProfileContainer = () => {
  const { dataReward, isLoading, listActivityData, tum, user } = useHook()
  return (
    <RootWrapper>
      <HeaderPage
        title="Thông tin cá nhân"
        rightComponent={<ChangePasswordModalLayout />}
      />

      <BaseSpace>
        <AccountInfoLayout user={user} />
        {tum?.id ? (
          <BaseSpace>
            <TumInfoLayout tum={tum} />
            <SectionBase title="Giải thưởng cá nhân">
              <BaseSpace>
                <BaseTableManagement
                  columns={[
                    convertColumnTable<IRewardActivity>({
                      key: 'id',
                      title: 'STT',
                      classNameWidthColumnOverwrite: 'number-column',
                      render: (_, record, index) => {
                        return (
                          <BaseText
                            fontWeight="medium"
                            opacity="0.7"
                            children={index + 1}
                          />
                        )
                      },
                    }),
                    convertColumnTable<IRewardActivity>({
                      key: 'name',
                      title: 'Tên giải',
                      classNameWidthColumnOverwrite: 'very-big-column',
                      render: (_, record) => {
                        return (
                          <BaseText
                            fontWeight="semibold"
                            children={record?.name || '-'}
                          />
                        )
                      },
                    }),
                    convertColumnTable<IRewardActivity>({
                      key: 'activity',
                      title: 'Tên hoạt động',
                      classNameWidthColumnOverwrite: 'very-big-column',
                      render: (_, record) => {
                        return (
                          <BaseText
                            fontWeight="medium"
                            children={record?.activity?.title ?? '-'}
                            colorText="statesGreenColor"
                          />
                        )
                      },
                    }),
                  ]}
                  className="row-overwrite-style"
                  dataSource={dataReward}
                  loading={isLoading}
                  pagination={{
                    pageSize: 5,
                  }}
                />
                <OtherRewardLayout />
              </BaseSpace>
            </SectionBase>

            <SectionBase title="Danh sách hoạt động/sự kiện đã tham gia">
              <BaseTableManagement
                columns={[
                  convertColumnTable<IActionEvent>({
                    key: 'id',
                    title: 'STT',
                    classNameWidthColumnOverwrite: 'number-column',
                    render: (_, record, index) => {
                      return (
                        <BaseText
                          fontWeight="medium"
                          opacity="0.7"
                          children={index + 1}
                        />
                      )
                    },
                  }),
                  convertColumnTable<IActionEvent>({
                    key: 'name',
                    title: 'Tên hoạt động',
                    classNameWidthColumnOverwrite: 'very-big-column',
                    render: (_, record) => {
                      return (
                        <BaseText
                          fontWeight="semibold"
                          children={record?.name || '-'}
                        />
                      )
                    },
                  }),
                  convertColumnTable<IActionEvent>({
                    key: 'event',
                    title: 'Sự kiện',
                    classNameWidthColumnOverwrite: 'very-big-column',
                    render: (_, record) => {
                      return (
                        <BaseText
                          fontWeight="medium"
                          children={record?.event?.name_event ?? '-'}
                          colorText="statesGreenColor"
                        />
                      )
                    },
                  }),
                ]}
                className="row-overwrite-style"
                dataSource={listActivityData}
                loading={isLoading}
                pagination={{
                  pageSize: 5,
                }}
              />
            </SectionBase>
          </BaseSpace>
        ) : null}
      </BaseSpace>
    </RootWrapper>
  )
}

export default ProfileContainer

const RootWrapper = styled.div`
  padding: 0 ${PADDING.md};
`
