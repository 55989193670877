import { requestGetRewardTumByTumId } from 'app/api/tradeUnionMembers'
import { IRewardTum, ITradeUnionMember } from 'app/api/tradeUnionMembers/model'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { _DEV_ } from 'app/constant'
import { Dayjs } from 'dayjs'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'

interface IDataTable extends IRewardTum {
  key: number
}

export interface IFormCreateRewardTum {
  name: string
  year: Dayjs
  description?: string
  link: string
}

export const OtherRewardLayout = ({ tum }: { tum?: ITradeUnionMember }) => {
  const [data, setData] = useState<IRewardTum[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getData = async () => {
    if (!tum?.id) {
      return
    }

    setIsLoading(true)
    try {
      const res: ResponseType<IRewardTum[]> = await requestGetRewardTumByTumId({
        id: tum?.id,
      })

      setData(res?.data ?? [])
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [tum?.id])

  const dataSource = useMemo(() => {
    return data?.map((item, index) => {
      return {
        key: index,
        ...item,
      }
    })
  }, [data])

  return (
    <SectionBase title="Giải thưởng khác">
      <BaseSpace>
        <BaseTableManagement
          dataSource={dataSource}
          loading={isLoading}
          columns={[
            convertColumnTable<IDataTable>({
              key: 'key',
              title: 'STT',
              fixed: 'left',
              classNameWidthColumnOverwrite: 'number-column',
              render: text => {
                return (
                  <BaseText children={text} fontWeight="medium" opacity="0.7" />
                )
              },
            }),
            convertColumnTable<IDataTable>({
              key: 'name',
              title: 'Tên giải',
              fixed: 'left',
              classNameWidthColumnOverwrite: 'very-big-column',
              render: text => {
                return <BaseText children={text} fontWeight="semibold" />
              },
            }),
            convertColumnTable<IDataTable>({
              key: 'description',
              title: 'Mô tả',
              render: text => {
                return (
                  <BaseText children={text} fontWeight="medium" opacity="0.7" />
                )
              },
            }),
            convertColumnTable<IDataTable>({
              key: 'year',
              title: 'Năm đạt giải',
              render: text => {
                return (
                  <BaseText children={text} fontWeight="medium" opacity="0.7" />
                )
              },
            }),
            convertColumnTable<IDataTable>({
              key: 'link',
              title: 'Tài liệu đính kèm',
              classNameWidthColumnOverwrite: 'very-big-column',
              render: text => {
                return (
                  <RenderValueTable
                    value={text}
                    type="Link"
                    onClick={() => window.open(text)}
                  />
                )
              },
            }),
          ]}
          className="row-overwrite-style"
          scroll={{ x: true }}
          pagination={{
            pageSize: 5,
          }}
        />
      </BaseSpace>
    </SectionBase>
  )
}
