import { message } from 'antd'
import { requestPostLogin } from 'app/api/auth'
import { requestGetActionById } from 'app/api/event'
import { IActionEvent } from 'app/api/event/model'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseEmpty } from 'app/components/common/BaseEmpty'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputPassword } from 'app/components/common/inputs/InputPassword'
import { Loading } from 'app/components/common/Loading'
import { SectionBase } from 'app/components/common/SectionBase'
import { FORMAT_DD_MM_YYYY_HH_MM } from 'app/constant'
import { IUser } from 'app/model/user.model'
import { useCheckInActionByMemberWithLocation } from 'app/react-query/hook/event'
import { isEqual } from 'lodash'
import { DateUtil, PADDING, ResponseType } from 'parkway-web-common'
import { memo, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'

const CheckInAction = () => {
  const { id } = useParams()
  const [form] = BaseForm.useForm()
  const { mutateAsync, isLoading: isLoadingSubmit } =
    useCheckInActionByMemberWithLocation()

  const [action, setAction] = useState<IActionEvent>({})

  const [loading, setLoading] = useState(false)

  const [location, setLocation] = useState<{
    latitude: null | number
    longitude: null | number
  }>({ latitude: null, longitude: null })
  const [error, setError] = useState<string | null>('')

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
          setError(null)
        },
        error => {
          setError(error.message)
        },
      )
    } else {
      setError('Geolocation is not supported by this browser.')
    }
  }

  const getAction = async () => {
    try {
      setLoading(true)
      if (!id) return
      const res: ResponseType<IActionEvent> = await requestGetActionById(
        id as unknown as number,
      )

      if (res) {
        setAction(res?.data)
      }
    } catch (error) {
      console.log('error', error)
      message.error('Lỗi không xác định')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAction()
    getLocation()
  }, [id])

  const handleSubmit = async (values: { email: string; password: string }) => {
    setLoading(true)
    try {
      const response: ResponseType<IUser> = await requestPostLogin({
        email: values.email,
        password: values.password,
      })

      const data = response?.data

      if (data?.member?.id) {
        await mutateAsync({
          actionId: action?.id,
          value: true,
          tumId: data?.member?.id,
          location: {
            lat: location?.latitude?.toString(),
            long: location?.longitude?.toString(),
          },
        })

        form?.resetFields()

        message.success('Check in thành công')
      } else {
        message.error(
          'Tài khoản không tồn tại hoặc bạn không phải đoàn viên? Vui lòng kiểm tra lại thông tin đăng nhập',
        )
      }
    } catch (error) {
      console.log('error', error)
      message.error('Lỗi, vui lòng liên hệ ban tổ chức nếu cần thiết')
    } finally {
      setLoading(false)
    }
  }

  return (
    <RootWrapper>
      {id ? (
        <BaseForm form={form} onFinish={handleSubmit}>
          <BaseSpace>
            <SectionBase title="Thông tin hoạt động">
              <BaseSpace>
                <BaseText
                  children={action?.name}
                  fontWeight="semibold"
                  fontSize="xs"
                />
                <BaseSpace size={8}>
                  <BaseText
                    children={'Thời gian diễn ra hoạt động'}
                    fontWeight="medium"
                    opacity="0.7"
                  />
                  <BaseText
                    children={`${DateUtil.formatDate(
                      action?.event?.time_start ?? '',
                      FORMAT_DD_MM_YYYY_HH_MM,
                    )} - ${DateUtil.formatDate(
                      action?.event?.time_end ?? '',
                      FORMAT_DD_MM_YYYY_HH_MM,
                    )}`}
                    colorText="statesGreenColor"
                  />
                </BaseSpace>
                <BaseSpace size={8}>
                  <BaseText
                    children={'Hoạt động thuộc sự kiện'}
                    fontWeight="medium"
                    opacity="0.7"
                  />
                  <BaseText
                    children={action?.event?.name_event}
                    fontWeight="semibold"
                    fontSize="xs"
                    colorText="otherBlueColor"
                  />
                </BaseSpace>
              </BaseSpace>
            </SectionBase>

            <SectionBase title="Đăng nhập để check in">
              <BaseSpace size={8}>
                <FormItem
                  name={'email'}
                  label={'Email'}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập email',
                    },
                  ]}
                >
                  <BaseInput placeholder="Vui lòng nhập email" />
                </FormItem>

                <FormItem
                  name={'password'}
                  label={'Mật khẩu'}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập mật khẩu',
                    },
                  ]}
                >
                  <InputPassword placeholder="Vui lòng nhập mật khẩu" />
                </FormItem>

                <BaseText
                  children={
                    'Lưu ý*: Bạn cần allow vị trí của bạn để có thể check in!'
                  }
                  colorText="statesOrangeColor"
                />

                <BaseRow justify={'center'}>
                  <BaseButton
                    onClick={form.submit}
                    type="primary"
                    loading={loading || isLoadingSubmit}
                    disabled={!location?.latitude || !location?.longitude}
                  >
                    {'Check in sự kiện'}
                  </BaseButton>
                </BaseRow>
              </BaseSpace>
            </SectionBase>
          </BaseSpace>
        </BaseForm>
      ) : (
        <SectionBase>
          <BaseEmpty description={'Không có hoạt động nào có thể check in'} />
        </SectionBase>
      )}
      {loading ? <Loading isAbsolute size="small" /> : null}

      <BaseSpace>
        <BaseCol />

        {error ? (
          <BaseText children={error} colorText="statesRedColor" />
        ) : null}
      </BaseSpace>
    </RootWrapper>
  )
}

export const CheckInActionPage = memo(CheckInAction, isEqual)

const RootWrapper = styled.div`
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: ${PADDING.xxxl};
`

const FormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
