import { ResponseType } from 'parkway-web-common'
import { IRole } from '../role/model'

export interface IParamsGetAccounts {
  search?: string
  limit?: number
  roleIds?: string[]
  is_active?: boolean
  page?: number
}

export interface IAccount {
  id?: number
  last_name?: string
  first_name?: string
  email?: string
  avatar?: string
  phone?: string
  roles?: IRole[]
  full_name?: string
  is_active?: boolean
  isTradeUnionMember?: boolean
}

export interface IPayloadCreateAccount {
  email?: string
  phone?: string
  role_ids: (number | undefined)[]
  full_name?: string
  is_active?: boolean
  password?: string
}

export interface IPayloadUpdateAccount {
  id?: number
  email?: string
  phone?: string
  role_ids?: (number | undefined)[]
  full_name?: string
  is_active?: boolean
}

export type IResponseAccountList = ResponseType<{
  users: IAccount[]
  count: number
}>

export enum RoleAccountEnum {
  Admin = 1,
  TradeUnionMember = 2,
}

export interface IPayloadChangePassword {
  id: number
  password?: string
}
