import React from 'react'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import { FormTradeUnionMemberDetails } from 'app/containers/TradeUnionMemberManagement/TradeUnionManagementDetails'
import { PageTitle } from 'app/components/common/PageTitle'

const TradeUnionMemberDetailPage: React.FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.union_members_detail)}</PageTitle>
      <FormTradeUnionMemberDetails />
    </>
  )
}
export default TradeUnionMemberDetailPage
