/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import styled from 'styled-components'
import { IActivityEvent, ITeam, TypeColumn } from '../../../../../../type'
import { BaseButton } from 'app/components/common/BaseButton'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'

interface IProps {
  activity: IActivityEvent
  index: number
  team: ITeam
  onDeleteDataTum?: (indexTum: number) => void
}

export const ColumnTumActivityOfTeam = ({
  activity,
  team,
  onDeleteDataTum,
}: IProps) => {
  const data = useMemo(() => {
    return team?.tums?.map((item, index) => {
      return {
        ...item,
        key: item?.id,
        index: index + 1,
      }
    })
  }, [team?.tums])

  return activity?.columns?.length ? (
    <BaseTableManagement
      columns={[
        ...((activity?.columns?.map((column, index) => {
          return {
            title: column?.name,
            dataIndex: column?.name,
            key: column?.keyOfMainData ?? column?.name,
            fixed: index === 0 ? 'left' : undefined,
            className: 'big-column',
            render: (_: any, record?: any) => {
              if (!column?.keyOfMainData) {
                switch (column?.type) {
                  case TypeColumn.STRING: {
                    return (
                      <BaseFormItem hiddenLabel>
                        <BaseInput
                          value={record?.[column?.name ?? '']}
                          placeholder={column?.name}
                          disabled
                        />
                      </BaseFormItem>
                    )
                  }
                  case TypeColumn.NUMBER: {
                    return (
                      <BaseFormItem hiddenLabel>
                        <BaseInputNumberWrapper
                          value={record?.[column?.name ?? '']}
                          placeholder={column?.name}
                          disabled
                        />
                      </BaseFormItem>
                    )
                  }
                  case TypeColumn.DATE: {
                    return (
                      <BaseFormItem hiddenLabel>
                        <DatePickerWrapper
                          value={
                            record?.[column?.name ?? '']
                              ? dayjs(record?.[column?.name ?? ''])
                              : undefined
                          }
                          placeholder={column?.name}
                          disabled
                        />
                      </BaseFormItem>
                    )
                  }

                  case TypeColumn.OTHER: {
                    return (
                      <BaseFormItem hiddenLabel>
                        <BaseInput
                          value={record?.[column?.name ?? '']}
                          placeholder={column?.name}
                        />
                      </BaseFormItem>
                    )
                  }
                }
              }

              return (
                <BaseText
                  children={record?.[column?.keyOfMainData ?? ''] ?? ''}
                  fontWeight="medium"
                  opacity="0.7"
                />
              )
            },
          }
        }) ?? []) as any),
        {
          title: '',
          dataIndex: 'key',
          key: 'key',
          fixed: 'right',
          width: '10%',
          render: (_, record: { index: number }) => {
            return (
              <BaseButton
                type="text"
                icon={<SvgDeleteDesignIcon />}
                onClick={() => onDeleteDataTum?.(record?.index - 1)}
              />
            )
          },
        },
      ]}
      dataSource={data}
      className="row-overwrite-style"
      scroll={{ x: true }}
      pagination={{
        pageSize: 5,
      }}
    />
  ) : null
}

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`

const BaseInputNumberWrapper = styled(InputNumber)`
  width: 100%;
`

const DatePickerWrapper = styled(BaseDatePicker)`
  width: 100%;
`
