import { IPayloadUpdateAccount } from 'app/api/account/model'
import { IRole } from 'app/api/role/model'
import R from 'app/assets/R'
import { IRefModal } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { usePagination } from 'app/hook'
import { useUpdateAccount } from 'app/react-query/hook/account'
import { useGetRoleList } from 'app/react-query/hook/role'
import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormUpdateAccount, IProps } from './type'

export const useHook = ({ account }: IProps) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IFormUpdateAccount>()
  const modalRef = useRef<IRefModal>({})

  const { mutateAsync: mutateAsyncUpdate, isLoading } = useUpdateAccount()

  const { data: dataRole, isLoading: isLoadingRole } = useGetRoleList({
    limit: 100,
  })

  const { flattenDataList } = usePagination()

  const roles: IRole[] = useMemo(() => {
    return flattenDataList(dataRole)?.data
  }, [dataRole])

  useEffect(() => {
    form?.setFieldsValue({
      phone: account?.phone,
      isActive: account?.is_active,
      name: account?.full_name,
      roleIds: account?.roles?.map(item => item?.id),
      email: account?.email,
    })
  }, [account])

  const handleSubmit = async (values: IFormUpdateAccount) => {
    const newAccount: IPayloadUpdateAccount = {
      id: account?.id,
      phone: values?.phone,
      is_active: !!values?.isActive,
      full_name: values?.name,
      role_ids: values?.roleIds ?? [],
    }

    await mutateAsyncUpdate?.(newAccount)
    modalRef?.current?.hide?.()
    form?.resetFields()
    notificationController.success({
      message: t(R.strings.success),
    })
  }

  return {
    form,
    modalRef,
    handleSubmit,
    isLoading,
    roles,
    isLoadingRole,
  }
}
