import { SyncOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseModalComponent } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { t } from 'i18next'
import { useHook } from './hook'
import { FilterLayout } from './layouts'

export const SyncFromUehModal = () => {
  const {
    columns,
    tableData,
    filter,
    onChangeFilter,
    form,
    onSubmit,
    refModal,
    isLoadingConfirm,
  } = useHook()

  return (
    <>
      <BaseModalComponent
        ref={refModal}
        titleModal={R.strings.sync_data}
        titleOpenModal={t(R.strings.sync_data)}
        handleSubmit={form.submit}
        widthModal={1200}
        isLoadingConfirm={isLoadingConfirm}
        buttonOpenModal={
          <BaseButton
            onClick={() => refModal?.current?.open?.()}
            icon={<SyncOutlined rev={undefined} />}
          >
            {t(R.strings.sync_data)}
          </BaseButton>
        }
        renderContent={
          <BaseForm form={form} onFinish={onSubmit}>
            <BaseSpace>
              <BaseRow gutter={[16, 8]} align={'middle'}>
                <BaseCol xl={16}>
                  <FilterLayout
                    filter={filter}
                    onChangeFilter={onChangeFilter}
                  />
                </BaseCol>
              </BaseRow>
              <BaseTableManagement
                columns={columns}
                dataSource={tableData.data}
                pagination={{ pageSize: 7 }}
                loading={tableData.loading}
                rowClassName="row-overwrite-style"
              />
            </BaseSpace>
          </BaseForm>
        }
      />
    </>
  )
}
