import { IActionEvent } from 'app/api/event/model'
import {
  requestGetActivityByTumId
} from 'app/api/tradeUnionMembers'
import { ITradeUnionMember } from 'app/api/tradeUnionMembers/model'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { _DEV_ } from 'app/constant'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'

export const ListActionEventLayout = ({ tum }: { tum?: ITradeUnionMember }) => {
  const [isLoading, setIsLoading] = useState(false)

  const [listActivity, setListActivity] = useState<IActionEvent[]>([])

  const getData = async () => {
    if (!tum?.id) return
    setIsLoading(true)
    try {
      const response: ResponseType<IActionEvent[]> =
        await requestGetActivityByTumId({ id: tum?.id })

      setListActivity(response?.data || [])
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [tum])

  const listActivityData = useMemo(() => {
    return listActivity.map((item, index) => ({
      ...item,
      key: index + 1,
    }))
  }, [listActivity])

  return (
    <SectionBase title="Danh sách hoạt động/sự kiện đã tham gia">
      <BaseTableManagement
        columns={[
          convertColumnTable<IActionEvent>({
            key: 'id',
            title: 'STT',
            classNameWidthColumnOverwrite: 'number-column',
            render: (_, record, index) => {
              return (
                <BaseText
                  fontWeight="medium"
                  opacity="0.7"
                  children={index + 1}
                />
              )
            },
          }),
          convertColumnTable<IActionEvent>({
            key: 'name',
            title: 'Tên hoạt động',
            classNameWidthColumnOverwrite: 'very-big-column',
            render: (_, record) => {
              return (
                <BaseText
                  fontWeight="semibold"
                  children={record?.name || '-'}
                />
              )
            },
          }),
          convertColumnTable<IActionEvent>({
            key: 'event',
            title: 'Sự kiện',
            classNameWidthColumnOverwrite: 'very-big-column',
            render: (_, record) => {
              return (
                <BaseText
                  fontWeight="medium"
                  children={record?.event?.name_event ?? '-'}
                  colorText="statesGreenColor"
                />
              )
            },
          }),
        ]}
        className="row-overwrite-style"
        dataSource={listActivityData}
        loading={isLoading}
        pagination={{
          pageSize: 5,
        }}
      />
    </SectionBase>
  )
}
