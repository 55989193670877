/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { ITeam } from 'app/containers/EventManagement/layouts/CreateEvent/type'
import { useCheckInActionByMember } from 'app/react-query/hook/event'
import { useMemo } from 'react'
import { IActivityEvent } from '../../../UpdateTumInActionEvent/type'

interface IProps {
  activity: IActivityEvent
  index: number
  team: ITeam
}

export const ColumnTumActivityOfTeam = ({ activity, team }: IProps) => {
  const { mutateAsync: mutateAsyncCheckIn, isLoading } =
    useCheckInActionByMember()
  const data = useMemo(() => {
    return team?.tums?.map((item, index) => {
      return {
        ...item,
        key: item?.id,
        index: index + 1,
      }
    })
  }, [team?.tums])

  const handleCheckIn = async ({
    value,
    tumId,
  }: {
    value: boolean
    tumId?: string
  }) => {
    if (!activity?.id) return

    if (!tumId) return

    await mutateAsyncCheckIn({
      value: value,
      actionId: activity?.id,
      tumId: tumId,
    })
  }

  return activity?.columns?.length ? (
    <BaseSpace style={{ display: 'flex', flex: 1, maxWidth: '100%' }}>
      <BaseTableManagement
        columns={[
          ...((activity?.columns?.map(column => {
            return {
              title: column?.name,
              dataIndex: column?.name,
              key: column?.keyOfMainData ?? column?.name,
              className: 'big-column',
              render: (_: any, record?: any) => {
                return (
                  <BaseText
                    children={
                      record?.[column?.keyOfMainData ?? column?.name ?? ''] ??
                      ''
                    }
                    fontWeight="medium"
                    opacity={column?.keyOfMainData ? '1' : '0.7'}
                  />
                )
              },
            }
          }) ?? []) as any),
          convertColumnTable<any>({
            key: 'checkedIn',
            title: 'Check in',
            fixed: 'right',
            render: (_, record) => {
              return (
                <BaseCheckbox
                  checked={record?.checkedIn}
                  value={record?.checkedIn}
                  onChange={e =>
                    handleCheckIn({
                      value: e?.target?.checked,
                      tumId: record?.key,
                    })
                  }
                />
              )
            },
          }),
        ]}
        dataSource={data}
        className="row-overwrite-style"
        scroll={{ x: true }}
        pagination={{
          pageSize: 5,
        }}
        loading={isLoading}
      />
    </BaseSpace>
  ) : null
}
