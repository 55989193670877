import R from 'app/assets/R'
import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { GenerateWorkInfo } from 'app/components/header/components/profile/ProfileDropdown'
import { ItemInfo } from 'app/containers/TradeUnionMemberManagement/TradeUnionManagementDetails/components'
import { IUser } from 'app/model/user.model'

export const AccountInfoLayout = ({ user }: { user?: IUser }) => {
  return (
    <SectionBase title="Thông tin tài khoản">
      <BaseRow gutter={[16, 16]} align={'middle'}>
        <BaseCol>
          <BaseAvatar alt={user?.full_name} size={100} />
        </BaseCol>
        <BaseCol xl={18}>
          <BaseSpace>
            <BaseText
              children={user?.full_name}
              fontWeight="semibold"
              fontSize="md"
            />
            <BaseText
              children={`Quyền: ${GenerateWorkInfo({
                roles: user?.roles,
                isTradeUnionMember: user?.isTradeUnionMember,
              })}`}
              fontWeight="medium"
              fontSize="xs"
            />

            <BaseRow gutter={8} align={'middle'}>
              <BaseCol xl={6}>
                <ItemInfo labelKeyI18n={R.strings.email} value={user?.email} />
              </BaseCol>
              <BaseCol xl={6}>
                <ItemInfo
                  labelKeyI18n={R.strings.phone}
                  value={user?.phone ?? '-'}
                />
              </BaseCol>
            </BaseRow>
          </BaseSpace>
        </BaseCol>
      </BaseRow>
    </SectionBase>
  )
}
