import { ITradeUnionMember } from 'app/api/tradeUnionMembers/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { ItemInfo } from 'app/containers/TradeUnionMemberManagement/TradeUnionManagementDetails/components'
import { StatusActivitiesMap } from 'app/containers/TradeUnionMemberManagement/type'
import { useTranslation } from 'react-i18next'

export const TumInfoLayout = ({ tum }: { tum?: ITradeUnionMember }) => {
  const { t } = useTranslation()

  return (
    <BaseSpace>
      <SectionBase>
        <BaseSpace>
          <BaseText
            children={t(R.strings.general_info)}
            fontWeight="semibold"
            fontSize="md"
          />
          <BaseRow gutter={[16, 10]}>
            <BaseCol xl={6}>
              <ItemInfo labelKeyI18n={R.strings.id_management} value="1" />
            </BaseCol>

            <BaseCol xl={6}>
              <ItemInfo
                labelKeyI18n={R.strings.full_name}
                value={tum?.fullName ?? '-'}
              />
            </BaseCol>

            <BaseCol xl={6}>
              <ItemInfo
                labelKeyI18n={R.strings.birthday}
                value={tum?.ngaySinh ?? '-'}
              />
            </BaseCol>

            <BaseCol xl={6}>
              <ItemInfo labelKeyI18n={R.strings.gender} value="Nam" />
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={[16, 10]}>
            <BaseCol xl={6}>
              <ItemInfo
                labelKeyI18n={R.strings.email}
                value={tum?.email ?? '-'}
                isHaveCopy
              />
            </BaseCol>

            <BaseCol xl={6}>
              <ItemInfo
                labelKeyI18n={R.strings.phone}
                value={tum?.dienThoaiDiDong ?? '-'}
                isHaveCopy
              />
            </BaseCol>

            <BaseCol xl={6}>
              <ItemInfo labelKeyI18n={R.strings.nations} value={tum?.danToc} />
            </BaseCol>

            <BaseCol xl={6}>
              <ItemInfo
                labelKeyI18n={R.strings.religion}
                value={tum?.tonGiao}
              />
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={[16, 10]}>
            <BaseCol xl={6}>
              <ItemInfo
                labelKeyI18n={R.strings.domicile}
                value={tum?.nguyenQuan}
              />
            </BaseCol>

            <BaseCol xl={6}>
              <ItemInfo
                labelKeyI18n={R.strings.address}
                value={tum?.noiOHienNay ?? '-'}
              />
            </BaseCol>

            <BaseCol xl={6}>
              <ItemInfo
                labelKeyI18n={R.strings.CCCD}
                value={tum?.cmnd ?? '-'}
                isHaveCopy
              />
            </BaseCol>
          </BaseRow>
        </BaseSpace>
      </SectionBase>
      <SectionBase>
        <BaseSpace>
          <BaseText
            children={t(R.strings.education_and_team_info)}
            fontWeight="semibold"
            fontSize="md"
          />
          <BaseRow gutter={[16, 10]}>
            <BaseCol xl={6}>
              <ItemInfo labelKeyI18n={"Học hàm"} value={tum?.hocHam} />
            </BaseCol>
            <BaseCol xl={6}>
              <ItemInfo labelKeyI18n={'Học vị'} value={tum?.hocVi} />
            </BaseCol>
            <BaseCol xl={6}>
              <ItemInfo
                labelKeyI18n={R.strings.Workplace}
                value={tum?.tenDonVi}
              />
            </BaseCol>

            <BaseCol xl={6}>
              <ItemInfo
                labelKeyI18n={R.strings.start_date_ueh}
                value={tum?.ngayVaoUEH}
              />
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={[16, 10]}>
            <BaseCol xl={6}>
              <ItemInfo
                labelKeyI18n={R.strings.university_subsidiary}
                value={tum?.tenPhanHieu}
              />
            </BaseCol>
            <BaseCol xl={6}>
              <ItemInfo
                labelKeyI18n={R.strings.employment_status}
                value={tum?.tinhTrangLamViec ?? '-'}
              />
            </BaseCol>
            <BaseCol xl={6}>
              <ItemInfo
                labelKeyI18n={R.strings.date_of_joining_the_party}
                value={tum?.ngayVaoCongDoan}
              />
            </BaseCol>

            <BaseCol xl={6}>
              <ItemInfo
                labelKeyI18n={R.strings.date_of_union_entry}
                value={tum?.ngayVaoCongDoan}
              />
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={[16, 10]}>
            <BaseCol xl={6}>
              <ItemInfo
                labelKeyI18n={R.strings.union_card_number}
                value={tum?.soTheDoanVien ?? '-'}
                isHaveCopy
              />
            </BaseCol>
            <BaseCol xl={6}>
              <ItemInfo
                labelKeyI18n={R.strings.union_positions}
                value={tum?.chucVucongdoan}
              />
            </BaseCol>

            <BaseCol xl={6}>
              <ItemInfo
                labelKeyI18n={R.strings.status_union_activities}
                value={
                  !tum?.tinhTrangSinhHoat
                    ? '-'
                    : StatusActivitiesMap?.[tum?.tinhTrangSinhHoat] ?? '-'
                }
              />
            </BaseCol>
          </BaseRow>
        </BaseSpace>
      </SectionBase>
    </BaseSpace>
  )
}
