import LoadingPage from 'app/page/other/loading'
import { getToken } from 'app/service/storage/storage-service'
import { WithChildrenProps } from 'parkway-web-common'
import React from 'react'
import { Navigate } from 'react-router-dom'
import { AUTH_LOGIN_PATH } from './route-path'

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const token = getToken()

  // const profile = useAppSelector(state => state.profileSlice)?.profile
  
  const profile =  true

  return token ? (
    profile ? (
      <>{children}</>
    ) : (
      <LoadingPage />
    )
  ) : (
    <Navigate to={AUTH_LOGIN_PATH} replace />
  )
}

export default RequireAuth
