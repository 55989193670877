import { TypeEventEnum } from './model'

// Endpoint
export const EventEndPoint = {
  ADMIN_GET_EVENT: 'admin/event',
  ADMIN_GET_EVENT_WITH_TUM: 'admin/event/with-tum',
  ADMIN_GET_EVENT_BY_ID: 'admin/event/by-id',
  ADMIN_CREATE_EVENT: 'admin/event/create',
  ADMIN_UPDATE_EVENT: 'admin/event/update',
  ADMIN_DELETE_EVENT: 'admin/event/delete',
  ADMIN_GET_ACTION_BY_EVENT_ID: 'admin/activity/by-event-id',
  ADMIN_CREATE_ACTION: 'admin/activity/create',
  ADMIN_UPDATE_ACTION: 'admin/activity/update',

  ADMIN_CREATE_ACTION_TYPE_ACTIVITY: 'admin/activity/create-type-activity',
  ADMIN_UPDATE_ACTION_TYPE_ACTIVITY: 'admin/activity/update-type-activity',

  ADMIN_GET_MEMBER_BY_PRIZE: 'admin/activity/tum-by-reward-id',
  ADMIN_GET_TEAM_BY_PRIZE: 'admin/reward/team/reward-id',
  ADMIN_PUSH_PRIZE: 'admin/reward/team-of-tum/presenting-reward',
  ADMIN_GET_MEMBER_PRIZE_BY_EVENT_ID: 'admin/activity/tum-reward-by-event-id',
  ADMIN_GET_TEAM_PRIZE_BY_EVENT_ID: 'admin/reward/team/event-id',
  
  ADMIN_GET_UNIT_PRIZE_BY_EVENT_ID: 'api/v1/event/:id/unit/prize',
  ADMIN_GET_MEMBER_BY_ACTIVITY_ID: 'admin/activity/tums-by-activity-id',
  GET_ACTION_NOT_JOIN_BY_MEMBER: 'activity/register/tum/register-tum/open',
  GET_REGISTER_MEMBER_WAITING_BY_ACTION: 'activity/register/tum/register-tum',
  GET_REGISTER_ACTION_WAITING_BY_MEMBER: 'activity/register/tum/register-tum',
  GET_REGISTER_MEMBER_BY_ACTION: 'activity/register/tum/register-tum',
  REGISTER_MEMBER_WAITING_CHANGE_STATUS:
    'activity/register/tum/register-tum/status',
  REGISTER_MEMBER_WITH_ACTION: 'activity/register/tum/register-tum',
  ACTION_CHECK_IN: 'admin/activity/check-in-tum',
  ACTION_CHECK_IN_WITH_LOCATION: 'admin/activity-tum/check-in-verify',
  GET_ACTION_EVENT_BY_ID: 'admin/activity/by-id',

  ADD_TUM_TO_ACTIVITY: 'admin/activity/add-tum-to-activity',

  //admin/event/download
  DOWNLOAD_EVENT_LIST: 'admin/event/download'
}

export const DefineTypeEventEnum = {
  [TypeEventEnum.ACTIVITY]: {
    text: 'Nhóm hội thi',
  },
  [TypeEventEnum.CARE]: {
    text: 'Hoạt động chăm lo',
  },
  [TypeEventEnum.NEWS]: {
    text: 'Nhóm thông tin',
  },
  [TypeEventEnum.TRAINING]: {
    text: 'Nhóm Tập huấn - Hội thảo - Đào tạo',
  },
}
