/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestDeleteRoleList,
  requestGetPermissionList,
  requestGetRoleList,
  requestPostRoleList,
  requestUpdateRoleList,
} from 'app/api/role'
import {
  IDeleteRole,
  IParamsGetRole,
  IPayloadCreateRole,
  IPayloadUpdate,
  IPermission,
  IResponsePermissions,
  IResponseRoleList,
  IRole,
} from 'app/api/role/model'
import { getToken } from 'app/service/storage/storage-service'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { RoleKeys } from '../query-key/role'
import { useVerifyPermission } from 'app/helpers/permission.helper'
import { PermissionEnum } from 'app/common/enum'

export function useGetRoleList(params: IParamsGetRole, enabled = true) {
  const token = getToken()
  const { verifyPermission } = useVerifyPermission()
  return useInfiniteQuery<IResponseQueryList<IRole[]>, undefined>(
    RoleKeys.listRole(params),
    async () => {
      if (!verifyPermission(PermissionEnum.ReadRole)) {
        return {
          data: [],
          total: 0,
        }
      }

      try {
        const res: IResponseRoleList = await requestGetRoleList(params)
        const data: IRole[] = res?.data?.rows || []
        return {
          data,
          total: res?.data?.total ?? 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export const useDeleteRole = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IDeleteRole, unknown>(
    async (body: IDeleteRole) => {
      await requestDeleteRoleList(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: RoleKeys.allRoles,
        })
      },
    },
  )
}

export const useUpdateRole = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadUpdate, unknown>(
    async (body?: IPayloadUpdate) => {
      await requestUpdateRoleList(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: RoleKeys.allRoles,
        })
      },
    },
  )
}

export const useCreateRole = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadCreateRole, unknown>(
    async (body?: IPayloadCreateRole) => {
      await requestPostRoleList(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: RoleKeys.allRoles,
        })
      },
    },
  )
}

// get Permission
export function useGetPermissionList(enabled = true) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<IPermission[]>, undefined>(
    RoleKeys.listPermissions(),
    async () => {
      try {
        const res: IResponsePermissions = await requestGetPermissionList()
        const data: IPermission[] = res?.data || []
        return {
          data,
          total: data.length,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
