import { FONT_SIZE, FONT_WEIGHT, media } from 'parkway-web-common'
import styled from 'styled-components'
import { BaseButton } from 'app/components/common/BaseButton'

export const Wrapper = styled.div`
  background-color: rgba(var(--background-rgb-color), 0.93);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  @media only screen and ${media.xs} {
    width: 20.75rem;
  }

  @media only screen and ${media.md} {
    width: 35rem;
  }
`

export const Title = styled.div`
  color: var(--primary-color);
  margin-left: 1rem;

  @media only screen and ${media.xs} {
    font-size: ${FONT_SIZE.lg};
    font-weight: ${FONT_WEIGHT.bold};
    line-height: 1.5625rem;
  }

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xxl};
    font-weight: ${FONT_WEIGHT.bold};
    line-height: 1.9375rem;
  }

  @media only screen and ${media.xl} {
    font-size: ${FONT_SIZE.xxxl};
    font-weight: ${FONT_WEIGHT.extraBold};
    line-height: 2.125rem;
  }
`

export const Logging = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
`

export const Relogin = styled(BaseButton)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 100%;
`

export const Back = styled(Relogin)``
