import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseTypography } from 'app/components/common/BaseTypography'
import { FONT_SIZE, FONT_WEIGHT, PADDING, media } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  align-items: center;
  width: 320px;
  padding: ${PADDING.xxxxs} 0px;
  .ant-space-item {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

export const TextName = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
`

export const JobTitleName = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.regular};
  text-align: center;
`

export const Text = styled(BaseTypography.Text)<{ $color?: string }>`
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 0.875rem;
  font-weight: ${FONT_WEIGHT.regular};
  & > a {
    display: block;
    color: ${props => props?.$color ?? 'var(--primary-color)'};
  }

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xs};
  }
`

export const ItemsDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--primary-color);
  opacity: 0.2;
`

export const BaseAvatarWrapper = styled(BaseAvatar)`
  width: 80px;
  height: 80px;
  font-size: ${FONT_SIZE.xxl};
  font-weight: ${FONT_WEIGHT.semibold};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ItemWrapper = styled(BaseRow)`
  cursor: pointer;
  width: 100%;
  padding: ${PADDING.xxxxs} 0px;
`

