import R from 'app/assets/R'
import { data } from './data'
import { BaseLayoutReport } from 'app/components/common/BaseLayoutReport'
import { useEffect } from 'react'
import { PermissionEnum } from 'app/common/enum'
import { useVerifyPermission } from 'app/helpers/permission.helper'

const ReportContainer = () => {
  const { user, verifyPermissionAndRedirect } = useVerifyPermission()

  useEffect(() => {
    verifyPermissionAndRedirect(PermissionEnum.ReadReport)
  }, [user])

  return <BaseLayoutReport data={data} titleI18nKey={R.strings.report} />
}
export default ReportContainer
