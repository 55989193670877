import { IParamsGetUnit } from 'app/api/unit/model'

export const UnitKeys = {
  allUnits: ['Units'] as const,
  listUnits: () => [...UnitKeys.allUnits, 'listUnits'] as const,
  listUnit: (filter?: IParamsGetUnit) =>
    [...UnitKeys.listUnits(), { filter }] as const,

  totalUnits: () => [...UnitKeys.allUnits, 'totalUnits'] as const,
  totalUnit: (filter?: IParamsGetUnit) =>
    [...UnitKeys.totalUnits(), { filter }] as const,
}
