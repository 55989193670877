import { ApiClient } from 'app/service/api-service'
import { AccountEndPoint } from './constant'
import {
    IAccount,
    IParamsGetAccounts,
    IPayloadChangePassword,
    IPayloadCreateAccount,
} from './model'

export const requestGetAccountList = (params: IParamsGetAccounts) =>
  ApiClient.Get({
    url: AccountEndPoint.ADMIN_GET_ACCOUNT,
    params: { params },
  })

export const requestCheckExistAccount = (params: { email: string }) =>
  ApiClient.Get({
    url: AccountEndPoint.CHECK_EXIST_ACCOUNT,
    params: { params },
  })

export const requestGetUserInfo = () =>
  ApiClient.Get({
    url: AccountEndPoint.GET_PROFILE,
    params: {},
  })

export const requestDeleteAccount = (listId: number[]) =>
  ApiClient.Post({
    url: AccountEndPoint.ADMIN_DELETE_ACCOUNT,
    body: { listId  },
  })

export const requestUpdateAccount = (body?: IAccount) =>
  ApiClient.Put({
    url: AccountEndPoint.ADMIN_UPDATE_ACCOUNT,
    body,
  })

export const requestCreateAccount = (body?: IPayloadCreateAccount) =>
  ApiClient.Post({
    url: AccountEndPoint.ADMIN_CREATE_ACCOUNT,
    body,
  })

export const requestChangePassword = (body?: IPayloadChangePassword) =>
  ApiClient.Put({
    url: AccountEndPoint.ADMIN_CHANGE_PASSWORD_ACCOUNT,
    body,
  })
