import { memo } from 'react'
import { isEqual } from 'lodash'
import { CreateEventContainer } from 'app/containers/EventManagement/layouts/CreateEvent'
import { PageTitle } from 'app/components/common/PageTitle'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

const CreateEventManagementCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.create_event)}</PageTitle>
      <CreateEventContainer />
    </>
  )
}
export const CreateEventManagementPage = memo(CreateEventManagementCpn, isEqual)
