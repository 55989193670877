/* eslint-disable @typescript-eslint/no-explicit-any */
import { IActionEvent } from 'app/api/event/model'
import { ITradeUnionMember } from 'app/api/tradeUnionMembers/model'
import { usePagination } from 'app/hook'
import { useGetActionByEvent } from 'app/react-query/hook/event'
import { IResponseQueryList } from 'app/react-query/model/common'
import { useMemo } from 'react'
import { IActivityEvent, ILocation, ITeam } from '../CreateEvent/type'
import {
  IColumnActivity,
  IDataTumActivity,
  IRewardActivity,
} from '../UpdateEvent/type'
import { IProps } from './type'

export const useHook = (props: IProps) => {
  const { data: dataApi, isLoading } = useGetActionByEvent({
    id: props?.event?.id ?? 0,
    eventId: props?.event?.id ?? 0,
  })

  const { flattenDataList } = usePagination()

  const dataFlatten = useMemo(() => {
    const res: IResponseQueryList<IActionEvent[]> = flattenDataList(dataApi)
    return res
  }, [dataApi])

  const data = useMemo(() => {
    const activityEvents: IActivityEvent[] =
      dataFlatten?.data?.map((item, index) => {
        const columns = item?.columns?.map(column => {
          return {
            name: column?.name_column,
            keyOfMainData: column?.key_of_main_data,
            type: column?.type_column,
          } as IColumnActivity
        })

        const rewards = item?.rewards?.map(reward => {
          return {
            id: reward?.id,
            name: reward?.name,
            countPerReward: reward?.countPerReward,
            description: reward?.description,
            gift: reward?.gift,
            type: reward?.type,
          } as IRewardActivity
        })

        const locations = item?.locations?.map(location => {
          return {
            id: location?.id,
            address: location?.address,
            lat: location?.lat,
            long: location?.long,
            radius: location?.radius,
            key_map_check_in: location?.key_map_check_in,
          } as ILocation
        })

        const teams = item?.teams?.map(team => {
          const tums = (team?.tums as any[])?.map(member => {
            let data: IDataTumActivity = {
              ...member.tum,
              id: member?.tum?.id,
              checkedIn: member?.is_checked_in,
              valueCare: member?.value_care,
            }

            columns?.forEach(column => {
              data = {
                ...data,
                [column?.name ?? '']: member?.listMemberCategory?.find(
                  category => category?.idCategory === column?.keyOfMainData,
                )?.value,
              }
            })

            return data
          })
          return {
            id: team?.id,
            name: team?.name,
            description: team?.description,
            tums,
          } as ITeam
        })

        const dataR = item?.members?.map(member => {
          let data: IDataTumActivity = {
            ...member.tum,
            id: member?.tum?.id,
            checkedIn: member?.is_checked_in,
            valueCare: member?.value_care,
          }

          columns?.forEach(column => {
            data = {
              ...data,
              [column?.name ?? '']: member?.listMemberCategory?.find(
                category => category?.idCategory === column?.keyOfMainData,
              )?.value,
            }
          })

          return data
        })

        return {
          id: item?.id,
          title: item?.name,
          valueCare: item?.valueCare,
          columns,
          data: dataR,
          rewards,
          key: index + 1,
          locations,
          teams,
        }
      }) ?? []

    return activityEvents ?? []
  }, [dataFlatten])

  const tumData = useMemo(() => {
    const activityEvents: {
      id?: any
      title?: string
      infoData?: ITradeUnionMember[]
    }[] = data?.map(item => {
      const infoData =
        item?.data?.map(data => {
          return {
            ...data,
            id: data?.id,
            value_care: data?.value_care,
          } as ITradeUnionMember
        }) ?? []

      return {
        id: item?.id,
        title: item?.title,
        infoData,
      }
    })

    return activityEvents
  }, [data])

  const downloadQRCode = () => {
    const getElementById = document?.getElementById('qrCodeEl') as any

    if (getElementById) {
      const qrCodeURL = getElementById
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream')
      console.log(qrCodeURL)
      const aEl = document.createElement('a')
      aEl.href = qrCodeURL
      aEl.download = 'QR_Code.png'
      document.body.appendChild(aEl)
      aEl.click()
      document.body.removeChild(aEl)
    }
  }

  return {
    data,
    isLoading,
    tumData,
    downloadQRCode,
  }
}
