import { IActionEvent } from 'app/api/event/model'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { FORMAT_DD_MM_YYYY_HH_MM } from 'app/constant'
import { useGetActionNotJoinByMember } from 'app/react-query/hook/event'
import { DateUtil } from 'parkway-web-common'
import { useMemo } from 'react'
import { ModalRegisterAction } from '../ModalRegisterAction'

interface IData extends IActionEvent {
  key?: number
}

export const ActionOpenRegisterLayout = () => {
  const { data: response, isLoading } = useGetActionNotJoinByMember()

  const data = useMemo(() => {
    return response?.data?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
        index: index + 1,
      }
    })
  }, [response])


  return (
    <BaseSpace>
      <BaseTableManagement
        dataSource={data}
        columns={[
          convertColumnTable<IData>({
            title: 'STT',
            key: 'key',
            classNameWidthColumnOverwrite: 'number-column',
            fixed: 'left',
            render: value => {
              return (
                <BaseText children={value} fontWeight="medium" opacity="0.7" />
              )
            },
          }),
          convertColumnTable<IData>({
            title: 'Tên hoạt động',
            key: 'name',
            classNameWidthColumnOverwrite: 'big-column',
            fixed: 'left',
            render: value => {
              return <BaseText children={value} fontWeight="semibold" />
            },
          }),
          convertColumnTable<IData>({
            title: 'Sự kiện',
            key: 'event',
            classNameWidthColumnOverwrite: 'big-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.event?.name_event}
                  fontWeight="medium"
                  opacity="0.7"
                />
              )
            },
          }),
          convertColumnTable<IData>({
            title: 'Thời gian đăng kí',
            key: 'event',
            classNameWidthColumnOverwrite: 'big-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={`${DateUtil.formatDate(
                    record?.event?.time_register_start ?? '',
                    FORMAT_DD_MM_YYYY_HH_MM,
                  )} - ${DateUtil.formatDate(
                    record?.event?.time_register_start ?? '',
                    FORMAT_DD_MM_YYYY_HH_MM,
                  )}`}
                  fontWeight="medium"
                  colorText="statesGreenColor"
                />
              )
            },
          }),
          convertColumnTable<IData>({
            title: 'Thời gian diễn ra sự kiện',
            key: 'event',
            classNameWidthColumnOverwrite: 'big-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={`${DateUtil.formatDate(
                    record?.event?.time_start ?? '',
                    FORMAT_DD_MM_YYYY_HH_MM,
                  )} - ${DateUtil.formatDate(
                    record?.event?.time_end ?? '',
                    FORMAT_DD_MM_YYYY_HH_MM,
                  )}`}
                  fontWeight="medium"
                  opacity="0.7"
                />
              )
            },
          }),
          convertColumnTable<IData>({
            title: '',
            key: 'key',
            width: '8%',
            render: (_, record) => {
              return <ModalRegisterAction action={record} />
            },
          }),
        ]}
        loading={isLoading}
      />
    </BaseSpace>
  )
}
