import { DashOutlined } from '@ant-design/icons'
import { IUnit } from 'app/api/unit/model'
import R from 'app/assets/R'
import { PermissionEnum } from 'app/common/enum'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useVerifyPermission } from 'app/helpers/permission.helper'
import { useDebounce, usePagination } from 'app/hook'
import { useGetUnitList } from 'app/react-query/hook/unit'
import { IResponseQueryList } from 'app/react-query/model/common'
import axios from 'axios'
import { Pagination, initialPagination } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ENV_CONFIG } from 'app/common/config'
import { DefineUnitType } from 'app/api/unit/constant'
import { useNavigate } from 'react-router'
import { UNIT_MANAGEMENT_DETAILS_PATH } from 'app/components/router/route-path'
import { BaseButton } from 'app/components/common/BaseButton'
import { UpdateModalLayout } from '../UpdateModal'
import { DeleteModalLayout } from '../DeleteModal'
import { UnitEnum } from 'app/api/tradeUnionMembers/model'
import { IFilterUnitList } from '../../type'

export const useHook = ({
  schoolName,
  filter,
}: {
  schoolName: UnitEnum
  filter?: IFilterUnitList
}) => {
  const { user, verifyPermission } = useVerifyPermission()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const filterDebounce = useDebounce(filter, 500)

  const [pagination, setPagination] = useState<Pagination>(initialPagination)

  const { flattenDataList } = usePagination()
  const { data, isLoading } = useGetUnitList({
    limit: pagination?.pageSize,
    page: pagination?.current,
    search: filterDebounce?.search,
    type: filterDebounce?.type,
    schoolName,
  })

  const flattenData = useMemo(() => {
    const res: IResponseQueryList<IUnit[]> = flattenDataList(data)

    return res
  }, [data])

  const dataTable = useMemo(() => {
    return flattenData?.data
  }, [flattenData])

  const total = useMemo(() => {
    return flattenData?.total ?? dataTable?.length
  }, [flattenData])

  const isHavePermissionUpdateUnit = useMemo(() => {
    return verifyPermission(PermissionEnum.UpdateAccount)
  }, [user])

  const isHavePermissionDeleteUnit = useMemo(() => {
    return verifyPermission(PermissionEnum.DeleteUnit)
  }, [user])

  const navigateToDetail = (unit?: IUnit) => {
    navigate(UNIT_MANAGEMENT_DETAILS_PATH, {
      state: {
        unit,
      },
    })
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<IUnit>({
        key: 'name',
        title: 'Tên đơn vị',
        classNameWidthColumnOverwrite: 'big-column',
        fixed: 'left',
        render: (_, record) => {
          return (
            <BaseButton type="text" onClick={() => navigateToDetail(record)}>
              <BaseText
                children={record?.name ?? '-'}
                fontWeight="semibold"
                colorText="collapseBackgroundColor"
              />
            </BaseButton>
          )
        },
      }),
      convertColumnTable<IUnit>({
        key: 'description',
        title: t(R.strings.description),
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={record?.description ?? '-'}
            />
          )
        },
      }),
      convertColumnTable<IUnit>({
        key: 'tradeUnionMembers',
        title: 'Số lượng đoàn viên',
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              colorText="statesGreenColor"
              children={record?.tradeUnionMembers?.length ?? 0}
            />
          )
        },
      }),

      convertColumnTable<IUnit>({
        key: 'type',
        title: 'Loại đơn vị',
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={DefineUnitType?.[record?.type]}
            />
          )
        },
      }),

      // tổ trưởng
      convertColumnTable<IUnit>({
        key: 'positions',
        title: 'Lãnh đạo',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={
                record?.positions?.length
                  ? record?.positions
                      ?.map(itm => `${itm.name}: ${itm?.member?.fullName}`)
                      .join(' | ')
                  : '-'
              }
            />
          )
        },
      }),
      // action
      convertColumnTable<IUnit>({
        key: 'id',
        title: '',
        fixed: 'right',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => {
          return (
            <BasePopover
              trigger="click"
              content={
                <BaseSpace>
                  {isHavePermissionUpdateUnit ? (
                    <UpdateModalLayout unit={record} />
                  ) : null}

                  {isHavePermissionDeleteUnit ? (
                    <DeleteModalLayout name={record?.name} id={record?.id} />
                  ) : null}
                </BaseSpace>
              }
            >
              <DashOutlined rev={undefined} rotate={90} />
            </BasePopover>
          )
        },
      }),
    ]
  }, [t])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const downloadExcel = async (): Promise<void> => {
    try {
      const response = await axios.get(
        `${ENV_CONFIG.API_ENDPOINT}unit/download`,
        {
          responseType: 'blob', // Thiết lập kiểu phản hồi là blob
          params: {
            search: filterDebounce?.search,
            type: filterDebounce?.type,
          },
        },
      )

      // Tạo một URL blob từ dữ liệu file
      const url = window.URL.createObjectURL(new Blob([response.data]))

      // Tạo thẻ <a> để tự động tải file về máy
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'units.xlsx') // Tên file Excel sẽ tải về
      document.body.appendChild(link)

      // Kích hoạt sự kiện click để tải file
      link.click()

      // Hủy URL sau khi tải xong
      link.parentNode?.removeChild(link) // Optional chaining để an toàn
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading Excel:', error)
    }
  }
  return {
    isLoading,
    columns,
    pagination: {
      ...pagination,
      total,
    },
    dataTable,
    handleTableChange,
    filter,
    downloadExcel,
  }
}
