import { requestCheckExistAccount } from 'app/api/account'
import { IPayloadCreateAccount } from 'app/api/account/model'
import { IRole } from 'app/api/role/model'
import R from 'app/assets/R'
import { IRefModal } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { usePagination } from 'app/hook'
import { useCreateAccount } from 'app/react-query/hook/account'
import { useGetRoleList } from 'app/react-query/hook/role'
import { ResponseType } from 'parkway-web-common'
import { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormUpdateAccount } from './type'

export const useHook = () => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IFormUpdateAccount>()
  const modalRef = useRef<IRefModal>({})

  const { data: dataRole, isLoading: isLoadingRole } = useGetRoleList({
    limit: 100,
  })

  const { flattenDataList } = usePagination()

  const roles: IRole[] = useMemo(() => {
    return flattenDataList(dataRole)?.data
  }, [dataRole])

  const { mutateAsync: mutateAsyncUpdate, isLoading } = useCreateAccount()

  const handleSubmit = async (values: IFormUpdateAccount) => {
    if (!values?.email) return
    const res: ResponseType<boolean> = await requestCheckExistAccount({
      email: values?.email,
    })

    if (res?.data) {
      const message = `Đã tồn tại email "${values?.email}", Vui lòng chọn email khác`
      notificationController.error({
        message,
      })
      form.setFields([
        {
          name: 'email',
          errors: [message],
        },
      ])
      return
    } else {
      const newAccount: IPayloadCreateAccount = {
        phone: values?.phone,
        is_active: !!values?.isActive,
        email: values?.email,
        full_name: values?.name,
        password: values?.password,
        role_ids: values?.roleIds ?? [],
      }

      const res = await mutateAsyncUpdate?.(newAccount)
      if (res) {
        modalRef?.current?.hide?.()
        form?.resetFields()
        notificationController.success({
          message: t(R.strings.created_successfully),
        })
      }

      return
    }
  }

  return {
    form,
    modalRef,
    isLoading,
    handleSubmit,
    isLoadingRole,
    roles,
  }
}
