import { memo } from 'react'
import { isEqual } from 'lodash'
import ApproveUnionMembersContainer from 'app/containers/ApproveUnionMembers'
import { PageTitle } from 'app/components/common/PageTitle'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

const ApproveUnionMembersCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.approve_union_members)}</PageTitle>
      <ApproveUnionMembersContainer />
    </>
  )
}
export const ApproveUnionMembersPage = memo(ApproveUnionMembersCpn, isEqual)
