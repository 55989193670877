import { StatusMemberRegisterWaiting } from 'app/api/event/model'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { notificationController } from 'app/controllers/notification-controller'
import { useChangeStatusMemberRegister } from 'app/react-query/hook/event'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ILocation, ITeam } from '../../../CreateEvent/type'
import R from 'app/assets/R'

export const ModalApproveMember = ({
  id,
  listTeam,
  locations,
}: {
  id?: number
  listTeam?: ITeam[]
  locations?: ILocation[]
}) => {
  const { t } = useTranslation()
  const refModal = useRef<IRefModal>({})
  const [form] = BaseForm.useForm<{ team: number; locationIds?: number[] }>()
  const { mutateAsync: mutateAsyncUpdate, isLoading } =
    useChangeStatusMemberRegister()

  const handleSubmit = async ({
    team,
    locationIds,
  }: {
    team?: number
    locationIds?: number[]
  }) => {
    if (!id) return
    const res = await mutateAsyncUpdate({
      id,
      status: StatusMemberRegisterWaiting.ACCEPTED,
      team,
      locationIds,
    })

    if (res) {
      notificationController?.success({ message: 'Đã phê duyệt thành viên!' })
      refModal?.current?.hide?.()
      window.location.reload()
    }
  }

  return (
    <BaseModalComponent
      ref={refModal}
      titleModal="Phê duyệt đăng kí"
      buttonOpenModal={
        <BaseButton
          children="Phê duyệt"
          onClick={() => refModal.current?.open?.()}
          type="text"
        />
      }
      handleSubmit={form?.submit}
      isLoadingConfirm={isLoading}
      renderContent={
        <BaseForm form={form} onFinish={handleSubmit}>
          <BaseSpace size={8}>
            {listTeam?.length ? (
              <BaseForm.Item
                label={'Đội/nhóm'}
                required
                name={'team'}
                rules={[
                  {
                    required: true,
                    message: t(R.strings.require_field, {
                      field: 'Đội/nhóm',
                    }),
                  },
                ]}
              >
                <BaseSelect
                  options={listTeam?.map(team => ({
                    value: team?.id,
                    label: team?.name,
                  }))}
                  placeholder={'Chọn'}
                />
              </BaseForm.Item>
            ) : null}

            {locations?.length ? (
              <BaseForm.Item
                label={'Địa điểm check in'}
                required
                name={'locationIds'}
                rules={[
                  {
                    required: true,
                    message: t(R.strings.require_field, {
                      field: 'Địa điểm check in',
                    }),
                  },
                ]}
              >
                <BaseSelect
                  options={locations?.map(location => ({
                    value: location?.id,
                    label: `${location?.address} (${location?.coordinates})`,
                  }))}
                  placeholder={'Chọn'}
                  mode="multiple"
                  maxTagCount={0}
                />
              </BaseForm.Item>
            ) : null}

            <BaseText
              children={`Bạn có chắc chắn phê duyệt đơn đăng kí này?`}
              fontWeight="medium"
              fontSize="xs"
            />
            <BaseText
              children={`Lưu ý: Sau khi phê duyệt, bạn có thể thay đổi trạng thái của đoàn viên trong hoạt động`}
              colorText="statesOrangeColor"
              opacity="0.8"
            />
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}
