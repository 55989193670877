import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { LOGOUT_PATH, PROFILE_PATH } from 'app/components/router/route-path'
import { useAppSelector } from 'app/redux/hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ChangePasswordModalLayout } from './ChangePassword'
import * as S from './ProfileOverlay.styles'

export const ItemProfileOverplay = ({
  icon,
  text,
  onClick,
  color,
}: {
  icon: string
  text: string
  onClick?: () => void
  color?: string
}) => {
  return (
    <S.ItemWrapper
      align={'middle'}
      justify={'start'}
      onClick={onClick}
      gutter={15}
      wrap={false}
    >
      <BaseImage src={icon} preview={false} width={20} height={20} />
      <BaseCol>
        <S.Text $color={color}>{text}</S.Text>
      </BaseCol>
    </S.ItemWrapper>
  )
}

export const ProfileOverlay: React.FC<{
  onHide?: () => void
  showModal?: () => void
}> = ({ onHide }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const user = useAppSelector(state => state?.user)?.profile

  const onPressProfile = () => {
    navigate(PROFILE_PATH)
    onHide?.()
  }

  const onPressLogout = () => {
    navigate(LOGOUT_PATH)
    onHide?.()
  }

  return (
    <>
      <S.RootWrapper direction="vertical" size={8}>
        <BaseSpace direction="vertical" size={8}>
          <S.BaseAvatarWrapper alt={user?.full_name} src={user?.avatar} />
          <BaseText
            children={user?.full_name}
            fontSize="xl"
            fontWeight="semibold"
          />
          <BaseText
            children={
              user?.roles?.length
                ? user?.roles?.map(role => role?.name).join(' & ')
                : '-'
            }
            fontWeight="medium"
            opacity="0.7"
          />
        </BaseSpace>
        <S.ItemsDivider />
        <ItemProfileOverplay
          icon={R.images.ic_user}
          onClick={onPressProfile}
          text={t(R.strings.profile)}
        />

        <ChangePasswordModalLayout />

        <ItemProfileOverplay
          icon={R.images.ic_logout}
          onClick={onPressLogout}
          text={t(R.strings.logout)}
        />
      </S.RootWrapper>
    </>
  )
}
