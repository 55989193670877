import { IRefModal } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormUpdateRole, IProps } from './type'
import { useGetPermissionList, useUpdateRole } from 'app/react-query/hook/role'
import { IPayloadUpdate, IPermission } from 'app/api/role/model'
import { notificationController } from 'app/controllers/notification-controller'
import R from 'app/assets/R'
import { usePagination } from 'app/hook'

export const useHook = ({ role }: IProps) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IFormUpdateRole>()
  const modalRef = useRef<IRefModal>({})
  const { data: dataPermission, isLoading: isLoadingPermission } =
    useGetPermissionList()

  const { flattenDataList } = usePagination()

  const permissions: IPermission[] = useMemo(() => {
    return flattenDataList(dataPermission)?.data
  }, [dataPermission])

  const { mutateAsync: mutateAsyncUpdate, isLoading } = useUpdateRole()

  useEffect(() => {
    form?.setFieldsValue({
      name: role?.name,
      description: role?.description,
      isActive: role?.is_active,
      permissionIds: role?.permissions?.map(item => item?.id) ?? [],
    })
  }, [role])

  const handleSubmit = async (values: IFormUpdateRole) => {
    const newAccount: IPayloadUpdate = {
      id: role?.id,
      name: values?.name,
      description: values?.description,
      is_active: values?.isActive,
      permissionIds: values?.permissionIds,
    }

    await mutateAsyncUpdate?.(newAccount)
    modalRef?.current?.hide?.()
    form?.resetFields()
    notificationController.success({
      message: t(R.strings.success),
    })
  }

  return {
    form,
    modalRef,
    handleSubmit,
    isLoading,

    permissions,
    isLoadingPermission,
  }
}
