import {
  requestGetTradeUnionMemberDetail
} from 'app/api/tradeUnionMembers'
import { ITradeUnionMember } from 'app/api/tradeUnionMembers/model'
import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { Loading } from 'app/components/common/Loading'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { _DEV_ } from 'app/constant'
import { ResponseType } from 'parkway-web-common'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import {
  InfoLayout,
  ListActionEventLayout,
  OtherRewardLayout,
  RewardOfEventLayout,
} from './layouts'
import * as S from './styles'
import { BaseCol } from 'app/components/common/BaseCol'
import { UpdateTumModal } from '../components'

export const FormTradeUnionMemberDetails: React.FC = () => {
  const { state } = useLocation()

  const tum: ITradeUnionMember = useMemo(() => {
    return state?.tum
  }, [state])

  const [data, setData] = useState<ITradeUnionMember | undefined>(undefined)
  const [loading, setLoading] = useState(false)

  const getDetailData = async () => {
    if (!tum?.id) return
    try {
      setLoading(true)
      const res: ResponseType<ITradeUnionMember> =
        await requestGetTradeUnionMemberDetail({ id: tum?.id })

      setData(res.data)
    } catch (error) {
      _DEV_ && console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getDetailData()
    }, 300)

    return () => clearTimeout(timer)
  }, [tum])

  return (
    <S.FormContent>
      <HeaderPage
        titleI18nKey={R.strings.union_members_detail}
        rightComponent={
          <BaseCol>
            <UpdateTumModal tum={data} isReloadPage/>
          </BaseCol>
        }
      />
      <BaseSpace>
        <InfoLayout tum={data} />
        <RewardOfEventLayout tum={tum} />
        <OtherRewardLayout tum={tum} />
        <ListActionEventLayout tum={tum} />
      </BaseSpace>

      {loading ? <Loading isAbsolute size="small" /> : null}
    </S.FormContent>
  )
}
