import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IFilterUnitList } from '../../type'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { UnitType } from 'app/api/unit/model'

interface IProps {
  onChangeFilter?: (filter: IFilterUnitList) => void
  filter?: IFilterUnitList
}

export const FilterLayout: React.FC<IProps> = ({ filter, onChangeFilter }) => {
  const { t } = useTranslation()

  const onChangeSearch = (value: string) => {
    onChangeFilter?.({ ...filter, search: value })
  }

  const onChangeUnitType = (value: UnitType) => {
    onChangeFilter?.({ ...filter, type: value })
  }

  return (
    <BaseForm>
      <BaseRow gutter={[16, 8]}>
        <BaseCol xl={6}>
          <BaseForm.Item hiddenLabel>
            <BaseInput
              placeholder={t(R.strings.search_by_name_description)}
              value={filter?.search}
              onChange={e => onChangeSearch?.(e?.target?.value)}
              allowClear
            />
          </BaseForm.Item>
        </BaseCol>

        <BaseCol xl={6}>
          <BaseForm.Item hiddenLabel>
            <BaseSelect
              placeholder={'Chọn loại đơn vị'}
              showSearch
              options={[
                {
                  value: UnitType.DEPARTMENT,
                  label: 'Tổ công đoàn',
                },
                {
                  value: UnitType.UNION_GROUP,
                  label: 'Công đoàn bộ phận',
                },
              ]}
              onChange={value => onChangeUnitType(value as UnitType)}
              allowClear
            />
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>
    </BaseForm>
  )
}
