import { IParamsGetRole } from 'app/api/role/model'

export const RoleKeys = {
  allRoles: ['Roles'] as const,
  listRoles: () => [...RoleKeys.allRoles, 'listRoles'] as const,
  listRole: (filter?: IParamsGetRole) =>
    [...RoleKeys.listRoles(), { filter }] as const,

    // permission key
  allPermissions: ['Permissions'] as const,
  listPermissions: () => [...RoleKeys.allPermissions, 'listPermissions'] as const,
}
