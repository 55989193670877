/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react'
import { IActivityEvent, TypeColumn } from '../../../../type'
import BaseText from 'app/components/common/BaseText'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import styled from 'styled-components'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { useUpdateEventContext } from '../../../../context'
import dayjs from 'dayjs'
import { BaseButton } from 'app/components/common/BaseButton'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { initialColumnsActivity } from '../../../../modals/ModalAddActivity/contants'

interface IProps {
  activity: IActivityEvent
  index: number
}

export const ColumnTumActivity = ({ activity, index }: IProps) => {
  const { updateDataTumInActivityByIndex } = useUpdateEventContext()

  const data = useMemo(() => {
    return activity?.data?.map((item, index) => {
      return {
        ...item,
        key: item?.id,
        index: index + 1,
      }
    })
  }, [activity?.data])

  const onChangeDataTum = (indexColumn: number, name: string, value: any) => {
    const newData = [...(activity?.data ?? [])]
    newData[indexColumn][name] = value
    updateDataTumInActivityByIndex?.(index, newData)
  }

  const onDeleteDataTum = (indexColumn: number) => {
    const newData = [...(activity?.data ?? [])]
    newData.splice(indexColumn, 1)
    updateDataTumInActivityByIndex?.(index, newData)
  }

  const columns = useMemo(() => {
    if (activity?.data?.length && !activity?.columns?.length) {
      return initialColumnsActivity
    }

    if (activity?.columns?.length) {
      return activity?.columns
    }

    return []
  }, [activity.columns])

  return columns?.length ? (
    <BaseTableManagement
      columns={[
        ...((columns?.map((column, index) => {
          return {
            title: column?.name,
            dataIndex: column?.name,
            key: column?.keyOfMainData ?? column?.name,
            fixed: index === 0 ? 'left' : undefined,
            className: 'big-column',
            render: (_: any, record?: any) => {
              if (!column?.keyOfMainData) {
                switch (column?.type) {
                  case TypeColumn.STRING: {
                    return (
                      <BaseFormItem hiddenLabel>
                        <BaseInput
                          value={record?.[column?.name ?? '']}
                          placeholder={column?.name}
                          onChange={e =>
                            onChangeDataTum(
                              index,
                              column?.name ?? '',
                              e?.target?.value,
                            )
                          }
                        />
                      </BaseFormItem>
                    )
                  }
                  case TypeColumn.NUMBER: {
                    return (
                      <BaseFormItem hiddenLabel>
                        <BaseInputNumberWrapper
                          value={record?.[column?.name ?? '']}
                          placeholder={column?.name}
                          onChange={value =>
                            onChangeDataTum(index, column?.name ?? '', value)
                          }
                        />
                      </BaseFormItem>
                    )
                  }
                  case TypeColumn.DATE: {
                    return (
                      <BaseFormItem hiddenLabel>
                        <DatePickerWrapper
                          value={
                            record?.[column?.name ?? '']
                              ? dayjs(record?.[column?.name ?? ''])
                              : undefined
                          }
                          placeholder={column?.name}
                          onChange={value =>
                            onChangeDataTum(index, column?.name ?? '', value)
                          }
                        />
                      </BaseFormItem>
                    )
                  }

                  case TypeColumn.OTHER: {
                    return (
                      <BaseFormItem hiddenLabel>
                        <BaseInput
                          value={record?.[column?.name ?? '']}
                          placeholder={column?.name}
                        />
                      </BaseFormItem>
                    )
                  }
                }
              }

              return (
                <BaseText
                  children={record?.[column?.keyOfMainData ?? ''] ?? ''}
                  fontWeight="medium"
                  opacity="0.7"
                />
              )
            },
          }
        }) ?? []) as any),
        {
          title: '',
          dataIndex: 'key',
          key: 'key',
          fixed: 'right',
          width: '10%',
          render: (_, record: { index: number }) => {
            return (
              <BaseButton
                type="text"
                icon={<SvgDeleteDesignIcon />}
                onClick={() => onDeleteDataTum(record?.index - 1)}
              />
            )
          },
        },
      ]}
      dataSource={data}
      className="row-overwrite-style"
      scroll={{ x: true }}
      pagination={{
        pageSize: 5,
      }}
    />
  ) : null
}

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`

const BaseInputNumberWrapper = styled(InputNumber)`
  width: 100%;
`

const DatePickerWrapper = styled(BaseDatePicker)`
  width: 100%;
`
