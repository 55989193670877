import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseModalComponent } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputPassword } from 'app/components/common/inputs/InputPassword'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import {
  BORDER_RADIUS,
  NAME_REGEX,
  PHONE_REGEX,
  REG_EMAIL,
} from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import { useHook } from './hook'

export const CreateModalLayout = () => {
  const { t } = useTranslation()
  const { form, modalRef, handleSubmit, isLoading, isLoadingRole, roles } =
    useHook()
  return (
    <BaseModalComponent
      titleModal={t(R.strings.create_account)}
      titleOpenModal={t(R.strings.create_account)}
      ref={modalRef}
      handleSubmit={form?.submit}
      borderRadiusButtonOpen={BORDER_RADIUS}
      borderButtonOpen={`1px solid ${convertedVariables.neutralBlack9Color}`}
      styleButtonOpen={{
        backgroundColor: convertedVariables?.backgroundColor,
      }}
      isLoadingConfirm={isLoading}
      renderContent={
        <BaseForm
          form={form}
          onFinish={handleSubmit}
          initialValues={{
            isActive: true,
          }}
        >
          <BaseSpace size={5}>
            <BaseForm.Item
              name={'name'}
              required
              label={t(R.strings.full_name)}
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.full_name),
                  }),
                },
                {
                  pattern: NAME_REGEX,
                  message: t(R.strings.regex_name),
                },
              ]}
            >
              <BaseInput placeholder={t(R.strings.enter_full_name)} />
            </BaseForm.Item>
            <BaseForm.Item
              name={'email'}
              label={t(R.strings.email)}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.email),
                  }),
                },
                {
                  pattern: REG_EMAIL,
                  message: t(R.strings.regex_email),
                },
              ]}
            >
              <BaseInput placeholder={t(R.strings.enter_email)} />
            </BaseForm.Item>

            <BaseForm.Item
              name={'phone'}
              label={t(R.strings.phone)}
              rules={[
                {
                  pattern: PHONE_REGEX,
                  message: t(R.strings.regex_phone),
                },
              ]}
            >
              <BaseInput placeholder={t(R.strings.enter_phone)} />
            </BaseForm.Item>

            <BaseRow gutter={[16, 8]}>
              <BaseCol xl={12}>
                <BaseForm.Item
                  label={t(R.strings.password)}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t(R.strings.require_field, {
                        field: t(R.strings.password),
                      }),
                    },
                  ]}
                  required
                >
                  <InputPassword
                    placeholder={t(R.strings.enter_field, {
                      field: t(R.strings.password),
                    })}
                  />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={12}>
                <BaseForm.Item
                  label={t(R.strings.re_password)}
                  name="re_password"
                  rules={[
                    {
                      required: true,
                      message: t(R.strings.require_field, {
                        field: t(R.strings.re_password),
                      }),
                    },
                    {
                      validator: (_, value, callback) => {
                        const { getFieldValue } = form

                        if (value && value !== getFieldValue('password')) {
                          callback(t(R.strings.re_password_not_match))
                        } else {
                          callback()
                        }
                      },
                    },
                  ]}
                  required
                >
                  <InputPassword placeholder={t(R.strings.re_password)} />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>
            <BaseForm.Item
              name={'roleIds'}
              label={t(R.strings.role)}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.role),
                  }),
                },
              ]}
            >
              <BaseSelect
                placeholder={t(R.strings.select_role)}
                showSearch
                loading={isLoadingRole}
                mode="multiple"
                maxTagCount={2}
                options={roles?.map(item => ({
                  label: item?.name,
                  value: item?.id,
                }))}
              />
            </BaseForm.Item>
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}
