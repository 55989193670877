import { ApiClient } from 'app/service/api-service'
import { UnitEndPoint } from './constant'
import { IParamsGetUnit, IPayloadCreateUnit, IPayloadUpdateUnit } from './model'

export const requestGetUnit = (params: IParamsGetUnit) =>
  ApiClient.Get({
    url: UnitEndPoint.UNIT,
    params: { params },
  })

export const requestGetUnitTotal = (params: IParamsGetUnit) =>
  ApiClient.Get({
    url: UnitEndPoint.TOTAL,
    params: { params },
  })

export const requestPostCreateUnit = (body: IPayloadCreateUnit) =>
  ApiClient.Post({
    url: UnitEndPoint.UNIT,
    body,
  })

export const requestUpdateUnit = (body: IPayloadUpdateUnit) =>
  ApiClient.Put({
    url: UnitEndPoint.UPDATE_UNIT.replace(
      'unit_id',
      body?.id?.toString() ?? '',
    ),
    body,
  })

export const requestDeleteUnit = (id: number) =>
  ApiClient.Post({
    url: UnitEndPoint.DELETE_UNIT.replace('unit_id', id.toString()),
    body: {},
  })

// get detail unit
export const requestGetDetailUnit = (id: number) =>
  ApiClient.Get({
    url: UnitEndPoint.DETAIL_UNIT,
    params: { id },
  })
