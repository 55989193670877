import HeaderPage from 'app/components/header/components/HeaderPage'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { getUserInfo } from 'app/redux/slices/userSlice'
import React, { useCallback, useEffect } from 'react'
import {
  DashboardAdminLayout,
  DashboardMemberLayout
} from './layouts'
import { ScrollWrapper } from './styles'

const DashboardContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const userData = useAppSelector(state => state.user)?.profile

  const renderLayout = useCallback(() => {
    if (!userData?.member?.id) {
      return <DashboardAdminLayout />
    }

    return <DashboardMemberLayout />
  }, [userData])()

  useEffect(() => {
    dispatch(getUserInfo())
  }, [])

  return (
    <ScrollWrapper>
      <HeaderPage title="Dashboard" />

      {renderLayout}
    </ScrollWrapper>
  )
}

export default DashboardContainer
