import R from 'app/assets/R'
import { IRefModal } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { usePagination } from 'app/hook'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ITradeUnionMember } from 'app/api/tradeUnionMembers/model'
import { useGetTradeUnionMember } from 'app/react-query/hook/tradeUnionMember'
import { IFormCreateUnit } from './type'
import { useCreateUnit } from 'app/react-query/hook/unit'
import { IPayloadCreateUnit, UnitType } from 'app/api/unit/model'

export const useHook = () => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IFormCreateUnit>()
  const modalRef = useRef<IRefModal>({})
  const [unitType, setUnitType] = useState<UnitType | undefined>(undefined)
  const { data: dataTradeUnionMember, isLoading: isLoadingTradeUnionMember } =
    useGetTradeUnionMember({ page: 1, limit: 10000 })

  const { flattenDataList } = usePagination()

  const tums: ITradeUnionMember[] = useMemo(() => {
    return flattenDataList(dataTradeUnionMember)?.data
  }, [dataTradeUnionMember])

  const { mutateAsync: mutateAsyncUpdate, isLoading } = useCreateUnit()

  const handleSubmit = async (values: IFormCreateUnit) => {
    const newUnit: IPayloadCreateUnit = {
      ...values,
    }

    const res = await mutateAsyncUpdate?.(newUnit)

    if (res) {
      modalRef?.current?.hide?.()
      form?.resetFields()
      notificationController.success({
        message: t(R.strings.success),
      })
    }
  }

  const onChangeUnitType = (value: UnitType) => {
    setUnitType(value)
  }

  return {
    form,
    modalRef,
    handleSubmit,
    isLoading,

    tums,
    isLoadingTradeUnionMember,
    unitType,
    onChangeUnitType,
  }
}
