import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  LAYOUT,
  PADDING
} from 'parkway-web-common'
import styled from 'styled-components'
import { EventStatusEnum } from './type'
import { convertedVariables } from 'app/styles/themes/themeVariables'


export const ScrollWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 250px;
  min-width: 1000px;
  padding: ${LAYOUT.desktop.paddingVertical} ${PADDING.md};
  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
`

export const HeadingTitlePage = styled.p`
  font-size: ${FONT_SIZE.xxl};
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.bold};
  padding-bottom: ${LAYOUT.desktop.headerPadding};
`


export const StatusEventWrapper = styled.div<{ $status?: EventStatusEnum }>`
  padding: 3px ${PADDING.xxs};
  border: 1px solid
    ${props => {
      switch (props?.$status) {
        case EventStatusEnum.NOT_OPEN:
          return convertedVariables.otherBlueColor
        case EventStatusEnum.REGISTRATION:
          return convertedVariables.otherBlueColor
        case EventStatusEnum.REGISTRATION_COMPLETED:
          return convertedVariables.statesOrangeColor
        case EventStatusEnum.IN_PROGRESS:
          return convertedVariables.statesGreenColor
        case EventStatusEnum.FINISHED:
          return convertedVariables.statesRedColor
      }
    }};

  background-color: ${props => {
    switch (props?.$status) {
      case EventStatusEnum.NOT_OPEN:
        return convertedVariables.statesBlueLightColor
      case EventStatusEnum.REGISTRATION:
        return convertedVariables.statesBlueLightColor
      case EventStatusEnum.REGISTRATION_COMPLETED:
        return convertedVariables.statesOrangeLightColor
      case EventStatusEnum.IN_PROGRESS:
        return convertedVariables.statesGreenLightColor
      case EventStatusEnum.FINISHED:
        return convertedVariables.statesRedLightColor
    }
  }};

  border-radius: ${BORDER_RADIUS};
  width: fit-content;
`

