// Authentication
export const AUTH_PATH = '/auth'
export const LOGOUT_PATH = '/logout'
export const LOGIN_PATH = 'login'
export const AUTH_LOGIN_PATH = '/auth/login'
export const AUTH_LOGIN_WITH_TOKEN = '/auth/login-with-token'
// profile
export const PROFILE_PATH = '/profile'

// Main
export const DASHBOARD_PATH = '/'
export const ACCOUNT_MANAGEMENT_PATH = '/account-management'
export const TRADE_UNION_MEMBER_MANAGEMENT_PATH =
  '/trade-union-members-management'
export const HRM_TRADE_UNION_MEMBER_MANAGEMENT_PATH =
  '/hrm-trade-union-members-management'
export const APPROVE_UNION_MEMBERS_PATH = '/approve-union-members'
export const EVENT_MANAGEMENT_PATH = '/event-management'
export const EVENT_MANAGEMENT_REPORT_PATH = '/event-management/event-report'
export const TRADE_UNION_MEMBER_DETAILS_PATH =
  '/trade-union-members-management/union_members_detail'

export const REPORT_PATH = '/report'
export const REPORT_EVENT_BY_STAGES_PATH = '/report/event-reporting-by-stage'

// role management
export const ROLE_MANAGEMENT_PATH = '/role-management'
export const ROLE_MANAGEMENT_DETAILS_PATH = '/role-management/role-details'

// event
export const TUM_PATH = '/tum'
export const EVENT_MANAGEMENT_DETAILS_PATH = '/event-management/detail_event'
export const REGISTER_ACTIVITY_PATH = '/register-join-activity'

export const CHECK_IN_ACTION_PATH_WITHOUT_ID = '/check-in-action'
export const CHECK_IN_ACTION_PATH = '/check-in-action/:id'
export const CREATE_EVENT_PATH = '/event-management/create-event'
export const UPDATE_EVENT_PATH = '/event-management/update-event'

// unit
export const UNIT_MANAGEMENT_PATH = '/unit-management'
export const UNIT_MANAGEMENT_DETAILS_PATH = '/unit-management/unit-detail'

// new ueh
export const NEW_UEH_PATH = 'https://congdoan.ueh.edu.vn/'
export const TIN_TIEU_DIEM_PATH = 'https://congdoan.ueh.edu.vn/tin-tieu-diem/'
export const CONG_TAC_NU_CONG_PATH =
  'https://congdoan.ueh.edu.vn/cong-tac-nu-cong/'
export const TUYEN_GIAO_VAN_THE_PATH =
  'https://congdoan.ueh.edu.vn/tuyen-giao-van-the/'
export const LUAT_DIEU_LE_CONG_DOAN_PATH =
  'https://congdoan.ueh.edu.vn/luat-dieu-le-cong-doan/'
export const THI_DUA_KHEN_THUONG_PATH =
  'https://congdoan.ueh.edu.vn/thi-dua-khen-thuong/'
export const CHINH_SACH_PHAP_LUAT_HO_TRO_CHUYEN_MON_PATH =
  'https://congdoan.ueh.edu.vn/chinh-sach-phap-luat-ho-tro-chuyen-mon/'
export const VAN_BAN_BIEU_MAU_PATH =
  'https://congdoan.ueh.edu.vn/van-ban-bieu-mau/'

export const LOGIN_BY_UEH =
  'https://login.ueh.edu.vn?returnURL=https://api.ueh.thailairnd.com.vn/auth/login-with-token'

export const FANPAGE_CONG_DOAN = 'https://www.facebook.com/congdoanueh';
export const LINK_THU_MUC_ANH_CLIP_SU_KIEN = 'https://congdoan.ueh.edu.vn/hinh-anh/'
