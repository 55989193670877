import { requestGetRewardByTumId } from 'app/api/tradeUnionMembers'
import { ITradeUnionMember } from 'app/api/tradeUnionMembers/model'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { _DEV_ } from 'app/constant'
import { IRewardActivity } from 'app/containers/EventManagement/layouts/UpdateEvent/type'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'

export const RewardOfEventLayout = ({ tum }: { tum?: ITradeUnionMember }) => {
  const [isLoading, setIsLoading] = useState(false)

  const [listReward, setListReward] = useState<IRewardActivity[]>([])

  const getData = async () => {
    if (!tum?.id) return
    setIsLoading(true)
    try {
      const rewardResponse: ResponseType<IRewardActivity[]> =
        await requestGetRewardByTumId({ id: tum?.id })

      setListReward(rewardResponse?.data || [])
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [tum])

  const dataReward = useMemo(() => {
    return listReward.map((item, index) => ({
      ...item,
      key: index + 1,
    }))
  }, [listReward])

  return (
    <SectionBase title="Giải thưởng cá nhân">
      <BaseTableManagement
        columns={[
          convertColumnTable<IRewardActivity>({
            key: 'id',
            title: 'STT',
            classNameWidthColumnOverwrite: 'number-column',
            render: (_, record, index) => {
              return (
                <BaseText
                  fontWeight="medium"
                  opacity="0.7"
                  children={index + 1}
                />
              )
            },
          }),
          convertColumnTable<IRewardActivity>({
            key: 'name',
            title: 'Tên giải',
            classNameWidthColumnOverwrite: 'very-big-column',
            render: (_, record) => {
              return (
                <BaseText
                  fontWeight="semibold"
                  children={record?.name || '-'}
                />
              )
            },
          }),
          convertColumnTable<IRewardActivity>({
            key: 'activity',
            title: 'Tên hoạt động',
            classNameWidthColumnOverwrite: 'very-big-column',
            render: (_, record) => {
              return (
                <BaseText
                  fontWeight="medium"
                  children={record?.activity?.title ?? '-'}
                  colorText="statesGreenColor"
                />
              )
            },
          }),
        ]}
        className="row-overwrite-style"
        dataSource={dataReward}
        loading={isLoading}
        pagination={{
          pageSize: 5,
        }}
      />
    </SectionBase>
  )
}
