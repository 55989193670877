const icons = {
    
    addNew: require("./images/addNew.png"),
    avatar: require("./images/avatar.png"),
    ic_avatar: require("./images/ic_avatar.png"),
    ic_bell: require("./images/ic_bell.png"),
    ic_chevron_down: require("./images/ic_chevron_down.png"),
    ic_connecttion: require("./images/ic_connecttion.png"),
    ic_contract: require("./images/ic_contract.png"),
    ic_copy: require("./images/ic_copy.png"),
    ic_customer: require("./images/ic_customer.png"),
    ic_leading: require("./images/ic_leading.png"),
    ic_logo_parkway: require("./images/ic_logo_parkway.png"),
    ic_logo_parkway_collapsed: require("./images/ic_logo_parkway_collapsed.png"),
    ic_logout: require("./images/ic_logout.png"),
    ic_parkway: require("./images/ic_parkway.png"),
    ic_password: require("./images/ic_password.png"),
    ic_playstore: require("./images/ic_playstore.png"),
    ic_ueh: require("./images/ic_ueh.png"),
    ic_united_kingdom_flag: require("./images/ic_united_kingdom_flag.png"),
    ic_user: require("./images/ic_user.png"),
    ic_verified: require("./images/ic_verified.png"),
    ic_vietnam_flag: require("./images/ic_vietnam_flag.png"),
    img_bg_auth: require("./images/img_bg_auth.png"),
    next: require("./images/next.png"),
    prev: require("./images/prev.png")
    }
export default icons