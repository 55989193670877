/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestCreateTradeUnionMember,
  requestDeleteTradeUnionMember,
  requestGetTradeUnionMemberList,
  requestUpdateTradeUnionMember, // Đảm bảo bạn import đúng hàm API
} from 'app/api/tradeUnionMembers'
import {
  IParamsGetTradeUnionMember,
  IPayloadCreateTradeUnionMember,
  IPayloadUpdateTradeUnionMember,
  IResponseTradeUnionMemberList,
  ITradeUnionMember,
} from 'app/api/tradeUnionMembers/model'
import { getToken } from 'app/service/storage/storage-service'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { AccountKeys } from '../query-key/account'
import { TradeUnionMemberKeys } from '../query-key/tradeUnionMember'

export function useGetTradeUnionMember(
  params: IParamsGetTradeUnionMember,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<ITradeUnionMember[]>, undefined>(
    TradeUnionMemberKeys.listTradeUnionMember(params),
    async () => {
      try {
        const res: IResponseTradeUnionMemberList =
          await requestGetTradeUnionMemberList(params)
        const data: ITradeUnionMember[] = res?.data?.rows || []
        return {
          data,
          total: res?.data?.count ?? 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

// create trade union member
export const useCreateTum = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadCreateTradeUnionMember, unknown>(
    async (body: IPayloadCreateTradeUnionMember) => {
      return await requestCreateTradeUnionMember(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: TradeUnionMemberKeys.allTradeUnionMember,
        })

        query.invalidateQueries({
          queryKey: AccountKeys.allAccounts,
        })
      },
    },
  )
}

export const useUpdateTum = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadUpdateTradeUnionMember, unknown>(
    async (
      body?: IPayloadUpdateTradeUnionMember
    ) => {
      return await requestUpdateTradeUnionMember(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: TradeUnionMemberKeys.allTradeUnionMember,
        })
        query.invalidateQueries({
          queryKey: AccountKeys.allAccounts,
        })
      },
    },
  )
}

// delete trade union member
export const useDeleteTum = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, { id: string }, unknown>(
    async (body: { id: string }) => {
      await requestDeleteTradeUnionMember(body?.id)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: TradeUnionMemberKeys.allTradeUnionMember,
        })
        query.invalidateQueries({
          queryKey: AccountKeys.allAccounts,
        })
      },
    },
  )
}
