import { memo } from 'react'
import { isEqual } from 'lodash'
import DetailEventManagementContainer from 'app/containers/EventManagement/DetailEvent'
import { PageTitle } from 'app/components/common/PageTitle'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

const DetailEventManagementCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.detail_event)}</PageTitle>
      <DetailEventManagementContainer />
    </>
  )
}
export const DetailEventManagementPage = memo(DetailEventManagementCpn, isEqual)
