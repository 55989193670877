import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EventStatusEnum, IFilterEventList } from '../../type'
import { TypeEventEnum } from 'app/api/event/model'
import styled from 'styled-components'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { DefineTypeEventEnum } from 'app/api/event/constant'

interface IProps {
  onChangeFilter?: (filter: IFilterEventList) => void
  filter?: IFilterEventList
}

export const FilterLayout: React.FC<IProps> = ({ filter, onChangeFilter }) => {
  const { t } = useTranslation()

  const onChangeSearch = (value: string) => {
    onChangeFilter?.({ ...filter, search: value })
  }

  const onChangeStatus = (value: EventStatusEnum[]) => {
    onChangeFilter?.({ ...filter, status: value })
  }

  const onChangeType = (value: TypeEventEnum[]) => {
    onChangeFilter?.({ ...filter, type: value })
  }

  return (
    <BaseForm>
      <BaseRow gutter={[16, 8]}>
        <BaseCol xl={8}>
          <BaseFormItem hiddenLabel>
            <BaseInput
              placeholder={t(R.strings.search)}
              value={filter?.search}
              onChange={e => onChangeSearch?.(e?.target?.value)}
              allowClear
            />
          </BaseFormItem>
        </BaseCol>
        
        <BaseCol xl={8}>
          <BaseFormItem hiddenLabel>
            <BaseSelect
              mode="multiple"
              placeholder={'Tất cả trạng thái'}
              options={[
                {
                  label: t(R.strings.not_open_register),
                  value: EventStatusEnum.NOT_OPEN,
                },
                {
                  label: t(R.strings.registration),
                  value: EventStatusEnum.REGISTRATION,
                },
                {
                  label: t(R.strings.registration_completed),
                  value: EventStatusEnum.REGISTRATION_COMPLETED,
                },
                {
                  label: t(R.strings.in_event),
                  value: EventStatusEnum.IN_PROGRESS,
                },
                {
                  label: t(R.strings.finished),
                  value: EventStatusEnum.FINISHED,
                },
              ]}
              onChange={value => onChangeStatus(value as EventStatusEnum[])}
              allowClear
            />
          </BaseFormItem>
        </BaseCol>

        <BaseCol xl={8}>
          <BaseFormItem hiddenLabel>
            <BaseSelect
              mode="multiple"
              placeholder={'Tất cả loại sự kiện'}
              options={[
                {
                  label: DefineTypeEventEnum[TypeEventEnum.CARE]?.text,
                  value: TypeEventEnum.CARE,
                },
                {
                  label: DefineTypeEventEnum[TypeEventEnum.ACTIVITY]?.text,
                  value: TypeEventEnum.ACTIVITY,
                },
                {
                  label: DefineTypeEventEnum[TypeEventEnum.NEWS]?.text,
                  value: TypeEventEnum.NEWS,
                },
                {
                  label: DefineTypeEventEnum[TypeEventEnum.TRAINING]?.text,
                  value: TypeEventEnum.TRAINING,
                },
              ]}
              onChange={value => onChangeType(value as TypeEventEnum[])}
              allowClear
            />
          </BaseFormItem>
        </BaseCol>
      </BaseRow>
    </BaseForm>
  )
}

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`
