import { ApiClient } from 'app/service/api-service'
import { TradeUnionMemberEndpoint } from './constant'
import {
  IParamsGetTradeUnionMember,
  IPayloadCreateRewardTum,
  IPayloadCreateTradeUnionMember,
  IPayloadUpdateTradeUnionMember,
  ITradeUnionMember,
} from './model'
import { TumHrmEndpoint } from '../tumHRM/constant'

export const requestGetTradeUnionMemberList = (
  params: IParamsGetTradeUnionMember,
) =>
  ApiClient.Get({
    url: TradeUnionMemberEndpoint.GET_TRADE_UNION_MEMBER,
    params: { params },
  })

  export const requestGetTradeUnionMemberDetail = (
    params: {id: string},
  ) =>
    ApiClient.Get({
      url: TradeUnionMemberEndpoint.TRADE_UNION_MEMBER_GET_BY_ID,
      params: { params },
    })

export const requestGetTumFomUeh = () =>
  ApiClient.Get({
    url: TradeUnionMemberEndpoint.GET_DATA_FROM_UEH,
    params: {},
  })


  export const requestGetTumCanSync = () =>
    ApiClient.Get({
      url: TumHrmEndpoint.GET_TUM_HRM_IS_SYNC,
      params: {},
    })

export const requestDeleteTradeUnionMember = (id: string) =>
  ApiClient.Put({
    url:  `${TradeUnionMemberEndpoint.TRADE_UNION_MEMBER_DELETE}?${id}`,
    body: { id },
  })

export const requestUpdateTradeUnionMember = (body?: IPayloadUpdateTradeUnionMember) =>
  ApiClient.Put({
    url: TradeUnionMemberEndpoint.TRADE_UNION_MEMBER_UPDATE,
    body,
  })

export const requestSyncTradeUnionMember = (body?: {
  data: ITradeUnionMember[]
}) =>
  ApiClient.Post({
    url: TradeUnionMemberEndpoint.TRADE_UNION_MEMBER_SYNC,
    body,
  })

export const requestCreateTradeUnionMember = (
  body?: IPayloadCreateTradeUnionMember,
) =>
  ApiClient.Post({
    url: TradeUnionMemberEndpoint.TRADE_UNION_MEMBER_CREATE,
    body,
  })

export const requestGetActivityByTumId = (params: { id?: string }) =>
  ApiClient.Get({
    url: TradeUnionMemberEndpoint.GET_ACTION_BY_TUM_ID,
    params: { params },
  })

export const requestGetRewardByTumId = (params: { id?: string }) =>
  ApiClient.Get({
    url: TradeUnionMemberEndpoint.GET_REWARD_BY_TUM_ID,
    params: { params },
  })

export const requestCreateRewardTum = (body?: IPayloadCreateRewardTum) =>
  ApiClient.Post({
    url: TradeUnionMemberEndpoint.CREATE_REWARD_TUM,
    body,
  })

export const requestDeleteRewardTum = (body?: { id: string }) =>
  ApiClient.Post({
    url: TradeUnionMemberEndpoint.DELETE_REWARD_TUM,
    body,
  })

export const requestGetRewardTumByTumId = (params: { id?: string }) =>
  ApiClient.Get({
    url: TradeUnionMemberEndpoint.REWARD_TUM_BY_TUM_ID,
    params: { params },
  })
