/* eslint-disable @typescript-eslint/no-explicit-any */
import { AvatarProps } from 'antd'
import { Rule } from 'antd/es/form'
import R from 'app/assets/R'
import {
  backgroundColorAvatarMap,
  colorAvatarMap,
} from 'app/components/common/BaseAvatar/contant'
import { BaseBadgeProps } from 'app/components/common/BaseBadge'
import { NotificationType } from 'app/components/common/BaseNotification'
import {
  getGetStartEndDateOfWeek,
  getStartEndDateOfMonth,
} from 'app/components/common/BaseWeekPicker'
import { IResponseFilter } from 'app/components/tables/BaseTableReport/FilterReport/type'
import { Dayjs } from 'dayjs'
import { flatten } from 'lodash'
import {
  FORMAT_CENTER_YYYY_MM_DD,
  PHONE_REGEX,
  REG_EMAIL,
  Severity,
  moment,
} from 'parkway-web-common'
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InfiniteData } from 'react-query'

interface NotificationSeverity {
  id: number
  name: NotificationType
}

export const defineColorBySeverity = (
  severity: NotificationType | undefined,
  rgb = false,
): string => {
  const postfix = rgb ? 'rgb-color' : 'color'
  switch (severity) {
    case 'error':
    case 'warning':
    case 'success':
      return `var(--${severity}-${postfix})`
    case 'info':
    default:
      return `var(--primary-${postfix})`
  }
}

export const notificationsSeverities: NotificationSeverity[] = [
  { id: 0, name: 'info' },
  { id: 1, name: 'success' },
  { id: 2, name: 'warning' },
  { id: 3, name: 'error' },
  { id: 4, name: 'mention' },
]

export const normalizeProp = (
  prop: string | number | [number, number],
): string =>
  typeof prop === 'number'
    ? `${prop}px`
    : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString()

export const mapBadgeStatus = (status: BaseBadgeProps['status']): Severity => {
  if (!status || status === 'default' || status === 'processing') {
    return 'info'
  }

  return status
}

interface UseMountedReturnVal {
  isMounted: MutableRefObject<boolean>
}

export const useMounted = (): UseMountedReturnVal => {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  return { isMounted }
}

export const usePagination = () => {
  function mapDataResponse<T>(data: InfiniteData<T>): T {
    return data?.pages?.[0]
  }

  const flattenData = (data?: InfiniteData<any>) => {
    if (!data) {
      return []
    }

    return flatten(data?.pages?.map(page => page))?.filter(
      (obj: any) => obj !== undefined,
    )
  }

  const flattenDataList = (data?: InfiniteData<any>) => {
    if (!data) {
      return []
    }

    return data?.pages?.[0]
  }

  return {
    mapDataResponse,
    flattenData,
    flattenDataList,
  }
}

export const initFilterTable = (): IResponseFilter => {
  const minMaxWeed = getStartEndDateOfMonth({
    month: moment().month() + 1,
    year: moment().year(),
  })

  const { startOfWeek, endOfWeek } = getGetStartEndDateOfWeek(
    moment().format(FORMAT_CENTER_YYYY_MM_DD),
    minMaxWeed.minDayOfMonth,
    minMaxWeed.maxDayOfMonth,
  )

  return {
    year: `${moment().year()}`,
    month: `${moment().month() + 1}`,
    week: {
      startDate: startOfWeek,
      endDate: endOfWeek,
    },
  }
}

export function convertToTitleCase(input: string): string {
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase()
}

export const mapColorWithFirstCharacter = (firstCharacter: string) => {
  const character = firstCharacter.toUpperCase()
  const backgroundColor = backgroundColorAvatarMap[character]
  const color = colorAvatarMap[character]
  return { backgroundColor, color }
}

export const useBaseImage = ({ alt, src }: AvatarProps) => {
  const firstCharacterOfName = useMemo(() => {
    if (src) return 'P'

    if (!alt) return 'P'

    const nameSplit = alt?.split(' ') ?? []
    const name = nameSplit?.[0] ?? ''
    return name.charAt(0)
  }, [alt, src])

  const color = useMemo(() => {
    if (src) return ''
    return mapColorWithFirstCharacter(firstCharacterOfName).color
  }, [firstCharacterOfName, src])

  const backgroundColor = useMemo(() => {
    if (src) return ''
    return mapColorWithFirstCharacter(firstCharacterOfName).backgroundColor
  }, [firstCharacterOfName, src])

  return { color, backgroundColor, firstCharacterOfName }
}

export const getBase64 = (file: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file as Blob)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })

export function compareArrays(arr1: string[], arr2: string[]): boolean {
  if (arr1.length !== arr2.length) {
    return false
  }
  arr1 = arr1.sort()
  arr2 = arr2.sort()
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }
  return true
}

export const useRuleHook = () => {
  const { t } = useTranslation()
  const getEmailRule = ({
    isRequire,
    field,
  }: {
    field: string
    isRequire?: boolean
  }): Rule[] => {
    let rules: Rule[] = []

    if (isRequire) {
      rules = rules.concat([
        {
          required: true,
          message: t(R.strings.require_field, {
            field,
          }),
        },
      ])
    }

    rules = rules.concat([
      {
        pattern: REG_EMAIL,
        message: t(R.strings.regex_email),
      },
      {
        max: 255,
        message: t(R.strings.maximum_field, {
          count: 255,
        }),
      },
    ])

    return rules
  }

  // get string rule
  const getStringRule = ({
    isRequire,
    field,
    maxLength = 255,
    minLength,
  }: {
    field: string
    isRequire?: boolean
    maxLength?: number
    minLength?: number
  }): Rule[] => {
    let rules: Rule[] = []

    if (isRequire) {
      rules = rules.concat([
        {
          required: true,
          message: t(R.strings.require_field, {
            field,
          }),
        },
      ])
    }

    if (maxLength) {
      rules = rules.concat([
        {
          max: maxLength,
          message: t(R.strings.maximum_field, {
            count: maxLength,
          }),
        },
      ])
    }

    if (minLength) {
      rules = rules.concat([
        {
          min: minLength,
          message: t(R.strings.minimum_field, {
            count: minLength,
          }),
        },
      ])
    }

    return rules
  }

  const getNormalRule = ({
    isRequire,
    field,
  }: {
    field: string
    isRequire?: boolean
    minLength?: number
  }): Rule[] => {
    let rules: Rule[] = []

    if (isRequire) {
      rules = rules.concat([
        {
          required: true,
          message: t(R.strings.require_field, {
            field,
          }),
        },
      ])
    }

    return rules
  }

  // get phone rule
  const getPhoneRule = ({
    isRequire,
    field,
  }: {
    field: string
    isRequire?: boolean
  }): Rule[] => {
    let rules: Rule[] = []

    if (isRequire) {
      rules = rules.concat([
        {
          required: true,
          message: t(R.strings.require_field, {
            field,
          }),
        },
      ])
    }

    rules = rules.concat([
      {
        pattern: PHONE_REGEX,
        message: t(R.strings.regex_phone),
      },
      {
        max: 10,
        message: t(R.strings.maximum_field, {
          count: 10,
        }),
      },
    ])

    return rules
  }

  // get number rule
  const getNumberRule = ({
    isRequire,
    field,
    max,
    min,
  }: {
    field: string
    isRequire?: boolean
    max?: number
    min?: number
  }): Rule[] => {
    let rules: Rule[] = []

    if (isRequire) {
      rules = rules.concat([
        {
          required: true,
          message: t(R.strings.require_field, {
            field,
          }),
        },
      ])
    }

    if (max) {
      rules = rules.concat([
        {
          type: 'number',
          max,
          message: t(R.strings.maximum_field, {
            count: max,
          }),
        },
      ])
    }

    if (min) {
      rules = rules.concat([
        {
          type: 'number',
          min,
          message: t(R.strings.minimum_field, {
            count: min,
          }),
        },
      ])
    }

    return rules
  }

  return {
    getEmailRule,
    getStringRule,
    getPhoneRule,
    getNumberRule,
    getNormalRule
  }
}

export function useDebounce<T>(value: T, delay = 500): T {
  const [debounceValue, setDebounceValue] = useState<T>(value)

  useEffect(() => {
    const handler = setTimeout(() => setDebounceValue(value), delay)

    return () => clearTimeout(handler)
  }, [value, delay])

  return debounceValue
}

export const disabledEndDate = (current: Dayjs, startDate?: Dayjs | null) => {
  if (!startDate) {
    return false // Nếu chưa chọn startDate, không giới hạn ngày
  }
  return current && current.isBefore(startDate, 'day') // Không cho phép chọn ngày trước startDate
}

export const disabledStartDate = (current: Dayjs, endDate?: Dayjs | null) => {
  if (!endDate) {
    return false
  }
  return current && current.isAfter(endDate, 'day')
}

export const disabledTimeStart = (
  current: Dayjs | null,
  endDate?: Dayjs | null,
): any => {
  if (!current || !endDate) {
    return {}
  }

  if (current?.isSame(endDate, 'day') && endDate) {
    const disabledHours =
      Array.from({ length: 24 }, (_, i) =>
        i > (endDate?.hour() ?? 0) ? i : undefined,
      )?.filter(i => i !== undefined) ?? []

    const disabledMinutes =
      Array.from({ length: 60 }, (_, i) =>
        i > (endDate?.minute() ?? 0) ? i : undefined,
      )?.filter(i => i !== undefined) ?? []

    return {
      disabledHours: () => disabledHours,
      disabledMinutes: () => disabledMinutes,
    }
  }
  return {}
}

export const disabledTimeEnd = (
  current: Dayjs | null,
  startDate?: Dayjs | null,
): any => {
  if (!current || !startDate) {
    return {}
  }

  if (current?.isSame(startDate, 'day') && startDate) {
    const disabledHours =
      Array.from({ length: 24 }, (_, i) =>
        i < (startDate?.hour() ?? 0) ? i : undefined,
      )?.filter(i => i !== undefined) ?? []

    const disabledMinutes =
      Array.from({ length: 60 }, (_, i) =>
        i < (startDate?.minute() ?? 0) ? i : undefined,
      )?.filter(i => i !== undefined) ?? []

    return {
      disabledHours: () => disabledHours,
      disabledMinutes: () => disabledMinutes,
    }
  }
  return {}
}
