import { MoreOutlined } from '@ant-design/icons'
import {
  IParamsGetNewTumRegister,
  INewTumRegister,
} from 'app/api/newTumRegister/model'

import R from 'app/assets/R'
import { PermissionEnum } from 'app/common/enum'
import { BaseButton } from 'app/components/common/BaseButton'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { TRADE_UNION_MEMBER_DETAILS_PATH } from 'app/components/router/route-path'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useVerifyPermission } from 'app/helpers/permission.helper'
import { useDebounce, usePagination } from 'app/hook'
import { useGetNewTumRegister } from 'app/react-query/hook/newTumRegister'
import { IResponseQueryList } from 'app/react-query/model/common'
import { Pagination, initialPagination } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { ApproveTumModal, RejectTumModal } from './components'
import { IDataTable } from './type'
import { StatusActivitiesMap } from '../TradeUnionMemberManagement/type'

interface IProps {
  status: string
}

export const useHook = ({ status }: IProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [pagination, setPagination] = useState<Pagination>(initialPagination)
  const [filter, setFilter] = useState<IParamsGetNewTumRegister>({})

  const filterDebounce = useDebounce(filter, 500)

  const { flattenDataList } = usePagination()
  const { data: dataApi, isLoading } = useGetNewTumRegister({
    search: filterDebounce?.search,
    page: pagination?.current,
    limit: pagination?.pageSize,
    status: status,
  })

  const flattenData = useMemo(() => {
    const res: IResponseQueryList<INewTumRegister[]> = flattenDataList(dataApi)

    return res
  }, [dataApi])

  const data: IDataTable[] = useMemo(() => {
    return (
      flattenData?.data?.map((item, index) => ({
        ...item,
        key: index + 1,
      })) ?? []
    )
  }, [flattenData])

  const total = useMemo(() => {
    return flattenData?.total ?? data?.length
  }, [flattenData, data])

  const onChangeFilter = (newFilter: IParamsGetNewTumRegister) => {
    setFilter(newFilter)
    setPagination(prev => ({ ...prev, current: 1 }))
  }

  const { user, verifyPermissionAndRedirect, verifyPermission } =
    useVerifyPermission()

  useEffect(() => {
    verifyPermissionAndRedirect(PermissionEnum.ReadTradeUnionMember)
  }, [user])

  const isPermissionUpdate = useMemo(() => {
    return verifyPermission(PermissionEnum.UpdateTradeUnionMember)
  }, [user])

  const columns = useMemo(() => {
    return [
      convertColumnTable<INewTumRegister>({
        key: 'fullName',
        title: t(R.strings.full_name),
        fixed: 'left',
        render: (_, record) => {
          return (
            <RenderValueTable
              type="Link"
              value={record?.fullName}
              onClick={() =>
                navigate(TRADE_UNION_MEMBER_DETAILS_PATH, {
                  state: {
                    tum: record,
                  },
                })
              }
            />
          )
        },
      }),
      convertColumnTable<INewTumRegister>({
        key: 'mql',
        title: t(R.strings.id_management),
        fixed: 'left',
        render: (_, record) => {
          return (
            <BaseText fontWeight="semibold" children={record?.mql ?? '-'} />
          )
        },
      }),
      convertColumnTable<INewTumRegister>({
        key: 'email',
        title: t(R.strings.email),
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={record?.email ?? '-'}
            />
          )
        },
      }),
      convertColumnTable<INewTumRegister>({
        key: 'tenDonVi',
        title: 'Đơn vị UEH',
        render: text => {
          return <BaseText fontWeight="medium" opacity="0.7" children={text} />
        },
      }),

      convertColumnTable<INewTumRegister>({
        key: 'gioiTinh',
        title: t(R.strings.gender),
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={record?.gioiTinh ?? '-'}
            />
          )
        },
      }),

      convertColumnTable<INewTumRegister>({
        key: 'cmnd',
        title: t(R.strings.status_union_activities),
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={StatusActivitiesMap?.[record?.tinhTrangSinhHoat ?? ''] ?? '-'}
            />
          )
        },
      }),

      convertColumnTable<INewTumRegister>({
        key: 'dienThoaiDiDong',
        title: t(R.strings.phone),
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={record?.dienThoaiDiDong ?? '-'}
            />
          )
        },
      }),

      convertColumnTable<INewTumRegister>({
        key: 'id',
        width: '5%',
        classNameWidthColumnOverwrite: 'number-column',
        fixed: 'right',
        render: (_, record) => {
          return (
            <>
              <BasePopover
                trigger={'click'}
                content={
                  <>
                    <BaseSpace>
                      {isPermissionUpdate ? (
                        <ApproveTumModal
                          name={record.fullName}
                          id={record.id}
                        />
                      ) : null}

                      {isPermissionUpdate ? (
                        <RejectTumModal name={record.fullName} id={record.id} />
                      ) : null}
                    </BaseSpace>
                  </>
                }
              >
                <BaseButton
                  icon={<MoreOutlined rev={undefined} />}
                  type="text"
                />
              </BasePopover>
            </>
          )
        },
      }),
    ]
  }, [t])

  const handleChangePagination = (pagination: Pagination) => {
    setPagination(prev => ({ ...prev, ...pagination }))
  }

  return {
    columns,
    pagination: {
      ...pagination,
      total,
    },
    onChangeFilter,
    filter,
    isLoading,
    total,
    handleChangePagination,
    data,
  }
}
