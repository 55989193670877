import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseModalComponent } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { NAME_REGEX, PHONE_REGEX, REG_EMAIL } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import { useHook } from './hook'
import { IProps } from './type'

export const UpdateModalLayout = (props: IProps) => {
  const { t } = useTranslation()
  const { form, modalRef, handleSubmit, isLoading, isLoadingRole, roles } =
    useHook(props)
  return (
    <BaseModalComponent
      titleModal={t(R.strings.update_account)}
      titleOpenModal={t(R.strings.update)}
      ref={modalRef}
      handleSubmit={form?.submit}
      isLoadingConfirm={isLoading}
      renderContent={
        <BaseForm form={form} onFinish={handleSubmit}>
          <BaseSpace size={5}>
            <BaseForm.Item
              name={'name'}
              required
              label={t(R.strings.full_name)}
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.full_name),
                  }),
                },
                {
                  pattern: NAME_REGEX,
                  message: t(R.strings.regex_name),
                },
              ]}
            >
              <BaseInput placeholder={t(R.strings.enter_full_name)} />
            </BaseForm.Item>
            <BaseForm.Item
              name={'email'}
              required
              label={t(R.strings.email)}
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.email),
                  }),
                },
                {
                  pattern: REG_EMAIL,
                  message: t(R.strings.regex_email),
                },
              ]}
            >
              <BaseInput placeholder={t(R.strings.enter_email)} disabled />
            </BaseForm.Item>
            <BaseForm.Item
              name={'phone'}
              label={t(R.strings.phone)}
              rules={[
                {
                  pattern: PHONE_REGEX,
                  message: t(R.strings.regex_phone),
                },
              ]}
            >
              <BaseInput placeholder={t(R.strings.enter_phone)} />
            </BaseForm.Item>

            <BaseForm.Item
              name={'roleIds'}
              label={t(R.strings.role)}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.role),
                  }),
                },
              ]}
            >
              <BaseSelect
                placeholder={t(R.strings.select_role)}
                showSearch
                loading={isLoadingRole}
                mode="multiple"
                maxTagCount={2}
                options={roles?.map(item => ({
                  label: item?.name,
                  value: item?.id,
                }))}
              />
            </BaseForm.Item>

            <BaseForm.Item name={'isActive'} label={t(R.strings.status)}>
              <BaseSelect
                defaultValue={true}
                placeholder={t(R.strings.please_select_status)}
                options={[
                  { label: t(R.strings.active_content), value: true },
                  {
                    label: t(R.strings.in_active),
                    value: false,
                  },
                ]}
              />
            </BaseForm.Item>
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}
