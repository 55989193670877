import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { useDeleteAccount } from 'app/react-query/hook/account'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  name?: string
  id?: number
}

export const DeleteModalLayout = (props: IProps) => {
  const { t } = useTranslation()

  const modalRef = useRef<IRefModal>({})

  const { mutateAsync: mutateAsyncDelete, isLoading } = useDeleteAccount()

  const handleDelete = async () => {
    // validate id
    if (!props?.id) {
      return
    }

    try {
      await mutateAsyncDelete({ listId: [props?.id] })
      modalRef.current?.hide?.()
    } catch (error) {
      console.log('Error:', error)
    }
  }

  return (
    <BaseModalComponent
      titleModal={t(R.strings.delete_account)}
      titleOpenModal={t(R.strings.delete)}
      widthModal={400}
      ref={modalRef}
      handleSubmit={handleDelete}
      isLoadingConfirm={isLoading}
      renderContent={
        <BaseSpace>
          <BaseRow gutter={8} align={'middle'} justify={'center'}>
            <BaseCol>
              <BaseText fontWeight="medium" fontSize="xs">
                {t(R.strings.are_you_want_delete_account)}
              </BaseText>
            </BaseCol>
            <BaseCol>
              <BaseText
                children={`"${props?.name ?? ''}"`}
                colorText="statesRedColor"
                fontWeight="medium"
                fontSize="xs"
              />
            </BaseCol>
          </BaseRow>
          <BaseRow align={'middle'} justify={'center'}>
            <BaseText fontWeight="medium" opacity="0.9">
              {t(R.strings.once_the_account_is_locked_it_cannot_be_recovered)}
            </BaseText>
          </BaseRow>
        </BaseSpace>
      }
    />
  )
}
