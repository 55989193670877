import { StatusMemberRegisterWaiting } from 'app/api/event/model'
import { BaseTabs } from 'app/components/common/BaseTabs'
import { PADDING } from 'parkway-web-common'
import styled from 'styled-components'
import {
  ActionOpenRegisterLayout,
  ActionRegisteredByStatusLayout,
} from './layouts'
import { SectionBase } from 'app/components/common/SectionBase'

const RegisterActionForMemberContainer = () => {
  return (
    <RootWrapper>
      <SectionBase>
        <BaseTabs
          tabPosition={'top'}
          items={[
            {
              label: 'Hoạt động đang mở đăng kí',
              children: <ActionOpenRegisterLayout />,
              key: '1',
            },
            {
              label: 'Hoạt động sự kiện đã đăng kí',
              children: (
                <ActionRegisteredByStatusLayout
                  status={StatusMemberRegisterWaiting.WAITING}
                />
              ),
              key: '2',
            },
            {
              label: 'Hoạt động sự kiện đã được duyệt',
              children: (
                <ActionRegisteredByStatusLayout
                  status={StatusMemberRegisterWaiting.ACCEPTED}
                />
              ),
              key: '3',
            },
            {
              label: 'Hoạt động sự kiện đã bị từ chối',
              children: (
                <ActionRegisteredByStatusLayout
                  status={StatusMemberRegisterWaiting.REJECTED}
                />
              ),
              key: '4',
            },
          ]}
        />
      </SectionBase>
    </RootWrapper>
  )
}

export default RegisterActionForMemberContainer

const RootWrapper = styled.div`
  padding: ${PADDING.md};
`
