import { TypeEventEnum } from 'app/api/event/model'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { SectionBase } from 'app/components/common/SectionBase'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEmpty, isEqual } from 'lodash'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useMemo } from 'react'
import styled from 'styled-components'
import { useUpdateEventContext } from '../../context'
import {
  ModalAddActivity,
  ModalAddEditReward,
  ModalAddLocation,
  ModalAddTeam,
  ModalEditActivity,
  ModalSelectTum,
} from '../../modals'
import {
  ColumnTumActivity,
  LocationLayout,
  RewardLayout,
  TeamLayout,
} from './layouts'

export const ActionEvent = () => {
  const {
    activities,
    removeActivityByIndex,
    updateTitleInActiveByIndex,
    updateDataTumInActivityByIndex,
    eventType,
    updateValueCareInActiveByIndex,
    isHaveTeam
  } = useUpdateEventContext()

  const textMemberDisplay = useMemo(() => {
    switch (eventType) {
      case TypeEventEnum.CARE:
        return 'Danh sách đối tượng được chăm lo'
      default:
        return 'Danh sách đối tượng tham gia'
    }
  }, [eventType])

  const titleDisplay = useMemo(() => {
    switch (eventType) {
      case TypeEventEnum.CARE:
        return 'Danh sách hoạt động Chăm lo'
      case TypeEventEnum.TRAINING:
        return 'Danh sách Hội thảo, Tập huấn'
      case TypeEventEnum.ACTIVITY:
        return 'Danh sách hoạt động hội thi'
      default:
        return 'Danh sách hoạt động'
    }
  }, [eventType])

  return (
    <BaseSpace>
      <RootWrapper>
        <BaseRow gutter={[16, 8]} align={'middle'} justify={'space-between'}>
          <BaseCol>
            <BaseText
              children={titleDisplay}
              fontWeight="medium"
              fontSize="xs"
            />
          </BaseCol>

          <BaseCol>
            <ModalAddActivity />
          </BaseCol>
        </BaseRow>

        <ContentWrapper>
          {activities?.length ? (
            activities?.map((item, index) => {
              return (
                <SectionBase key={index}>
                  <BaseCollapse
                    items={[
                      {
                        key: index,
                        label: (
                          <BaseRow gutter={16} align={'middle'}>
                            <BaseCol>
                              <BaseText
                                children={
                                  isEmpty(item?.title)
                                    ? 'Chưa có tên hoạt động'
                                    : item?.title
                                }
                                fontWeight="semibold"
                              />
                            </BaseCol>
                          </BaseRow>
                        ),
                        children: (
                          <BaseSpace
                            style={{
                              backgroundColor:
                                convertedVariables.neutralBlack9Color,
                              padding: 16,
                              borderRadius: 8,
                            }}
                          >
                            <BaseSpace
                              style={{
                                backgroundColor:
                                  convertedVariables.backgroundColor,
                                borderRadius: 8,
                                padding: 16,
                              }}
                            >
                              <BaseFormItem label={'Tên hoạt động'} required>
                                <BaseInput
                                  value={item?.title}
                                  placeholder="Nhập tên hoạt động"
                                  onChange={e =>
                                    updateTitleInActiveByIndex?.(
                                      index,
                                      e.target.value,
                                    )
                                  }
                                />
                              </BaseFormItem>
                            </BaseSpace>

                            {isEqual(eventType, TypeEventEnum.CARE) ? (
                              <BaseFormItem label={'Phần quà chăm lo'}>
                                <BaseInput
                                  value={item?.valueCare}
                                  placeholder="Nhập phần quà"
                                  onChange={e =>
                                    updateValueCareInActiveByIndex?.(
                                      index,
                                      e.target.value,
                                    )
                                  }
                                />
                              </BaseFormItem>
                            ) : null}

                            {(() => {
                              switch (eventType) {
                                case TypeEventEnum.CARE:
                                  return (
                                    <BaseSpace
                                      style={{
                                        backgroundColor:
                                          convertedVariables.backgroundColor,
                                        borderRadius: 8,
                                        padding: 16,
                                      }}
                                    >
                                      <BaseRow
                                        align={'middle'}
                                        justify={'space-between'}
                                        gutter={[16, 8]}
                                      >
                                        <BaseCol>
                                          <BaseText
                                            children={textMemberDisplay}
                                            fontSize="xs"
                                            fontWeight="medium"
                                          />
                                        </BaseCol>
                                        <BaseCol>
                                          <ModalSelectTum
                                            data={item?.data}
                                            onSubmit={data =>
                                              updateDataTumInActivityByIndex?.(
                                                index,
                                                data,
                                              )
                                            }
                                          />
                                        </BaseCol>
                                      </BaseRow>
                                      <ColumnTumActivity
                                        index={index}
                                        activity={item}
                                      />
                                    </BaseSpace>
                                  )
                                case TypeEventEnum.TRAINING:
                                  return (
                                    <>
                                      <BaseSpace
                                        style={{
                                          backgroundColor:
                                            convertedVariables.backgroundColor,
                                          borderRadius: 8,
                                          padding: 16,
                                        }}
                                      >
                                        <BaseRow
                                          align={'middle'}
                                          justify={'space-between'}
                                          gutter={[16, 8]}
                                        >
                                          <BaseCol>
                                            <BaseText
                                              children={textMemberDisplay}
                                              fontSize="xs"
                                              fontWeight="medium"
                                            />
                                          </BaseCol>
                                          <BaseCol>
                                            <ModalSelectTum
                                              data={item?.data}
                                              onSubmit={data =>
                                                updateDataTumInActivityByIndex?.(
                                                  index,
                                                  data,
                                                )
                                              }
                                            />
                                          </BaseCol>
                                        </BaseRow>
                                        <ColumnTumActivity
                                          index={index}
                                          activity={item}
                                        />
                                      </BaseSpace>
                                      
                                      <BaseSpace
                                        style={{
                                          backgroundColor:
                                            convertedVariables.backgroundColor,
                                          borderRadius: 8,
                                          padding: 16,
                                        }}
                                      >
                                        <BaseRow
                                          gutter={[16, 8]}
                                          align={'middle'}
                                          justify={'space-between'}
                                        >
                                          <BaseCol>
                                            <BaseText
                                              children={
                                                'Danh sách địa điểm (dùng cho check in)'
                                              }
                                              fontWeight="medium"
                                              fontSize="xs"
                                            />
                                          </BaseCol>

                                          <BaseCol>
                                            <ModalAddLocation index={index} />
                                          </BaseCol>
                                        </BaseRow>

                                        <LocationLayout
                                          activity={item}
                                          index={index}
                                        />
                                      </BaseSpace>
                                    </>
                                  )
                                case TypeEventEnum.ACTIVITY:
                                  return (
                                    <>
                                      {isHaveTeam ? (
                                        <BaseSpace
                                          style={{
                                            backgroundColor:
                                              convertedVariables.backgroundColor,
                                            borderRadius: 8,
                                            padding: 16,
                                          }}
                                        >
                                          <BaseSpace
                                            style={{
                                              backgroundColor:
                                                convertedVariables.backgroundColor,
                                              borderRadius: 8,
                                              padding: 16,
                                            }}
                                          >
                                            <BaseRow
                                              gutter={[16, 8]}
                                              align={'middle'}
                                              justify={'space-between'}
                                            >
                                              <BaseCol>
                                                <BaseText
                                                  children={
                                                    'Danh sách đội/nhóm'
                                                  }
                                                  fontWeight="medium"
                                                  fontSize="xs"
                                                />
                                              </BaseCol>

                                              <BaseCol>
                                                <ModalAddTeam index={index} />
                                              </BaseCol>
                                            </BaseRow>

                                            <TeamLayout
                                              activity={item}
                                              indexAc={index}
                                            />
                                          </BaseSpace>
                                        </BaseSpace>
                                      ) : (
                                        <BaseSpace
                                          style={{
                                            backgroundColor:
                                              convertedVariables.backgroundColor,
                                            borderRadius: 8,
                                            padding: 16,
                                          }}
                                        >
                                          <BaseRow
                                            align={'middle'}
                                            justify={'space-between'}
                                            gutter={[16, 8]}
                                          >
                                            <BaseCol>
                                              <BaseText
                                                children={textMemberDisplay}
                                                fontSize="xs"
                                                fontWeight="medium"
                                              />
                                            </BaseCol>
                                            <BaseCol>
                                              <ModalSelectTum
                                                data={item?.data}
                                                onSubmit={data =>
                                                  updateDataTumInActivityByIndex?.(
                                                    index,
                                                    data,
                                                  )
                                                }
                                              />
                                            </BaseCol>
                                          </BaseRow>
                                          <ColumnTumActivity
                                            index={index}
                                            activity={item}
                                          />
                                        </BaseSpace>
                                      )}
                                      <BaseSpace
                                        style={{
                                          backgroundColor:
                                            convertedVariables.backgroundColor,
                                          borderRadius: 8,
                                          padding: 16,
                                        }}
                                      >
                                        <BaseRow
                                          gutter={[16, 8]}
                                          align={'middle'}
                                          justify={'space-between'}
                                        >
                                          <BaseCol>
                                            <BaseText
                                              children={
                                                'Danh sách địa điểm (dùng cho check in)'
                                              }
                                              fontWeight="medium"
                                              fontSize="xs"
                                            />
                                          </BaseCol>

                                          <BaseCol>
                                            <ModalAddLocation index={index} />
                                          </BaseCol>
                                        </BaseRow>

                                        <LocationLayout
                                          activity={item}
                                          index={index}
                                        />
                                      </BaseSpace>

                                      <BaseSpace
                                        style={{
                                          backgroundColor:
                                            convertedVariables.backgroundColor,
                                          borderRadius: 8,
                                          padding: 16,
                                        }}
                                      >
                                        <BaseRow
                                          gutter={[16, 8]}
                                          align={'middle'}
                                          justify={'space-between'}
                                        >
                                          <BaseCol>
                                            <BaseText
                                              children={'Danh sách giải thưởng'}
                                              fontWeight="medium"
                                              fontSize="xs"
                                            />
                                          </BaseCol>

                                          <BaseCol>
                                            <ModalAddEditReward index={index} />
                                          </BaseCol>
                                        </BaseRow>

                                        <RewardLayout
                                          activity={item}
                                          index={index}
                                        />
                                      </BaseSpace>
                                    </>
                                  )
                              }
                            })()}
                          </BaseSpace>
                        ),
                        extra: (
                          <div onClick={e => e?.stopPropagation()}>
                            <BaseRow align={'middle'} gutter={8}>
                              <BaseCol>
                                <ModalEditActivity
                                  activityIndex={index}
                                  activity={item}
                                />
                              </BaseCol>
                              <BaseCol>
                                <BaseButton
                                  icon={<SvgDeleteDesignIcon />}
                                  onClick={() => removeActivityByIndex?.(index)}
                                  type="text"
                                />
                              </BaseCol>
                            </BaseRow>
                          </div>
                        ),
                        style: {
                          alignItems: 'center',
                        },
                      },
                    ]}
                  />
                </SectionBase>
              )
            })
          ) : (
            <BaseText children={'Danh sách trống'} fontWeight="medium" />
          )}
        </ContentWrapper>
      </RootWrapper>
    </BaseSpace>
  )
}

const ContentWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.neutralBlack9Color};
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`

const RootWrapper = styled(BaseSpace)``
