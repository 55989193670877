/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestChangePassword,
  requestCreateAccount,
  requestDeleteAccount,
  requestGetAccountList,
  requestUpdateAccount,
} from 'app/api/account'
import {
  IAccount,
  IParamsGetAccounts,
  IPayloadChangePassword,
  IPayloadCreateAccount,
  IResponseAccountList,
} from 'app/api/account/model'
import { getToken } from 'app/service/storage/storage-service'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { AccountKeys } from '../query-key/account'

export function useGetAccountList(params: IParamsGetAccounts, enabled = true) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<IAccount[]>, undefined>(
    AccountKeys.listAccount(params),
    async () => {
      try {
        const res: IResponseAccountList = await requestGetAccountList(params)
        const data: IAccount[] = res?.data?.users || []
        return {
          data,
          total: res?.data?.count ?? 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export const useDeleteAccount = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, { listId: number[] }, unknown>(
    async (body: { listId: number[] }) => {
      await requestDeleteAccount(body?.listId)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: AccountKeys.allAccounts,
        })
      },
    },
  )
}

export const useUpdateAccount = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IAccount, unknown>(
    async (body?: IAccount) => {
      await requestUpdateAccount(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: AccountKeys.allAccounts,
        })
      },
    },
  )
}

export const useChangePassword = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadChangePassword, unknown>(
    async (body?: IPayloadChangePassword) => {
      return await requestChangePassword(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: AccountKeys.allAccounts,
        })
      },
    },
  )
}

export const useCreateAccount = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadCreateAccount, unknown>(
    async (body?: IPayloadCreateAccount) => {
      return await requestCreateAccount(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: AccountKeys.allAccounts,
        })
      },
    },
  )
}
