import { IUnit, PositionType, UnitType } from 'app/api/unit/model'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { SectionBase } from 'app/components/common/SectionBase'
import { CopyOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { notificationController } from 'app/controllers/notification-controller'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { isEmpty, isEqual, isNil } from 'lodash'
import { DefineUnitType } from 'app/api/unit/constant'
export const ItemInfo = ({
  labelKeyI18n,
  value,
  note,
  isHaveCopy,
}: {
  labelKeyI18n?: string
  value?: string
  note?: string
  isHaveCopy?: boolean
}) => {
  const { t } = useTranslation()
  const handleCopy = () => {
    notificationController.success({
      message: t(R.strings.copy_success),
    })
  }
  return (
    <BaseSpace size={8}>
      <BaseRow>
        <BaseCol>
          <BaseText
            children={labelKeyI18n ? t(labelKeyI18n) : ''}
            colorText="primaryGradientButtonColor"
            fontWeight="medium"
            opacity="0.7"
          />
        </BaseCol>
        {note ? (
          <BaseCol>
            <BaseText
              children={`(${note})`}
              fontSize="xxxs"
              opacity="0.5"
              fontWeight="medium"
            />
          </BaseCol>
        ) : null}
      </BaseRow>
      <BaseRow gutter={10} align={'middle'}>
        <BaseCol>
          <BaseText children={value} fontWeight="semibold" />
        </BaseCol>
        {isHaveCopy && !isEmpty(value) ? (
          <BaseCol>
            <CopyToClipboard text={value ?? ''}>
              <CopyOutlinedIcon rev={undefined} onClick={handleCopy} />
            </CopyToClipboard>
          </BaseCol>
        ) : null}
      </BaseRow>
    </BaseSpace>
  )
}

const CopyOutlinedIcon = styled(CopyOutlined)`
  cursor: pointer;
`

export const InfoLayout = ({ unit }: { unit?: IUnit }) => {
  return (
    <SectionBase title="Thông tin đơn vị">
      <BaseRow gutter={[16, 8]} align={"top"}>
        <BaseCol xl={6}>
          <ItemInfo
            labelKeyI18n={'Tên đơn vị'}
            value={unit?.name ?? '-'}
            isHaveCopy
          />
        </BaseCol>
        <BaseCol xl={6}>
          <ItemInfo
            labelKeyI18n={'Loại đơn vị'}
            value={!isNil(unit?.type) ? DefineUnitType?.[unit?.type ?? ''] : '-'}
          />
        </BaseCol>
        <BaseCol xl={12}>
          <ItemInfo
            labelKeyI18n={'Mô tả'}
            value={unit?.description ?? '-'}
          />
        </BaseCol>
        {(() => {
          switch (unit?.type) {
            case UnitType.DEPARTMENT:
              return (
                <BaseCol xl={6}>
                  <ItemInfo
                    labelKeyI18n={'Tổ trưởng công đoàn'}
                    value={
                      unit?.positions?.find(it =>
                        isEqual(it.type, PositionType.UNION_LEADER),
                      )?.member?.fullName ?? '-'
                    }
                  />
                </BaseCol>
              )
            case UnitType.UNION_GROUP:
              return (
                <>
                  <BaseCol xl={6}>
                    <ItemInfo
                      labelKeyI18n={'Chủ tịch'}
                      value={
                        unit?.positions?.find(it =>
                          isEqual(it.type, PositionType.UNION_PRESIDENT),
                        )?.member?.fullName ?? '-'
                      }
                    />
                  </BaseCol>
                  <BaseCol xl={6}>
                    <ItemInfo
                      labelKeyI18n={'Phó chủ tịch'}
                      value={
                        unit?.positions?.find(it =>
                          isEqual(it.type, PositionType.UNION_VICE_PRESIDENT),
                        )?.member?.fullName ?? '-'
                      }
                    />
                  </BaseCol>
                  <BaseCol xl={6}>
                    <ItemInfo
                      labelKeyI18n={'Ủy viên công đoàn bộ phận'}
                      value={
                        unit?.positions?.find(it =>
                          isEqual(it.type, PositionType.UNION_MEMBER),
                        )?.member?.fullName ?? '-'
                      }
                    />
                  </BaseCol>
                </>
              )
          }
        })()}
      </BaseRow>
    </SectionBase>
  )
}
