/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestApproveTum,
  requestGetNewTumRegisterList,
  requestNewTumRegister,
} from 'app/api/newTumRegister'
import {
  INewTumRegister,
  IParamsGetNewTumRegister,
  IPayloadApproveTum,
  IPayloadReviewNewTumRegister,
  IResponseNewTumRegisterList,
} from 'app/api/newTumRegister/model'
import { getToken } from 'app/service/storage/storage-service'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { NewTumRegisterKeys } from '../query-key/newTumRegister'

export function useGetNewTumRegister(
  params: IParamsGetNewTumRegister,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<INewTumRegister[]>, undefined>(
    NewTumRegisterKeys.listNewTumRegister(params),
    async () => {
      try {
        const res: IResponseNewTumRegisterList =
          await requestGetNewTumRegisterList(params)
        const data: INewTumRegister[] = res?.data?.rows || []
        return {
          data,
          total: res?.data?.total ?? 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export const useReviewTum = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadReviewNewTumRegister, unknown>(
    async (body: IPayloadReviewNewTumRegister) => {
      return await requestNewTumRegister(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: NewTumRegisterKeys.allNewTumRegister,
        })
      },
    },
  )
}
export const useApproveTum = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadApproveTum, unknown>(
    async (body: IPayloadApproveTum) => {
      await requestApproveTum(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: NewTumRegisterKeys.allNewTumRegister,
        })
      },
    },
  )
}
