import { IParamsGetTradeUnionMemberHRM } from 'app/api/tradeUnionMembers/model'

export const HrmTradeUnionMemberKeys = {
  allHrmTradeUnionMember: ['HrmTradeUnionMember'] as const,
  listHrmTradeUnionMembers: () =>
    [
      ...HrmTradeUnionMemberKeys.allHrmTradeUnionMember,
      'listHrmTradeUnionMembers',
    ] as const,
  listHrmTradeUnionMember: (filter?: IParamsGetTradeUnionMemberHRM) =>
    [
      ...HrmTradeUnionMemberKeys.listHrmTradeUnionMembers(),
      { filter },
    ] as const,
}
