/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlusOutlined } from '@ant-design/icons'
import { requestPushRewardTum } from 'app/api/event'
import { IPrizeActionEvent } from 'app/api/event/model'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { ITeam } from 'app/containers/EventManagement/layouts/CreateEvent/type'
import { isEmpty, isEqual } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

interface IProps {
  data?: ITeam[]
  refetch?: () => void
  actionId?: number
  reward?: IPrizeActionEvent
  listTeam?: ITeam[]
}

export const ModalSelectTeam = ({
  data,
  reward,
  refetch,
  listTeam,
}: IProps) => {
  const modalRef = useRef<IRefModal>({})
  const [dataSelected, setDataSelected] = useState<ITeam[]>([])
  const [search, setSearch] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onSelected = (record: ITeam) => {
    const isSelected = dataSelected.some(item => item?.id === record?.id)

    if (isSelected) {
      setDataSelected([])
    } else {
      setDataSelected([record])
    }
  }

  const openModal = () => {
    modalRef?.current?.open?.()
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setDataSelected(data ?? [])
    }, 1000)

    return () => clearTimeout(timer)
  }, [data])

  const dataTable = useMemo(() => {
    const newData =
      listTeam?.map((item, index) => {
        return {
          ...item,
          key: index + 1,
          index: index + 1,
        }
      }) ?? []

    if (isEmpty(search)) return newData

    return newData?.filter(item => isEqual(item?.name, search))
  }, [search, listTeam])

  const onSubmit = async (data: ITeam[]) => {
    setIsLoading(true)
    try {
      await requestPushRewardTum({
        rewardId: reward?.id,
        teamActivityIds: data?.map(item => item?.id),
      })

      // refresh data
      refetch?.()
      modalRef?.current?.hide?.()
      setDataSelected([])
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = () => {
    onSubmit?.(dataSelected)
  }

  return (
    <BaseModalComponent
      titleModal={'Danh sách đội/nhóm'}
      ref={modalRef}
      buttonOpenModal={
        <BaseButton
          icon={data?.length ? null : <PlusOutlined rev={undefined} />}
          onClick={openModal}
        >
          {'Thay đổi danh sách giải thưởng'}
        </BaseButton>
      }
      widthModal={800}
      handleSubmit={handleSubmit}
      renderContent={
        <BaseSpace>
          <BaseFormItem label={'Tìm kiếm'}>
            <BaseInput
              value={search}
              onChange={e => setSearch(e?.target?.value)}
              placeholder="Tìm kiếm theo tên"
            />
          </BaseFormItem>

          <BaseTableManagement
            loading={isLoading}
            columns={[
              convertColumnTable<ITeam>({
                key: 'name',
                title: 'Tên đội/nhóm',
                render: (_, record) => {
                  return (
                    <BaseText
                      children={`${record?.name} `}
                      fontWeight="medium"
                    />
                  )
                },
              }),
              convertColumnTable<ITeam>({
                key: 'description',
                title: 'Mô tả',
                render: (_, record) => {
                  return (
                    <BaseText
                      children={record?.description ?? '-'}
                      fontWeight="medium"
                    />
                  )
                },
              }),

              {
                key: 'id',
                title: '',
                width: '10%',
                dataIndex: 'id',
                render: (_, record: ITeam) => {
                  const isSelected = dataSelected.some(
                    item => item?.id === record?.id,
                  )
                  return (
                    <BaseCheckbox
                      value={isSelected}
                      checked={isSelected}
                      onClick={() => onSelected(record)}
                    />
                  )
                },
              },
            ]}
            dataSource={dataTable}
            className="row-overwrite-style"
            scroll={{ x: true }}
            pagination={{
              pageSize: 5,
            }}
          />
        </BaseSpace>
      }
    />
  )
}

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`
