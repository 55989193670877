import {
  LAYOUT,
  PADDING
} from 'parkway-web-common'
import styled from 'styled-components'


export const ScrollWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 250px;
  min-width: 1000px;
  padding: ${LAYOUT.desktop.paddingVertical} ${PADDING.md};
  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
`

