import R from 'app/assets/R'
import { SectionBase } from 'app/components/common/SectionBase'
import HeaderPage from 'app/components/header/components/HeaderPage'
import React from 'react'
import * as S from './styles'
import { BaseTabs } from 'app/components/common/BaseTabs'
import ApproveUnionMembersTable from './components/ApproveUnionMembersTable'
import { ENewTumRegisterStatus } from './type'

const ApproveUnionMembers: React.FC = () => {
  return (
    <S.ScrollWrapper>
      <HeaderPage titleI18nKey={R.strings.approve_union_members} />

      <SectionBase>
        <BaseTabs
          items={[
            {
              key: 'WAITING',
              label: 'Đang chờ phê duyệt',
              children: (
                <ApproveUnionMembersTable
                  status={ENewTumRegisterStatus.WAITING}
                />
              ),
            },
            {
              key: 'ACCEPTED',
              label: 'Đã phê duyệt',
              children: (
                <ApproveUnionMembersTable
                  status={ENewTumRegisterStatus.ACCEPTED}
                />
              ),
            },
            {
              key: 'REJECTED',
              label: 'Từ chối',
              children: (
                <ApproveUnionMembersTable
                  status={ENewTumRegisterStatus.REJECTED}
                />
              ),
            },
          ]}
        />
      </SectionBase>
    </S.ScrollWrapper>
  )
}

export default ApproveUnionMembers
