import { IRole } from 'app/api/role/model'
import R from 'app/assets/R'
import BaseAvatarProfile from 'app/components/common/BaseAvatarProfile'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { useAppSelector } from 'app/redux/hooks'
import React, { useMemo, useState } from 'react'
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay'
import * as S from './styles'

export const GenerateWorkInfo = ({
  roles,
  isTradeUnionMember,
}: {
  roles?: IRole[]
  isTradeUnionMember?: boolean
}) => {
  if (isTradeUnionMember) {
    roles = roles?.concat([{ name: 'Đoàn viên' }])
  }

  if (!roles?.length) return '-'

  return roles?.map(component => `${component.name}`).join(' & ')
}

export const ProfileDropdown: React.FC = () => {
  // const { isTablet } = useResponsive()
  const [open, setOpen] = useState(false)
  const user = useAppSelector(state => state?.user)?.profile

  const hide = () => {
    setOpen(false)
  }

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }

  const jobTitle = useMemo(() => {
    return GenerateWorkInfo({
      isTradeUnionMember: user?.isTradeUnionMember,
      roles: user?.roles,
    })
  }, [user])

  return (
    <>
      <S.ProfileDropdownHeader
        as={BaseRow}
        gutter={[10, 10]}
        align="middle"
        wrap={false}
      >
        <BaseAvatarProfile
          jobTitle={jobTitle}
          name={user?.full_name ?? '-'}
          src={user?.avatar}
          isShowToolTip
        />
        <BaseCol>
          <S.BasePopoverCustom
            content={<ProfileOverlay onHide={hide} />}
            trigger="click"
            onOpenChange={handleOpenChange}
            open={open}
          >
            <S.ProfileDropdownHeader
              as={BaseRow}
              gutter={[10, 10]}
              align="middle"
              wrap={false}
            >
              <BaseCol>
                <BaseImage
                  src={R.images.ic_chevron_down}
                  preview={false}
                  width={16}
                  height={16}
                />
              </BaseCol>
            </S.ProfileDropdownHeader>
          </S.BasePopoverCustom>
        </BaseCol>
      </S.ProfileDropdownHeader>
    </>
  )
}
