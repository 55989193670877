import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseModalComponent } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { useTranslation } from 'react-i18next'
import { useHook } from './hook'
import { IProps } from './type'
import { UnitType } from 'app/api/unit/model'
import { useRuleHook } from 'app/hook'
import { UnitEnum } from 'app/api/tradeUnionMembers/model'

export const UpdateModalLayout = (props: IProps) => {
  const { t } = useTranslation()

  const {
    form,
    modalRef,
    handleSubmit,
    isLoading,
    isLoadingTradeUnionMember,
    tums,
    onChangeUnitType,
    unitType,
  } = useHook(props)

  const { getNormalRule } = useRuleHook()

  return (
    <BaseModalComponent
      titleModal={'Cập nhật đơn vị'}
      titleOpenModal={t(R.strings.update)}
      ref={modalRef}
      handleSubmit={form?.submit}
      isLoadingConfirm={isLoading}
      renderContent={
        <BaseForm form={form} initialValues={{}} onFinish={handleSubmit}>
          <BaseSpace size={5}>
            <BaseForm.Item
              name={'name'}
              required
              label={'Tên đơn vị'}
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: 'Tên đơn vị',
                  }),
                },
              ]}
            >
              <BaseInput placeholder={'Nhập tên'} />
            </BaseForm.Item>

            <BaseForm.Item
              name={'description'}
              label={t(R.strings.description)}
            >
              <BaseInput placeholder={t(R.strings.enter_description)} />
            </BaseForm.Item>

            <BaseForm.Item
              rules={[
                ...getNormalRule({
                  field: 'Tên trường thành viên',
                }),
              ]}
              label={'Tên trường thành viên'}
              name={'schoolName'}
            >
              <BaseSelect
                options={Object.values(UnitEnum).map(item => ({
                  label: item,
                  value: item,
                }))}
                filterSort={() => 0}
                showSearch
                placeholder={'Chọn tên trường thành viên'}
              />
            </BaseForm.Item>

            <BaseForm.Item
              name={'tradeUnionMemberIds'}
              label={'Thành viên công đoàn'}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: 'Thành viên công đoàn',
                  }),
                },
              ]}
            >
              <BaseSelect
                placeholder={'Chọn thành viên công đoàn'}
                showSearch
                loading={isLoadingTradeUnionMember}
                mode="multiple"
                maxTagCount={2}
                options={tums?.map(item => ({
                  label: item?.fullName,
                  value: item?.id,
                }))}
              />
            </BaseForm.Item>

            <BaseForm.Item
              name={'type'}
              label={'Loại đơn vị'}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: 'Loại đơn vị',
                  }),
                },
              ]}
            >
              <BaseSelect
                placeholder={'Chọn loại đơn vị'}
                showSearch
                loading={isLoadingTradeUnionMember}
                options={[
                  {
                    value: UnitType.DEPARTMENT,
                    label: 'Tổ công đoàn',
                  },
                  {
                    value: UnitType.UNION_GROUP,
                    label: 'Công đoàn bộ phận',
                  },
                ]}
                onChange={value => onChangeUnitType(value as UnitType)}
              />
            </BaseForm.Item>

            {(() => {
              if (!unitType) return null
              switch (unitType) {
                case UnitType.DEPARTMENT:
                  return (
                    <BaseForm.Item
                      name={'unionLeaderId'}
                      label={'Tổ trưởng công đoàn'}
                      required
                      rules={[
                        {
                          required: true,
                          message: t(R.strings.require_field, {
                            field: 'Tổ trưởng công đoàn',
                          }),
                        },
                      ]}
                    >
                      <BaseSelect
                        placeholder={'Chọn tổ trưởng công đoàn'}
                        showSearch
                        loading={isLoadingTradeUnionMember}
                        options={tums?.map(item => ({
                          label: item?.fullName,
                          value: item?.id,
                        }))}
                      />
                    </BaseForm.Item>
                  )
                case UnitType.UNION_GROUP:
                  return (
                    <>
                      <BaseForm.Item
                        name={'unionPresidentId'}
                        label={'Chủ tịch'}
                        required
                        rules={[
                          {
                            required: true,
                            message: t(R.strings.require_field, {
                              field: 'Chủ tịch',
                            }),
                          },
                        ]}
                      >
                        <BaseSelect
                          placeholder={'Chọn chủ tịch'}
                          showSearch
                          loading={isLoadingTradeUnionMember}
                          options={tums?.map(item => ({
                            label: item?.fullName,
                            value: item?.id,
                          }))}
                        />
                      </BaseForm.Item>

                      <BaseForm.Item
                        name={'unionVicePresidentId'}
                        label={'Phó chủ tịch'}
                        required
                        rules={[
                          {
                            required: true,
                            message: t(R.strings.require_field, {
                              field: 'Phó chủ tịch',
                            }),
                          },
                        ]}
                      >
                        <BaseSelect
                          placeholder={'Chọn phó chủ tịch'}
                          showSearch
                          loading={isLoadingTradeUnionMember}
                          options={tums?.map(item => ({
                            label: item?.fullName,
                            value: item?.id,
                          }))}
                        />
                      </BaseForm.Item>

                      <BaseForm.Item
                        name={'unionMemberId'}
                        label={'Ủy viên công đoàn bộ phận'}
                        required
                        rules={[
                          {
                            required: true,
                            message: t(R.strings.require_field, {
                              field: 'Ủy viên công đoàn bộ phận',
                            }),
                          },
                        ]}
                      >
                        <BaseSelect
                          placeholder={'Chọn ủy viên công đoàn bộ phận'}
                          showSearch
                          loading={isLoadingTradeUnionMember}
                          options={tums?.map(item => ({
                            label: item?.fullName,
                            value: item?.id,
                          }))}
                        />
                      </BaseForm.Item>
                    </>
                  )
              }
            })()}
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}
