import { IPayloadNewPassword, requestSetNewPassword } from 'app/api/auth'
import { IRefModal } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { LOGOUT_PATH } from 'app/components/router/route-path'
import { _DEV_ } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { IFormChangePassword } from './type'

export const useHook = () => {
  const navigate = useNavigate()
  const [form] = BaseForm.useForm<IFormChangePassword>()
  const modalRef = useRef<IRefModal>({})

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleSubmit = async (values: IFormChangePassword) => {
    if (!values?.oldPassword || !values?.password) return
    try {
      const newPass: IPayloadNewPassword = {
        old_password: values?.oldPassword,
        password: values?.password,
      }

      const res = await requestSetNewPassword(newPass)

      if (res) {
        modalRef?.current?.hide?.()
        form?.resetFields()
        notificationController.success({
          message:
            'Bạn đã thay đổi mật khẩu thành công, vui lòng đăng nhập lại',
          onClose: () => {
            navigate(LOGOUT_PATH)
          },
        })
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    form,
    modalRef,
    handleSubmit,
    isLoading,
  }
}
