import { IDashboardInfo } from 'app/api/dashboard/model'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import RevenueItem from 'app/containers/Dashboard/components/RevenueItem'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { formatMoney } from 'parkway-web-common'

export const InfoData = ({ data }: { data?: IDashboardInfo }) => {
  return (
    <BaseRow align={'middle'} justify={'space-between'} gutter={[16, 16]}>
      <BaseCol xl={6} style={{ flex: 1 }}>
        <RevenueItem
          color={convertedVariables.otherBlueColor}
          label="Tổng số sự kiện"
          value={formatMoney(data?.total?.totalActivity ?? 0, '')}
        />
      </BaseCol>
      <BaseCol xl={6} style={{ flex: 1 }}>
        <RevenueItem
          color={convertedVariables.statesGreenColor}
          label="Tổng số sự kiện đang diễn ra"
          value={formatMoney(data?.total?.totalActivityRunning ?? 0, '')}
        />
      </BaseCol>
      <BaseCol xl={6} style={{ flex: 1 }}>
        <RevenueItem
          color={convertedVariables.statesOrangeColor}
          label="Tổng số giải thưởng"
          value={formatMoney(data?.total?.totalReward ?? 0, '')}
        />
      </BaseCol>
      <BaseCol xl={6} style={{ flex: 1 }}>
        <RevenueItem
          color={convertedVariables.statesRedColor}
          label="Tổng số đoàn viên"
          value={formatMoney(data?.total?.totalTum ?? 0, '')}
        />
      </BaseCol>
    </BaseRow>
  )
}
