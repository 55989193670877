import styled from 'styled-components'
import ContentLayout from './content'
import { CreateEventProvider } from './context'
import { PADDING } from 'parkway-web-common'
import { BaseSpace } from 'app/components/common/BaseSpace'
import HeaderPage from 'app/components/header/components/HeaderPage'

export const CreateEventContainer = () => {
  return (
    <RootWrapper size={0}>
      <HeaderPage title="Tạo sự kiện" />
      <CreateEventProvider>
        <ContentLayout />
      </CreateEventProvider>
    </RootWrapper>
  )
}

const RootWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
`
