import { IParamsGetTradeUnionMember } from 'app/api/tradeUnionMembers/model'

export const TradeUnionMemberKeys = {
  allTradeUnionMember: ['TradeUnionMember'] as const,
  listTradeUnionMembers: () =>
    [
      ...TradeUnionMemberKeys.allTradeUnionMember,
      'listTradeUnionMembers',
    ] as const,
  listTradeUnionMember: (filter?: IParamsGetTradeUnionMember) =>
    [...TradeUnionMemberKeys.listTradeUnionMembers(), { filter }] as const,
}
