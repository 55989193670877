import { IRole } from 'app/api/role/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { usePagination } from 'app/hook'
import { useGetRoleList } from 'app/react-query/hook/role'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IFilterAccountList } from '../../type'
import { useVerifyPermission } from 'app/helpers/permission.helper'
import { PermissionEnum } from 'app/common/enum'

interface IProps {
  onChangeFilter?: (filter: IFilterAccountList) => void
  filter?: IFilterAccountList
}

export const FilterLayout: React.FC<IProps> = ({ filter, onChangeFilter }) => {
  const { t } = useTranslation()
  const { data: dataRole } = useGetRoleList({ limit: 100 })
  const { flattenDataList } = usePagination()

  const roles: IRole[] = useMemo(() => {
    return flattenDataList(dataRole)?.data ?? []
  }, [dataRole])

  const onChangeStatus = (value: boolean) => {
    onChangeFilter?.({ ...filter, status: value })
  }

  const onChangeSearch = (value: string) => {
    onChangeFilter?.({ ...filter, search: value })
  }

  const onChangeRole = (value: string[]) => {
    onChangeFilter?.({ ...filter, roleIds: value })
  }

  const { user, verifyPermission } = useVerifyPermission()

  const isHavePermissionReadRole = useMemo(() => {
    return verifyPermission(PermissionEnum.ReadRole)
  }, [user])

  return (
    <BaseForm>
      <BaseRow gutter={[16, 8]}>
        <BaseCol xl={6}>
          <BaseForm.Item hiddenLabel>
            <BaseInput
              placeholder={t(R.strings.search_by_name_phone_email)}
              value={filter?.search}
              onChange={e => onChangeSearch?.(e?.target?.value)}
              allowClear
            />
          </BaseForm.Item>
        </BaseCol>

        {isHavePermissionReadRole ? (
          <BaseCol xl={8}>
            <BaseForm.Item hiddenLabel>
              <BaseSelect
                allowClear
                placeholder={t(R.strings.all_role)}
                onChange={value => onChangeRole?.(value as string[])}
                value={filter?.roleIds}
                options={roles?.map(item => ({
                  label: item.name,
                  value: item.id,
                }))}
                showSearch
                mode="multiple"
                maxTagCount={2}
              />
            </BaseForm.Item>
          </BaseCol>
        ) : null}

        <BaseCol xl={6}>
          <BaseForm.Item hiddenLabel>
            <BaseSelect
              allowClear
              placeholder={t(R.strings.all_status)}
              onChange={value => onChangeStatus?.(value as boolean)}
              value={filter?.status}
              options={[
                {
                  label: t(R.strings.active_content),
                  value: 1,
                },
                {
                  label: t(R.strings.in_active),
                  value: 0,
                },
              ]}
            />
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>
    </BaseForm>
  )
}
