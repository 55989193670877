
function strings(){
    return{
        yes: "yes",
        no: "no",
        fill_barcode: "fill_barcode",
        see_more: "see_more",
        choose: "choose",
        find: "find",
        address: "address",
        language: "language",
        vietnamese: "vietnamese",
        english: "english",
        foreign_language: "foreign_language",
        home: "home",
        phone: "phone",
        email: "email",
        send: "send",
        next: "next",
        back: "back",
        minute: "minute",
        hour: "hour",
        send_code: "send_code",
        login: "login",
        user_name: "user_name",
        password: "password",
        re_password: "re_password",
        re_password_not_match: "re_password_not_match",
        change_password: "change_password",
        change_password_success: "change_password_success",
        login_success: "login_success",
        forgot_password: "forgot_password",
        require_field: "require_field",
        required: "required",
        minimum_field: "minimum_field",
        maximum_field: "maximum_field",
        register: "register",
        confirm_password: "confirm_password",
        verify_by_phone: "verify_by_phone",
        verify_by_email: "verify_by_email",
        code_verify: "code_verify",
        message_verify_phone: "message_verify_phone",
        message_verify_email: "message_verify_email",
        re_send_verify_code: "re_send_verify_code",
        resend: "resend",
        time_resend_verify_code: "time_resend_verify_code",
        verify: "verify",
        logout: "logout",
        description: "description",
        full_name: "full_name",
        regex_name: "regex_name",
        regex_user_name: "regex_user_name",
        using_by: "using_by",
        require_email: "require_email",
        require_contact_name: "require_contact_name",
        regex_email: "regex_email",
        regex_phone: "regex_phone",
        send_contact_success: "send_contact_success",
        please_enter_correct_format: "please_enter_correct_format",
        medical_examination: "medical_examination",
        hr: "hr",
        finance: "finance",
        inventory_management: "inventory_management",
        purchasing_management: "purchasing_management",
        customer_care: "customer_care",
        clinic_management: "clinic_management",
        management: "management",
        doctor: "doctor",
        company: "company",
        organizational_chart: "organizational_chart",
        announcement: "announcement",
        internal_news: "internal_news",
        onboarding: "onboarding",
        report: "report",
        marketing: "marketing",
        accounting: "accounting",
        work_schedule: "work_schedule",
        attendance_record: "attendance_record",
        mailbox: "mailbox",
        my_requests: "my_requests",
        my_tasks: "my_tasks",
        my_income: "my_income",
        dashboard: "dashboard",
        profile: "profile",
        read_all: "read_all",
        view_all: "view_all",
        remember_me: "remember_me",
        parkway_dental: "parkway_dental",
        description_parkway_auth: "description_parkway_auth",
        development: "development",
        customers: "customers",
        region: "region",
        revenue: "revenue",
        total_revenue: "total_revenue",
        pending_revenue: "pending_revenue",
        actual_revenue: "actual_revenue",
        compared_to_last_month: "compared_to_last_month",
        today_revenue: "today_revenue",
        branch_name: "branch_name",
        overall_today_summary: "overall_today_summary",
        overview: "overview",
        area: "area",
        clinic: "clinic",
        department: "department",
        ordinal_number: "ordinal_number",
        notification: "notification",
        not_done: "not_done",
        done: "done",
        in_progress: "in_progress",
        see_detail: "see_detail",
        mail: "mail",
        new_mail: "new_mail",
        important: "important",
        show_less: "show_less",
        collapse: "collapse",
        last_time_update: "last_time_update",
        service: "service",
        report_001: "report_001",
        report_002: "report_002",
        report_003: "report_003",
        report_004: "report_004",
        report_004_doctor: "report_004_doctor",
        report_004_area: "report_004_area",
        report_005: "report_005",
        report_006: "report_006",
        report_007: "report_007",
        report_008: "report_008",
        report_009: "report_009",
        report_010: "report_010",
        report_011: "report_011",
        report_012: "report_012",
        report_001_description: "report_001_description",
        report_002_description: "report_002_description",
        report_003_description: "report_003_description",
        report_004_description: "report_004_description",
        report_004_area_description: "report_004_area_description",
        report_004_doctor_description: "report_004_doctor_description",
        report_005_description: "report_005_description",
        report_006_description: "report_006_description",
        report_007_description: "report_007_description",
        report_008_description: "report_008_description",
        report_009_description: "report_009_description",
        report_010_description: "report_010_description",
        report_011_description: "report_011_description",
        report_012_description: "report_012_description",
        budget: "budget",
        spent_actual: "spent_actual",
        percent_budget_using: "percent_budget_using",
        planing: "planing",
        actuals: "actuals",
        cpql: "cpql",
        price: "price",
        percent_complete_target_api: "percent_complete_target_api",
        number_checkin: "number_checkin",
        percent_achievement: "percent_achievement",
        number_q_lead: "number_q_lead",
        see_with: "see_with",
        week: "week",
        choose_date: "choose_date",
        choose_week: "choose_week",
        document: "document",
        name_employee: "name_employee",
        number_date_re_cared: "number_date_re_cared",
        number_off_appointments_scheduled: "number_off_appointments_scheduled",
        number_check_in_achieved: "number_check_in_achieved",
        crv_1: "crv_1",
        crv_1_period: "crv_1_period",
        crv_2: "crv_2",
        crv_2_period: "crv_2_period",
        crv_1_growth_in_period: "crv_1_growth_in_period",
        crv_2_growth_in_period: "crv_2_growth_in_period",
        number_of_cases_closed: "number_of_cases_closed",
        crv3: "crv3",
        crv3_with_period: "crv3_with_period",
        percent_crv3_growth_in_period: "percent_crv3_growth_in_period",
        number_of_check_ins: "number_of_check_ins",
        week1: "week1",
        week2: "week2",
        week3: "week3",
        week4: "week4",
        week_no: "week_no",
        total_revenue_month: "total_revenue_month",
        total_revenue_target: "total_revenue_target",
        percent_of_target_complete: "percent_of_target_complete",
        month1: "month1",
        month2: "month2",
        month3: "month3",
        month4: "month4",
        month5: "month5",
        month6: "month6",
        month7: "month7",
        month8: "month8",
        month9: "month9",
        month10: "month10",
        month11: "month11",
        month12: "month12",
        month_no: "month_no",
        total_revenue_year: "total_revenue_year",
        group_product: "group_product",
        a1_implant: "a1_implant",
        a2_implant: "a2_implant",
        a3_implant: "a3_implant",
        total_revenue_actual: "total_revenue_actual",
        total_revenue_projected: "total_revenue_projected",
        total_revenue_planned: "total_revenue_planned",
        total_revenue_achieved: "total_revenue_achieved",
        group_customer: "group_customer",
        revenue_week: "revenue_week",
        hcm_area: "hcm_area",
        hanoi_area: "hanoi_area",
        digital_ad: "digital_ad",
        percentage_of_total_revenue: "percentage_of_total_revenue",
        system_wide: "system_wide",
        product: "product",
        quarter_in_year: "quarter_in_year",
        gender: "gender",
        age: "age",
        cases_in_product_group: "cases_in_product_group",
        fixed_braces: "fixed_braces",
        other_products: "other_products",
        revenue_of_product_group: "revenue_of_product_group",
        cases_in_area: "cases_in_area",
        revenue_of_area: "revenue_of_area",
        province: "province",
        telesales_cared: "telesales_cared",
        other_customer: "other_customer",
        old_customer: "old_customer",
        import_budget_week: "import_budget_week",
        import_budget_month: "import_budget_month",
        import_qlead_actual_week: "import_qlead_actual_week",
        import_qlead_actual_month: "import_qlead_actual_month",
        import_cpql_numbers_week: "import_cpql_numbers_week",
        import_cpql_numbers_month: "import_cpql_numbers_month",
        import_weekly_appointment_schedule: "import_weekly_appointment_schedule",
        appointment_schedule: "appointment_schedule",
        check_in_schedule: "check_in_schedule",
        import_monthly_appointment_schedule: "import_monthly_appointment_schedule",
        import_cost_of_goods_sold_and_selling_expenses_weekly: "import_cost_of_goods_sold_and_selling_expenses_weekly",
        cost_of_goods_sold_and_selling_expenses: "cost_of_goods_sold_and_selling_expenses",
        marketing_plan: "marketing_plan",
        import_cost_of_goods_sold_and_selling_expenses_monthly: "import_cost_of_goods_sold_and_selling_expenses_monthly",
        import_weekly_revenue: "import_weekly_revenue",
        import_monthly_revenue: "import_monthly_revenue",
        import: "import",
        from_date: "from_date",
        to_date: "to_date",
        start_date: "start_date",
        end_date: "end_date",
        number_planned: "number_planned",
        number_actual: "number_actual",
        export_excel: "export_excel",
        download_example_import_file: "download_example_import_file",
        upload: "upload",
        cp_nvl_live: "cp_nvl_live",
        doctor_department: "doctor_department",
        nursing_department: "nursing_department",
        advisory_department: "advisory_department",
        x_ray_technical_department: "x_ray_technical_department",
        reception_department: "reception_department",
        security_and_housekeeping_department: "security_and_housekeeping_department",
        initial_construction: "initial_construction",
        machinery_and_equipment: "machinery_and_equipment",
        rental_expenses: "rental_expenses",
        location_rental_tax_expenses: "location_rental_tax_expenses",
        other_operating_expenses: "other_operating_expenses",
        card_swipe_installment_fees: "card_swipe_installment_fees",
        others_cosmetics: "others_cosmetics",
        plan_number: "plan_number",
        performance: "performance",
        branch: "branch",
        call_center: "call_center",
        ccdc: "ccdc",
        location_rental_expenses: "location_rental_expenses",
        depreciation_expenses: "depreciation_expenses",
        salary_expenses_allowances: "salary_expenses_allowances",
        cost_of_goods_sold: "cost_of_goods_sold",
        selling_expenses: "selling_expenses",
        download_successful: "download_successful",
        import_file_successful: "import_file_successful",
        import_file_error: "import_file_error",
        month: "month",
        import_file: "import_file",
        created: "created",
        requested: "requested",
        recall: "recall",
        confirm_customer: "confirm_customer",
        confirmed: "confirmed",
        cancel: "cancel",
        orthodontic: "orthodontic",
        general_treatment: "general_treatment",
        treatments: "treatments",
        orthodontic_correction: "orthodontic_correction",
        invisalign: "invisalign",
        implant: "implant",
        number_of_cases_in_the_region: "number_of_cases_in_the_region",
        revenue_in_the_region: "revenue_in_the_region",
        hochiminh: "hochiminh",
        hanoi: "hanoi",
        annual_expenditure_amount: "annual_expenditure_amount",
        number_of_purchases: "number_of_purchases",
        average_order_value: "average_order_value",
        groupA1: "groupA1",
        groupA2: "groupA2",
        groupA3: "groupA3",
        groupA4: "groupA4",
        number_of_service_uses: "number_of_service_uses",
        number_new_lead: "number_new_lead",
        compared_to_last_day: "compared_to_last_day",
        year: "year",
        from_then_util_now: "from_then_util_now",
        quarter: "quarter",
        quarter_num: "quarter_num",
        male: "male",
        female: "female",
        other: "other",
        all_system: "all_system",
        porcelain_cosmetic_dentistry: "porcelain_cosmetic_dentistry",
        customer_care_plan: "customer_care_plan",
        number_customer_re_care: "number_customer_re_care",
        number_qlead_actual: "number_qlead_actual",
        account_management: "account_management",
        employee_id: "employee_id",
        account_name: "account_name",
        status: "status",
        active: "active",
        deleted: "deleted",
        error: "error",
        search: "search",
        all: "all",
        delete: "delete",
        done_button: "done_button",
        update: "update",
        edit: "edit",
        id_property: "id_property",
        barcode: "barcode",
        add_account: "add_account",
        first_name: "first_name",
        last_name: "last_name",
        enter_field: "enter_field",
        please_select_status: "please_select_status",
        inactive: "inactive",
        update_account: "update_account",
        action: "action",
        update_account_id_success: "update_account_id_success",
        delete_account: "delete_account",
        are_you_want_delete_account: "are_you_want_delete_account",
        profile_title: "profile_title",
        please_select_profile: "please_select_profile",
        once_the_account_is_locked_it_cannot_be_recovered: "once_the_account_is_locked_it_cannot_be_recovered",
        once_the_field_is_locked_it_cannot_be_recovered: "once_the_field_is_locked_it_cannot_be_recovered",
        please_select_role: "please_select_role",
        job_title: "job_title",
        update_time: "update_time",
          days_of_week_monday: "days_of_week.monday",
          days_of_week_tuesday: "days_of_week.tuesday",
          days_of_week_wednesday: "days_of_week.wednesday",
          days_of_week_thursday: "days_of_week.thursday",
          days_of_week_friday: "days_of_week.friday",
          days_of_week_saturday: "days_of_week.saturday",
          days_of_week_sunday: "days_of_week.sunday",
          job_title_content_management: "job_title_content.management",
          job_title_content_description: "job_title_content.description",
          job_title_content_name: "job_title_content.name",
          job_title_content_group: "job_title_content.group",
          job_title_content_add_job_title: "job_title_content.add_job_title",
          job_title_content_job_title_management: "job_title_content.job_title_management",
          job_title_content_hasTimekeeping: "job_title_content.hasTimekeeping",
          job_title_content_dayOffType: "job_title_content.dayOffType",
          job_title_content_dayOfDynamic: "job_title_content.dayOfDynamic",
          job_title_content_dayOfFixed: "job_title_content.dayOfFixed",
          job_title_content_id: "job_title_content.id",
          job_title_content_name_create: "job_title_content.name_create",
          job_title_content_please_select_job_title_management: "job_title_content.please_select_job_title_management",
          job_title_content_are_you_want_delete_job_title: "job_title_content.are_you_want_delete_job_title",
          job_title_content_once_the_job_title_is_locked_it_cannot_be_recovered: "job_title_content.once_the_job_title_is_locked_it_cannot_be_recovered",
          job_title_content_please_select_dayOffDynamic: "job_title_content.please_select_dayOffDynamic",
          job_title_content_please_select_job_title_group: "job_title_content.please_select_job_title_group",
          job_title_content_please_select_dayOffFixed: "job_title_content.please_select_dayOffFixed",
          job_title_field_job_title_string_id: "job_title_field.job_title_string_id",
          job_title_field_name: "job_title_field.name",
          job_title_field_description: "job_title_field.description",
          job_title_field_status: "job_title_field.status",
          job_title_field_role: "job_title_field.role",
          job_title_field_job_title_management: "job_title_field.job_title_management",
        job_title_level: "job_title_level",
          job_title_level_content_title: "job_title_level_content.title",
          job_title_level_content_management: "job_title_level_content.management",
          job_title_level_content_description: "job_title_level_content.description",
          job_title_level_content_name: "job_title_level_content.name",
          job_title_level_content_add: "job_title_level_content.add",
          job_title_level_content_id: "job_title_level_content.id",
          job_title_level_content_name_create: "job_title_level_content.name_create",
          job_title_level_content_are_you_want_delete: "job_title_level_content.are_you_want_delete",
          job_title_level_content_once_the_job_title_level_is_locked_it_cannot_be_recovered: "job_title_level_content.once_the_job_title_level_is_locked_it_cannot_be_recovered",
          job_title_level_field_job_title_level_string_id: "job_title_level_field.job_title_level_string_id",
          job_title_level_field_name: "job_title_level_field.name",
          job_title_level_field_description: "job_title_level_field.description",
          job_title_level_field_status: "job_title_level_field.status",
          job_title_level_field_job_title_level_id: "job_title_level_field.job_title_level_id",
        unit: "unit",
          unit_content_title: "unit_content.title",
          unit_content_create_unit_child: "unit_content.create_unit_child",
          unit_content_unit_parent: "unit_content.unit_parent",
          unit_content_management: "unit_content.management",
          unit_content_unit_department: "unit_content.unit_department",
          unit_content_job_title_management: "unit_content.job_title_management",
          unit_content_unitTypeId: "unit_content.unitTypeId",
          unit_content_description: "unit_content.description",
          unit_content_name: "unit_content.name",
          unit_content_add: "unit_content.add",
          unit_content__id: "unit_content._id",
          unit_content_id: "unit_content.id",
          unit_content_please_select_unit_department: "unit_content.please_select_unit_department",
          unit_content_please_select_unitTypeId: "unit_content.please_select_unitTypeId",
          unit_content_please_select_unit_job_title_management: "unit_content.please_select_unit_job_title_management",
          unit_content_name_create: "unit_content.name_create",
          unit_content_are_you_want_delete: "unit_content.are_you_want_delete",
          unit_content_once_the_unit_is_locked_it_cannot_be_recovered: "unit_content.once_the_unit_is_locked_it_cannot_be_recovered",
          unit_field_name: "unit_field.name",
          unit_field_description: "unit_field.description",
          unit_field_status: "unit_field.status",
          unit_field_unit_string_id: "unit_field.unit_string_id",
          unit_field_unit_department_string_id: "unit_field.unit_department_string_id",
          unit_field_unitTypeId: "unit_field.unitTypeId",
          unit_field_job_title_management: "unit_field.job_title_management",
        account: "account",
        create_profile: "create_profile",
        update_profile: "update_profile",
          profile_content_title: "profile_content.title",
          profile_content_management: "profile_content.management",
          profile_content_profile_department: "profile_content.profile_department",
          profile_content_job_title: "profile_content.job_title",
          profile_content_description: "profile_content.description",
          profile_content_firstname: "profile_content.firstname",
          profile_content_lastname: "profile_content.lastname",
          profile_content_email: "profile_content.email",
          profile_content_phone: "profile_content.phone",
          profile_content_address: "profile_content.address",
          profile_content_add: "profile_content.add",
          profile_content_name: "profile_content.name",
          profile_content_id: "profile_content.id",
          profile_content_mobile: "profile_content.mobile",
          profile_content_employeeid: "profile_content.employeeid",
          profile_content_employee_old_id: "profile_content.employee_old_id",
          profile_content_job_title_main_column: "profile_content.job_title_main_column",
          profile_content_job_title_secondary_column: "profile_content.job_title_secondary_column",
          profile_content_job_title_main: "profile_content.job_title_main",
          profile_content_job_title_secondary: "profile_content.job_title_secondary",
          profile_content_job_title_secondary_child: "profile_content.job_title_secondary_child",
          profile_content_please_select_department: "profile_content.please_select_department",
          profile_content_please_select_job_title: "profile_content.please_select_job_title",
          profile_content_please_select_account: "profile_content.please_select_account",
          profile_content_name_create: "profile_content.name_create",
          profile_content_info_section: "profile_content.info_section",
          profile_content_job_title_section: "profile_content.job_title_section",
          profile_content_are_you_want_delete: "profile_content.are_you_want_delete",
          profile_content_create_profile_success: "profile_content.create_profile_success",
          profile_content_once_the_profile_is_locked_it_cannot_be_recovered: "profile_content.once_the_profile_is_locked_it_cannot_be_recovered",
          profile_content_add_work_info_main: "profile_content.add_work_info_main",
          profile_content_add_work_info_aka: "profile_content.add_work_info_aka",
          profile_content_option_create: "profile_content.option_create",
          profile_content_option_select_id: "profile_content.option_select_id",
          profile_content_option_non: "profile_content.option_non",
          profile_content_skill: "profile_content.skill",
          profile_content_add_skill: "profile_content.add_skill",
          profile_content_please_select_skill: "profile_content.please_select_skill",
          profile_content_please_fill_skill: "profile_content.please_fill_skill",
          profile_content_industry: "profile_content.industry",
          profile_content_add_industry: "profile_content.add_industry",
          profile_content_please_select_industry: "profile_content.please_select_industry",
          profile_content_please_fill_industry: "profile_content.please_fill_industry",
          profile_content_level: "profile_content.level",
          profile_content_add_level: "profile_content.add_level",
          profile_content_please_select_level: "profile_content.please_select_level",
          profile_content_please_fill_level: "profile_content.please_fill_level",
          profile_content_degree: "profile_content.degree",
          profile_content_add_degree: "profile_content.add_degree",
          profile_content_please_select_degree: "profile_content.please_select_degree",
          profile_content_degree_classification: "profile_content.degree_classification",
          profile_content_add_degree_classification: "profile_content.add_degree_classification",
          profile_content_please_select_degree_classification: "profile_content.please_select_degree_classification",
          profile_content_area: "profile_content.area",
          profile_content_clinic: "profile_content.clinic",
          profile_content_department: "profile_content.department",
          profile_field_name: "profile_field.name",
          profile_field_email: "profile_field.email",
          profile_field_mobile: "profile_field.mobile",
          profile_field_status: "profile_field.status",
          profile_field_account: "profile_field.account",
          profile_field_last_name: "profile_field.last_name",
          profile_field_first_name: "profile_field.first_name",
          profile_field_employeeid: "profile_field.employeeid",
          profile_field_employee_old_id: "profile_field.employee_old_id",
          profile_field_description: "profile_field.description",
          profile_field_profileTypeId: "profile_field.profileTypeId",
          profile_field_profile_string_id: "profile_field.profile_string_id",
          profile_field_profile_department_string_id: "profile_field.profile_department_string_id",
          profile_field_skill: "profile_field.skill",
          profile_field_level: "profile_field.level",
          profile_field_industry: "profile_field.industry",
          profile_field_classification: "profile_field.classification",
          profile_field_workinfo_unitid: "profile_field.workinfo_unitid",
          profile_field_workinfo_job_title: "profile_field.workinfo_job_title",
          profile_field_workinfo_start_date: "profile_field.workinfo_start_date",
          profile_field_workinfo_end_date: "profile_field.workinfo_end_date",
          profile_field_workinfo_status: "profile_field.workinfo_status",
        projected_revenue: "projected_revenue",
        survey_report: "survey_report",
          survey_report_content_star: "survey_report_content.star",
          survey_report_content_by_day: "survey_report_content.by_day",
          survey_report_content_review_type_need_to_impove: "survey_report_content.review_type_need_to_impove",
          survey_report_content_next_promotor_score: "survey_report_content.next_promotor_score",
          survey_report_content_chart_by_day_label_header: "survey_report_content.chart_by_day_label_header",
          survey_report_content_chart_by_day_label_footer: "survey_report_content.chart_by_day_label_footer",
          survey_report_content_excellent: "survey_report_content.excellent",
          survey_report_content_good: "survey_report_content.good",
          survey_report_content_middle: "survey_report_content.middle",
          survey_report_content_bad: "survey_report_content.bad",
          survey_report_content_select_area: "survey_report_content.select_area",
          survey_report_content_select_clinic: "survey_report_content.select_clinic",
          survey_report_content_review: "survey_report_content.review",
          survey_report_content_unReview: "survey_report_content.unReview",
          survey_report_content_nps_average: "survey_report_content.nps_average",
          survey_report_content_nps_choosen: "survey_report_content.nps_choosen",
          survey_report_content_date: "survey_report_content.date",
          survey_report_content_start_no: "survey_report_content.start_no",
        marketing_bp: "marketing_bp",
        operation_bp: "operation_bp",
        training_bp: "training_bp",
        hr_bp: "hr_bp",
        accounting_bp: "accounting_bp",
        purchasing_bp: "purchasing_bp",
        other_expenses: "other_expenses",
        other_income: "other_income",
        interest_on_deposits: "interest_on_deposits",
        other_costs: "other_costs",
        business_management_expenses: "business_management_expenses",
        other_profits: "other_profits",
        download_excel_success: "download_excel_success",
        skill: "skill",
          skill_content_title: "skill_content.title",
          skill_content_management: "skill_content.management",
          skill_content_description: "skill_content.description",
          skill_content_industry: "skill_content.industry",
          skill_content_levelSkill: "skill_content.levelSkill",
          skill_content_rangeExperience: "skill_content.rangeExperience",
          skill_content_name: "skill_content.name",
          skill_content_add: "skill_content.add",
          skill_content_name_create: "skill_content.name_create",
          skill_content_are_you_want_delete: "skill_content.are_you_want_delete",
          skill_content_exists_skill: "skill_content.exists_skill",
          skill_content_once_the_skill_is_locked_it_cannot_be_recovered: "skill_content.once_the_skill_is_locked_it_cannot_be_recovered",
          skill_content_select_industry: "skill_content.select_industry",
          skill_content_select_level_skill: "skill_content.select_level_skill",
          skill_content_select_range_experience: "skill_content.select_range_experience",
          skill_field_name: "skill_field.name",
          skill_field_industry: "skill_field.industry",
          skill_field_levelSkill: "skill_field.levelSkill",
          skill_field_rangeExperience: "skill_field.rangeExperience",
          skill_field_status: "skill_field.status",
        degree: "degree",
          degree_content_title: "degree_content.title",
          degree_content_management: "degree_content.management",
          degree_content_see_degree: "degree_content.see_degree",
          degree_content_description: "degree_content.description",
          degree_content_industry: "degree_content.industry",
          degree_content_start: "degree_content.start",
          degree_content_end: "degree_content.end",
          degree_content_has_end: "degree_content.has_end",
          degree_content_no_end: "degree_content.no_end",
          degree_content_name: "degree_content.name",
          degree_content_add: "degree_content.add",
          degree_content_name_create: "degree_content.name_create",
          degree_content_placehoder_upload_document: "degree_content.placehoder_upload_document",
          degree_content_placeholder_upload_document: "degree_content.placeholder_upload_document",
          degree_content_are_you_want_delete: "degree_content.are_you_want_delete",
          degree_content_once_the_degree_is_locked_it_cannot_be_recovered: "degree_content.once_the_degree_is_locked_it_cannot_be_recovered",
          degree_content_select_industry: "degree_content.select_industry",
          degree_field_name: "degree_field.name",
          degree_field_degree: "degree_field.degree",
          degree_field_industry: "degree_field.industry",
          degree_field_status: "degree_field.status",
          degree_field_degree_classification: "degree_field.degree_classification",
          degree_classification_excellent: "degree_classification.excellent",
          degree_classification_good: "degree_classification.good",
          degree_classification_average_good: "degree_classification.average_good",
          degree_classification_average: "degree_classification.average",
          degree_classification_below_average: "degree_classification.below_average",
          degree_classification_weak: "degree_classification.weak",
          degree_classification_unknown: "degree_classification.unknown",
        check_in_planing: "check_in_planing",
          report_group_customer_care: "report_group.customer_care",
          report_group_marketing: "report_group.marketing",
          report_group_operation: "report_group.operation",
          report_group_accounting: "report_group.accounting",
          report_group_import_data: "report_group.import_data",
          report_group_debt_management: "report_group.debt_management",
          report_group_report: "report_group.report",
        check_in_customer_plan: "check_in_customer_plan",
        manage_customer_debt_contract: "manage_customer_debt_contract",
        manage_customer_debt: "manage_customer_debt",
        manage_customer_debt_remain: "manage_customer_debt_remain",
        manage_customer_patient_code: "manage_customer_patient_code",
        manage_customer_patient_name: "manage_customer_patient_name",
        manage_customer_debt_payment_total: "manage_customer_debt_payment_total",
        manage_customer_debt_deposit_total: "manage_customer_debt_deposit_total",
        manage_customer_debt_refund_total: "manage_customer_debt_refund_total",
        manage_customer_debt_paid_total: "manage_customer_debt_paid_total",
        manage_customer_debt_remain_total: "manage_customer_debt_remain_total",
        manage_customer_payment_type: "manage_customer_payment_type",
        manage_customer_clinic_name: "manage_customer_clinic_name",
        manage_customer_debt_treatment: "manage_customer_debt_treatment",
        manage_customer_debt_treatment_total: "manage_customer_debt_treatment_total",
        manage_customer_debt_treatment_date: "manage_customer_debt_treatment_date",
        manage_customer_debt_tip_end_name: "manage_customer_debt_tip_end_name",
        manage_customer_debt_tip_end_date: "manage_customer_debt_tip_end_date",
        manage_customer_choose_paid_type: "manage_customer_choose_paid_type",
        manage_customer_debt_choose_treatment: "manage_customer_debt_choose_treatment",
        manage_customer_debt_choose_tip: "manage_customer_debt_choose_tip",
        manage_customer_debt_tip_start_date: "manage_customer_debt_tip_start_date",
        manage_customer_tip_start_date: "manage_customer_tip_start_date",
        daily_invoice: "daily_invoice",
        deposit: "deposit",
        installment: "installment",
        one_time_payment: "one_time_payment",
        no_paid: "no_paid",
        booking: "booking",
        booking_employee: "booking_employee",
          booking_content_title: "booking_content.title",
          booking_content_management: "booking_content.management",
          booking_content_description: "booking_content.description",
          booking_content_name: "booking_content.name",
          booking_content_phone: "booking_content.phone",
          booking_content_treatments: "booking_content.treatments",
          booking_content_area: "booking_content.area",
          booking_content_clinic: "booking_content.clinic",
          booking_content_doctor: "booking_content.doctor",
          booking_content_date: "booking_content.date",
          booking_content_time: "booking_content.time",
          booking_content_note: "booking_content.note",
          booking_content_callCenter: "booking_content.callCenter",
          booking_content_calling: "booking_content.calling",
          booking_content_add: "booking_content.add",
          booking_content_name_create: "booking_content.name_create",
          booking_content_are_you_want_delete: "booking_content.are_you_want_delete",
          booking_content_exists_booking: "booking_content.exists_booking",
          booking_content_once_the_booking_is_locked_it_cannot_be_recovered: "booking_content.once_the_booking_is_locked_it_cannot_be_recovered",
          booking_content_profile_call_center: "booking_content.profile_call_center",
          booking_content_select_profile_call_center: "booking_content.select_profile_call_center",
          booking_field_name: "booking_field.name",
          booking_field_phone: "booking_field.phone",
          booking_field_treatments: "booking_field.treatments",
          booking_field_clinic: "booking_field.clinic",
          booking_field_doctor: "booking_field.doctor",
          booking_field_callCenter: "booking_field.callCenter",
          booking_field_status: "booking_field.status",
          booking_field_note: "booking_field.note",
          booking_field_area: "booking_field.area",
          booking_field_date: "booking_field.date",
          booking_field_time: "booking_field.time",
        process_appointment_requests: "process_appointment_requests",
        create_appointment: "create_appointment",
        BS: "BS",
        day: "day",
        appointment_note: "appointment_note",
        confirm: "confirm",
        guest_cancels_appointment: "guest_cancels_appointment",
        confirm_guest_cancels_appointment: "confirm_guest_cancels_appointment",
        confirm_describe_guest_cancels_appointment: "confirm_describe_guest_cancels_appointment",
        schedule_booking: "schedule_booking",
        patient_name: "patient_name",
        patient_name_required: "patient_name_required",
        patient_name_placeholder: "patient_name_placeholder",
        phone_label: "phone_label",
        phone_label_required: "phone_label_required",
        phone_label_placeholder: "phone_label_placeholder",
        area_label: "area_label",
        area_label_required: "area_label_required",
        area_label_placeholder: "area_label_placeholder",
        clinic_label: "clinic_label",
        clinic_label_required: "clinic_label_required",
        clinic_label_placeholder: "clinic_label_placeholder",
        category_service_label: "category_service_label",
        category_service_label_required: "category_service_label_required",
        category_service_label_placeholder: "category_service_label_placeholder",
        service_label: "service_label",
        service_label_required: "service_label_required",
        service_label_placeholder: "service_label_placeholder",
        treatment_label: "treatment_label",
        treatment_label_required: "treatment_label_required",
        treatment_label_placeholder: "treatment_label_placeholder",
        reason_label: "reason_label",
        reason_label_required: "reason_label_required",
        reason_label_placeholder: "reason_label_placeholder",
        duration_label: "duration_label",
        duration_label_required: "duration_label_required",
        duration_label_placeholder: "duration_label_placeholder",
        note_max_length: "note_max_length",
        number_of_character: "number_of_character",
        doctor_label: "doctor_label",
        doctor_label_required: "doctor_label_required",
        doctor_label_placeholder: "doctor_label_placeholder",
        time_label: "time_label",
        time_label_required: "time_label_required",
        time_label_placeholder: "time_label_placeholder",
        day_label: "day_label",
        day_label_required: "day_label_required",
        day_label_placeholder: "day_label_placeholder",
        status_label: "status_label",
        status_label_required: "status_label_required",
        status_label_placeholder: "status_label_placeholder",
        SA: "SA",
        CH: "CH",
        note_label: "note_label",
        note_label_placeholder: "note_label_placeholder",
        customer_cancel: "customer_cancel",
        created_by: "created_by",
        schedule_info: "schedule_info",
        update_info: "update_info",
        today: "today",
        search_doctor: "search_doctor",
        all_doctor: "all_doctor",
        BOOKING_EXISTS: "BOOKING_EXISTS",
        BOOKING_DOES_NOT_EXIST: "BOOKING_DOES_NOT_EXIST",
        schedule_appointment: "schedule_appointment",
        manage_appointment: "manage_appointment",
        limit_date: "limit_date",
        select_range_date_required: "select_range_date_required",
        num_kpi: "num_kpi",
        check_in_service_plan: "check_in_service_plan",
        kpi_plan: "kpi_plan",
        kpi_actual: "kpi_actual",
        item_management: "item_management",
          item_management_content_add_product_property: "item_management_content.add_product_property",
          item_management_content_add_property: "item_management_content.add_property",
          item_management_content_add_property_value: "item_management_content.add_property_value",
          item_management_content_category: "item_management_content.category",
          item_management_content_enter_job_title_level_id: "item_management_content.enter_job_title_level_id",
          item_management_content_all_status: "item_management_content.all_status",
          item_management_content_all_item: "item_management_content.all_item",
          item_management_content_add_job_title_level_doctor: "item_management_content.add_job_title_level_doctor",
          item_management_content_service: "item_management_content.service",
          item_management_content_type: "item_management_content.type",
          item_management_content_request_job_title_level_doctor: "item_management_content.request_job_title_level_doctor",
          item_management_content_job_title_doctor: "item_management_content.job_title_doctor",
          item_management_content_job_title_level_doctor: "item_management_content.job_title_level_doctor",
          item_management_content_treatment_use: "item_management_content.treatment_use",
          item_management_content_treatment_name: "item_management_content.treatment_name",
          item_management_content_step_perform: "item_management_content.step_perform",
          item_management_content_time_perform: "item_management_content.time_perform",
          item_management_content_condition_perform: "item_management_content.condition_perform",
          item_management_content_skill: "item_management_content.skill",
          item_management_content_material: "item_management_content.material",
          item_management_content_supplies: "item_management_content.supplies",
          item_management_content_dental_service: "item_management_content.dental_service",
          item_management_content_dental_product: "item_management_content.dental_product",
          item_management_content_insert_upper_treatment: "item_management_content.insert_upper_treatment",
          item_management_content_insert_lower_treatment: "item_management_content.insert_lower_treatment",
          item_management_content_insert_upper_step_perform: "item_management_content.insert_upper_step_perform",
          item_management_content_insert_lower_step_perform: "item_management_content.insert_lower_step_perform",
          item_management_content_add_product_service: "item_management_content.add_product_service",
          item_management_content_description_add_service: "item_management_content.description_add_service",
          item_management_content_description_add_product: "item_management_content.description_add_product",
          item_management_content_description_add_supplies: "item_management_content.description_add_supplies",
          item_management_content_description_add_asset: "item_management_content.description_add_asset",
          item_management_content_description_add_tool: "item_management_content.description_add_tool",
          item_management_content_description_add_combo: "item_management_content.description_add_combo",
          item_management_content_order_perform: "item_management_content.order_perform",
          item_management_content_are_you_want_delete_service: "item_management_content.are_you_want_delete_service",
          item_management_content_are_you_want_delete_product: "item_management_content.are_you_want_delete_product",
          item_management_content_are_you_want_delete_supplies: "item_management_content.are_you_want_delete_supplies",
          item_management_content_once_the_service_is_locked_it_cannot_be_recovered: "item_management_content.once_the_service_is_locked_it_cannot_be_recovered",
          item_management_content_once_the_product_is_locked_it_cannot_be_recovered: "item_management_content.once_the_product_is_locked_it_cannot_be_recovered",
          item_management_content_once_the_supplies_is_locked_it_cannot_be_recovered: "item_management_content.once_the_supplies_is_locked_it_cannot_be_recovered",
          item_management_content_delete_service: "item_management_content.delete_service",
          item_management_content_delete_product: "item_management_content.delete_product",
          item_management_content_delete_supplies: "item_management_content.delete_supplies",
          item_management_content_delete_asset: "item_management_content.delete_asset",
          item_management_content_delete_tool: "item_management_content.delete_tool",
          item_management_content_delete_treatment: "item_management_content.delete_treatment",
          item_management_content_are_you_want_delete_treatment: "item_management_content.are_you_want_delete_treatment",
          item_management_content_once_the_treatment_is_locked_it_cannot_be_recovered: "item_management_content.once_the_treatment_is_locked_it_cannot_be_recovered",
          item_management_content_are_you_want_delete_asset: "item_management_content.are_you_want_delete_asset",
          item_management_content_once_the_asset_is_locked_it_cannot_be_recovered: "item_management_content.once_the_asset_is_locked_it_cannot_be_recovered",
          item_management_content_are_you_want_delete_tool: "item_management_content.are_you_want_delete_tool",
          item_management_content_once_the_tool_is_locked_it_cannot_be_recovered: "item_management_content.once_the_tool_is_locked_it_cannot_be_recovered",
          item_management_content_add_treatment: "item_management_content.add_treatment",
          item_management_content_used_supplies: "item_management_content.used_supplies",
          item_management_content_add_supplies: "item_management_content.add_supplies",
          item_management_content_select_supplies: "item_management_content.select_supplies",
          item_management_content_select_treatment: "item_management_content.select_treatment",
          item_management_content_treatment: "item_management_content.treatment",
          item_management_content_not_select_supplies: "item_management_content.not_select_supplies",
          item_management_content_not_select_skill: "item_management_content.not_select_skill",
          item_management_content_select_job_title: "item_management_content.select_job_title",
          item_management_content_select_job_title_level: "item_management_content.select_job_title_level",
          item_management_content_info_service: "item_management_content.info_service",
          item_management_content_info_treatment: "item_management_content.info_treatment",
          item_management_content_code: "item_management_content.code",
          item_management_content_name: "item_management_content.name",
          item_management_content_name_service: "item_management_content.name_service",
          item_management_content_enter_name_service: "item_management_content.enter_name_service",
          item_management_content_condition_success_execute_step: "item_management_content.condition_success_execute_step",
          item_management_content_service_code: "item_management_content.service_code",
          item_management_content_service_code_old: "item_management_content.service_code_old",
          item_management_content_enter_service_code: "item_management_content.enter_service_code",
          item_management_content_enter_service_code_old: "item_management_content.enter_service_code_old",
          item_management_content_producer: "item_management_content.producer",
          item_management_content_select_producer: "item_management_content.select_producer",
          item_management_content_image_type: "item_management_content.image_type",
          item_management_content_image: "item_management_content.image",
          item_management_content_select_time_perform: "item_management_content.select_time_perform",
          item_management_content_purchase_unit: "item_management_content.purchase_unit",
          item_management_content_select_purchase_unit: "item_management_content.select_purchase_unit",
          item_management_content_select_category: "item_management_content.select_category",
          item_management_content_using_unit: "item_management_content.using_unit",
          item_management_content_select_using_unit: "item_management_content.select_using_unit",
          item_management_content_unit_used: "item_management_content.unit_used",
          item_management_content_select_unit_used: "item_management_content.select_unit_used",
          item_management_content_inventory_management: "item_management_content.inventory_management",
          item_management_content_info_material: "item_management_content.info_material",
          item_management_content_info_supplies: "item_management_content.info_supplies",
          item_management_content_product_name: "item_management_content.product_name",
          item_management_content_enter_product_name: "item_management_content.enter_product_name",
          item_management_content_product_code: "item_management_content.product_code",
          item_management_content_enter_product_code: "item_management_content.enter_product_code",
          item_management_content_material_name: "item_management_content.material_name",
          item_management_content_enter_material_name: "item_management_content.enter_material_name",
          item_management_content_material_code: "item_management_content.material_code",
          item_management_content_enter_material_code: "item_management_content.enter_material_code",
          item_management_content_supplies_name: "item_management_content.supplies_name",
          item_management_content_enter_supplies_name: "item_management_content.enter_supplies_name",
          item_management_content_supplies_code: "item_management_content.supplies_code",
          item_management_content_enter_supplies_code: "item_management_content.enter_supplies_code",
          item_management_content_asset_name: "item_management_content.asset_name",
          item_management_content_enter_asset_name: "item_management_content.enter_asset_name",
          item_management_content_asset_code: "item_management_content.asset_code",
          item_management_content_enter_asset_code: "item_management_content.enter_asset_code",
          item_management_content_tools_name: "item_management_content.tools_name",
          item_management_content_enter_tools_name: "item_management_content.enter_tools_name",
          item_management_content_tools_code: "item_management_content.tools_code",
          item_management_content_enter_tools_code: "item_management_content.enter_tools_code",
          item_management_content_info_asset: "item_management_content.info_asset",
          item_management_content_info_tool: "item_management_content.info_tool",
        treatment_management: "treatment_management",
          treatment_management_content_request_level_skill: "treatment_management_content.request_level_skill",
          treatment_management_content_treatment_old_id: "treatment_management_content.treatment_old_id",
          treatment_management_content_treatment_name: "treatment_management_content.treatment_name",
          treatment_management_content_treatment_code: "treatment_management_content.treatment_code",
          treatment_management_content_treatment_execute_time: "treatment_management_content.treatment_execute_time",
          treatment_management_content_treatment_doctor_time: "treatment_management_content.treatment_doctor_time",
          treatment_management_content_treatment_chair_time: "treatment_management_content.treatment_chair_time",
          treatment_management_content_enter_treatment_name: "treatment_management_content.enter_treatment_name",
          treatment_management_content_enter_treatment_code: "treatment_management_content.enter_treatment_code",
          treatment_management_content_enter_treatment_execute_time: "treatment_management_content.enter_treatment_execute_time",
          treatment_management_content_enter_treatment_doctor_time: "treatment_management_content.enter_treatment_doctor_time",
          treatment_management_content_enter_treatment_chair_time: "treatment_management_content.enter_treatment_chair_time",
          treatment_management_content_enter_treatment_old_id: "treatment_management_content.enter_treatment_old_id",
          treatment_management_content_selected_total_skill: "treatment_management_content.selected_total_skill",
          treatment_management_content_are_you_want_delete_treatment: "treatment_management_content.are_you_want_delete_treatment",
          treatment_management_content_once_the_treatment_is_locked_it_cannot_be_recovered: "treatment_management_content.once_the_treatment_is_locked_it_cannot_be_recovered",
          treatment_management_content_delete_treatment: "treatment_management_content.delete_treatment",
          treatment_management_content_please_enter_id_treatment: "treatment_management_content.please_enter_id_treatment",
          treatment_management_content_select_type: "treatment_management_content.select_type",
        YES: "YES",
        NO: "NO",
        material: "material",
        tool: "tool",
        supplies: "supplies",
        tools: "tools",
        asset: "asset",
        add_service: "add_service",
        create_service: "create_service",
        update_service: "update_service",
        add_product: "add_product",
        update_product: "update_product",
        add_supplies: "add_supplies",
        update_supplies: "update_supplies",
        add_asset: "add_asset",
        update_asset: "update_asset",
        add_tool: "add_tool",
        add_combo: "add_combo",
        update_tool: "update_tool",
        quantity: "quantity",
        unit_of_measure: "unit_of_measure",
        quantity_must_be_greater_than_0: "quantity_must_be_greater_than_0",
        add: "add",
        empty_list: "empty_list",
        add_main_image: "add_main_image",
        add_image: "add_image",
        note_size_image: "note_size_image",
        category: "category",
        select_category: "select_category",
        search_appointment: "search_appointment",
        search_appointment_s_label: "search_appointment_s_label",
        search_appointment_choose_clinic: "search_appointment_choose_clinic",
        search_appointment_date_time: "search_appointment_date_time",
        search_appointment_patient: "search_appointment_patient",
        search_appointment_dob: "search_appointment_dob",
        search_appointment_doctor: "search_appointment_doctor",
        search_appointment_location: "search_appointment_location",
        search_appointment_treatment: "search_appointment_treatment",
        search_appointment_send_sms: "search_appointment_send_sms",
        search_appointment_in: "search_appointment_in",
        add_schedule: "add_schedule",
        update_schedule: "update_schedule",
        total_schedule: "total_schedule",
        search_appointment_send_all: "search_appointment_send_all",
        search_appointment_updated_by: "search_appointment_updated_by",
        sms_successfully: "sms_successfully",
        search_appointment_doctor_shift: "search_appointment_doctor_shift",
        created_at: "created_at",
        sorry_currently_you_cant_download_excel_file: "sorry_currently_you_cant_download_excel_file",
        item_category: "item_category",
          item_category_content_add: "item_category_content.add",
          item_category_content_add_sub: "item_category_content.add_sub",
          item_category_content_name: "item_category_content.name",
          item_category_content_please_select_name: "item_category_content.please_select_name",
          item_category_content_are_you_want_delete: "item_category_content.are_you_want_delete",
          item_category_content_create_profile_success: "item_category_content.create_profile_success",
          item_category_content_once_the_profile_is_locked_it_cannot_be_recovered: "item_category_content.once_the_profile_is_locked_it_cannot_be_recovered",
          item_category_content_please_enter_id_item: "item_category_content.please_enter_id_item",
        report_14: "report_14",
          report_14_content_title: "report_14_content.title",
          report_14_content_number_of_case: "report_14_content.number_of_case",
          report_14_content_all_branch: "report_14_content.all_branch",
          report_14_content_all_age_group: "report_14_content.all_age_group",
          report_14_content_all_gender: "report_14_content.all_gender",
          report_14_content_all_area: "report_14_content.all_area",
        case: "case",
        you_dont_have_permission: "you_dont_have_permission",
        not_exist_page: "not_exist_page",
        role: "role",
          role_content_enter_role: "role_content.enter_role",
          role_content_name_role: "role_content.name_role",
          role_content_permission: "role_content.permission",
          role_content_update_permission: "role_content.update_permission",
          role_content_choose_group_permission: "role_content.choose_group_permission",
          role_content_enter_name_permission: "role_content.enter_name_permission",
          role_content_name_permission: "role_content.name_permission",
          role_content_total_permission_selected: "role_content.total_permission_selected",
          role_content_add_role: "role_content.add_role",
          role_content_enter_name_role: "role_content.enter_name_role",
          role_content_add_permission: "role_content.add_permission",
          role_content_info_permission: "role_content.info_permission",
          role_content_delete_role: "role_content.delete_role",
          role_content_modules: "role_content.modules",
          role_content_enter_description_role: "role_content.enter_description_role",
          role_content_description_role: "role_content.description_role",
          role_content_group_permission: "role_content.group_permission",
          role_content_update_role: "role_content.update_role",
          role_content_not_choose_permission: "role_content.not_choose_permission",
          role_content_role_selected_count: "role_content.role_selected_count",
        role_management: "role_management",
        all_status: "all_status",
        year_number: "year_number",
        tip_price: "tip_price",
        package_name: "package_name",
        package_price: "package_price",
        net_amount: "net_amount",
        paid: "paid",
        contract_company_value: "contract_company_value",
        contract_clinic_value: "contract_clinic_value",
        contract_code: "contract_code",
        note_print: "note_print",
        note: "note",
        service_amount: "service_amount",
        location: "location",
        statistical: "statistical",
        list_braces_tray: "list_braces_tray",
        braces_tray: "braces_tray",
        created_successfully: "created_successfully",
        success: "success",
        once_the_name_is_locked_it_cannot_be_recovered: "once_the_name_is_locked_it_cannot_be_recovered",
        are_you_want_delete_name: "are_you_want_delete_name",
        roles: "roles",
        rights: "rights",
        job_title_roles: "job_title_roles",
        day_off: "day_off",
        day_leave_policy: "day_leave_policy",
        day_leave: "day_leave",
        CONFLICT_TIME: "CONFLICT_TIME",
        waited: "waited",
        inventory: "inventory",
        dayoff: "dayoff",
        dayleave: "dayleave",
        profile_management: "profile_management",
        id: "id",
        select_all: "select_all",
        unselect_all: "unselect_all",
        copy_success: "copy_success",
        skill_title: "skill_title",
        my_profile: "my_profile",
        group_service: "group_service",
        select_debt_date: "select_debt_date",
        select_debt_date_all: "select_debt_date_all",
        old_password: "old_password",
        enter_old_password: "enter_old_password",
        new_password: "new_password",
        enter_new_password: "enter_new_password",
        confirm_new_password: "confirm_new_password",
        enter_confirm_new_password: "enter_confirm_new_password",
        the_new_password_that_you_entered_do_not_match: "the_new_password_that_you_entered_do_not_match",
        new_password_need_different_old_password: "new_password_need_different_old_password",
        change_password_success_and_please_re_login: "change_password_success_and_please_re_login",
        old_password_is_incorrect: "old_password_is_incorrect",
        wrong_username_or_password: "wrong_username_or_password",
        with_error_please_contact_with_admin: "with_error_please_contact_with_admin",
        your_session_has_expired_please_login_again: "your_session_has_expired_please_login_again",
        enter_email_or_phone: "enter_email_or_phone",
        dental_treatment_management: "dental_treatment_management",
        patient_record_management: "patient_record_management",
        enter_search_patient: "enter_search_patient",
        add_patient_record: "add_patient_record",
        update_patient_record: "update_patient_record",
        msbn: "msbn",
        enter_patient_code: "enter_patient_code",
        choose_province: "choose_province",
        choose_district_or_city: "choose_district_or_city",
        choose_ward: "choose_ward",
        enter_address: "enter_address",
        customer_source: "customer_source",
        choose_source: "choose_source",
        important_note: "important_note",
        enter_note: "enter_note",
        patient_info: "patient_info",
        update_by: "update_by",
        see_history_update_note: "see_history_update_note",
        history_update_patient_record: "history_update_patient_record",
        lasted_appointment: "lasted_appointment",
        attending_physician: "attending_physician",
        managment: "managment",
        birthday: "birthday",
        enter_phone: "enter_phone",
        enter_first_name: "enter_first_name",
        enter_last_name: "enter_last_name",
        enter_email: "enter_email",
        please_select_gender: "please_select_gender",
        choose_area: "choose_area",
        choose_clinic: "choose_clinic",
        select_birthday: "select_birthday",
        district: "district",
        ward: "ward",
        utilities: "utilities",
        lasted_appoinment: "lasted_appoinment",
        qr_generate: "qr_generate",
        input_link_you_want: "input_link_you_want",
        generate: "generate",
        value_qr: "value_qr",
        reset_QR: "reset_QR",
        download: "download",
        selecting: "selecting",
        please_select_employee: "please_select_employee",
        date_recall: "date_recall",
        unit_price: "unit_price",
        treatment_fee: "treatment_fee",
        vat_code: "vat_code",
        refund: "refund",
        cash: "cash",
        card: "card",
        card_type: "card_type",
        transfer: "transfer",
        transfer_type: "transfer_type",
        app_momo: "app_momo",
        select_service_treatment_required: "select_service_treatment_required",
        sync: "sync",
        debt: "debt",
        sync_for_up_to_one_day: "sync_for_up_to_one_day",
        sync_successfully: "sync_successfully",
        choose_service: "choose_service",
        booking_website_report: "booking_website_report",
        arrivals: "arrivals",
        number_of_booking_in_many_days: "number_of_booking_in_many_days",
        time_from_to: "time_from_to",
        number_arrivals: "number_arrivals",
        export_excel_sendmail_message: "export_excel_sendmail_message",
        profile_patient_record: "profile_patient_record",
        date_time: "date_time",
        main_content: "main_content",
        doctor_in_charge_of_the_shift: "doctor_in_charge_of_the_shift",
        prefix: "prefix",
        appointment: "appointment",
        call_reminder_schedule: "call_reminder_schedule",
        purchase_dental_products_services: "purchase_dental_products_services",
        payment_debt: "payment_debt",
        order_id: "order_id",
        monthly_installment_plan: "monthly_installment_plan",
        info_payment: "info_payment",
        late_payment_period: "late_payment_period",
        total_amount_due: "total_amount_due",
        payment_method: "payment_method",
        add_payment_method: "add_payment_method",
        issue_invoice: "issue_invoice",
        detail_payment: "detail_payment",
        total_payment_required: "total_payment_required",
        amount_paid_by_customer: "amount_paid_by_customer",
        enter_amount_paid_by_customer: "enter_amount_paid_by_customer",
        excess_money: "excess_money",
        total_payment_made: "total_payment_made",
        late_payment_processing: "late_payment_processing",
        complete_settlement: "complete_settlement",
        payment_option: "payment_option",
        e_wallet: "e_wallet",
        choose_payment_method: "choose_payment_method",
        enter_money: "enter_money",
        payment: "payment",
        add_product_service: "add_product_service",
        close: "close",
        invoice: "invoice",
        total_invoice: "total_invoice",
        alternative_application_program: "alternative_application_program",
        installment_payment: "installment_payment",
        payment_in_stages: "payment_in_stages",
        discount: "discount",
        product_does_not_support_installment_payment: "product_does_not_support_installment_payment",
        total: "total",
        total_amount: "total_amount",
        number_of_products_services: "number_of_products_services",
        subtotal_amount: "subtotal_amount",
        promotion: "promotion",
        disount_code: "disount_code",
        enter_discount_code: "enter_discount_code",
        total_payment: "total_payment",
        program: "program",
        add_alternative_application_program: "add_alternative_application_program",
        select_program: "select_program",
        select_payment_method: "select_payment_method",
        enter_name_company: "enter_name_company",
        tax_id: "tax_id",
        enter_tax_id: "enter_tax_id",
        total_amount_payment: "total_amount_payment",
        image_patient: "image_patient",
        add_treatment_procedure_folder: "add_treatment_procedure_folder",
        exist_folder_name: "exist_folder_name",
        note_maximum_image_upload_size_is_5_MB: "note_maximum_image_upload_size_is_5_MB",
        provider: "provider",
        provider_management: "provider_management",
        provider_add: "provider_add",
        provider_update: "provider_update",
        provider_delete: "provider_delete",
        provider_form_required: "provider_form_required",
        name: "name",
        contact: "contact",
        bankinfo: "bankinfo",
        TIN: "TIN",
        treatment_schedule: "treatment_schedule",
        total_amount_service: "total_amount_service",
        paid_amount: "paid_amount",
        refund_amount: "refund_amount",
        remaining_amount: "remaining_amount",
        patient_journey: "patient_journey",
        emergency_contact: "emergency_contact",
        next_of_kin: "next_of_kin",
        call_schedule: "call_schedule",
        dental_health_history: "dental_health_history",
        laboratory_results: "laboratory_results",
        transaction_history: "transaction_history",
        payment_history: "payment_history",
        under_treatment: "under_treatment",
        service_refund: "service_refund",
        treatment_information: "treatment_information",
        treating_physician: "treating_physician",
        no_treating_physician: "no_treating_physician",
        treatment_plan: "treatment_plan",
        no_assigned_physician: "no_assigned_physician",
        assigned_physician: "assigned_physician",
        approved: "approved",
        treatment_phase: "treatment_phase",
        phase_attending_physician: "phase_attending_physician",
        phase_approver: "phase_approver",
        add_phase: "add_phase",
        send_payment_SMS: "send_payment_SMS",
        send_treatment_receipt_email: "send_treatment_receipt_email",
        print_treatment_receipt: "print_treatment_receipt",
        print_contract: "print_contract",
        add_appointment: "add_appointment",
        appointment_session: "appointment_session",
        symptoms_diagnosis: "symptoms_diagnosis",
        treatment_direction: "treatment_direction",
        procedures_within_appointment: "procedures_within_appointment",
        equipment_information: "equipment_information",
        professional_exchange_with_physician: "professional_exchange_with_physician",
        image_size: "image_size",
        document_size: "document_size",
        upload_size_max_10_mb: "upload_size_max_10_mb",
        perform_procedure: "perform_procedure",
        compress: "compress",
        cancel_approval: "cancel_approval",
        not_available: "not_available",
        completed: "completed",
        in_treatment: "in_treatment",
        cancelled: "cancelled",
        suspended: "suspended",
        pending_execution: "pending_execution",
        payment_information: "payment_information",
        refund_money: "refund_money",
        code: "code",
        payment_code: "payment_code",
        payment_date: "payment_date",
        material_import: "material_import",
        material_import_management: "material_import_management",
        material_import_code: "material_import_code",
        material_import_add: "material_import_add",
        material_import_update: "material_import_update",
        material_import_delete: "material_import_delete",
        material_import_form_required: "material_import_form_required",
        material_management: "material_management",
        invoice_number: "invoice_number",
        invoice_symbol: "invoice_symbol",
        invoice_date: "invoice_date",
        invoice_price_tax: "invoice_price_tax",
        unit_import: "unit_import",
        total_price: "total_price",
        unit_use: "unit_use",
        unit_use_import: "unit_use_import",
        date_import: "date_import",
        select: "select",
        created_date: "created_date",
        material_category: "material_category",
        material_vat: "material_vat",
        detail: "detail",
        material_group: "material_group",
        provider_contact: "provider_contact",
        material_purpose: "material_purpose",
        patient_code: "patient_code",
        material_export: "material_export",
        material_category_delete: "material_category_delete",
        material_category_update: "material_category_update",
        provider_code: "provider_code",
        doctors_and_nurses_perform: "doctors_and_nurses_perform",
        doctors_perform: "doctors_perform",
        nurses_perform: "nurses_perform",
        stop_performing: "stop_performing",
        pending_payment: "pending_payment",
        not_occurred: "not_occurred",
        occurred: "occurred",
        have_problem: "have_problem",
        update_appointment_session: "update_appointment_session",
        name_appointment_session: "name_appointment_session",
        delete_appointment_session: "delete_appointment_session",
        update_treatment_phase: "update_treatment_phase",
        delete_treatment_phase: "delete_treatment_phase",
        add_appointment_session: "add_appointment_session",
        enter_content: "enter_content",
        content: "content",
        attached: "attached",
        amount: "amount",
        schedule_without_paid: "schedule_without_paid",
        patient_arrive_at_the_clinic: "patient_arrive_at_the_clinic",
        the_patient_is_counseled_by_a_counselor: "the_patient_is_counseled_by_a_counselor",
        the_patient_undergoes_treatment: "the_patient_undergoes_treatment",
        the_patient_leaves: "the_patient_leaves",
        performer: "performer",
        enter_phase_name: "enter_phase_name",
        phase_name: "phase_name",
        enter_name_appointment_session: "enter_name_appointment_session",
        trade_union_members_management: "trade_union_members_management",
        hrm_trade_union_members_management: "hrm_trade_union_members_management",
        approve_union_members: "approve_union_members",
        union_members_detail: "union_members_detail",
        event_management: "event_management",
        event_report: "event_report",
        id_management: "id_management",
        domicile: "domicile",
        CCCD: "CCCD",
        view_detail: "view_detail",
        nations: "nations",
        religion: "religion",
        Workplace: "Workplace",
        university_subsidiary: "university_subsidiary",
        academic: "academic",
        start_date_ueh: "start_date_ueh",
        employment_status: "employment_status",
        date_of_joining_the_party: "date_of_joining_the_party",
        date_of_union_entry: "date_of_union_entry",
        union_card_number: "union_card_number",
        status_union_activities: "status_union_activities",
        union_positions: "union_positions",
        enter_union_card_number: "enter_union_card_number",
        enter_union_positions: "enter_union_positions",
        admin: "admin",
        trade_union_member: "trade_union_member",
        all_role: "all_role",
        in_active: "in_active",
        active_content: "active_content",
        enter_full_name: "enter_full_name",
        create_account: "create_account",
        create_event: "create_event",
        update_event: "update_event",
        not_happen: "not_happen",
        in_event: "in_event",
        number_trade_union_register: "number_trade_union_register",
        trade_union_register_event: "trade_union_register_event",
        check_in: "check_in",
        checked_in: "checked_in",
        not_check_in: "not_check_in",
        event_name: "event_name",
        enter_event_name: "enter_event_name",
        estimated_number_of_union_members: "estimated_number_of_union_members",
        event_reporting_by_stage: "event_reporting_by_stage",
        general_info: "general_info",
        education_and_team_info: "education_and_team_info",
        sync_data: "sync_data",
        permission: "permission",
        name_role: "name_role",
        enter_description: "enter_description",
        select_permission: "select_permission",
        add_new_role: "add_new_role",
        enter_name_description_for_search: "enter_name_description_for_search",
        select_role: "select_role",
        search_by_name_phone_email: "search_by_name_phone_email",
        search_by_name_description: "search_by_name_description",
        women_workers: "women_workers",
        propaganda_culture: "propaganda_culture",
        union_regulations: "union_regulations",
        commendation_reward: "commendation_reward",
        policies_laws_professional_support: "policies_laws_professional_support",
        union_activities_categorization: "union_activities_categorization",
        all_union_activities_categorization: "all_union_activities_categorization",
        select_union_activities_categorization: "select_union_activities_categorization",
        end_date_must_be_after_start_date: "end_date_must_be_after_start_date",
        add_address: "add_address",
        select_union_certificate: "select_union_certificate",
        union_certificate: "union_certificate",
        click_or_drag_file_to_this_area_to_upload: "click_or_drag_file_to_this_area_to_upload",
        support_for_a_single_or_bulk_upload: "support_for_a_single_or_bulk_upload",
        update_success: "update_success",
          permission_content_create_event: "permission_content.create_event",
          permission_content_read_event: "permission_content.read_event",
          permission_content_update_event: "permission_content.update_event",
          permission_content_delete_event: "permission_content.delete_event",
          permission_content_create_account: "permission_content.create_account",
          permission_content_read_account: "permission_content.read_account",
          permission_content_update_account: "permission_content.update_account",
          permission_content_delete_account: "permission_content.delete_account",
          permission_content_change_password: "permission_content.change_password",
          permission_content_update_role_account: "permission_content.update_role_account",
          permission_content_read_report: "permission_content.read_report",
          permission_content_update_trade_union_member: "permission_content.update_trade_union_member",
          permission_content_read_trade_union_member: "permission_content.read_trade_union_member",
          permission_content_sync_trade_union_member: "permission_content.sync_trade_union_member",
          permission_content_create_trade_union_member: "permission_content.create_trade_union_member",
          permission_content_delete_trade_union_member: "permission_content.delete_trade_union_member",
          permission_content_create_role: "permission_content.create_role",
          permission_content_read_role: "permission_content.read_role",
          permission_content_update_role: "permission_content.update_role",
          permission_content_delete_role: "permission_content.delete_role",
          permission_content_read_role_permission: "permission_content.read_role_permission",
          permission_content_update_role_permission: "permission_content.update_role_permission",
          permission_content_create_role_permission: "permission_content.create_role_permission",
          permission_content_delete_role_permission: "permission_content.delete_role_permission",
          permission_content_create_new_tum_register: "permission_content.create_new_tum_register",
          permission_content_read_new_tum_register: "permission_content.read_new_tum_register",
          permission_content_update_new_tum_register: "permission_content.update_new_tum_register",
          permission_content_approve_new_tum_register: "permission_content.approve_new_tum_register",
          permission_content_create_tum_hrm: "permission_content.create_tum_hrm",
          permission_content_read_tum_hrm: "permission_content.read_tum_hrm",
          permission_content_update_tum_hrm: "permission_content.update_tum_hrm",
          permission_content_create_unit: "permission_content.create_unit",
          permission_content_read_unit: "permission_content.read_unit",
          permission_content_update_unit: "permission_content.update_unit",
          permission_content_delete_unit: "permission_content.delete_unit",
          permission_content_export_unit: "permission_content.export_unit",
        event: "event",
        delete_name_success: "delete_name_success",
        not_open_register: "not_open_register",
        registration: "registration",
        registration_completed: "registration_completed",
        finished: "finished",
        previous: "previous",
        start_date_event: "start_date_event",
        end_date_event: "end_date_event",
        start_date_register: "start_date_register",
        end_date_register: "end_date_register",
        start_date_must_be_before_end_date: "start_date_must_be_before_end_date",
        end_date_register_must_be_before_start_date: "end_date_register_must_be_before_start_date",
        start_date_event_must_be_after_end_date_register: "start_date_event_must_be_after_end_date_register",
        detail_event: "detail_event",
        register_join_activity: "register_join_activity",
        news_union: "news_union",
        login_with_ueh: "login_with_ueh",
        logging: "logging",
        or: "or",
        token_error: "token_error",
        relogin: "relogin",
        unit_management: "unit_management",
        unit_detail: "unit_detail"
}}
export default strings
        