import { IEvent } from 'app/api/event/model'
import ContentLayout from './content'
import { UpdateEventProvider } from './context'
import styled from 'styled-components'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { PADDING } from 'parkway-web-common'
import HeaderPage from 'app/components/header/components/HeaderPage'

export const UpdateEventContainer = ({ event }: { event: IEvent }) => {
  return (
    <RootWrapper size={0}>
      <HeaderPage title="Cập nhật sự kiện" />
      <UpdateEventProvider event={event}>
        <ContentLayout />
      </UpdateEventProvider>
    </RootWrapper>
  )
}

const RootWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
`
