/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AcademicRankEnum,
  DegreeEnum,
  EthnicGroups,
  ITradeUnionMember,
  UnitEnum,
} from 'app/api/tradeUnionMembers/model'
import { IUnit } from 'app/api/unit/model'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { notificationController } from 'app/controllers/notification-controller'
import { usePagination, useRuleHook } from 'app/hook'
import { useUpdateTum } from 'app/react-query/hook/tradeUnionMember'
import { useGetUnitList } from 'app/react-query/hook/unit'
import { isEmpty, isEqual } from 'lodash'
import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DepartmentsEnum,
  PositionEnum,
  statusActivitiesOptions,
} from '../../type'
import dayjs, { Dayjs } from 'dayjs'
import moment from 'moment'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import styled from 'styled-components'

interface IFormCreateTradeUnionMember {
  mql?: string
  last_name?: string
  first_name?: string
  gioiTinh?: string
  ngaySinh?: Dayjs
  nguyenQuan?: string
  cmnd?: string
  tenDonVi?: string
  tinhTrangLamViec?: string
  dienThoaiDiDong?: string
  email?: string
  danToc?: string
  tonGiao?: string
  noiOHienNay?: string
  hocHam?: AcademicRankEnum
  hocVi?: DegreeEnum
  tenPhanHieu?: UnitEnum
  ngayVaoUEH?: Dayjs
  ngayVaoCongDoan?: Dayjs
  soTheDoanVien?: string
  tinhTrangSinhHoat?: string
  chucVucongdoan?: string
  fullName?: string
  unitId?: string
}

export const UpdateTumModal = ({
  tum,
  isReloadPage = false,
}: {
  tum?: ITradeUnionMember
  isReloadPage?: boolean
}) => {
  const { t } = useTranslation()
  const modalRef = useRef<IRefModal>({})
  const [form] = BaseForm.useForm<IFormCreateTradeUnionMember>()
  const {
    getEmailRule,
    getNumberRule,
    getPhoneRule,
    getStringRule,
    getNormalRule,
  } = useRuleHook()
  const { mutateAsync, isLoading } = useUpdateTum()

  const { data: unitData, isLoading: isLoadingUnit } = useGetUnitList({
    limit: 10000,
    page: 1,
  })

  const { flattenDataList } = usePagination()

  const units: IUnit[] = useMemo(() => {
    return flattenDataList(unitData)?.data ?? []
  }, [unitData])

  const handleOpen = () => {
    modalRef.current?.open?.()
  }

  const handleSubmit = async (values: IFormCreateTradeUnionMember) => {
    if (!tum?.id) {
      return
    }

    const res = await mutateAsync({
      ...values,
      id: tum?.id,
      chucVucongdoan: (values?.chucVucongdoan as unknown as string[]).join(','),
      ngaySinh: values?.ngaySinh?.format('DD/MM/YYYY'),
      ngayVaoUEH: values?.ngayVaoUEH?.format('DD/MM/YYYY'),
      ngayVaoCongDoan: values?.ngayVaoCongDoan?.format('DD/MM/YYYY'),
    })

    if (res) {
      modalRef.current?.hide?.()
      form?.resetFields()

      notificationController.success({
        message: 'Cập nhật đoàn viên thành công',
      })

      if (isReloadPage) {
        window.location.reload()
      }
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const chucVucongdoan = tum?.chucVucongdoan
      ?.split(',')
      ?.filter(itm => !isEmpty(itm?.trim())) as any

    form?.setFieldsValue({
      ...tum,
      chucVucongdoan: isEmpty(chucVucongdoan) ? undefined : chucVucongdoan,
      unitId: tum?.unit?.id,
      ngaySinh: !isEmpty(tum?.ngaySinh)
        ? dayjs(moment(tum?.ngaySinh, 'DD/MM/YYYY').toDate())
        : undefined,
      ngayVaoUEH: !isEmpty(tum?.ngayVaoUEH)
        ? dayjs(moment(tum?.ngayVaoUEH, 'DD/MM/YYYY').toDate())
        : undefined,
      ngayVaoCongDoan: !isEmpty(tum?.ngayVaoCongDoan)
        ? dayjs(moment(tum?.ngayVaoCongDoan, 'DD/MM/YYYY').toDate())
        : undefined,
    })
  }, [tum])

  return (
    <BaseModalComponent
      titleModal={`Cập nhật đoàn viên "${tum?.fullName}"`}
      handleSubmit={form?.submit}
      buttonOpenModal={
        <BaseButton onClick={handleOpen} type="text">
          {'Cập nhật đoàn viên'}
        </BaseButton>
      }
      isLoadingConfirm={isLoading}
      ref={modalRef}
      widthModal={900}
      renderContent={
        <BaseForm form={form} onFinish={handleSubmit}>
          <BaseSpace size={0}>
            <BaseRow align={'top'} justify={'space-between'} gutter={16}>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  required
                  rules={[
                    ...getStringRule({
                      field: 'Họ và tên',
                      isRequire: true,
                    }),
                  ]}
                  label={'Họ và tên'}
                  name={'fullName'}
                >
                  <BaseInput placeholder="Nhập họ và tên" />
                </BaseForm.Item>
              </BaseCol>

              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  required
                  rules={[
                    ...getPhoneRule({
                      field: 'Điện thoại di động',
                      isRequire: true,
                    }),
                  ]}
                  label={'Số điện thoại'}
                  name={'dienThoaiDiDong'}
                >
                  <BaseInput placeholder="Nhập số điện thoại" />
                </BaseForm.Item>
              </BaseCol>

              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  required
                  rules={[
                    ...getEmailRule({
                      field: 'Email',
                      isRequire: true,
                    }),
                  ]}
                  name={'email'}
                  label={'Email'}
                >
                  <BaseInput
                    placeholder="Nhập email"
                    disabled={
                      !(isEqual(tum?.email, 'cập nhật') || isEmpty(tum?.email))
                    }
                  />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>

            <BaseRow align={'top'} justify={'space-between'} gutter={16}>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn giới tính',
                    },
                  ]}
                  label={'Giới tính'}
                  name={'gioiTinh'}
                >
                  <BaseSelect
                    style={{ width: '100%' }}
                    options={[
                      {
                        label: 'Nam',
                        value: 'Nam',
                      },
                      {
                        label: 'Nữ',
                        value: 'Nữ',
                      },
                    ]}
                    placeholder="Chọn giới tính"
                  />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  label={'Mã quản lý'}
                  rules={[
                    ...getStringRule({
                      field: 'Mã quản lý',
                      isRequire: true,
                    }),
                  ]}
                  name={'mql'}
                  required
                >
                  <BaseInput placeholder="Nếu không có, điền: Không có" />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  required
                  rules={[
                    ...getStringRule({
                      field: 'Quê quán',
                      isRequire: true,
                    }),
                  ]}
                  label={'Quê quán'}
                  name={'nguyenQuan'}
                >
                  <BaseInput placeholder="Nhập quê quán" />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>

            <BaseRow align={'top'} justify={'space-between'} gutter={16}>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  required
                  rules={[
                    ...getNumberRule({
                      field: 'CMND',
                      isRequire: true,
                    }),
                    {
                      pattern: new RegExp('^[0-9]{9}$|^[0-9]{12}$'),
                      message: 'CCCD phải có 9 hoặc 12 kí tự',
                    },
                  ]}
                  label={'Căn cước công dân'}
                  name={'cmnd'}
                >
                  <BaseInput placeholder="Nhập Căn cước công dân" />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  required
                  rules={[
                    ...getStringRule({
                      field: 'Tên đơn vị',
                      isRequire: true,
                    }),
                  ]}
                  label={'Đơn vị công tác'}
                  name={'tenDonVi'}
                >
                  <BaseSelect
                    placeholder="Chọn"
                    options={Object.values(DepartmentsEnum).map(department => ({
                      label: department,
                      value: department,
                    }))}
                    showSearch
                  />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  required
                  rules={[
                    ...getStringRule({
                      field: 'Tình trạng làm việc',
                      isRequire: true,
                    }),
                  ]}
                  label={'Tình trạng làm việc'}
                  name={'tinhTrangLamViec'}
                >
                  <BaseInput placeholder="Nhập tình trạng làm việc" />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>

            <BaseRow align={'top'} justify={'space-between'} gutter={16}>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item label={'Dân tộc'} name={'danToc'}>
                  <BaseSelect
                    options={Object.values(EthnicGroups).map(item => ({
                      label: item,
                      value: item,
                    }))}
                    filterSort={() => 0}
                    showSearch
                    placeholder="Chọn dân tộc"
                  />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  label={'Tôn giáo'}
                  name={'tonGiao'}
                  required
                  rules={[
                    ...getNormalRule({
                      field: 'Tôn giáo',
                      isRequire: true,
                    }),
                  ]}
                >
                  <BaseInput placeholder="Nếu không có, điền: Không có" />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  required
                  rules={[
                    ...getStringRule({
                      field: 'Nơi ở hiện nay',
                      isRequire: true,
                    }),
                  ]}
                  label={'Địa chỉ'}
                  name={'noiOHienNay'}
                >
                  <BaseInput placeholder="Nhập địa chỉ" />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>

            <BaseRow align={'top'} justify={'space-between'} gutter={16}>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item label={'Học hàm'} name={'hocHam'}>
                  <BaseSelect
                    options={Object.values(AcademicRankEnum).map(item => ({
                      label: item,
                      value: item,
                    }))}
                    filterSort={() => 0}
                    showSearch
                    placeholder="Chọn học hàm"
                  />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  label={'Học vị'}
                  name={'hocVi'}
                  required
                  rules={[
                    ...getNormalRule({
                      field: 'Học vị',
                      isRequire: true,
                    }),
                  ]}
                >
                  <BaseSelect
                    options={Object.values(DegreeEnum).map(item => ({
                      label: item,
                      value: item,
                    }))}
                    filterSort={() => 0}
                    showSearch
                    placeholder="Chọn học vị"
                  />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item label={'Ngày vào UEH'} name={'ngayVaoUEH'}>
                  <Picker
                    picker={'date'}
                    allowClear={false}
                    placeholder="Nhập ngày vào UEH"
                  />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>

            <BaseRow align={'top'} justify={'space-between'} gutter={16}>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  label={'Ngày vào công đoàn'}
                  name={'ngayVaoCongDoan'}
                >
                  <Picker
                    picker={'date'}
                    allowClear={false}
                    placeholder="Nhập ngày vào công đoàn"
                  />
                </BaseForm.Item>
              </BaseCol>

              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  rules={[
                    ...getStringRule({
                      field: 'Tên trường thành viên',
                    }),
                  ]}
                  label={'Tên trường thành viên'}
                  name={'tenPhanHieu'}
                >
                  <BaseSelect
                    options={Object.values(UnitEnum).map(item => ({
                      label: item,
                      value: item,
                    }))}
                    filterSort={() => 0}
                    showSearch
                  />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  label={'Ngày sinh'}
                  name={'ngaySinh'}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn ngày sinh',
                    },
                  ]}
                >
                  <Picker
                    picker={'date'}
                    allowClear={false}
                    placeholder="Nhập ngày sinh"
                  />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>

            <BaseRow align={'top'} justify={'space-between'} gutter={16}>
              <BaseCol xl={8} style={{ flex: 1 }}>
                <BaseForm.Item
                  rules={[
                    ...getNumberRule({
                      field: 'Số thẻ đoàn viên',
                    }),
                  ]}
                  label={'Số thẻ đoàn viên'}
                  name={'soTheDoanVien'}
                >
                  <BaseInput placeholder="Nhập số thẻ đoàn viên" />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={16} style={{ flex: 1 }}>
                <BaseForm.Item
                  required
                  rules={[
                    ...getStringRule({
                      field: 'Tình trạng sinh hoạt',
                      isRequire: true,
                    }),
                  ]}
                  label={'Tình trạng sinh hoạt'}
                  name={'tinhTrangSinhHoat'}
                >
                  <BaseSelect
                    placeholder="Chọn tình trạng sinh hoạt"
                    options={statusActivitiesOptions}
                    showSearch
                  />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={24}>
                <BaseForm.Item
                  label={'Đơn vị công đoàn'}
                  required
                  rules={[
                    ...getNormalRule({
                      field: 'Đơn vị công đoàn',
                      isRequire: true,
                    }),
                  ]}
                  name={'unitId'}
                >
                  <BaseSelect
                    placeholder={'Chọn'}
                    options={units?.map(unit => ({
                      label: `${unit?.name} (mã: ${unit?.id})`,
                      value: unit?.id,
                    }))}
                    showSearch
                    loading={isLoadingUnit}
                  />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={24}>
                <BaseForm.Item
                  label={t(R.strings.union_positions)}
                  required
                  rules={[
                    ...getNormalRule({
                      field: 'Chức vụ công đoàn',
                      isRequire: true,
                    }),
                  ]}
                  name={'chucVucongdoan'}
                >
                  <BaseSelect
                    placeholder={'Chọn'}
                    options={Object.values(PositionEnum).map(position => ({
                      label: position,
                      value: position,
                    }))}
                    showSearch
                    mode="multiple"
                  />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}
const Picker = styled(BaseDatePicker)`
  width: 100%;
`
