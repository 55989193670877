import { BaseButton } from 'app/components/common/BaseButton'
import BaseText from 'app/components/common/BaseText'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useMemo } from 'react'
import { useUpdateEventContext } from '../../../../context'
import { IActivityEvent, ILocation } from '../../../../type'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'

export const LocationLayout = ({
  activity,
  index,
}: {
  activity: IActivityEvent
  index: number
}) => {
  const { deleteLocationInActivityByIndex } = useUpdateEventContext()

  const data = useMemo(() => {
    return activity?.locations?.map((item, index) => {
      return {
        ...item,
        key: index,
      }
    })
  }, [activity?.locations])

  const handleDelete = (recordIndex?: number) => {
    if (recordIndex === undefined) return

    deleteLocationInActivityByIndex?.(index, recordIndex)
  }

  return (
    <BaseTableManagement
      dataSource={data}
      columns={[
        convertColumnTable<ILocation & { key: number }>({
          key: 'key',
          title: 'STT',
          fixed: 'left',
          classNameWidthColumnOverwrite: 'number-column',
          render: (index: number) => {
            return (
              <BaseText
                children={index + 1}
                fontWeight="medium"
                opacity="0.7"
              />
            )
          },
        }),
        convertColumnTable<ILocation>({
          key: 'address',
          title: 'Địa chỉ',
          fixed: 'left',
          classNameWidthColumnOverwrite: 'big-column',
          render: (text?: string) => {
            return <BaseText children={text} fontWeight="medium" />
          },
        }),
        convertColumnTable<ILocation>({
          key: 'key_map_check_in',
          title: 'Toạ độ',
          render: (_, record) => {
            return (
              <BaseText
                children={`${record?.lat}, ${record?.long}`}
                fontWeight="medium"
                opacity="0.7"
              />
            )
          },
        }),

        convertColumnTable<ILocation>({
          key: 'radius',
          title: 'Bán kính (m)',
          render: (text?: string) => {
            return (
              <BaseText children={text} fontWeight="medium" opacity="0.7" />
            )
          },
        }),

        {
          key: 'key',
          title: '',
          width: '5%',
          fixed: 'right',
          render: (_, record) => {
            return (
              <BaseButton
                type="text"
                onClick={() => handleDelete(record?.key)}
                icon={<SvgDeleteDesignIcon />}
              />
            )
          },
        },
      ]}
      scroll={{ x: true }}
      pagination={{
        pageSize: 5,
      }}
      className="row-overwrite-style"
    />
  )
}
