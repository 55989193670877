import { ApiClient } from 'app/service/api-service'
import { NewTumRegisterEndpoint } from './constant'
import {
  IPayloadReviewNewTumRegister,
  IParamsGetNewTumRegister,
  IPayloadApproveTum,
} from './model'

export const requestGetNewTumRegisterList = (
  params: IParamsGetNewTumRegister,
) =>
  ApiClient.Get({
    url: NewTumRegisterEndpoint.GET_NEW_TUM_REGISTER,
    params: { params },
  })

export const requestNewTumRegister = (body?: IPayloadReviewNewTumRegister) =>
  ApiClient.Post({
    url: NewTumRegisterEndpoint.NEW_TUM_REGISTER,
    body,
  })

export const requestApproveTum = (body?: IPayloadApproveTum) =>
  ApiClient.Put({
    url: NewTumRegisterEndpoint.APPROVE_TUM,
    body,
  })

//check-email-in-ueh-api
export const requestCheckEmailInUehApi = (params: { email: string }) =>
  ApiClient.Get({
    url: NewTumRegisterEndpoint.CHECK_EMAIL_IN_UEH_API,
    params: { params },
  })

//check-email-in-system
export const requestCheckEmailInSystem = (params: { email: string }) =>
  ApiClient.Get({
    url: NewTumRegisterEndpoint.CHECK_EMAIL_IN_SYSTEM,
    params: { params },
  })
