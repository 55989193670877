import { useState } from 'react'
import {
  IDataTumActivity,
  IActivityEvent,
  IColumnActivity,
  TypeColumn,
  IRewardActivity,
} from '../../type'
import { isEqual } from 'lodash'

export const useActivityHook = () => {
  const [activities, setActivities] = useState<IActivityEvent[]>([])

  const addActivity = (activity: IActivityEvent) => {
    setActivities([...activities, activity])
  }

  const updateTitleInActiveByIndex = (index: number, title: string) => {
    const newActivities = [...activities]
    newActivities[index].title = title
    setActivities(newActivities)
  }

  const removeActivityByIndex = (index: number) => {
    setActivities(prev => prev?.filter?.((_, idx) => !isEqual(index, idx)))
  }

  const addColumnInActivityByIndex = (
    index: number,
    column: IColumnActivity,
  ) => {
    setActivities(prev =>
      prev?.map?.((activity, idx) => {
        if (isEqual(index, idx)) {
          return {
            ...activity,
            columns: [...(activity?.columns ?? []), column],
          }
        }
        return activity
      }),
    )
  }

  const removeColumnInActivityByIndex = (
    activityIndex: number,
    columnIndex: number,
  ) => {
    setActivities(prev =>
      prev?.map?.((activity, idx) => {
        if (isEqual(activityIndex, idx)) {
          return {
            ...activity,
            columns: activity?.columns?.filter?.(
              (_, index) => !isEqual(index, columnIndex),
            ),
          }
        }
        return activity
      }),
    )
  }

  const updateColumnNameInActivityByIndex = (
    activityIndex: number,
    columnIndex: number,
    name: string,
  ) => {
    setActivities(prev =>
      prev?.map((item, index) => {
        if (isEqual(activityIndex, index)) {
          return {
            ...item,
            columns: item?.columns?.map((column, idx) => {
              if (isEqual(columnIndex, idx)) {
                return {
                  ...column,
                  name,
                }
              }
              return column
            }),
          }
        }
        return item
      }),
    )
  }

  const updateColumnTypeInActivityByIndex = (
    activityIndex: number,
    columnIndex: number,
    type: TypeColumn,
  ) => {
    setActivities(prev =>
      prev?.map((item, index) => {
        if (isEqual(activityIndex, index)) {
          return {
            ...item,
            columns: item?.columns?.map((column, idx) => {
              if (isEqual(columnIndex, idx)) {
                return {
                  ...column,
                  type,
                }
              }
              return column
            }),
          }
        }
        return item
      }),
    )
  }

  const updateDataTumInActivityByIndex = (
    activityIndex: number,
    data: IDataTumActivity[],
  ) => {
    setActivities(prev =>
      prev?.map((item, index) => {
        if (isEqual(activityIndex, index)) {
          return {
            ...item,
            data,
          }
        }
        return item
      }),
    )
  }

  const updateActivityByIndex = (index: number, activity: IActivityEvent) => {
    setActivities(prev =>
      prev?.map((item, idx) => {
        if (isEqual(index, idx)) {
          return activity
        }
        return item
      }),
    )
  }

  const updateRewardInActivityByIndex = (
    activityIndex: number,
    rewardIndex: number,
    reward: IRewardActivity,
  ) => {
    setActivities(prev =>
      prev?.map((item, index) => {
        if (isEqual(activityIndex, index)) {
          return {
            ...item,
            rewards: item?.rewards?.map((item, idx) => {
              if (isEqual(rewardIndex, idx)) {
                return reward
              }
              return item
            }),
          }
        }
        return item
      }),
    )
  }

  const deleteRewardInActivityByIndex = (
    activityIndex: number,
    rewardIndex: number,
  ) => {
    setActivities(prev =>
      prev?.map((item, index) => {
        if (isEqual(activityIndex, index)) {
          return {
            ...item,
            rewards: item?.rewards?.filter?.(
              (_, idx) => !isEqual(idx, rewardIndex),
            ),
          }
        }
        return item
      }),
    )
  }

  const addRewardInActivityByIndex = (
    activityIndex: number,
    reward: IRewardActivity,
  ) => {
    setActivities(prev =>
      prev?.map((item, index) => {
        if (isEqual(activityIndex, index)) {
          return {
            ...item,
            rewards: [...(item?.rewards ?? []), reward],
          }
        }
        return item
      }),
    )
  }

  const updateValueCareInActiveByIndex = (index: number, value: string) => {
    const newActivities = [...activities]
    newActivities[index].valueCare = value
    setActivities(newActivities)
  }

  const resetAllData = () => {
    setActivities([]);
  }

  const handleInitActivity = (data: IActivityEvent[]) => {
    setActivities(data)
  }

  return {
    activities,
    addActivity,
    updateTitleInActiveByIndex,
    removeActivityByIndex,
    addColumnInActivityByIndex,
    removeColumnInActivityByIndex,
    updateColumnNameInActivityByIndex,
    updateColumnTypeInActivityByIndex,
    updateDataTumInActivityByIndex,
    updateActivityByIndex,
    updateRewardInActivityByIndex,
    deleteRewardInActivityByIndex,
    addRewardInActivityByIndex,
    resetAllData,
    updateValueCareInActiveByIndex,
    handleInitActivity
  }
}
