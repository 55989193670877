import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import {
  IFilterTradeUnionList,
  StatusActivitiesEnum,
  statusActivitiesOptions,
} from 'app/containers/TradeUnionMemberManagement/type'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  onChangeFilter?: (filter: IFilterTradeUnionList) => void
  filter?: IFilterTradeUnionList
}

export const FilterLayout: React.FC<IProps> = ({ filter, onChangeFilter }) => {
  const { t } = useTranslation()

  const onChangeSearch = (value: string) => {
    onChangeFilter?.({ ...filter, search: value })
  }

  const onChangeSource = (source?: number) => {
    onChangeFilter?.({ ...filter, source })
  }

  const onChangeTTSH = (value: StatusActivitiesEnum) => {
    onChangeFilter?.({ ...filter, tinhTrangSinhHoat: value })
  }

  return (
    <BaseForm>
      <BaseRow gutter={[16, 8]}>
        <BaseCol xl={8}>
          <BaseForm.Item hiddenLabel>
            <BaseInput
              placeholder={t(R.strings.search)}
              value={filter?.search}
              onChange={e => onChangeSearch?.(e?.target?.value)}
              allowClear
            />
          </BaseForm.Item>
        </BaseCol>
        <BaseCol xl={8}>
          <BaseForm.Item hiddenLabel>
            <BaseSelect
              placeholder={'Nguồn dữ liệu'}
              value={filter?.source}
              onChange={e => onChangeSource?.(e as number)}
              options={[
                {
                  value: 1,
                  label: 'Từ UEH',
                },
                {
                  value: 0,
                  label: 'Từ hệ thống công đoàn',
                },
              ]}
              allowClear
            />
          </BaseForm.Item>
        </BaseCol>
        <BaseCol xl={8}>
          <BaseForm.Item hiddenLabel>
            <BaseSelect
              placeholder={'Tình trạng sinh hoạt'}
              value={filter?.tinhTrangSinhHoat}
              onChange={e => onChangeTTSH?.(e as StatusActivitiesEnum)}
              allowClear
              showSearch
              options={statusActivitiesOptions}
            />
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>
    </BaseForm>
  )
}
