import MainLayout from 'app/components/layouts/main/MainLayout'
import RequireAuth from 'app/components/router/require-auth-router'
import LoginContainer from 'app/containers/Login'
import LoginWithTokenContainer from 'app/containers/LoginWithToken'
import { withLoading } from 'app/hocs/with-loading.hoc'
import { AccountManagementPage } from 'app/page/menu/account'
import { DashboardPage } from 'app/page/menu/dashboard'
import { EventManagementPage } from 'app/page/menu/event'
import { TradeUnionMemberManagementPage } from 'app/page/menu/trade-union-member'
import { HrmTradeUnionMemberManagementPage } from 'app/page/menu/hrm-trade-union-member'
import { ApproveUnionMembersPage } from 'app/page/menu/approve-union-members'
import TradeUnionMemberDetailPage from 'app/page/menu/trade-union-member/trade-union-member-details'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { NotFound } from '../common/NotFound'
import NotAuth from './not-auth-router'
import {
  ACCOUNT_MANAGEMENT_PATH,
  AUTH_PATH,
  CHECK_IN_ACTION_PATH,
  CREATE_EVENT_PATH,
  DASHBOARD_PATH,
  EVENT_MANAGEMENT_DETAILS_PATH,
  EVENT_MANAGEMENT_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  PROFILE_PATH,
  REGISTER_ACTIVITY_PATH,
  REPORT_PATH,
  ROLE_MANAGEMENT_PATH,
  TRADE_UNION_MEMBER_DETAILS_PATH,
  TRADE_UNION_MEMBER_MANAGEMENT_PATH,
  HRM_TRADE_UNION_MEMBER_MANAGEMENT_PATH,
  APPROVE_UNION_MEMBERS_PATH,
  UPDATE_EVENT_PATH,
  AUTH_LOGIN_WITH_TOKEN,
  UNIT_MANAGEMENT_PATH,
  UNIT_MANAGEMENT_DETAILS_PATH,
} from './route-path'
import { ReportPage } from 'app/page/menu/report'
import { RoleManagementPage } from 'app/page/menu/role'
import { DetailEventManagementPage } from 'app/page/menu/event/detail'
import { RegisterForMemberPage } from 'app/page/menu/event/register-activity'
import { CheckInActionPage } from 'app/page/other/check-in-action'
import { ProfilePage } from 'app/page/menu/profile'
import { CreateEventManagementPage } from 'app/page/menu/event/create'
import { UpdateEventManagementPage } from 'app/page/menu/event/update'
import { UnitManagementPage } from 'app/page/menu/unit'
import { UnitDetailPage } from 'app/page/menu/unit/detail'

const Logout = React.lazy(() => import('./logout-router'))
const AuthLayout = React.lazy(() => import('app/components/layouts/AuthLayout'))

const Dashboard = withLoading(DashboardPage)
const EventManagement = withLoading(EventManagementPage)
const CreateEventManagement = withLoading(CreateEventManagementPage)
const UpdateEventManagement = withLoading(UpdateEventManagementPage)

const DetailEventManagement = withLoading(DetailEventManagementPage)
const AccountManagement = withLoading(AccountManagementPage)
const RegisterActivity = withLoading(RegisterForMemberPage)

const TradeUnionMemberManagement = withLoading(TradeUnionMemberManagementPage)
const HrmTradeUnionMemberManagement = withLoading(
  HrmTradeUnionMemberManagementPage,
)
const ApproveUnionMembers = withLoading(ApproveUnionMembersPage)
const TradeUnionMemberDetails = withLoading(TradeUnionMemberDetailPage)

const Report = withLoading(ReportPage)
const Profile = withLoading(ProfilePage)

const RoleManagement = withLoading(RoleManagementPage)
const UnitManagement = withLoading(UnitManagementPage)
const UnitDetail = withLoading(UnitDetailPage)

const AuthLayoutFallback = withLoading(AuthLayout)
const LogoutFallback = withLoading(Logout)

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  )

  const authLayout = (
    <NotAuth>
      <AuthLayoutFallback />
    </NotAuth>
  )

  return (
    <BrowserRouter>
      <Routes>
        <Route path={DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<Dashboard />} />
          <Route path="*" element={<NotFound />} />
          <Route
            path={ACCOUNT_MANAGEMENT_PATH}
            element={<AccountManagement />}
          />
          <Route path={EVENT_MANAGEMENT_PATH} element={<EventManagement />} />
          <Route path={CREATE_EVENT_PATH} element={<CreateEventManagement />} />
          <Route path={UPDATE_EVENT_PATH} element={<UpdateEventManagement />} />
          <Route
            path={EVENT_MANAGEMENT_DETAILS_PATH}
            element={<DetailEventManagement />}
          />

          <Route path={REGISTER_ACTIVITY_PATH} element={<RegisterActivity />} />

          <Route
            path={TRADE_UNION_MEMBER_MANAGEMENT_PATH}
            element={<TradeUnionMemberManagement />}
          />
          <Route
            path={HRM_TRADE_UNION_MEMBER_MANAGEMENT_PATH}
            element={<HrmTradeUnionMemberManagement />}
          />
          <Route
            path={APPROVE_UNION_MEMBERS_PATH}
            element={<ApproveUnionMembers />}
          />
          <Route
            path={TRADE_UNION_MEMBER_DETAILS_PATH}
            element={<TradeUnionMemberDetails />}
          />

          <Route path={REPORT_PATH} element={<Report />} />

          <Route path={PROFILE_PATH} element={<Profile />} />

          <Route path={ROLE_MANAGEMENT_PATH} element={<RoleManagement />} />

          <Route path={UNIT_MANAGEMENT_PATH} element={<UnitManagement />} />

          <Route path={UNIT_MANAGEMENT_DETAILS_PATH} element={<UnitDetail />} />
        </Route>

        <Route path={AUTH_PATH} element={authLayout}>
          <Route path={LOGIN_PATH} element={<LoginContainer />} />
          <Route
            path={AUTH_LOGIN_WITH_TOKEN}
            element={<LoginWithTokenContainer />}
          />
        </Route>
        <Route path={LOGOUT_PATH} element={<LogoutFallback />} />
        <Route path={CHECK_IN_ACTION_PATH} element={<CheckInActionPage />} />
      </Routes>
    </BrowserRouter>
  )
}
