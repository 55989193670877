import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import * as Auth from 'app/components/layouts/AuthLayout/styles'
import { DASHBOARD_PATH, LOGIN_BY_UEH } from 'app/components/router/route-path'
import { useAppDispatch } from 'app/redux/hooks'
import { doLogin } from 'app/redux/slices/authSlice'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import * as S from './styles'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseImage } from 'app/components/common/BaseImage'

interface LoginFormData {
  email: string
  password: string
}

export const initValues: LoginFormData = {
  email: '',
  password: '',
}

const LoginContainer: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [isLoading, setLoading] = useState(false)

  const handleSubmit = (values: LoginFormData) => {
    setLoading(true)
    dispatch(doLogin(values))
      .unwrap()
      .then(() => {
        navigate(DASHBOARD_PATH)
      })
      .catch(() => undefined)
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Auth.FormWrapper>
      <Auth.FormBaseWrapper>
        <Auth.BaseFormWrapper>
          <BaseForm
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark="optional"
            initialValues={initValues}
          >
            <Auth.FormTitle>{t(R.strings.login)}</Auth.FormTitle>
            <Auth.FormItem
              name="email"
              label={t(R.strings.email)}
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.email),
                  }),
                },
                {
                  pattern:
                    /^(?:\+84|0)\d{9,10}|^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: t(R.strings.regex_email),
                },
              ]}
            >
              <Auth.FormInput placeholder={t(R.strings.enter_email_or_phone)} />
            </Auth.FormItem>
            <Auth.FormItem
              label={t(R.strings.password)}
              name="password"
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.password),
                  }),
                },
              ]}
            >
              <Auth.FormInputPassword placeholder={t(R.strings.password)} />
            </Auth.FormItem>
            <Auth.ActionsWrapper>
              <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
                <Auth.FormCheckbox>
                  <S.RememberMeText>
                    {t(R.strings.remember_me)}
                  </S.RememberMeText>
                </Auth.FormCheckbox>
              </BaseForm.Item>
              <Link to="/auth/forgot-password">
                <S.ForgotPasswordText>
                  {t(R.strings.forgot_password)}
                </S.ForgotPasswordText>
              </Link>
            </Auth.ActionsWrapper>
            <BaseForm.Item noStyle>
              <Auth.SubmitButton
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                {t(R.strings.login)}
              </Auth.SubmitButton>
            </BaseForm.Item>

            <BaseDivider>{t(R.strings.or)}</BaseDivider>

            <Auth.SubmitButton
              onClick={() => window.open(LOGIN_BY_UEH, '_self')}
            >
              <BaseImage
                src={R.images.ic_ueh}
                width={20}
                height={20}
                preview={false}
              />
              {t(R.strings.login_with_ueh)}
            </Auth.SubmitButton>
          </BaseForm>
        </Auth.BaseFormWrapper>
      </Auth.FormBaseWrapper>
    </Auth.FormWrapper>
  )
}
export default LoginContainer
