import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseModalComponent } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { useTranslation } from 'react-i18next'
import { useHook } from './hook'
import { IProps } from './type'
import { DefinePermissionEnum } from 'app/api/role/constant'

export const UpdateModalLayout = (props: IProps) => {
  const { t } = useTranslation()

  const {
    form,
    modalRef,
    handleSubmit,
    isLoading,
    isLoadingPermission,
    permissions,
  } = useHook(props)

  return (
    <BaseModalComponent
      titleModal={"Cập nhật phân quyền"}
      titleOpenModal={t(R.strings.update)}
      ref={modalRef}
      handleSubmit={form?.submit}
      isLoadingConfirm={isLoading}
      renderContent={
        <BaseForm form={form} onFinish={handleSubmit}>
          <BaseSpace size={5}>
            <BaseForm.Item
              name={'name'}
              required
              label={t(R.strings.name_role)}
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.name_role),
                  }),
                },
              ]}
            >
              <BaseInput placeholder={'Nhập tên quyền'} />
            </BaseForm.Item>
            <BaseForm.Item
              name={'description'}
              label={t(R.strings.description)}
            >
              <BaseInput placeholder={t(R.strings.enter_description)} />
            </BaseForm.Item>

            <BaseForm.Item
              name={'permissionIds'}
              label={t(R.strings.permission)}
              required
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.permission),
                  }),
                },
              ]}
            >
              <BaseSelect
                placeholder={t(R.strings.select_permission)}
                showSearch
                loading={isLoadingPermission}
                mode="multiple"
                maxTagCount={2}
                options={permissions?.map(item => ({
                  label: item?.permission
                    ? t(DefinePermissionEnum?.[item?.permission]?.keyI18n)
                    : item?.name,
                  value: item?.id,
                }))}
                disabled={props?.role?.is_block_action}
              />
            </BaseForm.Item>
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}
