import { useCreateEventContext } from '../../context'

export const useHook = () => {
  const {
    handleSubmit,
    form,
    eventType,
    onChangeEventType,
    formInfo,
    setFormInfo,
    setIsHaveTeam,
    isHaveTeam,
  } = useCreateEventContext()

  return {
    form,
    eventType,
    onChangeEventType,
    handleSubmit,
    formInfo,
    setFormInfo,
    setIsHaveTeam,
    isHaveTeam,
  }
}
