import { IPayloadChangePassword } from 'app/api/account/model'
import { IRefModal } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useChangePassword } from 'app/react-query/hook/account'
import { useRef } from 'react'
import { IFormChangePassword, IProps } from './type'
import { notificationController } from 'app/controllers/notification-controller'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'

export const useHook = ({ account }: IProps) => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IFormChangePassword>()
  const modalRef = useRef<IRefModal>({})

  const { mutateAsync: mutateAsyncChangePassword, isLoading } =
    useChangePassword()

  const handleSubmit = async (values: IFormChangePassword) => {
    if (!account?.id || !values?.password) return
    const newAccount: IPayloadChangePassword = {
      id: account?.id,
      password: values?.password,
    }

    const res = await mutateAsyncChangePassword?.(newAccount)

    if (res) {
      modalRef?.current?.hide?.()
      form?.resetFields()
      notificationController.success({
        message: t(R.strings.change_password_success),
      })
    }
  }

  return {
    form,
    modalRef,
    handleSubmit,
    isLoading,
  }
}
