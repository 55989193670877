/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
import { ENV_CONFIG } from 'app/common/config'
import { LOGOUT_PATH } from 'app/components/router/route-path'
import { notificationController } from 'app/controllers/notification-controller'
import {
  ApiServiceType,
  IApiServiceProps,
  ResponseType,
  STATUS_AUTHORIZE,
  STATUS_FORBIDDEN,
  TIME_OUT_API,
} from 'parkway-web-common'
import { getToken, refetchToken } from './storage/storage-service'
import { t } from 'i18next'
import R from 'app/assets/R'
import { MSG_CODE_TO_MESSAGE_KEY } from './constant'
import { isEqual } from 'lodash'

const createAPI = (props?: IApiServiceProps) => {
  const APIInstant = require('axios').default.create()
  APIInstant.defaults.baseURL = props?.baseUrl ?? ENV_CONFIG.API_ENDPOINT
  APIInstant.defaults.timeout = TIME_OUT_API
  APIInstant.defaults.headers = { 'Content-Type': 'application/json' }
  const token = props?.token ?? getToken()
  APIInstant.interceptors.request.use(
    async (config: { headers: { Authorization: any } }) => {
      config.headers.Authorization =
        token && token !== '' ? `Bearer ${token}` : ''
      return config
    },
    Promise.reject,
  )

  APIInstant.interceptors.response.use(
    (response: { data: any }) => {
      const data: ResponseType<any> = response?.data

      if (data && data?.msgcode === STATUS_AUTHORIZE) {
        // window.location.replace(LOGOUT_PATH)
        notificationController.error({
          message: t(R.strings.your_session_has_expired_please_login_again),
        })
      } else if (data && data?.msgcode === STATUS_FORBIDDEN) {
        // window.location.replace(LOGOUT_PATH)
        notificationController.error({
          message: t(R.strings.your_session_has_expired_please_login_again),
        })
      }

      return response
    },
    (error: { response: { data: any } }) => {
      const data: ResponseType<any> = error?.response?.data
      // console.log({error});
      const pathname = window.location.pathname
      if (
        data &&
        data?.msgcode === STATUS_AUTHORIZE &&
        pathname !== LOGOUT_PATH
      ) {
        // window.location.replace(LOGOUT_PATH)
        notificationController.error({
          message: t(R.strings.your_session_has_expired_please_login_again),
        })
      } else if (
        data &&
        data?.msgcode === STATUS_FORBIDDEN &&
        pathname !== LOGOUT_PATH
      ) {
        // window.location.replace(LOGOUT_PATH)
        notificationController.error({
          message: t(R.strings.your_session_has_expired_please_login_again),
        })
      }
      return Promise.reject(error)
    },
  )
  return APIInstant
}

/* Support function */
async function handleResult(api: Promise<any>) {
  return api
    .then((response: { data: any }) => {
      return Promise.resolve(response?.data)
    })
    .catch(
      (error: {
        response: {
          data: { message: any; msgcode?: string; statusCode?: number }
          status: number
        }
      }) => {
        let message = R.strings.with_error_please_contact_with_admin
        const pathname = window.location.pathname

        if (error?.response) {
          const code = error?.response?.data?.msgcode

          if (code && !!MSG_CODE_TO_MESSAGE_KEY[code]) {
            message = MSG_CODE_TO_MESSAGE_KEY[code]
          } else {
            message = `Error: ${
              error?.response?.data?.message || error?.response?.status
            }`
          }
          if (
            `${error?.response?.status}` === `${STATUS_AUTHORIZE}` &&
            pathname !== LOGOUT_PATH
          ) {
            message = R.strings.your_session_has_expired_please_login_again
            refetchToken()
            // window.location.replace(LOGOUT_PATH)
          }

          if (
            isEqual(
              `${error?.response?.status}`,
              `${STATUS_FORBIDDEN}`,
            ) &&
            pathname !== LOGOUT_PATH
          ) {
            message = R.strings.your_session_has_expired_please_login_again
            refetchToken()
            // window.location.replace(LOGOUT_PATH)
          }
        }
        notificationController.error({
          message: t(message),
        })
        return Promise.reject(new Error(`${error?.response?.status}`))
      },
    )
}

interface IApiServiceType extends ApiServiceType {
  PostWithParams: ({
    url,
    body,
    params,
  }: {
    url: string
    body: any
    params: any
  }) => any
}

export const ApiServiceClient = (props?: IApiServiceProps) => {
  return {
    Get: ({ url, params }) => handleResult(createAPI(props)?.get(url, params)),
    Post: ({ url, body }) => handleResult(createAPI(props)?.post(url, body)),
    PostWithParams: ({ url, body, params }) =>
      handleResult(createAPI(props)?.post(url, body, params)),
    Put: ({ url, body }) => handleResult(createAPI(props)?.put(url, body)),
    Patch: ({ url, body }) => handleResult(createAPI(props)?.patch(url, body)),
    Delete: ({ url, body }) =>
      handleResult(createAPI(props)?.delete(url, body)),
  } as IApiServiceType
}
