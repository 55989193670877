/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US'
import deVi from 'antd/lib/locale/vi_VN'
import { AppRouter } from 'app/components/router/app-router'
import { LanguageSupportType } from 'app/i18n/constant'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import {
  getCurrentLanguage,
  getToken,
} from 'app/service/storage/storage-service'
import GlobalStyle from 'app/styles/GlobalStyle'
import { themeObject } from 'app/styles/themes/themeVariables'
import { useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import './app/i18n'
import { getUserInfo } from 'app/redux/slices/userSlice'

const queryClient = new QueryClient()

const App: React.FC = () => {
  const language = getCurrentLanguage() as LanguageSupportType
  const theme = useAppSelector(state => state.theme.theme)
  const { i18n } = useTranslation()
  const token = getToken()
  const dispatch = useAppDispatch()

  const getLanguage = () => {
    if (language) {
      i18n.changeLanguage(language)
      return
    }
  }

  const getData = async () => {
    if (token) {
      await dispatch(getUserInfo())
    }
  }

  useEffect(() => {
    getLanguage()
    getData()
  }, [token])

  return (
    <QueryClientProvider client={queryClient}>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      <HelmetProvider>
        <ConfigProvider locale={language === 'en' ? enUS : deVi}>
          <AppRouter />
        </ConfigProvider>
      </HelmetProvider>
    </QueryClientProvider>
  )
}
export default App
