import { PlusOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { useRef } from 'react'
import { ITeam } from '../../type'
import { useCreateEventContext } from '../../context'

export const ModalAddTeam = ({ index }: { index: number }) => {
  const { addTeamInActivityByIndex } = useCreateEventContext()
  const [form] = BaseForm.useForm<ITeam>()
  const modalRef = useRef<IRefModal>({})

  const openModal = () => {
    modalRef?.current?.open?.()
  }

  const handleSubmit = (values: ITeam) => {
    addTeamInActivityByIndex?.(index, values)
    form?.resetFields()
    modalRef?.current?.hide?.()
  }

  return (
    <BaseModalComponent
      titleModal={'Thêm đội/nhóm'}
      handleSubmit={form?.submit}
      ref={modalRef}
      buttonOpenModal={
        <BaseButton icon={<PlusOutlined rev={undefined} />} onClick={openModal}>
          {'Thêm đội/nhóm'}
        </BaseButton>
      }
      renderContent={
        <BaseForm form={form} onFinish={handleSubmit}>
          <BaseForm.Item
            label={'Tên đội/nhóm'}
            name={'name'}
            required
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên đội/nhóm',
              },
            ]}
          >
            <BaseInput placeholder="Nhập tên đội/nhóm" />
          </BaseForm.Item>

          <BaseForm.Item label={'Mô tả đội/nhóm'} name={'description'}>
            <TextArea placeholder="Nhập mô tả đội/nhóm" />
          </BaseForm.Item>
        </BaseForm>
      }
    />
  )
}
