import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { DASHBOARD_PATH } from 'app/components/router/route-path'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import * as S from './MainSider/styles'

interface SliderLogoProps {
  isSliderCollapsed: boolean
  toggleSlider: () => void
}
export const SliderLogo: React.FC<SliderLogoProps> = ({
  isSliderCollapsed,
}) => {
  const [isShow, setIsShow] = React.useState(!isSliderCollapsed)

  useEffect(() => {
    if (isSliderCollapsed) {
      setIsShow(false)
    } else {
      const timer = setTimeout(() => {
        setIsShow(true)
      }, 500)

      return () => clearTimeout(timer)
    }
  }, [isSliderCollapsed])

  return (
    <S.SliderLogoDiv>
      <S.SliderLogoLink to={DASHBOARD_PATH}>
        <BaseRow align={'middle'} gutter={[4, 4]} wrap={false}>
          <BaseCol>
            <ImageWrapper
              src={R.images.ic_ueh}
              width={!isSliderCollapsed ? 50 : 40}
              height={!isSliderCollapsed ? 50 : 40}
              preview={false}
            />
          </BaseCol>
          {/* {isShow ? (
            <TextWrapper className={isShow ? '' : 'hide-item'}>
              <BaseSpace size={3}>
                <BaseText
                  children={'Công đoàn'}
                  fontWeight="bold"
                  fontSize="md"
                />
                <BaseText
                  children={'Đại học Kinh tế TP.HCM (UEH)'}
                  fontWeight="semibold"
                  fontSize="xxs"
                />
              </BaseSpace>
            </TextWrapper>
          ) : undefined} */}
          <TextWrapper className={isShow ? '' : 'hide-item'}>
            <BaseSpace size={3}>
              <BaseText
                children={'Công đoàn'}
                fontWeight="bold"
                fontSize="md"
              />
              <BaseText
                children={'Đại học Kinh tế TP.HCM (UEH)'}
                fontWeight="semibold"
                fontSize="xxs"
              />
            </BaseSpace>
          </TextWrapper>
        </BaseRow>
      </S.SliderLogoLink>
    </S.SliderLogoDiv>
  )
}

const TextWrapper = styled(BaseCol)`
  opacity: 1;
  transition: all 0.5s ease-in-out;
  &.hide-item {
    opacity: 0;
    transition: all 0.01s ease-in-out;
  }
`

const ImageWrapper = styled(BaseImage)`
  transition: width 0.5s ease-in-out;
`
