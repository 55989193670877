import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import React from 'react'
import {
  CreateTumModal,
  FilterLayout,
  SyncFromUehModal
} from './components'
import { useHook } from './hook'
import * as S from './styles'

const TradeUnionMemberManagement: React.FC = () => {
  const {
    columns,
    pagination,
    filter,
    onChangeFilter,
    handleChangePagination,
    isLoading,
    data,
    isPermissionCreate,
    isPermissionSyncFromUeh,
    downloadExcel,
    isPermissionDownload,
  } = useHook()

  return (
    <S.ScrollWrapper>
      <HeaderPage
        titleI18nKey={R.strings.trade_union_members_management}
        rightComponent={
          isPermissionCreate || isPermissionSyncFromUeh ? (
            <>
              {isPermissionCreate ? (
                <>
                  <BaseCol>
                    <CreateTumModal />
                  </BaseCol>
                </>
              ) : null}

              {isPermissionSyncFromUeh ? (
                <BaseCol>
                  <SyncFromUehModal />
                </BaseCol>
              ) : null}
            </>
          ) : undefined
        }
        isExport={isPermissionDownload}
        onPressExport={downloadExcel}
      />

      <SectionBase>
        <BaseSpace>
          <FilterLayout filter={filter} onChangeFilter={onChangeFilter} />
          <BaseTableManagement
            columns={columns}
            dataSource={data}
            pagination={pagination}
            loading={isLoading}
            onChange={handleChangePagination}
            rowClassName="row-overwrite-style"
          />
        </BaseSpace>
      </SectionBase>
    </S.ScrollWrapper>
  )
}

export default TradeUnionMemberManagement
