import { BaseForm } from 'app/components/common/forms/BaseForm'
import { IActivityEvent, IColumnActivity, TypeColumn } from '../../type'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { useUpdateEventContext } from '../../context'
import { BaseButton } from 'app/components/common/BaseButton'
import { PlusOutlined } from '@ant-design/icons'
import { initialColumnsActivity } from './contants'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import styled from 'styled-components'
import BaseText from 'app/components/common/BaseText'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { TypeEventEnum } from 'app/api/event/model'
import { isEqual } from 'lodash'

export const ModalEditActivity = ({
  activity,
  activityIndex,
}: {
  activity: IActivityEvent
  activityIndex: number
}) => {
  const { updateActivityByIndex, eventType } = useUpdateEventContext()

  const [form] = BaseForm.useForm<IActivityEvent>()
  const [columns, setColumns] = useState<IColumnActivity[]>(
    initialColumnsActivity,
  )
  const modalRef = useRef<IRefModal>({})

  const openModal = () => {
    modalRef?.current?.open?.()
  }

  const handleSubmit = (values: IActivityEvent) => {
    switch (eventType) {
      case TypeEventEnum.ACTIVITY:
        updateActivityByIndex?.(activityIndex, {
          title: values?.title,
          columns,
          data: activity?.data,
        })
        break

      case TypeEventEnum.TRAINING:
      case TypeEventEnum.CARE:
        updateActivityByIndex?.(activityIndex, {
          title: values?.title,
          columns: initialColumnsActivity,
          data: activity?.data,
        })
        break
    }
    modalRef?.current?.hide?.()
  }

  const onChangeNameColumn = (index: number, name: string) => {
    const newColumns = [...columns]
    newColumns[index].name = name
    setColumns(newColumns)
  }

  const onChangeTypeColumn = (index: number, type: TypeColumn) => {
    const newColumns = [...columns]
    newColumns[index].type = type
    setColumns(newColumns)
  }

  const onDeleteColumn = (index: number) => {
    const newColumns = [...columns]
    newColumns.splice(index, 1)
    setColumns(newColumns)
  }

  const addNewColumn = () => {
    setColumns([
      ...columns,
      {
        name: '',
        type: undefined,
      },
    ])
  }

  const disableSubmit = useMemo(() => {
    return columns?.some(item => !item?.name || !item?.type)
  }, [columns])

  useEffect(() => {
    if (activity) {
      form?.setFieldsValue({
        title: activity?.title,
      })
      setColumns(activity?.columns ?? [])
    }
  }, [activity])

  const titleModal = useMemo(() => {
    switch (eventType) {
      case TypeEventEnum.ACTIVITY:
        return 'Cập nhật hoạt động'
      case TypeEventEnum.TRAINING:
        return 'Cập nhật hội thảo, tập huấn'
      case TypeEventEnum.CARE:
        return 'Cập nhật hoạt động chăm lo'
      default:
        return 'Cập nhật hoạt động'
    }
  }, [eventType])

  const textOpenModal = useMemo(() => {
    switch (eventType) {
      case TypeEventEnum.ACTIVITY:
        return 'Cập nhật hoạt động sự kiện'
      case TypeEventEnum.TRAINING:
        return 'Cập nhật hội thảo, tập huấn'
      case TypeEventEnum.CARE:
        return 'Cập nhật hoạt động chăm lo'
      default:
        return 'Cập nhật hoạt động'
    }
  }, [eventType])

  return (
    <BaseModalComponent
      titleModal={titleModal}
      handleSubmit={form?.submit}
      ref={modalRef}
      isDisableSubmit={disableSubmit}
      buttonOpenModal={
        <BaseButton onClick={openModal}>{textOpenModal}</BaseButton>
      }
      widthModal={800}
      renderContent={
        <BaseForm form={form} onFinish={handleSubmit}>
          <BaseSpace>
            <BaseFormItem
              name={'title'}
              required
              label={'Tên hoạt động'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tên sự kiện',
                },
              ]}
            >
              <BaseInput placeholder="Nhập tên sự kiện" />
            </BaseFormItem>

            {isEqual(eventType, TypeEventEnum.ACTIVITY) ? (
              <>
                <BaseRow
                  gutter={[16, 8]}
                  align={'middle'}
                  justify={'space-between'}
                >
                  <BaseCol>
                    <BaseText
                      children={'Danh sách các cột dữ liệu'}
                      fontSize="xs"
                      fontWeight="semibold"
                    />
                  </BaseCol>
                  <BaseCol>
                    <BaseButton
                      icon={<PlusOutlined rev={undefined} />}
                      onClick={addNewColumn}
                    >
                      {'Thêm cột dữ liệu'}
                    </BaseButton>
                  </BaseCol>
                </BaseRow>

                <ContentWrapper>
                  <ValueWrapper size={8}>
                    {columns?.map((item, index) => {
                      return (
                        <BaseRow
                          key={index}
                          gutter={8}
                          wrap={false}
                          align={'middle'}
                          justify={'space-between'}
                        >
                          <BaseCol xl={10}>
                            <BaseFormItem hiddenLabel>
                              <BaseInput
                                value={item?.name}
                                onChange={e =>
                                  onChangeNameColumn(index, e.target.value)
                                }
                                placeholder="Tên cột"
                                disabled={!!item?.keyOfMainData}
                              />
                            </BaseFormItem>
                          </BaseCol>
                          <BaseCol xl={10}>
                            <BaseFormItem hiddenLabel>
                              <BaseSelect
                                options={[
                                  {
                                    value: TypeColumn.STRING,
                                    label: 'Chuỗi kí tự',
                                  },
                                  {
                                    value: TypeColumn.NUMBER,
                                    label: 'Số',
                                  },
                                  {
                                    value: TypeColumn.DATE,
                                    label: 'Ngày tháng',
                                  },
                                ]}
                                value={item?.type}
                                placeholder="Chọn kiểu dữ liệu"
                                onChange={value =>
                                  onChangeTypeColumn(index, value as TypeColumn)
                                }
                                disabled={!!item?.keyOfMainData}
                              />
                            </BaseFormItem>
                          </BaseCol>
                          <BaseCol xl={2}>
                            <BaseButton
                              icon={<SvgDeleteDesignIcon />}
                              onClick={() => onDeleteColumn(index)}
                              type="text"
                              disabled={!!item?.keyOfMainData}
                            />
                          </BaseCol>
                        </BaseRow>
                      )
                    })}
                  </ValueWrapper>
                </ContentWrapper>
              </>
            ) : null}
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}

const ContentWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.neutralBlack9Color};
  padding: ${PADDING.xxs};
  border-radius: ${BORDER_RADIUS};
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`

const ValueWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.xxxs};
`
