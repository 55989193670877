// Message code define

import R from 'app/assets/R'
import { RoleAccountEnum } from './model'

// Endpoint
export const AccountEndPoint = {
  ADMIN_GET_ACCOUNT: 'admin/account/list',
  ADMIN_DELETE_ACCOUNT: 'admin/account/delete-by-ids',
  ADMIN_CREATE_ACCOUNT: 'admin/account/create',
  ADMIN_UPDATE_ACCOUNT: 'admin/account/update',
  ADMIN_CHANGE_PASSWORD_ACCOUNT: 'admin/account/change-password',

  CHECK_EXIST_ACCOUNT: 'account/is-account-exist',
  GET_PROFILE: 'account/profile',
}

export const DefineRole = {
  [RoleAccountEnum.Admin]: {
    textKeyI18n: R.strings.admin,
  },
  [RoleAccountEnum.TradeUnionMember]: {
    textKeyI18n: R.strings.trade_union_member,
  },
}
