import { ResponseType } from 'parkway-web-common'
import { ITradeUnionMember, UnitEnum } from '../tradeUnionMembers/model'
export enum UnitType {
  DEPARTMENT = 'department',
  UNION_GROUP = 'union_group',
}

export enum PositionType {
  UNION_LEADER = 'union_leader',
  UNION_PRESIDENT = 'union_president',
  UNION_VICE_PRESIDENT = 'union_vice_president',
  UNION_MEMBER = 'union_member',
}

export interface IParamsGetUnit {
  search?: string
  limit?: number
  page?: number
  type?: UnitType
  schoolName?: UnitEnum
}

export interface IPosition {
  id?: number
  name?: string
  description?: string
  is_deleted?: boolean
  type: PositionType
  member?: ITradeUnionMember
}

export interface IUnit {
  id?: number
  name?: string
  description?: string
  is_deleted?: boolean
  tradeUnionMembers?: ITradeUnionMember[]
  positions?: IPosition[]
  type: UnitType
  schoolName?: UnitEnum
}

export type IResponseUnitList = ResponseType<{
  data: IUnit[]
  total: number
}>

export type IResponseUnitTotal = ResponseType<number>

export interface IPayloadCreateUnit {
  name?: string
  descriptions?: string
  type: UnitType
  
  unionLeaderId?: number | string
  unionPresidentId?: number | string
  unionVicePresidentId?: number | string
  unionMemberId?: number | string
  tradeUnionMemberIds?: number[]

  schoolName?: UnitEnum
}

export interface IPayloadUpdateUnit extends IPayloadCreateUnit {
  id?: number
}
