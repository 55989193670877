import { requestGetUnitTotal } from './../../api/unit/index'
/* eslint-disable @typescript-eslint/no-explicit-any */
import { UnitEnum } from 'app/api/tradeUnionMembers/model'
import {
  requestDeleteUnit,
  requestGetUnit,
  requestPostCreateUnit,
  requestUpdateUnit,
} from 'app/api/unit'
import {
  IParamsGetUnit,
  IPayloadCreateUnit,
  IPayloadUpdateUnit,
  IResponseUnitList,
  IUnit
} from 'app/api/unit/model'
import { getToken } from 'app/service/storage/storage-service'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { IResponseQueryList } from '../model/common'
import { UnitKeys } from '../query-key/unit'

export function useGetUnitList(params: IParamsGetUnit, enabled = true) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<IUnit[]>, undefined>(
    UnitKeys.listUnit(params),
    async () => {
      try {
        const res: IResponseUnitList = await requestGetUnit(params)
        const data: IUnit[] = res?.data?.data || []
        return {
          data,
          total: res?.data?.total ?? 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export function useGetUnitTotal(params: IParamsGetUnit, enabled = true) {
  const token = getToken()
  return useQuery<
    | {
        [UnitEnum.Business]: number
        [UnitEnum.EconomicsLawAndPublicAdministration]: number
        [UnitEnum.TechnologyAndDesign]: number
      }
    | undefined,
    undefined
  >(
    UnitKeys.totalUnit(params),
    async () => {
      try {
        const [
          business,
          economicsLawAndPublicAdministration,
          technologyAndDesign,
        ] = await Promise.all([
          requestGetUnitTotal({ ...params, schoolName: UnitEnum.Business }),
          requestGetUnitTotal({
            ...params,
            schoolName: UnitEnum.EconomicsLawAndPublicAdministration,
          }),
          requestGetUnitTotal({
            ...params,
            schoolName: UnitEnum.TechnologyAndDesign,
          }),
        ])

        return {
          [UnitEnum.Business]: business?.data ?? 0,
          [UnitEnum.EconomicsLawAndPublicAdministration]:
            economicsLawAndPublicAdministration?.data ?? 0,
          [UnitEnum.TechnologyAndDesign]: technologyAndDesign?.data ?? 0,
        }
      } catch (error: any) {
        return undefined
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export const useDeleteUnit = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, { id: number }, unknown>(
    async ({ id }: { id: number }) => {
      return await requestDeleteUnit(id)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: UnitKeys.allUnits,
        })
      },
    },
  )
}

export const useUpdateUnit = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadUpdateUnit, unknown>(
    async (body: IPayloadUpdateUnit) => {
      return await requestUpdateUnit(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: UnitKeys.allUnits,
        })
      },
    },
  )
}

export const useCreateUnit = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadCreateUnit, unknown>(
    async (body: IPayloadCreateUnit) => {
      return await requestPostCreateUnit(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: UnitKeys.allUnits,
        })
      },
    },
  )
}
