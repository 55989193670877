import { PADDING } from 'parkway-web-common';
import styled from 'styled-components'


export const FormContent = styled.div`
  padding: ${PADDING.md};
  position: relative;
`

export const BaseActionWrapper = styled.div`
  flex: 1;
  margin-bottom: 20px;
`;

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
