import { DashOutlined } from '@ant-design/icons'
import { DefinePermissionEnum } from 'app/api/role/constant'
import { IRole } from 'app/api/role/model'
import R from 'app/assets/R'
import { ENV_CONFIG } from 'app/common/config'
import { PermissionEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useVerifyPermission } from 'app/helpers/permission.helper'
import { useDebounce, usePagination } from 'app/hook'
import { useGetRoleList } from 'app/react-query/hook/role'
import { IResponseQueryList } from 'app/react-query/model/common'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import axios from 'axios'
import { PADDING, Pagination, initialPagination } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DisplayPermissionLayout, UpdateModalLayout } from './layouts'
import { DeleteModalLayout } from './layouts/DeleteModal'
import { IFilterAccountList } from './type'

export const useHook = () => {
  const { user, verifyPermission } = useVerifyPermission()
  const { t } = useTranslation()
  const [filter, setFilter] = useState<IFilterAccountList>({})

  const filterDebounce = useDebounce(filter, 500)

  const [pagination, setPagination] = useState<Pagination>(initialPagination)

  const { flattenDataList } = usePagination()
  const { data, isLoading } = useGetRoleList({
    limit: pagination?.pageSize,
    page: pagination?.current,
    search: filterDebounce?.search,
  })

  const flattenData = useMemo(() => {
    const res: IResponseQueryList<IRole[]> = flattenDataList(data)

    return res
  }, [data])

  const dataTable = useMemo(() => {
    return flattenData?.data
  }, [flattenData])

  const total = useMemo(() => {
    return flattenData?.total ?? dataTable?.length
  }, [flattenData])

  const isHavePermissionUpdateRole = useMemo(() => {
    return verifyPermission(PermissionEnum.UpdateAccount)
  }, [user])

  const isHavePermissionDeleteRole = useMemo(() => {
    return verifyPermission(PermissionEnum.DeleteRole)
  }, [user])

  const columns = useMemo(() => {
    return [
      convertColumnTable<IRole>({
        key: 'name',
        title: t(R.strings.name_role),
        classNameWidthColumnOverwrite: 'big-column',
        fixed: 'left',
        render: (_, record) => {
          return (
            <BaseText children={record?.name ?? '-'} fontWeight="semibold" />
          )
        },
      }),
      convertColumnTable<IRole>({
        key: 'description',
        title: t(R.strings.description),
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={record?.description ?? '-'}
            />
          )
        },
      }),
      convertColumnTable<IRole>({
        key: 'permissions',
        title: t(R.strings.permission),
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          let permissions = record?.permissions ?? []
          let countRemain = 0

          // nếu list nhiều hơn 2 thì chỉ hiển thị 2 cái đầu tiên
          countRemain = permissions?.length - 2
          if (permissions?.length > 2) {
            permissions = permissions.slice(0, 2)
          }

          return (
            <BaseRow gutter={[16, 15]} align={'middle'}>
              {permissions?.map((item, index) => {
                return (
                  <PermissionItemWrapper key={index}>
                    <BaseText
                      children={
                        item?.permission
                          ? t(DefinePermissionEnum?.[item?.permission]?.keyI18n)
                          : item?.name ?? '-'
                      }
                      fontWeight="medium"
                    />
                  </PermissionItemWrapper>
                )
              })}
              <BaseCol>
                {countRemain > 0 && (
                  <DisplayPermissionLayout
                    count={countRemain}
                    permissions={record?.permissions ?? []}
                  />
                )}
              </BaseCol>
            </BaseRow>
          )
        },
      }),
      convertColumnTable<IRole>({
        key: 'is_active',
        title: '',
        fixed: 'right',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => {
          return (
            <BasePopover
              trigger="click"
              content={
                <BaseSpace>
                  {isHavePermissionUpdateRole ? (
                    <UpdateModalLayout role={record} />
                  ) : null}

                  {!record?.is_block_action && isHavePermissionDeleteRole ? (
                    <DeleteModalLayout name={record?.name} id={record?.id} />
                  ) : null}
                </BaseSpace>
              }
            >
              <DashOutlined rev={undefined} rotate={90} />
            </BasePopover>
          )
        },
      }),
    ]
  }, [t])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const onChangeFilter = (newFilter: IFilterAccountList) => {
    setFilter(newFilter)
    setPagination(initialPagination)
  }
  const downloadExcel = async (): Promise<void> => {
    try {
      const response = await axios.get(
        `${ENV_CONFIG.API_ENDPOINT}admin/role/download`,
        {
          responseType: 'blob', // Thiết lập kiểu phản hồi là blob
          params: {
            limit: pagination?.pageSize,
            page: pagination?.current,
            search: filterDebounce?.search,
          },
        },
      )

      // Tạo một URL blob từ dữ liệu file
      const url = window.URL.createObjectURL(new Blob([response.data]))

      // Tạo thẻ <a> để tự động tải file về máy
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'roles.xlsx') // Tên file Excel sẽ tải về
      document.body.appendChild(link)

      // Kích hoạt sự kiện click để tải file
      link.click()

      // Hủy URL sau khi tải xong
      link.parentNode?.removeChild(link) // Optional chaining để an toàn
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading Excel:', error)
    }
  }
  return {
    isLoading,
    columns,
    pagination: {
      ...pagination,
      total,
    },
    dataTable,
    handleTableChange,
    onChangeFilter,
    filter,
    downloadExcel,
  }
}

const PermissionItemWrapper = styled(BaseCol)`
  padding: 5px 10px;
  background-color: ${convertedVariables.neutralBlack9Color};
  border-radius: ${PADDING.xl};
`
