import { PageTitle } from 'app/components/common/PageTitle'
import RegisterForMemberContainer from 'app/containers/EventManagement/RegisterForMember'
import { isEqual } from 'lodash'
import { memo } from 'react'

const RegisterForMemberCpn = () => {
  return (
    <>
      <PageTitle>{'Đăng kí hoạt động/sự kiện'}</PageTitle>
      <RegisterForMemberContainer />
    </>
  )
}
export const RegisterForMemberPage = memo(RegisterForMemberCpn, isEqual)
