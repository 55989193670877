/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DepartmentsEnum,
  GenderTradeUnionEnum,
  StatusActivitiesEnum,
} from 'app/containers/TradeUnionMemberManagement/type'
import { ResponseType } from 'parkway-web-common'

export enum EthnicGroups {
  Kinh = "Kinh",
  Tay = "Tày",
  Thai = "Thái",
  Muong = "Mường",
  Hmong = "H'Mông",
  Khmer = "Khmer",
  Nung = "Nùng",
  Hoa = "Hoa",
  Dao = "Dao",
  GiaRai = "Gia Rai",
  Ede = "Ê Đê",
  BaNa = "Ba Na",
  XoDang = "Xơ Đăng",
  SanChay = "Sán Chay",
  CoHo = "Cơ Ho",
  Cham = "Chăm",
  SanDiu = "Sán Dìu",
  Hre = "Hrê",
  Mnong = "Mnông",
  RaGlai = "Ra Glai",
  Tho = "Thổ",
  CoTu = "Cơ Tu",
  Churu = "Churu",
  KhmerKrom = "Khmer Krom",
  Lao = "Lào",
  LaChi = "La Chí",
  LaHa = "La Ha",
  PuPeo = "Pu Péo",
  PhuLa = "Phù Lá",
  SiLa = "Si La",
  Cong = "Cống",
  ODu = "Ơ Đu",
  Brau = "Brâu",
  RoMam = "Rơ Măm",
  HaNhi = "Hà Nhì",
  ChuRu = "Chu Ru",
  Khang = "Kháng",
  Lu = "Lự",
  LoLo = "Lô Lô",
  BoY = "Bố Y",
  Co = "Cờ Ho",
  TaOi = "Tà Ôi",
  ChoRo = "Chơ Ro",
  KaTu = "Ka Tu",
  BruVanKieu = "Bru Vân Kiều",
  CoTuoi = "Cơ Tu",
  Chut = "Chứt",
  KhoMu = "Khơ Mú",
  Mang = "Mảng",
  PaThen = "Pà Thẻn",
  SiLaD = "Si La (D)",
  PuPeoD = "Pu Péo (D)",
  DaoRed = "Dao Đỏ",
  EdeBanar = "Ê Đê (Ba Na)",

  // nước ngoài
  Forein = "Người nước ngoài",
  // khác
  Other = "Không rõ",
}



export interface IParamsGetTradeUnionMember {
  search?: string
  gender?: GenderTradeUnionEnum
  page?: number
  limit?: number

  source?: number
  tinhTrangSinhHoat?: StatusActivitiesEnum
}

export interface IParamsGetTradeUnionMemberHRM {
  search?: string
  page?: number
  limit?: number
  unit?: DepartmentsEnum,
  isSync?: number
}

export interface ITradeUnionMember {
  id?: string
  mql?: string
  last_name?: string
  first_name?: string
  gioiTinh?: string
  ngaySinh?: string
  nguyenQuan?: string
  cmnd?: string
  tenDonVi?: string
  tinhTrangLamViec?: string
  dienThoaiDiDong?: string
  email?: string
  danToc?: EthnicGroups
  tonGiao?: string
  noiOHienNay?: string
  hocHam?: AcademicRankEnum
  hocVi?: DegreeEnum
  tenPhanHieu?: UnitEnum
  ngayVaoUEH?: string
  ngayVaoCongDoan?: string
  soTheDoanVien?: string
  tinhTrangSinhHoat?: string
  chucVucongdoan?: string
  fullName?: string
  is_active?: boolean

  is_sync?: boolean
  isSync?: boolean
  valueCare?: string
  unit?: any
}

export enum AcademicRankEnum {
  Professor = "Giáo sư",
  AssociateProfessor = "Phó Giáo sư",
}

export enum DegreeEnum {
  Doctor = "Tiến sĩ",
  Master = 'Thạc sĩ',
  Bachelor = 'Cử nhân',
}

export enum UnitEnum {
  Business = "Trường Kinh doanh",
  EconomicsLawAndPublicAdministration = "Trường Kinh tế, Luật và Quản lý nhà nước",
  TechnologyAndDesign = "Trường Công nghệ và Thiết kế",
  // Không thuộc trường thành viên
  Other = "Không thuộc trường thành viên",
}

export type IResponseTradeUnionMemberList = ResponseType<{
  count: number
  rows: ITradeUnionMember[]
  total?: number
}>

export interface IPayloadCreateTradeUnionMember {
  mql?: string
  last_name?: string
  first_name?: string
  gioiTinh?: string
  ngaySinh?: string
  nguyenQuan?: string
  cmnd?: string
  tenDonVi?: string
  tinhTrangLamViec?: string
  dienThoaiDiDong?: string
  email?: string
  danToc?: string
  tonGiao?: string
  noiOHienNay?: string
  hocHam?: AcademicRankEnum
  hocVi?: DegreeEnum
  tenPhanHieu?: UnitEnum
  ngayVaoUEH?: string
  ngayVaoCongDoan?: string
  soTheDoanVien?: string
  tinhTrangSinhHoat?: string
  chucVucongdoan?: string
  fullName?: string
  unitId?: string
}

export interface IPayloadUpdateTradeUnionMember {
  id?: string
  mql?: string
  last_name?: string
  first_name?: string
  gioiTinh?: string
  ngaySinh?: string
  nguyenQuan?: string
  cmnd?: string
  tenDonVi?: string
  tinhTrangLamViec?: string
  dienThoaiDiDong?: string
  email?: string
  danToc?: string
  tonGiao?: string
  noiOHienNay?: string
  hocHam?: AcademicRankEnum
  hocVi?: DegreeEnum
  tenPhanHieu?: UnitEnum
  ngayVaoUEH?: string
  ngayVaoCongDoan?: string
  soTheDoanVien?: string
  tinhTrangSinhHoat?: string
  chucVucongdoan?: string
  fullName?: string
  unitId?: string
}

export interface IRewardTum {
  id?: number
  name?: string
  description?: string
  link?: string
  year?: string
}

export interface IPayloadCreateRewardTum {
  trade_union_member_id?: string
  name?: string
  description?: string
  link?: string
  year?: string
}

export interface IParamDeleteRewardTum {
  id: number
}
