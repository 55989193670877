import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { IRewardActivity } from '../EventManagement/layouts/UpdateEvent/type'
import { useEffect, useMemo, useState } from 'react'
import { IActionEvent } from 'app/api/event/model'
import { getUserInfo } from 'app/redux/slices/userSlice'
import { _DEV_ } from 'app/constant'
import {
  requestGetActivityByTumId,
  requestGetRewardByTumId,
} from 'app/api/tradeUnionMembers'
import { ResponseType } from 'parkway-web-common'

export const useHook = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.user)?.profile
  const [isLoading, setIsLoading] = useState(false)

  const [listReward, setListReward] = useState<IRewardActivity[]>([])
  const [listActivity, setListActivity] = useState<IActionEvent[]>([])

  const getData = async () => {
    if (!user?.member?.id) return
    setIsLoading(true)
    try {
      const response = await Promise.all([
        requestGetActivityByTumId({ id: user?.member?.id }),
        requestGetRewardByTumId({ id: user?.member?.id }),
      ])

      const activityResponse: ResponseType<IActionEvent[]> = response?.[0]
      const rewardResponse: ResponseType<IRewardActivity[]> = response?.[1]

      setListActivity(activityResponse?.data || [])
      setListReward(rewardResponse?.data || [])
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    dispatch(getUserInfo())
  }, [])

  useEffect(() => {
    getData()
  }, [user])

  const dataReward = useMemo(() => {
    return listReward.map((item, index) => ({
      ...item,
      key: index + 1,
    }))
  }, [listReward])

  const listActivityData = useMemo(() => {
    return listActivity.map((item, index) => ({
      ...item,
      key: index + 1,
    }))
  }, [listActivity])

  return {
    isLoading,
    dataReward,
    listActivityData,
    user,
    tum: user?.member,
  }
}
