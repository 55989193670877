import { DASHBOARD_PATH } from 'app/components/router/route-path'
import { useResponsive } from 'parkway-web-common'
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Header } from '../../../header'
import MainContent from '../MainContent'
import { MainHeader } from '../MainHeader'
import MainSider from '../sider/MainSider'
import * as S from './styles'

const MainLayout: React.FC = () => {
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true)
  const [siderCollapsed, setSiderCollapsed] = useState(false)
  const { isDesktop } = useResponsive()
  const location = useLocation()

  const toggleSider = () => setSiderCollapsed(!siderCollapsed)

  useEffect(() => {
    setIsTwoColumnsLayout(
      [ DASHBOARD_PATH].includes(location.pathname) && isDesktop,
    )
  }, [location.pathname, isDesktop])

  return (
    <S.LayoutMaster>
      <MainSider
        isCollapsed={siderCollapsed}
        setCollapsed={setSiderCollapsed}
      />
      <S.LayoutMain>
        <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <Header
            toggleSlider={toggleSider}
            isSliderOpened={!siderCollapsed}
            isTwoColumnsLayout={isTwoColumnsLayout}
          />
        </MainHeader>
        <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <Outlet />
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  )
}

export default MainLayout
