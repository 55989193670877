import R from 'app/assets/R'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'app/redux/hooks'
import {
  DASHBOARD_PATH,
  LOGIN_BY_UEH,
  LOGIN_PATH,
} from 'app/components/router/route-path'
import { doLoginWithToken } from 'app/redux/slices/authSlice'
import { useLocation } from 'react-router-dom'
import { notificationController } from 'app/controllers/notification-controller'

import { BaseImage } from 'app/components/common/BaseImage'
import { BaseSpin } from 'app/components/common/BaseSpin'
import { LoadingOutlined } from '@ant-design/icons'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const LoginContainer: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const query = useQuery()

  const [isLoading, setLoading] = useState(true)
  const [isError, setError] = useState(false)

  useEffect(() => {
    dispatch(doLoginWithToken({ token: query.get('t') as string }))
      .unwrap()
      .then(() => {
        navigate(DASHBOARD_PATH)
      })
      .catch(() => {
        setError(true)
        notificationController.error({ message: t('token_error') })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <S.Wrapper>
      <BaseImage src={R.images.ic_ueh} width={50} height={50} preview={false} />
      <S.Logging>
        {isError ? (
          <S.ButtonsWrapper>
            <S.Relogin
              onClick={() => window.open(LOGIN_BY_UEH, '_self')}
              type="primary"
            >
              {t(R.strings.relogin)}
            </S.Relogin>
            <S.Back onClick={() => navigate(LOGIN_PATH)}>
              {t(R.strings.back)}
            </S.Back>
          </S.ButtonsWrapper>
        ) : (
          <>
            <BaseSpin
              spinning={isLoading}
              indicator={<LoadingOutlined rev={undefined} />}
            />
            <S.Title>{t(R.strings.logging)}...</S.Title>
          </>
        )}
      </S.Logging>
    </S.Wrapper>
  )
}
export default LoginContainer
