import { ENV_CONFIG } from 'app/common/config'
import { useDebounce } from 'app/hook'
import axios from 'axios'
import { useState } from 'react'
import { IFilterUnitList } from './type'
import { useGetUnitTotal } from 'app/react-query/hook/unit'

export const useHook = () => {
  const [filter, setFilter] = useState<IFilterUnitList>({})

  const filterDebounce = useDebounce(filter, 500)

  const onChangeFilter = (newFilter: IFilterUnitList) => {
    setFilter(newFilter)
  }

  const { data, isLoading } = useGetUnitTotal({
    type: filterDebounce?.type,
    search: filterDebounce?.search,
  })

  const downloadExcel = async (): Promise<void> => {
    try {
      const response = await axios.get(
        `${ENV_CONFIG.API_ENDPOINT}unit/download`,
        {
          responseType: 'blob', // Thiết lập kiểu phản hồi là blob
          params: {
            search: filterDebounce?.search,
            type: filterDebounce?.type,
          },
        },
      )

      // Tạo một URL blob từ dữ liệu file
      const url = window.URL.createObjectURL(new Blob([response.data]))

      // Tạo thẻ <a> để tự động tải file về máy
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'units.xlsx') // Tên file Excel sẽ tải về
      document.body.appendChild(link)

      // Kích hoạt sự kiện click để tải file
      link.click()

      // Hủy URL sau khi tải xong
      link.parentNode?.removeChild(link) // Optional chaining để an toàn
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading Excel:', error)
    }
  }
  return {
    onChangeFilter,
    filter,
    downloadExcel,
    data,
    isLoading,
  }
}
