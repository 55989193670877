import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { Loading } from 'app/components/common/Loading'
import { useGetDashboardInfo } from 'app/react-query/hook/dashboard'
import {
  EventByTypeLayout,
  InfoData,
  Top10EventHaveMemberLayout,
  Top10MemberLayout,
} from './layouts'

export const DashboardAdminLayout = () => {
  const { data, isLoading } = useGetDashboardInfo()

  return (
    <BaseSpace>
      <InfoData data={data} />

      <EventByTypeLayout data={data} />

      <BaseRow align={'top'} justify={'space-between'} gutter={[16, 16]}>
        <BaseCol xl={12} style={{ flex: 1 }}>
          <Top10EventHaveMemberLayout
            top10MemberPrize={data?.top?.top10Activity ?? []}
            isLoading={isLoading}
          />
        </BaseCol>
        <BaseCol xl={12} style={{ flex: 1 }}>
          <Top10MemberLayout
            top10MemberJoinEvent={data?.top?.top10Tum ?? []}
            isLoading={isLoading}
          />
        </BaseCol>
      </BaseRow>

      {isLoading ? <Loading isAbsolute size="default" /> : null}
    </BaseSpace>
  )
}
