import { EditOutlined } from '@ant-design/icons'
import { message } from 'antd'
import { DefineTypeEventEnum } from 'app/api/event/constant'
import { IEvent, TypeEventEnum } from 'app/api/event/model'
import { PermissionEnum } from 'app/common/enum'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { UPDATE_EVENT_PATH } from 'app/components/router/route-path'
import { FORMAT_DD_MM_YYYY_HH_MM } from 'app/constant'
import { useVerifyPermission } from 'app/helpers/permission.helper'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import { BORDER_RADIUS, DateUtil, PADDING } from 'parkway-web-common'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'
import { ExpandedRowRender } from '../layouts'
import { StatusEventWrapper } from '../styles'
import { DefineEventStatus, EventStatusEnum } from '../type'
import { ListMemberPrizeLayout, ListTeamPrizeLayout } from './layouts'
import axios from 'axios'
import { ENV_CONFIG } from 'app/common/config'

const DetailEventContainer = () => {
  const navigate = useNavigate()
  const route = useLocation()
  const { t } = useTranslation()
  const { user, verifyPermission } = useVerifyPermission()

  const isHavePermissionUpdateEvent = useMemo(() => {
    return verifyPermission(PermissionEnum.UpdateEvent)
  }, [user])

  const event: IEvent = useMemo(() => {
    return route?.state?.event
  }, [route])

  useEffect(() => {
    if (!event) {
      message.error('Không tìm thấy sự kiện')
    }
  }, [event])

  const onNavigateToUpdate = () => {
    navigate(UPDATE_EVENT_PATH, {
      state: {
        event,
      },
    })
  }

  const downloadExcel = async (): Promise<void> => {
    if (!event?.id) return
    try {
      const response = await axios.get(
        `${ENV_CONFIG.API_ENDPOINT}admin/activity/download`,
        {
          responseType: 'blob',
          params: {
            id: event?.id ?? 0,
            eventId: event?.id ?? 0,
          },
        },
      )
      // Tạo một URL blob từ dữ liệu file
      const url = window.URL.createObjectURL(new Blob([response.data]))

      // Tạo thẻ <a> để tự động tải file về máy
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `Thông tin chi tiết sự kiện ${event?.name_event}.xlsx`,
      ) // Tên file Excel sẽ tải về
      document.body.appendChild(link)

      // Kích hoạt sự kiện click để tải file
      link.click()

      // Hủy URL sau khi tải xong
      link.parentNode?.removeChild(link) // Optional chaining để an toàn
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading Excel:', error)
    }
  }

  return (
    <RootWrapper size={0}>
      <HeaderPage
        title={`Chi tiết sự kiện "${event?.name_event}"`}
        rightComponent={
          isHavePermissionUpdateEvent ? (
            <BaseCol>
              <BaseButton
                icon={<EditOutlined rev={undefined} />}
                onClick={onNavigateToUpdate}
              >
                {'Cập nhật sự kiện'}
              </BaseButton>
            </BaseCol>
          ) : null
        }
        isExport
        onPressExport={downloadExcel}
      />

      <BaseSpace>
        <SectionBase title="Thông tin sự kiện">
          <BaseSpace>
            <BaseRow gutter={[16, 16]} align={'top'} justify={'start'}>
              <BaseCol xl={8}>
                <BaseSpace size={4}>
                  <BaseText
                    children={'Tên sự kiện'}
                    fontWeight="medium"
                    fontSize="xs"
                    opacity="0.7"
                  />
                  <BaseText
                    children={event?.name_event ?? '-'}
                    fontWeight="semibold"
                    fontSize="xs"
                  />
                </BaseSpace>
              </BaseCol>
              <BaseCol xl={8}>
                <BaseSpace size={4}>
                  <BaseText
                    children={'Loại sự kiện'}
                    fontWeight="medium"
                    fontSize="xs"
                    opacity="0.7"
                  />
                  <BaseText
                    children={
                      event?.type ? DefineTypeEventEnum[event?.type]?.text : '-'
                    }
                    fontWeight="semibold"
                    colorText="statesGreenColor"
                    fontSize="xs"
                  />
                </BaseSpace>
              </BaseCol>
              {event?.link ? (
                <BaseCol xl={8}>
                  <BaseSpace size={8}>
                    <BaseText
                      children={'Link bài viết'}
                      fontWeight="medium"
                      fontSize="xs"
                      opacity="0.7"
                    />
                    <a href={event?.link} target="_blank">
                      <BaseText
                        children={event?.link}
                        fontWeight="medium"
                        fontSize="xs"
                        colorText="collapseBackgroundColor"
                      />
                    </a>
                  </BaseSpace>
                </BaseCol>
              ) : null}
            </BaseRow>
            <BaseRow gutter={[20, 20]} align={'top'} justify={'start'}>
              {isEqual(event?.type, TypeEventEnum.NEWS) ||
              isEqual(event?.type, TypeEventEnum.CARE) ? null : (
                <BaseCol xl={8}>
                  <BaseSpace size={4}>
                    <BaseText
                      children={'Thời gian đăng kí'}
                      fontWeight="medium"
                      fontSize="xs"
                      opacity="0.7"
                    />
                    <BaseText
                      children={`${DateUtil?.formatDate(
                        event?.time_register_start ?? '',
                        FORMAT_DD_MM_YYYY_HH_MM,
                      )} - ${DateUtil?.formatDate(
                        event?.time_register_end ?? '',
                        FORMAT_DD_MM_YYYY_HH_MM,
                      )}`}
                      fontWeight="semibold"
                      fontSize="xs"
                    />
                  </BaseSpace>
                </BaseCol>
              )}

              {isEqual(event?.type, TypeEventEnum.NEWS) ? null : (
                <BaseCol xl={8}>
                  <BaseSpace size={4}>
                    <BaseText
                      children={'Thời gian diễn ra sự kiện'}
                      fontWeight="medium"
                      fontSize="xs"
                      opacity="0.7"
                    />
                    <BaseText
                      children={`${DateUtil?.formatDate(
                        event?.time_start ?? '',
                        FORMAT_DD_MM_YYYY_HH_MM,
                      )} - ${DateUtil?.formatDate(
                        event?.time_end ?? '',
                        FORMAT_DD_MM_YYYY_HH_MM,
                      )}`}
                      fontWeight="semibold"
                      fontSize="xs"
                    />
                  </BaseSpace>
                </BaseCol>
              )}

              {event?.status ? (
                <BaseCol xl={8}>
                  <BaseSpace size={4}>
                    <BaseText
                      children={'Trạng thái sự kiện'}
                      fontWeight="medium"
                      fontSize="xs"
                      opacity="0.7"
                    />
                    <StatusEventWrapper
                      $status={(() => {
                        return event?.status
                      })()}
                    >
                      <BaseText
                        children={(() => {
                          return t(DefineEventStatus[event?.status]?.keyI18n)
                        })()}
                        colorText={(() => {
                          switch (event?.status) {
                            case EventStatusEnum.NOT_OPEN:
                              return 'otherBlueColor'
                            case EventStatusEnum.REGISTRATION:
                              return 'otherBlueColor'
                            case EventStatusEnum.REGISTRATION_COMPLETED:
                              return 'statesOrangeColor'
                            case EventStatusEnum.IN_PROGRESS:
                              return 'statesGreenColor'
                            case EventStatusEnum.FINISHED:
                              return 'statesRedColor'
                          }
                        })()}
                        fontWeight="medium"
                      />
                    </StatusEventWrapper>
                  </BaseSpace>
                </BaseCol>
              ) : null}
            </BaseRow>

            <BaseSpace size={4}>
              <BaseText
                children={'Thông tin sự kiện'}
                fontWeight="medium"
                fontSize="xs"
                opacity="0.7"
              />
              <BaseText
                children={event?.description ?? '-'}
                fontWeight="medium"
                fontSize="xs"
              />
            </BaseSpace>
          </BaseSpace>
        </SectionBase>

        {!isEqual(event?.type, TypeEventEnum.NEWS) ? (
          <ExpandedRowRender event={event} />
        ) : null}

        {isEqual(event?.type, TypeEventEnum.ACTIVITY) ? (
          <SectionBase title="Thống kê">
            <BaseSpace>
              <ListMemberPrizeLayout event={event} />

              <ListTeamPrizeLayout event={event} />
            </BaseSpace>
          </SectionBase>
        ) : null}
      </BaseSpace>
    </RootWrapper>
  )
}

export default DetailEventContainer

const RootWrapper = styled(BaseSpace)`
  padding: 0px ${PADDING.md};

  .button-background {
    background-color: ${convertedVariables.backgroundColor};
    border-radius: ${BORDER_RADIUS};
  }
`
