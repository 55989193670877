import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useCreateEventContext } from './context'
import { ActionEvent, InfoEvent } from './layouts'
import { isEmpty, isEqual } from 'lodash'
import { TypeEventEnum } from 'app/api/event/model'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseButton } from 'app/components/common/BaseButton'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import { useMemo } from 'react'

const ContentLayout = () => {
  const { t } = useTranslation()
  const {
    form,
    handleSubmit,
    eventType,
    prevSteps,
    isLoadingSubmit,
    activities,
  } = useCreateEventContext()

  const isDisableSubmit = useMemo(() => {
    return activities?.some(item => {
      if (isEmpty(item?.title)) return true

      if (isEqual(eventType, TypeEventEnum.ACTIVITY) && !item?.teams?.length)
        return true

      // all is pass
      return false
    })
  }, [activities, eventType])

  return (
    <BaseForm form={form} onFinish={handleSubmit}>
      <BaseSpace>
        <SectionBase>
          <InfoEvent />
        </SectionBase>

        {isEqual(eventType, TypeEventEnum.NEWS) ? null : (
          <SectionBase>
            <ActionEvent />
          </SectionBase>
        )}

        <BaseRow justify={'end'}>
          <BaseRow gutter={8} align={'middle'}>
            <BaseCol>
              <BaseButton onClick={prevSteps}>{t(R.strings.cancel)}</BaseButton>
            </BaseCol>
            <BaseCol>
              <BaseButton
                loading={isLoadingSubmit}
                disabled={isDisableSubmit}
                onClick={form?.submit}
              >
                {t(R.strings.confirm)}
              </BaseButton>
            </BaseCol>
          </BaseRow>
        </BaseRow>
      </BaseSpace>
    </BaseForm>
  )
}

export default ContentLayout
