import { IMemberActionEvent } from 'app/api/event/model'
import { PermissionEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { TRADE_UNION_MEMBER_DETAILS_PATH } from 'app/components/router/route-path'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import {
  IRewardActivity
} from 'app/containers/EventManagement/layouts/CreateEvent/type'
import { useVerifyPermission } from 'app/helpers/permission.helper'
import { usePagination } from 'app/hook'
import { useGetMemberOfPrize } from 'app/react-query/hook/event'
import { ResponseType } from 'parkway-web-common'
import { useMemo } from 'react'
import { useNavigate } from 'react-router'
import { ModalSelectTum } from '../ModalSelectTum'

export const ChildRow = ({
  reward,
  actionId,
}: {
  reward: IRewardActivity
  actionId?: number
}) => {
  const {
    data: dataApi,
    refetch,
    isLoading,
  } = useGetMemberOfPrize({
    id: reward?.id ?? 0,
  })
  const navigate = useNavigate()
  const { flattenDataList } = usePagination()

  const data = useMemo(() => {
    const res: ResponseType<IMemberActionEvent[]> = flattenDataList(dataApi)
    return res?.data?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
        index: index + 1,
      }
    })
  }, [dataApi])

  const { user, verifyPermission } = useVerifyPermission()

  const isHavePermissionUpdateEvent = useMemo(() => {
    return verifyPermission(PermissionEnum.UpdateEvent)
  }, [user])

  return (
    <SectionBase>
      <BaseSpace>
        <BaseRow gutter={[16, 8]} align={'middle'} justify={'space-between'}>
          <BaseCol>
            <BaseText
              children={'Danh sách đạt giải'}
              fontWeight="semibold"
              fontSize="xs"
            />
          </BaseCol>
          {isHavePermissionUpdateEvent ? (
            <BaseCol>
              <ModalSelectTum
                actionId={actionId}
                data={data}
                refetch={refetch}
                reward={reward}
              />
            </BaseCol>
          ) : null}
        </BaseRow>

        <BaseTableManagement
          dataSource={data}
          scroll={{ x: true }}
          pagination={{
            pageSize: 5,
          }}
          rowClassName="row-overwrite-style"
          loading={isLoading}
          columns={[
            convertColumnTable<{ index: number }>({
              key: 'index',
              title: 'STT',
              fixed: 'left',
              classNameWidthColumnOverwrite: 'number-column',
              render: (value: number) => (
                <BaseText children={value} opacity="0.7" fontWeight="medium" />
              ),
            }),

            convertColumnTable<IMemberActionEvent>({
              key: 'tum',
              title: 'Họ và tên',
              fixed: 'left',
              classNameWidthColumnOverwrite: 'big-column',
              render: (_, record) => (
                <RenderValueTable
                  onClick={() =>
                    navigate(TRADE_UNION_MEMBER_DETAILS_PATH, {
                      state: {
                        item: record,
                      },
                    })
                  }
                  value={record?.tum?.fullName}
                  type="Link"
                />
              ),
            }),
            convertColumnTable<IMemberActionEvent>({
              key: 'tum',
              title: 'Đơn vị',
              render: (_, record) => (
                <BaseText
                  children={record?.tum?.tenDonVi}
                  fontWeight="medium"
                />
              ),
            }),

            convertColumnTable<IMemberActionEvent>({
              key: 'tum',
              title: 'CMND/CCCD',
              render: (_, record) => (
                <BaseText
                  children={record?.tum?.email}
                  opacity="0.7"
                  fontWeight="medium"
                />
              ),
            }),
          ]}
        />
      </BaseSpace>
    </SectionBase>
  )
}
