import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import React from 'react'
import { FilterLayout, SyncFromUehModal } from './components'
import { useHook } from './hook'
import * as S from './styles'

const HrmTradeUnionMemberManagement: React.FC = () => {
  const {
    columns,
    pagination,
    filter,
    onChangeFilter,
    handleChangePagination,
    isLoading,
    data,
    isPermissionSyncFromUeh,
  } = useHook()

  return (
    <S.ScrollWrapper>
      <HeaderPage
        titleI18nKey={R.strings.hrm_trade_union_members_management}
        rightComponent={
          isPermissionSyncFromUeh ? (
            <BaseCol>
              <SyncFromUehModal />
            </BaseCol>
          ) : undefined
        }
      />

      <SectionBase>
        <BaseSpace>
          <FilterLayout filter={filter} onChangeFilter={onChangeFilter} />
          <BaseTableManagement
            columns={columns}
            dataSource={data}
            pagination={pagination}
            loading={isLoading}
            onChange={handleChangePagination}
            rowClassName="row-overwrite-style"
          />
        </BaseSpace>
      </SectionBase>
    </S.ScrollWrapper>
  )
}

export default HrmTradeUnionMemberManagement
