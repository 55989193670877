import { memo, useMemo } from 'react'
import { isEqual } from 'lodash'
import { UpdateEventContainer } from 'app/containers/EventManagement/layouts/UpdateEvent'
import { PageTitle } from 'app/components/common/PageTitle'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'
import { useLocation } from 'react-router'
import { IEvent } from 'app/api/event/model'

const UpdateEventManagementCpn = () => {
  const { t } = useTranslation()
  const { state } = useLocation()

  const event: IEvent = useMemo(() => {
    return state?.event
  }, [state])

  return (
    <>
      <PageTitle>{t(R.strings.create_event)}</PageTitle>
      <UpdateEventContainer event={event} />
    </>
  )
}
export const UpdateEventManagementPage = memo(UpdateEventManagementCpn, isEqual)
