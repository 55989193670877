import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { requestGetUserInfo } from 'app/api/account'
import { IUser } from 'app/model/user.model'
import { ResponseType } from 'parkway-web-common'

export interface UserState {
  profile?: IUser
}

const initialState: UserState = {
  profile: undefined,
}

export const getUserInfo = createAsyncThunk(
  'user/info',
  async () => {
    const response: ResponseType<IUser> = await requestGetUserInfo()
    return response?.data
  },
)

export const userSlice = createSlice({
  name: 'user/info',
  initialState,
  reducers: {
    changeProfile: (state, action) => {
      state.profile = action.payload
    },
    resetProfile: state => {
      state.profile = undefined
    },
  },
  extraReducers: builder => {
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.profile = action.payload
    })
  },
})

export const { resetProfile, changeProfile } = userSlice.actions

export default userSlice.reducer
