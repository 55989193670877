import { IPayloadCreateRole, IPermission } from 'app/api/role/model'
import R from 'app/assets/R'
import { IRefModal } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { usePagination } from 'app/hook'
import { useCreateRole, useGetPermissionList } from 'app/react-query/hook/role'
import { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormCreateRole } from './type'

export const useHook = () => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<IFormCreateRole>()
  const modalRef = useRef<IRefModal>({})
  const { data: dataPermission, isLoading: isLoadingPermission } =
    useGetPermissionList()

  const { flattenDataList } = usePagination()

  const permissions: IPermission[] = useMemo(() => {
    return flattenDataList(dataPermission)?.data
  }, [dataPermission])

  const { mutateAsync: mutateAsyncUpdate, isLoading } = useCreateRole()

  const handleSubmit = async (values: IFormCreateRole) => {
    const newAccount: IPayloadCreateRole = {
      name: values?.name,
      description: values?.description,
      permissionIds: values?.permissionIds,
    }

    await mutateAsyncUpdate?.(newAccount)
    modalRef?.current?.hide?.()
    form?.resetFields()
    notificationController.success({
      message: t(R.strings.success),
    })
  }

  return {
    form,
    modalRef,
    handleSubmit,
    isLoading,

    permissions,
    isLoadingPermission,
  }
}
