import { BaseSpace } from 'app/components/common/BaseSpace'
import { ListNewLayout } from './layouts'
import { useAppSelector } from 'app/redux/hooks'
import { ActionOpenRegisterLayout } from 'app/containers/EventManagement/RegisterForMember/layouts'
import { SectionBase } from 'app/components/common/SectionBase'
export const DashboardMemberLayout = () => {
  const user = useAppSelector(state => state?.user)?.profile

  return (
    <BaseSpace>
      <ListNewLayout />

      {user?.member?.id ? (
        <BaseSpace>
          <SectionBase title="Danh sách hoạt động/sự kiện đang mở đăng kí">
            <ActionOpenRegisterLayout />
          </SectionBase>
        </BaseSpace>
      ) : null}
    </BaseSpace>
  )
}
