import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseModalComponent } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { InputPassword } from 'app/components/common/inputs/InputPassword'
import { useTranslation } from 'react-i18next'
import { useHook } from './hook'
import { BaseImage } from 'app/components/common/BaseImage'
import * as S from '../ProfileOverlay.styles'

export const ChangePasswordModalLayout = () => {
  const { t } = useTranslation()
  const { form, modalRef, handleSubmit, isLoading, onOpenModal } = useHook()
  return (
    <BaseModalComponent
      titleModal={t(R.strings.change_password)}
      buttonOpenModal={
        <S.ItemWrapper
          align={'middle'}
          justify={'start'}
          onClick={onOpenModal}
          gutter={15}
          wrap={false}
        >
          <BaseImage
            src={R.images.ic_password}
            preview={false}
            width={20}
            height={20}
          />
          <BaseCol>
            <S.Text>{t(R.strings.change_password)}</S.Text>
          </BaseCol>
        </S.ItemWrapper>
      }
      ref={modalRef}
      handleSubmit={form?.submit}
      isLoadingConfirm={isLoading}
      renderContent={
        <BaseForm form={form} onFinish={handleSubmit}>
          <BaseSpace size={5}>
            <BaseRow>
              <BaseCol xl={24}>
                <BaseForm.Item
                  label={t(R.strings.old_password)}
                  name="oldPassword"
                  rules={[
                    {
                      required: true,
                      message: t(R.strings.require_field, {
                        field: t(R.strings.old_password),
                      }),
                    },
                  ]}
                  required
                >
                  <InputPassword
                    placeholder={t(R.strings.enter_field, {
                      field: t(R.strings.password),
                    })}
                  />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>
            <BaseRow gutter={[16, 8]}>
              <BaseCol xl={12}>
                <BaseForm.Item
                  label={t(R.strings.password)}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t(R.strings.require_field, {
                        field: t(R.strings.password),
                      }),
                    },
                  ]}
                  required
                >
                  <InputPassword
                    placeholder={t(R.strings.enter_field, {
                      field: t(R.strings.password),
                    })}
                  />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={12}>
                <BaseForm.Item
                  label={t(R.strings.re_password)}
                  name="rePassword"
                  rules={[
                    {
                      required: true,
                      message: t(R.strings.require_field, {
                        field: t(R.strings.re_password),
                      }),
                    },
                    {
                      validator: (_, value, callback) => {
                        const { getFieldValue } = form

                        if (value && value !== getFieldValue('password')) {
                          callback(t(R.strings.re_password_not_match))
                        } else {
                          callback()
                        }
                      },
                    },
                  ]}
                  required
                >
                  <InputPassword placeholder={t(R.strings.re_password)} />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}
