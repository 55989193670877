import { IRefModal } from 'app/components/common/ModalComponent'
import { useMemo, useRef, useState } from 'react'
import { IProps } from './type'

export const useHook = (props: IProps) => {
  const modalRef = useRef<IRefModal>({})
  // state search
  const [search, setSearch] = useState<string>('')

  const onChangeSearch = (value: string) => {
    setSearch(value)
  }

  // close modal
  const handleCloseModal = () => {
    modalRef.current.hide?.()
  }

  // open modal
  const handleOpenModal = () => {
    modalRef.current.open?.()
  }

  const permissions = useMemo(() => {
    return props?.permissions.filter(item => {
      return (
        item?.name?.toLowerCase().includes(search.toLowerCase())
      )
    })
  }, [props, search])

  return {
    modalRef,
    permissions,
    handleCloseModal,
    handleOpenModal,
    search,
    onChangeSearch,
  }
}
