import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import {
  IFilterTradeUnionList,
} from 'app/containers/TradeUnionMemberManagement/type'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  onChangeFilter?: (filter: IFilterTradeUnionList) => void
  filter?: IFilterTradeUnionList
}

export const FilterLayout: React.FC<IProps> = ({ filter, onChangeFilter }) => {
  const { t } = useTranslation()

  const [search, setSearch] = useState('')

  const onChangeSearch = (value: string) => {
    setSearch(value)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      onChangeFilter?.({ ...filter, search })
    }, 500)

    return () => clearTimeout(timer)
  }, [search])

  return (
    <BaseForm >
      <BaseRow gutter={[16, 8]} >
        <BaseCol xl={16}>
          <BaseForm.Item hiddenLabel>
            <BaseInput
              placeholder={t(R.strings.search)}
              value={search}
              onChange={e => onChangeSearch?.(e?.target?.value)}
              allowClear
            />
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>
    </BaseForm>
  )
}
