import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { useHook } from '../../hook'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { FilterLayout } from '../Filter'
import { useMemo } from 'react'
import { ENewTumRegisterStatus } from '../../type'

interface ApproveUnionMembersTableProps {
  status: string
}

const ApproveUnionMembersTable = ({
  status,
}: ApproveUnionMembersTableProps) => {
  const {
    columns,
    pagination,
    filter,
    onChangeFilter,
    handleChangePagination,
    isLoading,
    data,
  } = useHook({ status })

  const _columns = useMemo(() => {
    return columns.filter(item =>
      status !== ENewTumRegisterStatus.WAITING ? item.key !== 'id' : item,
    )
  }, [status, columns])

  return (
    <BaseSpace>
      <FilterLayout filter={filter} onChangeFilter={onChangeFilter} />
      <BaseTableManagement
        columns={_columns}
        dataSource={data}
        pagination={pagination}
        loading={isLoading}
        onChange={handleChangePagination}
        rowClassName="row-overwrite-style"
      />
    </BaseSpace>
  )
}

export default ApproveUnionMembersTable
