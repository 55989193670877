import { IEvent, TypeEventEnum } from 'app/api/event/model'
import R from 'app/assets/R'

export interface IFilterEventList {
  search?: string
  status?: EventStatusEnum[]
  type?: TypeEventEnum[]
}

export enum EventStatusEnum {
  NOT_OPEN = 'NOT_OPEN', // chưa mở đăng kí
  REGISTRATION = 'REGISTRATION', //  đang đăng kí
  REGISTRATION_COMPLETED = 'REGISTRATION_COMPLETED', // đăng kí hoàn tất
  IN_PROGRESS = 'IN_PROGRESS', // đang diễn ra
  FINISHED = 'FINISHED', // kết thức
}

export interface IEventTableData extends IEvent{
  key?: number
}


export const DefineEventStatus = {
  [EventStatusEnum.NOT_OPEN]: {
    keyI18n: R.strings.not_open_register,
  },
  [EventStatusEnum.REGISTRATION]: {
    keyI18n: R.strings.registration,
  },
  [EventStatusEnum.REGISTRATION_COMPLETED]: {
    keyI18n: R.strings.registration_completed,
  },
  [EventStatusEnum.IN_PROGRESS]: {
    keyI18n: R.strings.in_event,
  },
  [EventStatusEnum.FINISHED]: {
    keyI18n: R.strings.finished,
  },
}
