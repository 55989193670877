import { UnitEnum } from 'app/api/tradeUnionMembers/model'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import React from 'react'
import { IFilterUnitList } from '../../type'
import { useHook } from './hook'

export const ListUnit: React.FC<{
  schoolName: UnitEnum
  filter?: IFilterUnitList
}> = props => {
  const { columns, dataTable, handleTableChange, isLoading, pagination } =
    useHook(props)

  return (
    <BaseTableManagement
      pagination={pagination}
      columns={columns}
      dataSource={dataTable}
      onChange={handleTableChange}
      loading={isLoading}
      scroll={{x: true}}
    />
  )
}
