import { ITradeUnionMember } from 'app/api/tradeUnionMembers/model'
import R from 'app/assets/R'

export enum GenderTradeUnionEnum {
  All = 0,
  Male = 1,
  Female = 2,
}

export enum ENewTumRegisterStatus {
  WAITING = 'WAITING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export interface IFilterTradeUnionList {
  search?: string
  gender?: GenderTradeUnionEnum
}

export const DefineGender = {
  [GenderTradeUnionEnum.All]: {
    textKeyI18n: R.strings.all,
  },
  [GenderTradeUnionEnum.Male]: {
    textKeyI18n: R.strings.male,
  },
  [GenderTradeUnionEnum.Female]: {
    textKeyI18n: R.strings.female,
  },
}

export interface Pagination {
  current: number
  pageSize: number
  total?: number
}

export interface PaginationWithSearch extends Pagination {
  search?: string
}

export interface IDataTable extends ITradeUnionMember {
  key?: number
}
