/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestGetDashboardInfo } from 'app/api/dashboard'
import { IDashboardInfo } from 'app/api/dashboard/model'
import { useAppSelector } from 'app/redux/hooks'
import { useQuery } from 'react-query'
import { DashboardInfoKeys } from '../query-key/dashboard'
import { ResponseType } from 'parkway-web-common'

export function useGetDashboardInfo() {
  const user = useAppSelector(state => state?.user)?.profile
  return useQuery<IDashboardInfo, undefined>(
    DashboardInfoKeys.dashboardInfo(user?.member?.id ?? ''),
    async () => {
      const resPromiseAll: ResponseType<IDashboardInfo> =
        await requestGetDashboardInfo()

      const data: IDashboardInfo = resPromiseAll?.data ?? {}

      return data
    },
    {
      cacheTime: 5000,
    },
  )
}