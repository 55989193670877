import { PermissionEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import {
  IRewardActivity,
  ITeam,
} from 'app/containers/EventManagement/layouts/CreateEvent/type'
import { useVerifyPermission } from 'app/helpers/permission.helper'
import { usePagination } from 'app/hook'
import { useGetTeamOfPrize } from 'app/react-query/hook/event'
import { ResponseType } from 'parkway-web-common'
import { useMemo } from 'react'
import { ModalSelectTeam } from '../ModalSelectTeam'

export const ChildRowTeam = ({
  reward,
  actionId,
  teams,
}: {
  reward: IRewardActivity
  actionId?: number
  teams: ITeam[]
}) => {
  const {
    data: dataApi,
    refetch,
    isLoading,
  } = useGetTeamOfPrize({
    id: reward?.id ?? 0,
  })

  const { flattenDataList } = usePagination()

  const data = useMemo(() => {
    const res: ResponseType<ITeam[]> = flattenDataList(dataApi)
    return res?.data?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
        index: index + 1,
      }
    })
  }, [dataApi])

  const { user, verifyPermission } = useVerifyPermission()

  const isHavePermissionUpdateEvent = useMemo(() => {
    return verifyPermission(PermissionEnum.UpdateEvent)
  }, [user])

  return (
    <SectionBase>
      <BaseSpace>
        <BaseRow gutter={[16, 8]} align={'middle'} justify={'space-between'}>
          <BaseCol>
            <BaseText
              children={'Danh sách đạt giải'}
              fontWeight="semibold"
              fontSize="xs"
            />
          </BaseCol>
          {isHavePermissionUpdateEvent ? (
            <BaseCol>
              <ModalSelectTeam
                actionId={actionId}
                data={data}
                refetch={refetch}
                reward={reward}
                listTeam={teams}
              />
            </BaseCol>
          ) : null}
        </BaseRow>

        <BaseTableManagement
          dataSource={data}
          scroll={{ x: true }}
          pagination={{
            pageSize: 5,
          }}
          rowClassName="row-overwrite-style"
          loading={isLoading}
          columns={[
            convertColumnTable<{ index: number }>({
              key: 'index',
              title: 'STT',
              fixed: 'left',
              classNameWidthColumnOverwrite: 'number-column',
              render: (value: number) => (
                <BaseText children={value} opacity="0.7" fontWeight="medium" />
              ),
            }),

            convertColumnTable<ITeam>({
              key: 'name',
              title: 'Tên đội/nhóm',
              fixed: 'left',
              classNameWidthColumnOverwrite: 'big-column',
              render: (_, record) => (
                <BaseText children={record?.name ?? ''} fontWeight="semibold" />
              ),
            }),
            convertColumnTable<ITeam>({
              key: 'description',
              title: 'Mô tả',
              fixed: 'left',
              classNameWidthColumnOverwrite: 'big-column',
              render: (_, record) => (
                <BaseText children={record?.description ?? '-'} opacity="0.7" />
              ),
            }),

            convertColumnTable<ITeam>({
              key: 'tums',
              title: 'Số thành viên',
              fixed: 'left',
              classNameWidthColumnOverwrite: 'big-column',
              render: (_, record) => (
                <BaseText children={record?.tums?.length ?? 0} opacity="0.7" colorText='statesGreenColor' />
              ),
            }),
          ]}
        />
      </BaseSpace>
    </SectionBase>
  )
}
