import { PlusOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { useRef } from 'react'
import styled from 'styled-components'
import { useCreateEventContext } from '../../context'
import { IRewardActivity, RewardTypeEnum } from '../../type'

export const ModalAddEditReward = ({ index }: { index: number }) => {
  const { addRewardInActivityByIndex } = useCreateEventContext()
  const [form] = BaseForm.useForm<IRewardActivity>()
  const modalRef = useRef<IRefModal>({})

  const openModal = () => {
    modalRef?.current?.open?.()
  }

  const handleSubmit = (values: IRewardActivity) => {
    console.log('values', values);
    
    addRewardInActivityByIndex?.(index, values)
    form?.resetFields()
    modalRef?.current?.hide?.()
  }

  return (
    <BaseModalComponent
      titleModal={'Thêm giải thưởng'}
      handleSubmit={form?.submit}
      ref={modalRef}
      buttonOpenModal={
        <BaseButton
          icon={<PlusOutlined rev={undefined} />}
          onClick={openModal}
        >
          {'Thêm giải thưởng'}
        </BaseButton>
      }
      renderContent={
        <BaseForm form={form} onFinish={handleSubmit}>
          <BaseForm.Item
            label={'Tên giải thưởng'}
            name={'name'}
            required
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên giải thưởng',
              },
            ]}
          >
            <BaseInput placeholder="Nhập tên giải thưởng" />
          </BaseForm.Item>

          <BaseForm.Item
            label={'Mô tả giải thưởng'}
            name={'description'}
            required
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập mô tả giải thưởng',
              },
            ]}
          >
            <TextArea placeholder="Nhập mô tả giải thưởng" />
          </BaseForm.Item>

          <BaseForm.Item
            label={'Số giải thưởng'}
            name={'countPerReward'}
            required
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập số giải thưởng',
              },
            ]}
            initialValue={1}
          >
            <InputNumberWrapper min={1} placeholder="Nhập số giải thưởng" />
          </BaseForm.Item>

          <BaseForm.Item
            label={'Phần quà'}
            name={'gift'}
            required
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập phần quà',
              },
            ]}
          >
            <BaseInput placeholder="Nhập phần quà" />
          </BaseForm.Item>

          <BaseForm.Item
            label={'Đối tượng nhận giải'}
            name={'type'}
            required
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn đối tượng nhận giải',
              },
            ]}
          >
            <BaseSelect
              placeholder={'Chọn đối tượng nhận giải'}
              options={[
                {
                  label: 'Cá nhân',
                  value: RewardTypeEnum.SINGLE,
                },
                {
                  label: 'Tập thể',
                  value: RewardTypeEnum.GROUP,
                },
              ]}
            />
          </BaseForm.Item>
        </BaseForm>
      }
    />
  )
}

const InputNumberWrapper = styled(InputNumber)`
  width: 100%;
`
