import { EditOutlined } from '@ant-design/icons'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseModalComponent } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useUpdateEventContext } from './context'
import { ActionEvent } from './layouts'
import { useMemo } from 'react'
import { isEmpty } from 'lodash'

const ContentLayout = () => {
  const { modalRef, handleSubmit, activities, isLoadingSubmit } = useUpdateEventContext()
  const isDisableSubmit = useMemo(() => {
    return activities?.some(item => isEmpty(item?.title))
  }, [activities])

  return (
    <BaseModalComponent
      titleModal={'Cập nhật danh sách hoạt động'}
      ref={modalRef}
      handleSubmit={handleSubmit}
      widthModal={1250}
      isShowLineBottom={false}
      isDisableSubmit={isDisableSubmit}
      isLoadingConfirm={isLoadingSubmit}
      buttonOpenModal={
        <BaseButton
          icon={<EditOutlined rev={undefined} />}
          onClick={() => modalRef?.current?.open?.()}
        >
          {'Cập nhật hoạt động'}
        </BaseButton>
      }
      renderContent={
        <BaseForm>
          <BaseSpace>
            <ActionEvent />
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}

export default ContentLayout
