import R from 'app/assets/R'
import { IGroupReport } from 'app/components/common/BaseLayoutReport/type'
import { REPORT_EVENT_BY_STAGES_PATH } from 'app/components/router/route-path'
import { DateUtil } from 'parkway-web-common'

export const data: IGroupReport[] = [
  {
    id: 1,
    titleI18nKey: R.strings.report,
    reports: [
      {
        id: 11,
        label: '001',
        descriptionI18nKey: R.strings.event_reporting_by_stage,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_EVENT_BY_STAGES_PATH,
      },
    ],
  },
]
