import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import React, { useEffect, useMemo } from 'react'
import { useHook } from './hook'
import { CreateModalLayout, FilterLayout } from './layouts'
import * as S from './styles'
import { useVerifyPermission } from 'app/helpers/permission.helper'
import { PermissionEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'

const RoleManagementContainer: React.FC = () => {
  const {
    columns,
    dataTable,
    handleTableChange,
    isLoading,
    pagination,
    filter,
    onChangeFilter,
    downloadExcel,
  } = useHook()

  const { user, verifyPermissionAndRedirect, verifyPermission } =
    useVerifyPermission()

  useEffect(() => {
    verifyPermissionAndRedirect(PermissionEnum.ReadRole)
  }, [user])

  const isCreateRole = useMemo(() => {
    return verifyPermission(PermissionEnum.CreateRole)
  }, [user])

  return (
    <S.ScrollWrapper>
      <HeaderPage
        titleI18nKey={R.strings.role_management}
        rightComponent={
          isCreateRole ? (
            <BaseCol>
              <CreateModalLayout />
            </BaseCol>
          ) : null
        }
        isExport={verifyPermission(PermissionEnum.ReadRole)}
        onPressExport={downloadExcel}
      />

      <SectionBase>
        <BaseSpace>
          <FilterLayout filter={filter} onChangeFilter={onChangeFilter} />
          <BaseTableManagement
            pagination={pagination}
            columns={columns}
            dataSource={dataTable}
            onChange={handleTableChange}
            loading={isLoading}
          />
        </BaseSpace>
      </SectionBase>
    </S.ScrollWrapper>
  )
}

export default RoleManagementContainer
