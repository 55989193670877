import { message } from 'antd'
import {
  requestCreateRewardTum,
  requestDeleteRewardTum,
  requestGetRewardTumByTumId,
} from 'app/api/tradeUnionMembers'
import { IRewardTum, ITradeUnionMember } from 'app/api/tradeUnionMembers/model'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePopconfirm } from 'app/components/common/BasePopconfirm'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { _DEV_ } from 'app/constant'
import { Dayjs } from 'dayjs'
import { FORMAT_ONLY_YEAR, ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useRef, useState } from 'react'

interface IDataTable extends IRewardTum {
  key: number
}

export interface IFormCreateRewardTum {
  name: string
  year: Dayjs
  description?: string
  link: string
}

export const OtherRewardLayout = ({ tum }: { tum?: ITradeUnionMember }) => {
  const [form] = BaseForm.useForm<IFormCreateRewardTum>()
  const modalCreateRef = useRef<IRefModal>(null)
  const [data, setData] = useState<IRewardTum[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getData = async () => {
    if (!tum?.id) {
      return
    }

    setIsLoading(true)
    try {
      const res: ResponseType<IRewardTum[]> = await requestGetRewardTumByTumId({
        id: tum?.id,
      })

      setData(res?.data ?? [])
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [tum?.id])

  const dataSource = useMemo(() => {
    return data?.map((item, index) => {
      return {
        key: index,
        ...item,
      }
    })
  }, [data])

  const handleDelete = async (id?: number) => {
    if (!id) {
      return
    }

    setIsLoading(true)
    try {
      const res = await requestDeleteRewardTum({ id: id?.toString() })

      if (res) {
        getData()
        message.success('Xóa giải thưởng thành công')
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCreateReward = async (values: IFormCreateRewardTum) => {
    if (!tum?.id) {
      return
    }

    setIsLoading(true)
    try {
      // do something
      const res = await requestCreateRewardTum({
        link: values?.link,
        year: values?.year?.format(FORMAT_ONLY_YEAR),
        name: values?.name,
        description: values?.description,
        trade_union_member_id: tum?.id,
      })

      if (res) {
        getData()
        message.success('Thêm giải thưởng thành công')
        modalCreateRef.current?.hide?.()
        form?.resetFields?.()
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  const openModalCreate = () => {
    modalCreateRef.current?.open?.()
  }

  return (
    <BaseSpace>
      <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
        <BaseCol>
          <BaseText
            children={'Giải thưởng khác'}
            fontWeight="semibold"
            fontSize="xs"
          />
        </BaseCol>

        <BaseCol>
          <BaseModalComponent
            titleModal="Thêm giải thưởng"
            buttonOpenModal={
              <BaseButton
                onClick={openModalCreate}
                children={'Thêm giải thưởng'}
              />
            }
            handleSubmit={form?.submit}
            ref={modalCreateRef}
            renderContent={
              <BaseForm form={form} onFinish={handleCreateReward}>
                <BaseSpace size={0}>
                  <BaseForm.Item
                    required
                    label={'Tên giải'}
                    name={'name'}
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập tên giải thưởng',
                      },
                    ]}
                  >
                    <BaseInput placeholder="Nhập tên giải" />
                  </BaseForm.Item>

                  <BaseForm.Item label={'Mô tả'} name={'description'}>
                    <BaseInput placeholder="Nhập mô tả" />
                  </BaseForm.Item>

                  <BaseForm.Item
                    required
                    label={'Năm đạt giải'}
                    name={'year'}
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng chọn năm đạt giải',
                      },
                    ]}
                  >
                    <BaseDatePicker
                      picker="year"
                      placeholder="Chọn năm đạt giải"
                      format={FORMAT_ONLY_YEAR}
                      style={{
                        width: '100%',
                      }}
                    />
                  </BaseForm.Item>

                  <BaseForm.Item
                    label={'Link chứng từ giải thưởng'}
                    name={'link'}
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập link chứng từ giải thưởng',
                      },
                    ]}
                  >
                    <BaseInput placeholder="Nhập chứng từ giải thưởng" />
                  </BaseForm.Item>
                  <BaseText
                    children={
                      'Lưu ý: Hiện tại do hạ tầng hệ thống đang được cập nhật, vui lòng chụp ảnh chứng từ giải thưởng và upload lên hệ thống sau.'
                    }
                    colorText="statesOrangeColor"
                    style={{
                      fontStyle: 'italic',
                    }}
                  />
                </BaseSpace>
              </BaseForm>
            }
          />
        </BaseCol>
      </BaseRow>

      <BaseTableManagement
        dataSource={dataSource}
        loading={isLoading}
        columns={[
          convertColumnTable<IDataTable>({
            key: 'key',
            title: 'STT',
            fixed: 'left',
            classNameWidthColumnOverwrite: 'number-column',
            render: text => {
              return (
                <BaseText children={text} fontWeight="medium" opacity="0.7" />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            key: 'name',
            title: 'Tên giải',
            fixed: 'left',
            classNameWidthColumnOverwrite: 'very-big-column',
            render: text => {
              return <BaseText children={text} fontWeight="semibold" />
            },
          }),
          convertColumnTable<IDataTable>({
            key: 'description',
            title: 'Mô tả',
            render: text => {
              return (
                <BaseText children={text} fontWeight="medium" opacity="0.7" />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            key: 'year',
            title: 'Năm đạt giải',
            render: text => {
              return (
                <BaseText children={text} fontWeight="medium" opacity="0.7" />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            key: 'link',
            title: 'Tài liệu đính kèm',
            classNameWidthColumnOverwrite: 'very-big-column',
            render: text => {
              return (
                <RenderValueTable
                  value={text}
                  type="Link"
                  onClick={() => window.open(text)}
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            key: 'key',
            title: '',
            classNameWidthColumnOverwrite: 'small-column',
            fixed: 'right',
            render: (_, record) => {
              return (
                <BasePopconfirm
                  title="Bạn có thực sự muốn xoá giải thưởng này?"
                  children={
                    <BaseButton
                      onClick={() => handleDelete(record?.id)}
                      icon={<SvgDeleteDesignIcon />}
                      type="text"
                    />
                  }
                />
              )
            },
          }),
        ]}
        className="row-overwrite-style"
        scroll={{ x: true }}
        pagination={{
          pageSize: 5,
        }}
      />
    </BaseSpace>
  )
}
