/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlusOutlined } from '@ant-design/icons'
import {
  requestGetMemberByActivityId,
  requestPushRewardTum,
} from 'app/api/event'
import { IMemberActionEvent, IPrizeActionEvent } from 'app/api/event/model'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { RewardTypeEnum } from 'app/containers/EventManagement/layouts/CreateEvent/type'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

interface IProps {
  data?: IMemberActionEvent[]
  refetch?: () => void
  actionId?: number
  reward?: IPrizeActionEvent
}

export const ModalSelectTum = ({ data, actionId, reward, refetch }: IProps) => {
  const modalRef = useRef<IRefModal>({})
  const [dataSelected, setDataSelected] = useState<IMemberActionEvent[]>([])
  const [search, setSearch] = useState<string>('')
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [dataApi, setDataApi] = useState<IMemberActionEvent[]>([])

  const getDataApi = async () => {
    if (!actionId) return
    try {
      const res: ResponseType<IMemberActionEvent[]> =
        await requestGetMemberByActivityId(actionId)

      setDataApi(res?.data ?? [])
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  console.log({ dataApi, actionId })

  useEffect(() => {
    getDataApi()
  }, [actionId])

  const onSelected = (record: IMemberActionEvent) => {
    if (isEqual(reward?.type, RewardTypeEnum.SINGLE)) {
      const isSelected = dataSelected.some(
        item => item?.tum?.id === record?.tum?.id,
      )

      if (isSelected) {
        setDataSelected([])
      } else {
        setDataSelected([record])
      }

      return
    }

    const isSelected = dataSelected.some(
      item => item?.tum?.id === record?.tum?.id,
    )

    if (isSelected) {
      setDataSelected(
        dataSelected.filter(item => item?.tum?.id !== record?.tum?.id),
      )
    } else {
      setDataSelected([...dataSelected, record])
    }
  }

  const openModal = () => {
    modalRef?.current?.open?.()
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setDataSelected(data ?? [])
    }, 1000)

    return () => clearTimeout(timer)
  }, [data])

  const dataTable = useMemo(() => {
    return dataApi?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
        index: index + 1,
      }
    })
  }, [search, dataApi])

  const onSubmit = async (data: IMemberActionEvent[]) => {
    setIsLoading(true)
    try {
      await requestPushRewardTum({
        rewardId: reward?.id,
        tumActivityIds: data?.map(item => item?.id),
      })

      // refresh data
      refetch?.()
      modalRef?.current?.hide?.()
      setDataSelected([])
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = () => {
    onSubmit?.(dataSelected)
  }

  return (
    <BaseModalComponent
      titleModal={'Danh sách đoàn viên tham gia'}
      ref={modalRef}
      buttonOpenModal={
        <BaseButton
          icon={data?.length ? null : <PlusOutlined rev={undefined} />}
          onClick={openModal}
        >
          {'Thay đổi danh sách giải thưởng'}
        </BaseButton>
      }
      widthModal={800}
      handleSubmit={handleSubmit}
      isLoadingConfirm={isLoadingSubmit}
      renderContent={
        <BaseSpace>
          <BaseFormItem label={'Tìm kiếm'}>
            <BaseInput
              value={search}
              onChange={e => setSearch(e?.target?.value)}
              placeholder="Tìm kiếm theo tên, email"
            />
          </BaseFormItem>

          <BaseText children={'Danh sách'} fontWeight="medium" fontSize="xs" />
          <BaseTableManagement
            loading={isLoading}
            columns={[
              convertColumnTable<IMemberActionEvent>({
                key: 'tum',
                title: 'Họ và tên',
                render: (_, record) => {
                  return (
                    <BaseText
                      children={`${record?.tum?.fullName} ${
                        record?.tum?.is_active ? '' : '(Dừng công tác)'
                      }`}
                      fontWeight="medium"
                      colorText={
                        record?.tum?.is_active
                          ? 'primaryColor'
                          : 'statesRedColor'
                      }
                    />
                  )
                },
              }),
              convertColumnTable<IMemberActionEvent>({
                key: 'tum',
                title: 'Email',
                render: (_, record) => {
                  return (
                    <BaseText
                      children={record?.tum?.email}
                      fontWeight="medium"
                      colorText={
                        record?.tum?.is_active
                          ? 'primaryColor'
                          : 'statesRedColor'
                      }
                    />
                  )
                },
              }),
              convertColumnTable<IMemberActionEvent>({
                key: 'tum',
                title: 'Mã quản lý',
                render: (_, record) => {
                  return (
                    <BaseText
                      children={record?.tum?.mql}
                      fontWeight="medium"
                      colorText={
                        record?.tum?.is_active
                          ? 'primaryColor'
                          : 'statesRedColor'
                      }
                    />
                  )
                },
              }),
              
              {
                key: 'id',
                title: '',
                width: '10%',
                dataIndex: 'id',
                render: (_, record: IMemberActionEvent) => {
                  const isSelected = dataSelected.some(
                    item => item?.tum?.id === record.tum?.id,
                  )
                  return (
                    <BaseCheckbox
                      value={isSelected}
                      checked={isSelected}
                      onClick={() => onSelected(record)}
                      disabled={!record?.tum?.is_active}
                    />
                  )
                },
              },
            ]}
            dataSource={dataTable}
            className="row-overwrite-style"
            scroll={{ x: true }}
            pagination={{
              pageSize: 5,
            }}
          />
        </BaseSpace>
      }
    />
  )
}

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`
